<script>
    export let inputId = null;
	export let checked = false;
    export let disabled = false;
</script>

<label class="toggle">
	<input id={inputId || ''} class="toggle-input" type="checkbox" bind:checked on:change disabled={disabled}>
	<span class="toggle-track">
		<span class="toggle-switch raised"></span>
	</span>
</label>

<style>
.toggle {
    position: relative;
}
.toggle-input {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
    width: 1px;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: absolute;
    left: 0;
    top: 0;
    z-index: -99999;
	white-space: nowrap;
    visibility: hidden;
}

.toggle-track, .toggle-switch {
	display: inline-block;
	vertical-align: text-bottom;
}

.toggle-track {
    cursor: pointer;
	position: relative;
	/* top: -0.125em; */
	width: 30px;
	height: 18px;
	background-color: var(--slate-o20);
	/* border: 0.0625em solid rgba(0, 0, 0, 0.15); */
	border-radius: 24em;
	transition: 150ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
	transition-property: background-color, border-color;
    transform: translateZ(0);
}

.toggle .toggle-input:checked ~ .toggle-track {
	background-color: var(--blue);
	/* border-color: rgba(0, 0, 0, 0.05); */
}

.toggle-switch {
	position: absolute;
	top: 2px;
	right: 14px;
	bottom: 2px;
	left: 2px;
	background-color: white;
	border-radius: 24em;
	/* box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2), inset 1px 1px 3px rgba(255, 255, 255, 0.8); */
	transition: 150ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
	transition-property: left, right;
	transition-delay: 0s, 40ms;
    transform: translateZ(0);
}

.toggle:active .toggle-switch {
	/* box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2), inset 1px 1px 3px rgba(255, 255, 255, 0.8), inset 1px 1px 6px rgba(0, 0, 0, 0.1); */
}

.toggle .toggle-input:checked ~ .toggle-track .toggle-switch {
	right: 2px;
	left: 14px;
	transition-property: left, right;
	transition-delay: 40ms, 0s;
}

.toggle .toggle-input:disabled ~ .toggle-track .toggle-switch {
    /* background-color: #ccc; */
    opacity: 0.5;
}
</style>
