<script context="module">
    import gql from 'graphql-tag';
    import {FRAGMENTS as EditFormSpec_FRAGMENTS} from '../../../components/EditFormSpec.svelte';
    import {FRAGMENTS as MessageTemplateConnection_FRAGMENTS} from '../../../components/MessageTemplateConnection.svelte';

    const FRAGMENTS = {};
    FRAGMENTS.EditForm_contactField = gql`
        fragment EditForm_contactField on ContactField {
            ref
            data {
                name
            }
        }
    `;
    FRAGMENTS.EditForm_formField = gql`
        fragment EditForm_formField on FormField {
            ref
            data {
                apiName
                contactField {
                    ...EditForm_contactField
                }
            }
        }
        ${FRAGMENTS.EditForm_contactField}
    `;
    FRAGMENTS.EditForm_formSpec = gql`
        fragment EditForm_formSpec on FormSpec {
            ref
            data {
                testSubmissionJson
                fields {
                    ...EditForm_formField
                }
            }
        }
        ${FRAGMENTS.EditForm_formField}
    `;
    FRAGMENTS.EditForm_sequence = gql`
        fragment EditForm_sequence on Sequence {
            ref
            data {
                name
                isDeleted
            }
        }
    `;
    FRAGMENTS.EditForm_contactStatus = gql`
        fragment EditForm_contactStatus on ContactStatus {
            ref
            data {
                name
            }
        }
    `;
    FRAGMENTS.EditForm_form = gql`
        fragment EditForm_form on Form {
            ref
            data {
                name
                title
                isHosted
                isDeleted
                deletedAt
                willCreateDashboardNotification
                willSendEmailNotification
                spec {
                    ...EditForm_formSpec
                }
                draftSpec {
                    ...EditFormSpec_formSpec
                }
                willSendMessage {
                    ref
                    data {
                        ...MessageTemplateConnection_messageTemplate
                    }
                }
                mightAddSequence {
                    ...EditForm_sequence
                }
                mightSetContactStatus {
                    ...EditForm_contactStatus
                }
                org {
                    ref
                    data {
                        logoHandle
                        ...MessageTemplateConnection_org
                        sequences {
                            ...EditForm_sequence
                        }
                        contactStatuses {
                            ...EditForm_contactStatus
                        }
                        smsLocal {
                            number
                        }
                        smsTollFree {
                            number
                        }
                    }
                }
            }
        }
        ${FRAGMENTS.EditForm_formSpec}
        ${FRAGMENTS.EditForm_sequence}
        ${FRAGMENTS.EditForm_contactStatus}
        ${EditFormSpec_FRAGMENTS.EditFormSpec_formSpec}
        ${MessageTemplateConnection_FRAGMENTS.MessageTemplateConnection_messageTemplate}
        ${MessageTemplateConnection_FRAGMENTS.MessageTemplateConnection_org}
    `;

    const QUERIES = {};
    QUERIES.form = gql`
        query form($formId: ID!) {
            findFormByID(id: $formId) {
                ...EditForm_form
            }
        }
        ${FRAGMENTS.EditForm_form}
    `;
    QUERIES.whoami = gql`
        query getUserByOrg($orgId: ID!) {
            getUserByOrg(orgId: $orgId) {
                ref
                data {
                    email
                }
            }
        }
    `;

    const MUTATIONS = {};
    MUTATIONS.updateForm = gql`
        mutation updateForm($id: ID!, $data: PartialUpdateFormInput!) {
            partialUpdateForm(id: $id, data: $data) {
                ...EditForm_form
            }
        }
        ${FRAGMENTS.EditForm_form}
    `;
    MUTATIONS.updateMessageTemplateStatusOfForm = gql`
        mutation updateMessageTemplateStatusOfForm(
            $formId: ID!
            $messageTemplateId: ID
            $status: String!
        ) {
            updateMessageTemplateStatusOfForm(
                formId: $formId
                messageTemplateId: $messageTemplateId
                status: $status
            ) {
                ...EditForm_form
            }
        }
        ${FRAGMENTS.EditForm_form}
    `;
    MUTATIONS.updateSequenceOfForm = gql`
        mutation updateSequenceOfForm(
            $formId: ID!
            $sequenceId: ID
            $status: Boolean!
        ) {
            updateSequenceOfForm(
                formId: $formId
                sequenceId: $sequenceId
                status: $status
            ) {
                ...EditForm_form
            }
        }
        ${FRAGMENTS.EditForm_form}
    `;
    MUTATIONS.updateContactStatusOfForm = gql`
        mutation updateContactStatusOfForm(
            $formId: ID!
            $contactStatusId: ID
            $status: Boolean!
        ) {
            updateContactStatusOfForm(
                formId: $formId
                contactStatusId: $contactStatusId
                status: $status
            ) {
                ...EditForm_form
            }
        }
        ${FRAGMENTS.EditForm_form}
    `;
    MUTATIONS.createNewFormDraft = gql`
        mutation createNewFormDraft($formId: ID!) {
            createNewFormDraft(formId: $formId) {
                ...EditForm_form
            }
        }
        ${FRAGMENTS.EditForm_form}
    `;
    MUTATIONS.publishFormDraft = gql`
        mutation publishFormDraft($formId: ID!, $formSpecId: ID!) {
            publishFormDraft(formId: $formId, formSpecId: $formSpecId) {
                ...EditForm_form
            }
        }
        ${FRAGMENTS.EditForm_form}
    `;
    MUTATIONS.deleteFormField = gql`
        mutation deleteFormField($id: ID!) {
            deleteFormField(id: $id) {
                ref
            }
        }
    `;
    MUTATIONS.deleteFormSpec = gql`
        mutation deleteFormSpec($formId: ID!, $formSpecId: ID!) {
            deleteFormSpec(formId: $formId, formSpecId: $formSpecId) {
                ...EditForm_form
            }
        }
        ${FRAGMENTS.EditForm_form}
    `;
    MUTATIONS.retrieveForm = gql`
        mutation retrieveForm($formId: ID!) {
            retrieveForm(formId: $formId) {
                ref
            }
        }
    `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getClient, mutation} from 'svelte-apollo';
    import _debounce from 'lodash/debounce';
    import _get from 'lodash/get';
    import _has from 'lodash/has';
    import _find from 'lodash/find';
    import _map from 'lodash/map';
    import _keys from 'lodash/keys';
    import _forEach from 'lodash/forEach';
    import _trim from 'lodash/trim';
    import {fly} from 'svelte/transition';
    import {Router, Route, navigate} from 'svelte-routing';
    import Select from 'svelte-select';
    import NotFound from '../../NotFound.svelte';
    import {watchQuery} from '../../../svelte-apollo-watch-query';
    import HtmlHeadTitle from '../../../components/HtmlHeadTitle.svelte';
    import OrgPageLayout from '../../../components/OrgPageLayout.svelte';
    import EditFormSpec from '../../../components/EditFormSpec.svelte';
    import Toggle from '../../../components/Toggle.svelte';
    import MessageTemplateConnection from '../../../components/MessageTemplateConnection.svelte';
    import MutationInput from '../../../components/MutationInput.svelte';
    import {selectOnClick} from '../../../actions/selectOnClick.js';
    import {autoheight} from '../../../actions/autoheight.js';
    import {getFormEndpoint} from '../../../formEndpoints';
    import LogoPreview from '../../../components/LogoPreview.svelte';

    export let orgId;
    export let formId;
    export let location;

    const client = getClient();
    const updateFormMutation = mutation(MUTATIONS.updateForm);
    const deleteFormFieldMutation = mutation(MUTATIONS.deleteFormField);
    const deleteFormSpecMutation = mutation(MUTATIONS.deleteFormSpec);
    const publishFormDraftMutation = mutation(MUTATIONS.publishFormDraft);
    const createNewFormDraftMutation = mutation(MUTATIONS.createNewFormDraft);
    const retrieveFormMutation = mutation(MUTATIONS.retrieveForm);
    const updateMessageTemplateStatusOfFormMutation = mutation(
        MUTATIONS.updateMessageTemplateStatusOfForm,
    );
    const updateSequenceOfFormMutation = mutation(
        MUTATIONS.updateSequenceOfForm,
    );
    const updateContactStatusOfFormMutation = mutation(
        MUTATIONS.updateContactStatusOfForm,
    );
    const orgPath = `/orgs/${orgId}`;
    const orgSettingsPath = `${orgPath}/settings`;
    const formPath = `/orgs/${orgId}/forms/${formId}`;

    let whoami;
    client
        .query({query: QUERIES.whoami, variables: {orgId: orgId}})
        .then((result) => {
            whoami = result.data.getUserByOrg;
        });

    const formQuery = watchQuery(client, {
        query: QUERIES.form,
        variables: {formId: formId},
        pollInterval: undefined,
    });
    $: formData = _get($formQuery, 'data.findFormByID') || {};
    $: orgSequences = _get(formData, 'data.org.data.sequences', []);
    $: orgContactStatuses = _get(formData, 'data.org.data.contactStatuses', []);

    $: isFormLoaded = !!_get(formData, 'ref');
    $: isHostedForm = !!_get(formData, 'data.isHosted');
    $: isDeleted = !!_get(formData, 'data.isDeleted');
    $: pageTitle = isDeleted
        ? 'Deleted Form'
        : isFormLoaded
          ? isHostedForm
              ? 'Edit Hosted Form'
              : 'Edit Webhook Form'
          : 'Edit Form';

    $: hostedFormUrl = _get(formData, 'ref')
        ? `${location.origin}/hosted/${btoa(_get(formData, 'ref'))}`
        : 'loading...';
    $: formEndpoint = _get(formData, 'ref')
        ? getFormEndpoint(_get(formData, 'ref'))
        : 'loading...';
    $: hasSpec = !!_get(formData, 'data.spec.ref');
    $: hasDraft = !!_get(formData, 'data.draftSpec.ref');

    let nameEntry = '';
    let titleEntry = '';
    let willCreateNotificationEntry = true;
    let willSendEmailNotificationEntry = true;
    let willSendMessageEntry = false;

    // Init form data when it loads (from the network).
    let hasInitName = false;
    let hasInitTitle = false;
    let hasInitDashboard = false;
    let hasInitEmailNotification = false;
    let hasInitWillSendMessage = false;
    function initFormFields() {
        if (!_get(formData, 'ref')) return;
        if (_has(formData, 'data.name') && !hasInitName) {
            nameEntry = formData.data.name;
            hasInitName = true;
        }
        if (_has(formData, 'data.title') && !hasInitTitle) {
            titleEntry = formData.data.title;
            hasInitTitle = true;
        }
        if (
            _has(formData, 'data.willCreateDashboardNotification') &&
            !hasInitDashboard
        ) {
            willCreateNotificationEntry =
                formData.data.willCreateDashboardNotification;
            hasInitDashboard = true;
        }
        if (
            _has(formData, 'data.willSendEmailNotification') &&
            !hasInitEmailNotification
        ) {
            willSendEmailNotificationEntry =
                formData.data.willSendEmailNotification;
            hasInitEmailNotification = true;
        }
        if (_has(formData, 'data.willSendMessage') && !hasInitWillSendMessage) {
            willSendMessageEntry = !!formData.data.willSendMessage;
            hasInitWillSendMessage = true;
        }
    }
    $: formData, initFormFields();

    $: titleChanging = false;
    let nameSaving = null;
    let titleSaving = null;
    let dashboardSaving = null;
    let emailNotificationSaving = null;

    $: if (_get(formData, 'data.spec', false) == null) {
        // Redirect to edit draft if the form has never been published.
        navigate(`${formPath}/draft`, {replace: true});
    } else if (_get(formData, 'data.draftSpec', false) == null) {
        // Redirect away from editing draft if the draft is removed (by deleting or publishing).
        navigate(formPath, {replace: true});
    }

    const saveFormDebounced = _debounce(saveForm, 1000);
    $: nameEntry, titleEntry, saveFormDebounced();
    $: willCreateNotificationEntry, willSendEmailNotificationEntry, saveForm();

    $: if (
        hasInitWillSendMessage &&
        !willSendMessageEntry &&
        _get(formData, 'data.willSendMessage')
    ) {
        handleDisconnectWillSendMessage(_get(formData, 'willSendMessage.ref'));
    }

    let savingData;
    async function saveForm() {
        if (!formData || Object.keys(formData).length === 0) {
            return;
        }
        let updates = {};
        savingData = updates;
        let optimisticName =
            nameSaving === null ? formData.data.name : nameSaving;
        let nameDirty = hasInitName && nameEntry != optimisticName;
        if (nameDirty) updates.name = nameEntry;
        nameSaving = nameDirty ? nameEntry : null;

        let optimisticTitle =
            titleSaving === null ? formData.data.title : titleSaving;
        let titleDirty = hasInitTitle && titleEntry != optimisticTitle;
        if (titleDirty) updates.title = titleEntry;
        titleSaving = titleDirty ? titleEntry : null;

        let optimisticDashboard =
            dashboardSaving === null
                ? formData.data.willCreateDashboardNotification
                : dashboardSaving;
        let dashboardDirty =
            hasInitDashboard &&
            willCreateNotificationEntry != optimisticDashboard;
        if (dashboardDirty)
            updates.willCreateDashboardNotification =
                willCreateNotificationEntry;
        dashboardSaving = dashboardDirty ? willCreateNotificationEntry : null;

        let optimisticEmailNotification =
            emailNotificationSaving === null
                ? formData.data.willSendEmailNotification
                : emailNotificationSaving;
        let emailNotificationDirty =
            hasInitEmailNotification &&
            willSendEmailNotificationEntry != optimisticEmailNotification;
        if (emailNotificationDirty)
            updates.willSendEmailNotification = willSendEmailNotificationEntry;
        emailNotificationSaving = emailNotificationDirty
            ? willSendEmailNotificationEntry
            : null;

        let maybeClearSavingState = () => {
            titleChanging = false;
            if (savingData === updates) {
                titleSaving = null;
                nameSaving = null;
                dashboardSaving = null;
                emailNotificationSaving = null;
                savingData = null;
            }
        };

        if (!_keys(updates).length) {
            maybeClearSavingState();
            return;
        }

        await updateForm(_get(formData, 'ref'), updates);

        maybeClearSavingState();
        // dispatch('save');

        updateHostedFormPreview();
    }

    function updateHostedFormPreview() {
        _forEach(window.frames, (frame) => {
            frame.refreshHostedFormData && frame.refreshHostedFormData();
        });
    }

    async function updateForm(formId, data) {
        try {
            // let response = await mutate(client, {
            //     mutation: MUTATIONS.updateForm,
            //     variables: {
            //         id: formId,
            //         data: data,
            //     },
            // });
            let response = await updateFormMutation({
                variables: {
                    id: formId,
                    data: data,
                },
            });
            formData.data = response.data.partialUpdateForm.data;
        } catch (error) {
            // FIXME handle errors better...
            alert(error.message);
        }
    }
    let isPublishingDraft = false;
    async function publishFormDraft() {
        if (isPublishingDraft) return;
        isPublishingDraft = true;
        try {
            // let response = await mutate(client, {
            //     mutation: MUTATIONS.publishFormDraft,
            //     variables: {
            //         formId: formId,
            //         formSpecId: formData.data.draftSpec.ref,
            //     },
            // });
            let tempFormData = {...formData};
            let response = await publishFormDraftMutation({
                variables: {
                    formId: formId,
                    formSpecId: formData.data.draftSpec.ref,
                },
            });

            tempFormData.data = response.data.publishFormDraft.data;
            formData = tempFormData;
            // Then delete the (now outdated) form spec.
            // ATTENTION Do NOT delete FormFields or FormSpecs if they've ever been published!
            // A FormSubmissionField references a FormSpec, so we need to keep them forever.
            // specToDelete && await deleteFormSpec(specToDelete);
        } catch (error) {
            // FIXME handle errors better...
            alert(error.message);
        }
        isPublishingDraft = false;
    }
    async function deleteFormSpec(spec) {
        try {
            await Promise.all([
                ..._map(_get(spec, 'data.fields', []), (formField) =>
                    // mutate(client, {
                    //     mutation: MUTATIONS.deleteFormField,
                    //     variables: {id: formField.ref},
                    // }),
                    deleteFormFieldMutation({
                        mutation: MUTATIONS.deleteFormField,
                        variables: {id: formField.ref},
                    }),
                ),
            ]);
            // const result = await mutate(client, {
            //     mutation: MUTATIONS.deleteFormSpec,
            //     variables: {formId: formId, formSpecId: spec.ref},
            // });
            const result = await deleteFormSpecMutation({
                variables: {formId: formId, formSpecId: spec.ref},
            });
            let tempFormData = {...formData};
            tempFormData.data = result.data.deleteFormSpec.data;
            formData = tempFormData;
            // FIXME Need cache.evict() and cache.gc() (coming in apollo-client v3)
            await $formQuery.observableQuery.refetch();
        } catch (error) {
            // FIXME handle errors better...
            alert(error.message);
        }
    }

    async function handleNewDraft() {
        if (hasDraft) {
            alert('Cannot overwrite existing draft.');
            return;
        }

        try {
            // const result = await mutate(client, {
            //     mutation: MUTATIONS.createNewFormDraft,
            //     variables: {
            //         formId: formId,
            //     },
            // });
            const result = await createNewFormDraftMutation({
                variables: {
                    formId: formId,
                },
            });
            let tempFormData = {...formData};
            tempFormData.data = result.data.createNewFormDraft.data;
            formData = tempFormData;
        } catch (error) {
            console.error(error);
        }
        navigate(`${formPath}/draft`);
    }
    let handlePublishDraft = () =>
        confirm('Confirm that you want to Publish:') && publishFormDraft();
    let isDeletingDraft = false;
    async function handleDeleteDraft() {
        if (isDeletingDraft) return;
        isDeletingDraft = true;
        // You can't delete the draft if there isn't a "live" webhook.
        if (!formData.data.spec) {
            alert(
                'Could not delete draft since it is a new form that has never been published.',
            );
            return;
        }
        if (!confirm('Confirm that you want to delete this draft:')) {
            return;
        }
        let formSpecToDelete = formData.data.draftSpec;
        // await updateForm(formId, {
        //     draftSpec: {disconnect: true},
        // });
        await deleteFormSpec(formSpecToDelete);
        isDeletingDraft = false;
    }

    let isSavingWillSendMessage = false;
    async function handleCreateWillSendMessage() {
        if (isSavingWillSendMessage) return;
        isSavingWillSendMessage = true;

        // const result = await mutate(client, {
        //     mutation: MUTATIONS.updateMessageTemplateStatusOfForm,
        //     variables: {
        //         formId: formId,
        //         messageTemplateId: null,
        //         status: 'create',
        //     },
        // });
        const result = await updateMessageTemplateStatusOfFormMutation({
            variables: {
                formId: formId,
                messageTemplateId: null,
                status: 'create',
            },
        });
        formData.data = result.data.updateMessageTemplateStatusOfForm.data;
        isSavingWillSendMessage = false;
        updateHostedFormPreview();
    }
    async function handleConnectWillSendMessage(messageTemplateId) {
        if (isSavingWillSendMessage) return;
        isSavingWillSendMessage = true;

        // const result = await mutate(client, {
        //     mutation: MUTATIONS.updateMessageTemplateStatusOfForm,
        //     variables: {
        //         formId: formId,
        //         messageTemplateId: messageTemplateId,
        //         status: 'connect',
        //     },
        // });
        const result = await updateMessageTemplateStatusOfFormMutation({
            variables: {
                formId: formId,
                messageTemplateId: messageTemplateId,
                status: 'connect',
            },
        });
        formData.data = result.data.updateMessageTemplateStatusOfForm.data;
        isSavingWillSendMessage = false;
        updateHostedFormPreview();
    }
    async function handleDisconnectWillSendMessage(messageTemplateId) {
        if (isSavingWillSendMessage) return;
        isSavingWillSendMessage = true;

        // const result = await mutate(client, {
        //     mutation: MUTATIONS.updateMessageTemplateStatusOfForm,
        //     variables: {
        //         formId: formId,
        //         messageTemplateId: messageTemplateId,
        //         status: 'disconnect',
        //     },
        // });
        const result = await updateMessageTemplateStatusOfFormMutation({
            variables: {
                formId: formId,
                messageTemplateId: messageTemplateId,
                status: 'disconnect',
            },
        });
        formData.data = result.data.updateMessageTemplateStatusOfForm.data;
        isSavingWillSendMessage = false;
        updateHostedFormPreview();
    }

    let savingFormSequence = false;
    async function updateFormSequence(sequence) {
        if (savingFormSequence) return;
        if (
            _get(sequence, 'ref') == _get(formData, 'data.mightAddSequence.ref')
        )
            return;
        savingFormSequence = true;

        // const result = await mutate(client, {
        //     mutation: MUTATIONS.updateSequenceOfForm,
        //     variables: {
        //         formId: formId,
        //         sequenceId: sequence ? sequence.ref : null,
        //         status: sequence ? true : false,
        //     },
        // });
        const result = await updateSequenceOfFormMutation({
            variables: {
                formId: formId,
                sequenceId: sequence ? sequence.ref : null,
                status: sequence ? true : false,
            },
        });
        let tempFormData = {...formData};
        tempFormData.data = result.data.updateSequenceOfForm.data;
        formData = tempFormData;
        savingFormSequence = false;
        updateHostedFormPreview();
    }

    let savingFormContactStatus = false;
    async function updateFormContactStatus(contactStatus) {
        if (savingFormContactStatus) return;
        if (
            _get(contactStatus, 'ref') ==
            _get(formData, 'data.mightSetContactStatus.ref')
        )
            return;
        savingFormContactStatus = true;

        // const result = await mutate(client, {
        //     mutation: MUTATIONS.updateContactStatusOfForm,
        //     variables: {
        //         formId: formId,
        //         contactStatusId: contactStatus ? contactStatus.ref : null,
        //         status: contactStatus ? true : false,
        //     },
        // });
        const result = await updateContactStatusOfFormMutation({
            variables: {
                formId: formId,
                contactStatusId: contactStatus ? contactStatus.ref : null,
                status: contactStatus ? true : false,
            },
        });
        let tempFormData = {...formData};
        tempFormData.data = result.data.updateContactStatusOfForm.data;
        formData = tempFormData;
        savingFormContactStatus = false;
    }

    let savingConvertToWebhook = false;
    async function convertFormToWebhook() {
        if (savingConvertToWebhook) return;
        savingConvertToWebhook = true;
        if (
            confirm(
                "Danger: Are you sure you want to convert from a hosted form to a webhook form? You can't undo this action, but you can always create a new form if you change your mind.",
            )
        ) {
            await updateForm(formId, {
                isHosted: false,
            });
        }
        savingConvertToWebhook = false;
    }

    const embedScriptSrc = _trim(__APP_ORIGIN__, '/') + '/embed/magic.js';
    // ATTN: Potentially strange parsing of any occurence of <script> tags within a svelete template?
    const embedScriptTag =
        `[script async defer src="${embedScriptSrc}"][/script]`
            .replace(/\[/g, '<')
            .replace(/\]/g, '>');

    async function handleClickRetrieveButton(formId) {
        try {
            const response = await retrieveFormMutation({
                variables: {
                    formId: formId,
                },
            });
            let tempFormData = formData;
            tempFormData.data.isDeleted = false;
            tempFormData.data.deletedAt = null;
            formData = tempFormData;
        } catch (error) {
            console.log(error);
            alert(error);
        }
    }
</script>

<HtmlHeadTitle {pageTitle} />

<OrgPageLayout {orgId} title={pageTitle}>
    <div class="cols">
        <article class="form-form growcol">
            {#if $formQuery.errors}
                errors: <pre>{JSON.stringify($formQuery.errors, null, 4)}</pre>
            {:else if !isFormLoaded}
                <section class="card">Loading...</section>
            {:else}
                {#if isDeleted}
                    <section class="card">
                        <div class="fieldrow">
                            <p class="deleted-notification">
                                This lead form has bean temporarily deleted and
                                will be permanently deleted soon. <br />To
                                prevent permanent deletion or to make
                                modifications you must first restore the Lead
                                Form.
                            </p>
                        </div>
                        <div class="fieldrow">
                            <div class="deleted-info">
                                <div class="deleted-style">
                                    <div class="status-text">
                                        <ion-icon
                                            name="trash-outline"
                                            class="status-icon"
                                        />
                                        <span>Deleted At</span>
                                    </div>
                                    <span class="date-text"
                                        >{formData.data.deletedAt
                                            ? formData.data.deletedAt.split(
                                                  'T',
                                              )[0]
                                            : ''}</span
                                    >
                                </div>

                                <button
                                    type="button"
                                    title="restore"
                                    on:click|stopPropagation={() =>
                                        handleClickRetrieveButton(formData.ref)}
                                    class="button"
                                >
                                    <!-- class="form-retrieve-button" -->

                                    <!-- disabled={isDeletingForms} -->
                                    <!-- <ion-icon name="refresh-outline" style="position:relative; top:1px;" /> -->
                                    Restore
                                    <ion-icon
                                        name="reload-outline"
                                        style="position:relative; top:1px;"
                                        class="form-retrieve-icon"
                                    />
                                    <!-- <ion-icon name="sync-outline" style="position:relative; top:1px;" /> -->
                                </button>
                            </div>
                        </div>
                    </section>
                {/if}
                {#if isHostedForm}
                    <section class="card">
                        <div class="fieldrow">
                            <label class="fieldlabel form-name" for="formName">
                                Form Name
                            </label>
                            <MutationInput
                                type="text"
                                id="formName"
                                placeholder="Pricing Request Form"
                                bind:value={titleEntry}
                                savingValue={titleSaving}
                                savedValue={_get(
                                    formData,
                                    'data.title',
                                    'No Form Name',
                                )}
                                disabled={isDeleted}
                                style="font-size: 18px; font-weight:600"
                            />
                        </div>
                        <div class="fieldrow">
                            <label class="fieldlabel" for="formTitle">
                                {#if isHostedForm}
                                    Form Heading
                                {:else}
                                    Form Title (not seen by contacts)
                                {/if}
                            </label>
                            <!-- <input bind:value={nameEntry} type="text" id="formName" placeholder="Pricing Form" /> -->
                            <MutationInput
                                type="text"
                                id="formName"
                                placeholder="Where should we send our pricing details?"
                                bind:value={nameEntry}
                                savingValue={nameSaving}
                                savedValue={_get(
                                    formData,
                                    'data.name',
                                    'Untitled Form',
                                )}
                                disabled={isDeleted}
                            />
                        </div>
                        <div class="fieldrow">
                            <div class="fieldlabel">Org Logo</div>
                            <div style="display:flex;">
                                <div style="margin-right:20px;">
                                    <LogoPreview
                                        fileHandle={_get(
                                            formData,
                                            'data.org.data.logoHandle',
                                        )}
                                    />
                                </div>
                                <a href={orgSettingsPath} class="button">
                                    <span class="btn-text">
                                        Edit in Org Settings
                                    </span>
                                    <ion-icon
                                        name="arrow-forward"
                                        class="btn-icon"
                                    />
                                </a>
                            </div>
                        </div>
                    </section>
                    <section class="card">
                        <h1>5-minute Installation</h1>
                        <div class="fieldrow">
                            <label class="fieldlabel" for="hostedFormUrl">
                                Hosted Form URL
                            </label>
                            <p class="fieldhelp">
                                Add a link to your website with this destination
                                ("href"):
                            </p>
                            <input
                                value={hostedFormUrl}
                                readonly
                                use:selectOnClick
                                type="text"
                                id="hostedFormUrl"
                                style="box-shadow:none; font-family:monospace; font-size:14px;"
                            />
                        </div>
                    </section>
                {:else}
                    <section class="card">
                        <div class="fieldrow">
                            <label class="fieldlabel form-name" for="formName">
                                Form Name
                            </label>
                            <MutationInput
                                type="text"
                                id="formName"
                                placeholder="Pricing Request Form"
                                bind:value={titleEntry}
                                savingValue={titleSaving}
                                savedValue={_get(
                                    formData,
                                    'data.title',
                                    'No Form Name',
                                )}
                                disabled={isDeleted}
                                style="font-size: 18px; font-weight:600"
                            />
                        </div>
                        <div class="fieldrow">
                            <label class="fieldlabel" for="formTitle">
                                {#if isHostedForm}
                                    Form Heading
                                {:else}
                                    Form Title (not seen by contacts)
                                {/if}
                            </label>
                            <!-- <input bind:value={nameEntry} type="text" id="formName" placeholder="Pricing Form" /> -->
                            <MutationInput
                                type="text"
                                id="formTitle"
                                placeholder="Where should we send our pricing details?"
                                bind:value={nameEntry}
                                savingValue={nameSaving}
                                savedValue={_get(
                                    formData,
                                    'data.name',
                                    'Untitled Form',
                                )}
                                disabled={isDeleted}
                            />
                        </div>
                        <div class="fieldrow">
                            <label class="fieldlabel" for="formEndpoint">
                                Webhook URL
                            </label>
                            <p class="fieldhelp">
                                Copy & paste this URL and use it as your custom
                                form "action" on your website:
                            </p>
                            <input
                                value={formEndpoint}
                                readonly
                                use:selectOnClick
                                type="text"
                                id="formEndpoint"
                                style="box-shadow:none; font-family:monospace; font-size:14px;"
                            />
                        </div>
                    </section>
                {/if}
                <section class="card">
                    <h1>Actions</h1>
                    <div class="fieldrow" class:muted={dashboardSaving != null}>
                        <label class="fieldlabel">
                            <Toggle
                                bind:checked={willCreateNotificationEntry}
                                disabled={isDeleted}
                            />
                            Notify Me
                            <span class="muted"
                                >every time someone submits this form.</span
                            >
                        </label>
                        {#if willCreateNotificationEntry}
                            <div
                                transition:fly|local={{y: -20, duration: 200}}
                                style="padding: 5px 0 0 20px;"
                            >
                                <label class="fieldlabel">
                                    <span class="muted">
                                        <Toggle
                                            checked={true}
                                            disabled={true}
                                            readonly={true}
                                        />
                                    </span>
                                    Dashboard Notifications
                                </label>
                                <label
                                    class="fieldlabel"
                                    class:muted={emailNotificationSaving !=
                                        null}
                                >
                                    <Toggle
                                        bind:checked={willSendEmailNotificationEntry}
                                        disabled={_get(
                                            formData,
                                            'data.isDeleted',
                                            false,
                                        )}
                                    />
                                    Email Notifications
                                    {#if _get(whoami, 'data.email')}
                                        to <strong
                                            >{_get(
                                                whoami,
                                                'data.email',
                                            )}</strong
                                        >
                                    {/if}
                                </label>
                            </div>
                        {/if}
                    </div>
                    <div class="fieldrow" class:muted={isSavingWillSendMessage}>
                        <label class="fieldlabel">
                            <Toggle
                                bind:checked={willSendMessageEntry}
                                disabled={isDeleted}
                            />
                            Send a message immediately
                            <span class="muted"
                                >every time someone submits this form.</span
                            >
                        </label>
                    </div>
                    {#if willSendMessageEntry}
                        <div
                            transition:fly|local={{y: -20, duration: 200}}
                            style="margin:0 0 20px 40px;"
                        >
                            <MessageTemplateConnection
                                orgData={_get(formData, 'data.org')}
                                messageTemplateData={_get(
                                    formData,
                                    'data.willSendMessage',
                                )}
                                on:create={handleCreateWillSendMessage}
                                on:connect={async (event) =>
                                    await handleConnectWillSendMessage(
                                        event.detail,
                                    )}
                                on:disconnect={async (event) =>
                                    await handleDisconnectWillSendMessage(
                                        event.detail,
                                    )}
                            />
                        </div>
                    {/if}
                    <div class="fieldrow" class:muted={savingFormSequence}>
                        <label class="fieldlabel">
                            Start this Action Sequence <span class="muted"
                                >only when a brand new contact submits this
                                form.</span
                            > (recommended)
                        </label>
                        <Select
                            items={orgSequences.filter(
                                (sequence) => !sequence.data.isDeleted,
                            )}
                            placeholder="None selected."
                            optionIdentifier="ref"
                            selectedValue={_find(orgSequences, {
                                ref: _get(
                                    formData,
                                    'data.mightAddSequence.ref',
                                ),
                            })}
                            getOptionLabel={(option, filterText) =>
                                option.isCreator
                                    ? `Create "${filterText}"`
                                    : option.data.name}
                            getSelectionLabel={(option) => option.data.name}
                            isCreatable={false}
                            isClearable={true}
                            createItem={() => {}}
                            on:select={(event) =>
                                updateFormSequence(event.detail)}
                            on:clear={(event) => updateFormSequence(null)}
                            isDisabled={savingFormSequence || isDeleted}
                            showIndicator={!_get(
                                formData,
                                'data.mightAddSequence.ref',
                            )}
                        />
                    </div>
                    <div class="fieldrow" class:muted={savingFormContactStatus}>
                        <label class="fieldlabel">
                            Set this Contact Status <span class="muted"
                                >only when a brand new contact submits this
                                form.</span
                            > (recommended)
                        </label>
                        <Select
                            items={orgContactStatuses}
                            placeholder="None selected."
                            optionIdentifier="ref"
                            selectedValue={_find(orgContactStatuses, {
                                ref: _get(
                                    formData,
                                    'data.mightSetContactStatus.ref',
                                ),
                            })}
                            getOptionLabel={(option, filterText) =>
                                option.isCreator
                                    ? `Create "${filterText}"`
                                    : option.data.name}
                            getSelectionLabel={(option) => option.data.name}
                            isCreatable={false}
                            isClearable={true}
                            createItem={() => {}}
                            on:select={(event) =>
                                updateFormContactStatus(event.detail)}
                            on:clear={(event) => updateFormContactStatus(null)}
                            isDisabled={savingFormContactStatus || isDeleted}
                            showIndicator={!_get(
                                formData,
                                'data.mightSetContactStatus.ref',
                            )}
                        />
                    </div>
                </section>
                {#if isHostedForm}
                    <section class="card">
                        <h1>Advanced</h1>
                        <div style="padding-top:10px;">
                            <button
                                type="button"
                                on:click={convertFormToWebhook}
                                class="button danger"
                                disabled={savingConvertToWebhook || isDeleted}
                                class:muted={savingConvertToWebhook}
                            >
                                <ion-icon name="radio" class="btn-icon" />
                                <span class="btn-text">
                                    Convert this Form to a Webhook and customize
                                    the fields
                                </span>
                            </button>
                            <p
                                class="muted"
                                style="font-size:11px; margin:0; padding: 5px 0 0;"
                            >
                                For advanced website builders. Design & build a
                                custom form on your website,<br />
                                and point it at our webhook form endpoint. Or send
                                data from 3rd-party services<br />
                                like Typeform or Zapier.
                            </p>
                        </div>
                    </section>
                {:else}
                    <section class="card">
                        <Router>
                            <Route path="/">
                                <header class="cols">
                                    <h1 class="flexcol">
                                        Webhook Field Mapping
                                        <span class="chip">
                                            {#if hasSpec}
                                                <span class="activity online"
                                                ></span>
                                                live
                                            {:else}
                                                <span class="activity offline"
                                                ></span>
                                                not published
                                            {/if}
                                        </span>
                                    </h1>
                                    <div class="fixedcol tools">
                                        <ion-icon
                                            name="lock-closed-outline"
                                            class="muted"
                                            style="margin-right:10px; font-size:20px;"
                                        />
                                        {#if hasDraft}
                                            <a
                                                href="{formPath}/draft"
                                                class="button"
                                                class:disabled={isDeleted}
                                            >
                                                <!-- disabled={} -->
                                                <span class="btn-text">
                                                    Edit Draft Field Mapping
                                                </span>
                                            </a>
                                        {:else}
                                            <button
                                                type="button"
                                                on:click|stopPropagation={handleNewDraft}
                                                class="button"
                                                disabled={isDeleted}
                                            >
                                                <span class="btn-text">
                                                    Edit Field Mapping
                                                </span>
                                            </button>
                                        {/if}
                                    </div>
                                </header>
                                <div>
                                    {#if hasSpec}
                                        {#if _get(formData, 'data.spec.data.fields', []).length}
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th class="formfield"
                                                            >Field Name (as sent
                                                            via Webhook)</th
                                                        >
                                                        <!-- <th>Example Value</th> -->
                                                        <th class="connection"
                                                        ></th>
                                                        <th
                                                            class="contactfield"
                                                        >
                                                            Contact Field
                                                            <!-- <a href="/orgs/{orgId}/contactfields" style="font-weight:300;">manage</a> -->
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {#each _get(formData, 'data.spec.data.fields', []) as field (field.ref)}
                                                        <tr>
                                                            <td
                                                                class="formfield"
                                                            >
                                                                <input
                                                                    readonly
                                                                    disabled
                                                                    value={field
                                                                        .data
                                                                        .apiName}
                                                                    type="text"
                                                                />
                                                            </td>
                                                            <!-- <td>
                                                                <code>{JSON.stringify(_get(testSubmissionBody, field.apiName))}</code>
                                                            </td> -->
                                                            <td
                                                                class="connection"
                                                            >
                                                                <div
                                                                    class="link-graphic"
                                                                >
                                                                    <span
                                                                        class="link-start"
                                                                    ></span>
                                                                    <span
                                                                        class="link-bar"
                                                                    ></span>
                                                                    <span
                                                                        class="link-end"
                                                                    ></span>
                                                                </div>
                                                            </td>
                                                            <td
                                                                class="contactfield"
                                                            >
                                                                <input
                                                                    readonly
                                                                    disabled
                                                                    value={_get(
                                                                        field,
                                                                        'data.contactField.data.name',
                                                                        '<error: contact support>',
                                                                    )}
                                                                    type="text"
                                                                />
                                                            </td>
                                                        </tr>
                                                    {/each}
                                                </tbody>
                                            </table>
                                        {:else}
                                            Error: this form has no fields. Did
                                            you forget to edit the field
                                            mappings?
                                        {/if}
                                    {:else}
                                        Not published.
                                        {#if hasDraft}
                                            <a
                                                href="{formPath}/draft"
                                                class="button"
                                                class:disabled={isDeleted}
                                            >
                                                <span class="btn-text">
                                                    Edit Draft Field Mapping
                                                </span>
                                            </a>
                                        {:else}
                                            <button
                                                type="button"
                                                on:click|stopPropagation={handleNewDraft}
                                                class="button"
                                                disabled={isDeleted}
                                            >
                                                <span class="btn-text">
                                                    Setup Field Mapping
                                                </span>
                                            </button>
                                        {/if}
                                    {/if}
                                </div>
                            </Route>
                            <Route path="draft">
                                <div class:muted={isDeletingDraft}>
                                    <header class="cols">
                                        <h1 class="flexcol">
                                            Webhook Field Mapping
                                            <span class="chip"> draft </span>
                                        </h1>
                                        <div class="fixedcol tools">
                                            <span
                                                class="muted"
                                                style="margin-right:10px;"
                                            >
                                                Draft autosaved.
                                            </span>
                                            {#if formData.data.spec}
                                                <button
                                                    type="button"
                                                    on:click|stopPropagation={handlePublishDraft}
                                                    class="button primary"
                                                    class:muted={isPublishingDraft}
                                                    disabled={isDeleted}
                                                >
                                                    <span class="btn-text">
                                                        Publish Draft
                                                    </span>
                                                </button>
                                                <button
                                                    type="button"
                                                    on:click|stopPropagation={handleDeleteDraft}
                                                    class="button danger"
                                                    disabled={isDeleted}
                                                >
                                                    <span class="btn-text">
                                                        Delete Draft
                                                    </span>
                                                </button>
                                                <a
                                                    href={formPath}
                                                    class="button"
                                                    class:disabled={isDeleted}
                                                >
                                                    <span class="btn-text">
                                                        Back to Live Form Fields
                                                    </span>
                                                </a>
                                            {:else}
                                                <button
                                                    type="button"
                                                    on:click|stopPropagation={handlePublishDraft}
                                                    class="button primary"
                                                    class:muted={isPublishingDraft}
                                                    disabled={isDeleted}
                                                >
                                                    <span class="btn-text">
                                                        Publish Webhook
                                                    </span>
                                                </button>
                                            {/if}
                                        </div>
                                    </header>
                                    <div>
                                        {#if _get(formData, 'data.draftSpec.ref')}
                                            <EditFormSpec
                                                {orgId}
                                                formSpecId={_get(
                                                    formData,
                                                    'data.draftSpec.ref',
                                                )}
                                                {formEndpoint}
                                            />
                                        {/if}
                                    </div>
                                </div>
                            </Route>
                            <Route path="" component={NotFound} />
                        </Router>
                    </section>
                {/if}
            {/if}
        </article>
        {#if isHostedForm}
            <aside class="fixedcol form-preview-col">
                <div
                    class="card"
                    style="position:relative; padding-bottom:70px; margin-bottom:-55px;"
                >
                    <h1>Form Preview</h1>
                    <div
                        style="height:45px; position:absolute; left:-1px; right:-1px; bottom:-1px; background:linear-gradient(to bottom, transparent, white)"
                    ></div>
                </div>
                <div class="marvel-device iphone-x">
                    <div class="notch">
                        <div class="camera"></div>
                        <div class="speaker"></div>
                    </div>
                    <div class="top-bar"></div>
                    <div class="sleep"></div>
                    <div class="bottom-bar"></div>
                    <div class="volume"></div>
                    <div class="overflow">
                        <div class="shadow shadow--tr"></div>
                        <div class="shadow shadow--tl"></div>
                        <div class="shadow shadow--br"></div>
                        <div class="shadow shadow--bl"></div>
                    </div>
                    <div class="screen">
                        <div class="form-preview no-touch-container">
                            <div class="no-touch-overlay"></div>
                            <iframe src={hostedFormUrl}></iframe>
                        </div>
                    </div>
                </div>
            </aside>
        {/if}
    </div>
</OrgPageLayout>

<style>
    @import '../../../styles/device-mocks.min.css';

    .card {
        background: var(--white);
        border: 1px solid var(--offoffwhite);
        border-radius: 9px;
        padding: 20px;
    }
    .card + .card {
        margin-top: 20px;
    }
    .form-form {
        margin: 0 20px;
    }
    h1 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        margin: 0;
        padding: 0 0 10px;
    }
    .cols h1 {
        padding: 0;
    }
    .cols {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
    }
    .fixedcol {
        flex-grow: 0;
        flex-shrink: 0;
    }
    .growcol {
        flex-grow: 1;
        flex-shrink: 0;
    }
    .flexcol {
        flex-grow: 1;
        flex-shrink: 1;
    }
    .no-touch-container {
        position: relative;
    }
    .no-touch-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
    }
    .form-preview-col {
        /* width: 320px; */
        margin-right: 20px;
        align-self: flex-start;
    }
    .form-preview {
        /* padding-top: 26px; */
        height: 100%;
        box-sizing: border-box;
    }
    .form-preview iframe {
        border: none;
        height: 100%;
        width: 100%;
    }
    .tools {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .chip {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 600;
        line-height: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 15px;
        padding: 0 10px;
        margin-left: 10px;
        background: var(--offwhite);
    }
    .activity {
        display: inline-block;
        width: 9px;
        height: 9px;
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.25);
        /* box-shadow: var(--box-shadow-raised-level1); */
        margin-right: 2px;
    }
    .activity.online {
        background: var(--green);
    }
    .activity.offline {
        background: var(--red);
    }
    table {
        margin-top: 20px;
        width: 100%;
    }
    table .formfield,
    table .contactfield {
        width: 40%;
    }
    table .formfield input,
    table .contactfield input {
        width: 100%;
    }
    table .formfield input {
        font-family: monospace;
    }
    table .connection {
        width: 120px;
    }
    th,
    td {
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        vertical-align: middle;
        padding: 5px 0;
    }
    th {
        font-weight: 600;
    }
    .link-graphic {
        position: relative;
    }
    .link-bar {
        position: absolute;
        background: var(--slate-o10);
        background: var(--lightblue);
        left: 23px;
        right: 23px;
        top: 50%;
        margin-top: -1px;
        height: 2px;
        border-radius: 2px;
    }
    .link-start,
    .link-end {
        position: absolute;
        top: 50%;
        margin-top: -5px; /* half height + border-width */
        border-radius: 5px;
        width: 6px;
        height: 6px;
        border: 2px solid var(--slate-o10);
        border-color: var(--blue);
    }
    .link-start {
        left: 10px;
    }
    .link-end {
        right: 10px;
    }
    .disabled {
        pointer-events: none;
        /* color: #ccc; */
        cursor: not-allowed;
    }
    .form-name {
        font-size: 18px;
        font-weight: 600;
    }
    .deleted-notification {
        color: red;
        font-weight: 600;
    }
    .form-retrieve-icon {
        width: 20px;
        height: 20px;
        margin-left: 10px;
    }
    .deleted-info {
        display: flex;
        align-items: center;
    }
    .deleted-style {
        display: flex;
        flex-direction: column;
        width: 115px;
        height: 45px;
        padding: 4px 15px;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 5px;
        color: #d65543;
        background: rgba(214, 67, 67, 0.12);
    }
    .status-text {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px 0px 2px 0px;
    }
    .date-text {
        margin-top: -10px;
        text-decoration: none !important;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
    .status-icon {
        width: 15px;
        height: 15px;
        margin-right: 4px;
    }
    .button {
        padding: 10px 20px 10px 20px;
        margin-left: 20px;
    }
</style>
