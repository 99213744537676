<script context="module">
    import gql from 'graphql-tag';
    import {FRAGMENTS as ConversationListItem_FRAGMENTS} from '../../components/ConversationListItem.svelte';

    const FRAGMENTS = {};
    FRAGMENTS.OrgMessages_conversation = gql`
        fragment OrgMessages_conversation on Conversation {
            ...ConversationListItem_conversation
        }
        ${ConversationListItem_FRAGMENTS.ConversationListItem_conversation}
    `;

    const QUERIES = {};
    QUERIES.orgConversations = gql`
        query orgConversations($orgId: ID!) {
            orgConversations(orgId: $orgId, size: 50) {
                page {
                    data {
                        ...OrgMessages_conversation
                    }
                }
                aggregate {
                    count
                }
            }
        }
        ${FRAGMENTS.OrgMessages_conversation}
    `;

    const MUTATIONS = {};
    // MUTATIONS.updateForm = gql`
    //     mutation updateForm($id: ID!, $data: PartialUpdateFormInput!) {
    //         partialUpdateForm(id: $id, data: $data) {
    //             ...form
    //         }
    //     }
    //     ${FRAGMENTS.form}
    // `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getClient, query, mutation} from 'svelte-apollo';
    import {navigate} from 'svelte-routing';
    import _get from 'lodash/get';
    import _isNumber from 'lodash/isNumber';
    import {watchQuery} from '../../svelte-apollo-watch-query';
    import HtmlHeadTitle from '../../components/HtmlHeadTitle.svelte';
    import ConversationListItem from '../../components/ConversationListItem.svelte';
    import OrgPageLayout from '../../components/OrgPageLayout.svelte';

    export let orgId;

    const client = getClient();
    const pageTitle = 'Messages';

    const orgConversationsQuery = watchQuery(client, {
        query: QUERIES.orgConversations,
        variables: {orgId: orgId},
        pollInterval: 30000,
    });
    $: orgConversationsData = _get(
        $orgConversationsQuery,
        'data.orgConversations',
        {},
    );
    $: orgConversationsCount = _get(orgConversationsData, 'aggregate.count');
    $: orgConversations = _get(orgConversationsData, 'page.data', []);

    // async function createForm() {
    //     try {
    //         let response = await mutate(client, {
    //             mutation: CREATE_FORM,
    //             variables: {
    //                 form: {
    //                     org: {connect: orgId},
    //                     name: 'Form 1', // FIXME better name generation
    //                 },
    //             }
    //         });
    //         navigate(`/orgs/${orgId}/forms/${response.data.createForm._id}`);
    //     } catch (error) {
    //         // FIXME handle errors better...
    //         alert(error.message)
    //     }
    // }
</script>

<HtmlHeadTitle {pageTitle} />

<OrgPageLayout {orgId} title={pageTitle}>
    <section class="card listgroup">
        <header class="listgroup-head">
            <!-- <div class="listgroup-bulkaction">
                <a>Select all</a>
            </div> -->
            <div class="listgroup-head-left">
                <span class="chip">
                    {_isNumber(orgConversationsCount)
                        ? orgConversationsCount
                        : 'loading'} conversations
                </span>
            </div>
            <!-- <div class="listgroup-head-right">
                <a>Sort Options</a>
            </div> -->
        </header>
        <ul>
            {#if $orgConversationsQuery.loading && !orgConversations.length}
                <!-- <li></li> -->
            {:else if $orgConversationsQuery.errors}
                <li>
                    errors: <pre>{JSON.stringify(
                            $orgConversationsQuery.errors,
                            null,
                            4,
                        )}</pre>
                </li>
            {:else if orgConversations.length}
                {#each orgConversations as conversation (_get(conversation, 'contact.ref'))}
                    <li>
                        <!-- <div class="listgroup-bulkaction">
                            <a>Select all</a>
                        </div> -->
                        <ConversationListItem conversationData={conversation} />
                    </li>
                {/each}
            {:else}
                <li>
                    <div class="empty-message">
                        <div class="iconcol">
                            <ion-icon name="chatbubble-ellipses" />
                        </div>
                        <div class="textcol">
                            <div class="text-primary">No messages yet!</div>
                            <div class="text-secondary">
                                To start a new conversation, start from your
                                <a href={`/orgs/${orgId}/contacts`}
                                    >Contacts list</a
                                >.
                            </div>
                        </div>
                    </div>
                </li>
            {/if}
            <!-- <li><a href="create-contact" on:click|preventDefault={createForm}>Create a New Form</a></li> -->
        </ul>
    </section>
</OrgPageLayout>

<style>
    .listgroup {
        margin: 0 20px;
    }
    .listgroup-head,
    .listgroup li {
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }
    .listgroup-head-left {
        flex-grow: 1;
        flex-shrink: 1;
        padding: 12px 15px;
    }
    /* .listgroup-head-right {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 12px 15px;
} */
    .chip {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 700;
        line-height: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 15px;
        padding: 0 10px;
        background: var(--offwhite);
    }
    ul,
    li {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .card {
        background: var(--white);
        border: 1px solid var(--offoffwhite);
        border-radius: 9px;
    }
    .empty-message {
        margin: 20px;
        padding: 20px;
        background: var(--lightblue);
        color: var(--blue);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        /* justify-content: space-around; */
        width: 100%;
        box-sizing: border-box;
    }
    .empty-message .iconcol {
        flex-grow: 0;
        flex-shrink: 0;
        line-height: 20px;
        font-size: 48px;
        margin-right: 10px;
    }
    .empty-message .textcol {
        flex-grow: 1;
        flex-shrink: 0;
    }
    .empty-message .text-primary {
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
    }
    .empty-message .text-secondary {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        opacity: 0.8;
    }
</style>
