<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};
    FRAGMENTS.SearchListItemContact_contact = gql`
        fragment SearchListItemContact_contact on Contact {
            ref
            data {
                uids {
                    ref
                    data {
                        value
                        contactField {
                            ref
                            data {
                                isEmail
                                isSms
                            }
                        }
                    }
                }
                profile {
                    ref
                    data {
                        valueJson
                        contactField {
                            ref
                            data {
                                isFirstName
                                isLastName
                            }
                        }
                    }
                }
            }
        }
    `;

    const QUERIES = {};
    const MUTATIONS = {};

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import _get from 'lodash/get';
    import _find from 'lodash/find';
    import _trim from 'lodash/trim';
    import _map from 'lodash/map';
    import _reduce from 'lodash/reduce';
    import _zip from 'lodash/zip';
    import {navigate} from 'svelte-routing';
    import {parsePhoneNumberFromString} from 'libphonenumber-js';
    import escapeStringRegexp from 'escape-string-regexp';

    export let contactData;
    export let searchText = '';
    export let hasEnterAction = false;

    $: contactPath = `/contacts/${_get(contactData, 'ref')}`;
    const getProfile = (contact, where) => {
        let value = _get(
            _find(_get(contact, 'data.uids', []), {
                data: {contactField: {data: where}},
            }),
            'data.value',
        );
        if (value) {
            return value;
        }
        let valueJson = _get(
            _find(_get(contact, 'data.profile', []), {
                data: {contactField: {data: where}},
            }),
            'data.valueJson',
        );
        if (valueJson) {
            return JSON.parse(valueJson);
        }
    };

    $: profileFirstName = getProfile(contactData, {isFirstName: true}) || '';
    $: profileLastName = getProfile(contactData, {isLastName: true}) || '';
    $: nameDisplay = _trim(
        `${markupFuzzact(searchText, profileFirstName)} ${markupFuzzact(
            searchText,
            profileLastName,
        )}`,
    );

    $: profileEmail = markupFuzzact(
        searchText,
        getProfile(contactData, {isEmail: true}) || '',
    );

    $: profileSms = getProfile(contactData, {isSms: true}) || '';
    $: phoneNumber =
        parsePhoneNumberFromString(profileSms) ||
        parsePhoneNumberFromString('+1' + profileSms);
    $: phoneNumberDisplay = markupFuzzact(
        searchText,
        phoneNumber ? phoneNumber.formatNational() : '',
    );
    $: phoneNumberUri = phoneNumber ? phoneNumber.getURI() : '';

    function markupFuzzact(needleText, haystackText) {
        let words = needleText.split(/\s+/);
        let wordRegexps = _map(words, (word) => {
            let regexpChars = _map(word.split(''), (c) =>
                escapeStringRegexp(c),
            );
            let regexpStr =
                _map(regexpChars, (c) => `([^${c}]*)${c}`).join('') + '(.*)';
            return new RegExp(regexpStr, 'i');
        });
        let bestMatch = null;
        let bestScore = -1;
        for (var i = 0; i < words.length; i++) {
            let result = wordRegexps[i].exec(haystackText);
            if (!result) {
                continue;
            }
            let captureGroups = result.slice(1);
            let startGroup = captureGroups[0];
            let endGroup = captureGroups[captureGroups.length - 1];
            let spreadGroups = captureGroups.slice(1, captureGroups.length - 1);
            let matchLength =
                words[i].length +
                _reduce(
                    spreadGroups,
                    (accumulator, str) => accumulator + str.length,
                    0,
                );
            let queryLength = words[i].length;
            let spreadScore = queryLength / matchLength;
            let score =
                (startGroup.length == 0 ? 2 : 1) * spreadScore * queryLength;
            if (score > bestScore) {
                bestScore = score;
                bestMatch = {
                    word: words[i],
                    captureGroups: captureGroups,
                };
            }
        }
        if (!bestMatch) {
            return haystackText;
        }

        let openTag = '<span class="qmatch">';
        let closeTag = '</span>';
        let markup = _reduce(
            _zip(bestMatch.word.split(''), bestMatch.captureGroups.slice(1)),
            (accumulator, [char, between]) =>
                accumulator + openTag + char + closeTag + between,
            bestMatch.captureGroups[0],
        );
        return markup;
    }
    // function markupNgrams(needleText, haystackText) {
    //     needleText = _trim(needleText);
    //     if (!needleText) return haystackText;
    //
    //     let openIdxs = [];
    //     let closeIdxs = [];
    //     let found;
    //     for (let n = needleText.length; n > 0; n--) {
    //         for (let needleIdx = 0; needleIdx <= needleText.length - n; needleIdx++) {
    //             let regexp = new RegExp(needleText.substr(needleIdx, n), 'gi');
    //             while ((found = regexp.exec(haystackText)) !== null) {
    //                 openIdxs.push(regexp.lastIndex - found[0].length);
    //                 closeIdxs.push(regexp.lastIndex);
    //             }
    //         }
    //         if (openIdxs.length) break;
    //     }
    //     if (!openIdxs.length) return haystackText;
    //     openIdxs.sort();
    //     closeIdxs.sort();
    //     console.log(openIdxs, closeIdxs, haystackText);
    //
    //     let openTag = '<span class="qmatch">';
    //     let closeTag = '</span>';
    //     let markup = haystackText.replace(/</g, '¯').replace(/>/g, '˘').split('');
    //     for (let i = 0; i < openIdxs.length; i++) {
    //         markup.splice(openIdxs[i] + i + i, 0, openTag);
    //         markup.splice(closeIdxs[i] + (i+1) + i, 0, closeTag);
    //     }
    //     return markup.join('').replace(/¯/g, '&lsaquo;').replace(/˘/g, '&rsaquo;');
    // }
</script>

<div class="row">
    <a href="{contactPath}/sequences" class="contact-link">
        <span class="name">
            {@html nameDisplay || 'unknown'}
        </span>
        <span class="email-phone">
            {@html profileEmail || ''}
            {#if profileEmail && phoneNumberDisplay}
                •
            {/if}
            {@html phoneNumberDisplay || ''}
        </span>
        {#if hasEnterAction}
            <span class="enter-action-indicator"> enter </span>
        {/if}
    </a>
</div>

<style>
    .row {
        border-top: 1px solid var(--offwhite);
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
    }
    .row > * {
        padding-left: 10px;
        padding-right: 10px;
    }
    .row > :first-child {
        padding-left: 15px;
    }
    .row > :last-child {
        padding-right: 15px;
    }
    .contact-link {
        flex-grow: 1;
        flex-shrink: 0;
        text-decoration: none;
        font-size: 14px;
        line-height: 20px;
        padding-top: 15px;
        padding-bottom: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;
    }
    .name {
        display: block;
        font-weight: 600;
    }
    /* .contact-link:hover .name {
    text-decoration: underline;
} */
    .contact-link:hover {
        background: var(--lightblue);
    }
    .email-phone {
        display: block;
        opacity: 0.6;
        font-weight: 400;
    }
    .enter-action-indicator {
        background: var(--lightblue);
        color: var(--blue);
        font-family: monospace;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        padding: 0 4px;
        border-radius: 2px;
    }
    :global(.qmatch) {
        color: var(--blue);
    }
    :global(.qmatch) {
        color: var(--blue);
    }
</style>
