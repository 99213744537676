<script>
import { getClient, query } from 'svelte-apollo';
import { navigate } from "svelte-routing";
import _get from 'lodash/get';
import _trimEnd from 'lodash/trimEnd';
import { watchQuery } from '../svelte-apollo-watch-query';
import { getUser, login, signup, logout } from '../auth0';
import Dropdown from './Dropdown.svelte';
import SidebarFooter from './SidebarFooter.svelte';

export let fakeOrgName = '';

const userPromise = getUser();
let isAccountDropdownOpen = false;
</script>

<div class="layout">
    <div class="layout-main">
        <div class="org-header">
            <Dropdown open={isAccountDropdownOpen} on:cancel={() => isAccountDropdownOpen = false}>
                <button type="button" slot="trigger" class="account-dropdown" on:click={() => isAccountDropdownOpen = !isAccountDropdownOpen}>
                    {#await userPromise}
                        <span class="org-name">
                            {#if fakeOrgName}
                                {fakeOrgName}
                            {:else}
                                <span class="faketext" style="border-width:3px; width:70px;"></span>
                            {/if}
                            <ion-icon class="caret" name="chevron-down-outline" />
                        </span>
                        <span class="user-name">
                            <span class="user-activity loading"></span>
                            Identifying...
                        </span>
                    {:then user}
                        <span class="org-name">
                            {#if fakeOrgName}
                                {fakeOrgName}
                            {:else}
                                <span class="faketext" style="border-width:3px; width:70px;"></span>
                            {/if}
                            <ion-icon class="caret" name="chevron-down-outline" />
                        </span>
                        <span class="user-name">
                            {#if user}
                                <span class="user-activity online"></span>
                                {user && user.name || '(no name)'}
                            {:else}
                                <span class="user-activity offline"></span>
                                Anonymous
                            {/if}
                        </span>
                    {:catch error}
                        <span class="org-name">
                            Error
                        </span>
                        <span class="user-name">
                            {error.message}
                        </span>
                    {/await}
                </button>
                <div slot="menu" class="account-menu">
                    <ul>
                        {#await userPromise}
                            <!-- nothing -->
                        {:then user}
                            {#if user}
                                <li>
                                    <a class="account-menu-item" href="/logout" on:click|preventDefault={() => logout()}>
                                        <ion-icon name="power-outline" />
                                        <span class="text">
                                            Sign Out
                                        </span>
                                    </a>
                                </li>
                            {:else}
                                <li>
                                    <a class="account-menu-item" href="/login" on:click|preventDefault={() => login()}>
                                        <ion-icon name="finger-print-outline" />
                                        <span class="text">
                                            Sign In
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <a class="account-menu-item" href="/signup" on:click|preventDefault={() => signup()}>
                                        👋
                                        <span class="text">
                                            Sign Up
                                        </span>
                                    </a>
                                </li>
                            {/if}
                        {/await}
                    </ul>
                </div>
            </Dropdown>
        </div>
        <nav>
            <a href="#fakelink" on:click|preventDefault class="nav-item">
                <ion-icon name="home-outline" />
                <span class="faketext" style="border-width:2px; opacity:0.8;">
                    Dashboard
                </span>
            </a>
            <a href="#fakelink" on:click|preventDefault class="nav-item">
                <ion-icon name="people-outline" />
                <!-- <ion-icon name="body-outline" /> -->
                <span class="faketext" style="border-width:2px; opacity:0.8;">
                    Contacts
                </span>
            </a>
            <a href="#fakelink" on:click|preventDefault class="nav-item">
                <ion-icon name="chatbubbles-outline" />
                <!-- <ion-icon name="mail-outline" /> -->
                <span class="faketext" style="border-width:2px; opacity:0.8;">
                    Messages
                </span>
            </a>
            <a href="#fakelink" on:click|preventDefault class="nav-item">
                <ion-icon name="clipboard-outline" />
                <!-- <ion-icon name="download-outline" /> -->
                <!-- <ion-icon name="enter-outline" /> -->
                <!-- <ion-icon name="log-in-outline" /> -->
                <span class="faketext" style="border-width:2px; opacity:0.8;">
                    Lead Forms
                </span>
            </a>
            <a href="#fakelink" on:click|preventDefault class="nav-item">
                <ion-icon name="play-forward-outline" />
                <!-- <ion-icon name="git-merge-outline" /> -->
                <span class="faketext" style="border-width:2px; opacity:0.8;">
                    Action Sequences
                </span>
            </a>
        </nav>
    </div>
    <div class="layout-footer">
        <SidebarFooter />
    </div>
</div>

<style>
.layout {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
}
.layout-main {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    overflow-y: visible;
    --webkit-overflow-scrolling: touch;
}
.layout-footer {
    flex-grow: 0;
    flex-shrink: 0;
}
.org-header {
    padding: 0;
    margin: 0;
}
.account-dropdown {
    padding: 11px 15px 14px;
    text-align: left;
    width: 100%;
}
.account-dropdown:hover {
    background: rgba(0,0,0,0.18);
}
@supports (backdrop-filter: saturate(120%) brightness(84%)) {
    .account-dropdown:hover {
        background: transparent;
        backdrop-filter: saturate(120%) brightness(84%);
    }
}
.account-dropdown .caret {
    font-size: 16px;
    vertical-align: baseline;
    margin-left: 3px;
}
.org-name {
    display: block;
    font-size: 20px;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    white-space: nowrap;
}
.user-name {
    display: block;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.user-activity {
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 5px;
    background: rgba(255,255,255,0.5);
    box-shadow: var(--box-shadow-raised-level1);
    margin-right: 2px;
}
.user-activity.online {
    background: var(--green);
}
.user-activity.offline {
    background: var(--red);
}
.account-menu {
    display: block;
    background: var(--slate);
    color: #fff;
    /* border: 1px solid var(--offoffwhite); */
    box-shadow: rgba(0,0,0,.2) 0 1px 2px;
    border-radius: 4px;
    padding: 4px 0;
    margin-top: 2px;
    width: 90%;
    margin-left: 5%;
    box-sizing: border-box;
    margin-top: -6px;
    overflow: hidden;
    transition: transform 150ms ease-in-out;
    transform: scale(1) translateZ(0);
    /* transition: transform 200ms cubic-bezier(.47,1.64,.41,.8); */
}
.account-menu:hover {
    transform: scale(1.02) translateZ(0);
}
.account-menu ul,
.account-menu li {
    list-style: none;
    padding: 0;
    margin: 0;
}
.account-menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    padding: 5px 10px;
    text-decoration: none;
}
.account-menu-item ion-icon {
    font-size: 16px;
    flex-grow: 0;
    flex-shrink: 0;
}
.account-menu-item .text {
    flex-grow: 1;
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 5px;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
}
nav {
    padding: 10px 0;
}
.nav-item {
    display: block;
    text-decoration: none;
    border: none;
    padding: 8px 15px;
    color: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.04em;
    cursor: not-allowed;
}
.nav-item ion-icon {
    font-size: 20px;
    vertical-align: text-bottom;
    padding-bottom: 2px;
    line-height: 24px;
    margin-right: 5px;
    --ionicon-stroke-width: 40px;
}
/*
.nav-item:hover,
.nav-item.active {
    background: rgba(0,0,0,.18);
}
@supports (backdrop-filter: saturate(120%) brightness(84%)) {
    .nav-item:hover,
    .nav-item.active {
        background: transparent;
        backdrop-filter: saturate(120%) brightness(84%);
    }
}
*/
</style>
