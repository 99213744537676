// We also considered the ProseMirror library, which has a suggestions or
// autosuggest plugin, and also supports rich text.

import Tribute from "tributejs";
import _get from 'lodash/get';
import _assign from 'lodash/assign';
import _omit from 'lodash/omit';

export function tribute(el, options) {
    var tribute = new Tribute({
        trigger: '###',
        values: [],
        selectTemplate: function(item) {
            if (item) {
                return item.original.value;
            } else {
                // required to prevent lockup and console error when pressing enter/tab on no match found
                return this.current.collection.trigger + this.current.mentionText;
            }
        },
        menuItemTemplate: function(item) {
            return item.original.key + ' e.g. "' + item.original.exampleValue + '"';
        },
    });
    function updateOptions(options) {
        tribute.append(0, _get(options, 'values', []), true);
        _assign(tribute.collection[0], _omit(options, 'values'));
    }
    updateOptions(options);
    tribute.attach(el);

	return {
        update: (options) => updateOptions(options),
		destroy: () => tribute.detach(el),
	}
}
