<script context="module">
    import gql from 'graphql-tag';
    import {FRAGMENTS as OrgForm_FRAGMENTS} from '../../components/OrgForm.svelte';
    const FRAGMENTS = {};
    const QUERIES = {};
    QUERIES.org = gql`
        query org($id: ID!) {
            findOrgByID(id: $id) {
                ref
                data {
                    ...OrgForm_org
                }
            }
        }
        ${OrgForm_FRAGMENTS.OrgForm_org}
    `;

    QUERIES.getUserByOrg = gql`
        query getUserByOrg($orgId: ID!) {
            getUserByOrg(orgId: $orgId) {
                ref
                data {
                    email
                }
            }
        }
    `;

    const MUTATIONS = {};

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getClient} from 'svelte-apollo';
    import _get from 'lodash/get';
    import {watchQuery} from '../../svelte-apollo-watch-query';
    import HtmlHeadTitle from '../../components/HtmlHeadTitle.svelte';
    import OrgPageLayout from '../../components/OrgPageLayout.svelte';
    import OrgForm from '../../components/OrgForm.svelte';

    const client = getClient();
    const pageTitle = 'Org Settings';

    export let orgId;

    $: orgQuery = watchQuery(client, {
        query: QUERIES.org,
        variables: {id: orgId},
        pollInterval: 0,
    });
    $: orgData = _get($orgQuery, 'data.findOrgByID', {});
    $: userQuery = watchQuery(client, {
        query: QUERIES.getUserByOrg,
        variables: {orgId: orgId},
        pollInterval: 0,
    });
    scrollbars;
    $: userEmail = _get($userQuery, 'data.getUserByOrg.data.email', {});
</script>

<HtmlHeadTitle {pageTitle} />

<OrgPageLayout {orgId} title={pageTitle}>
    <div class="org-form">
        <OrgForm orgId={orgId} orgData={orgData.data} {userEmail} />
    </div>
</OrgPageLayout>

<style>
    /* .card {
    background: var(--white);
    border: 1px solid var(--offoffwhite);
    border-radius: 9px;
    overflow: hidden;
} */
    .org-form {
        /* margin: 0 20px; */
        padding: 0 20px 20px;
    }
</style>
