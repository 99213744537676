<script context="module">
export const contextKey = {};
</script>

<script>
// openModal(component, props, optionalPathOverride)
// ...or...
// navigate(modalPath) => location change captured by modalController and downward routerContext does not change.
// navigate(currentPath, {modal: {modalKey: 'abc'}}) => state change handled by modalcontroller but passthrough downward routerContext.
// ModalController needs to detect when it hits a modal path or modal state,
// which is then rendered automatically (we could use a fake path /modal/:modalKey to capture modal-only routes),
// and needs to update the child router location context based on either parent router location context or
// overridden router location context.
// We could have a PreferModal component that, when rendered... no, the original route would already be handled.  The only way to accomplish this is with a routing event and preventDefault() or similar.  That's actually reasonable... but how do you handle it?  You would need to detect the rendered route... maybe you're given the route node?
// We need to detect route changes.  I propose that... routers have an activeRoute or else block.
import { setContext, getContext, onDestroy, tick } from 'svelte';
import { writable, derived } from 'svelte/store';
import { navigate, Router, Route } from 'svelte-routing';
// import Modal from './Modal.svelte';
import _get from 'lodash/get';
import Contact from '../routes/orgs/contacts/Contact.svelte';
import Search from '../routes/Search.svelte';
import { LOCATION } from 'svelte-routing/src/contexts.js';
import { globalLocation } from 'svelte-routing/src/stores.js';

// export let routerLocation;
// $: routerLocation && updateModal();

const location = getContext(LOCATION) || globalLocation;
let modalRouter;
let hasModal;
// let lastNonModalLocation = null;
const modalLocation = writable(null);
const childLocation = writable(null);
setContext(LOCATION, childLocation);

// When location changes, update modalLocation, clear modalRoute (?)
// When modalRoute
// How can we tell which location the modalRoute is being triggered for???


const onLocationChange = () => {
    if (!modalRouter) {
        // Wait until finished mounting.
        return;
    }
    // console.log('onLocationChange $location', $location)
    hasModal = modalRouter.hasRoute($location.pathname);
    $modalLocation = $location;
    // console.log('onLocationChange modalLocation.set()', $modalLocation);
    if (!hasModal) {
        $childLocation = $location;
        // lastNonModalLocation = $location;
    // } else {
    //     $childLocation = lastNonModalLocation || {pathname: '/'};
    }
    if (!$childLocation) {
        $childLocation = {pathname: '/'};
    }
    // console.log('onLocationChange childLocation.set()', $childLocation);
};
$: modalRouter, $location, onLocationChange();

// let showModal = ({modalKey, props, pushUrl}) => {
//     let fromState = window.history.state;
//     let fromPathname = window.location.pathname;
//     window.history.pushState({
//         modal: {modalKey, props},
//         returnTo: {
//             pathname: fromPathname,
//             state: fromState,
//         }
//     }, '', pushUrl || window.location.pathname);
//     updateModal();
// };
setContext(contextKey, {
    // show: showModal,
    cancel: function cancelModal() {
        // console.log('cancel $childLocation', $childLocation)
        navigate($childLocation.pathname, {state: $childLocation.state});
    },
});

// Note: It never really "hurts" to call updateModal (i.e. no such thing as calling it "too much".)
// It's more important to call it every time we might possibly need to either render a modal, switch
// modals, or hide the modal.
// function updateModal(event) {
//     modal = _get(window.history, 'state.modal');
//     console.log('updateModal', modal);
//     modal && event && event.stopImmediatePropagation();
// };
</script>

<!-- <svelte:window on:popstate={updateModal} on:hashchange={updateModal} /> -->

<div class="app-surface" class:has-modal={hasModal}>
    <slot />
</div>
<Router location={$modalLocation} bind:this={modalRouter}>
    <Route path="/contacts/:contactId/*" component={Contact} />
    <Route path="/orgs/:orgId/search" component={Search} />
</Router>

<style>
.app-surface {
    transition: 200ms transform ease-in-out;
    height: 100vh;
    overflow: visible;
    border-radius: 2px;
    transform: scale(1.0) translateZ(0);
    transform-origin: 50% -50%;
    box-shadow: var(--black) 0 0 30px;
    background: white;
}
.app-surface.has-modal {
    /* transform: scale(0.97) translateZ(0); */
    pointer-events: none;
    user-select: none;
}
</style>
