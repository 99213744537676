<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};
    const QUERIES = {};
    const MUTATIONS = {};

    FRAGMENTS.EditFormSpec_formField = gql`
        fragment EditFormSpec_formField on FormField {
            ref
            data {
                apiName
                contactField {
                    ref
                }
            }
        }
    `;
    FRAGMENTS.EditFormSpec_formSpec = gql`
        fragment EditFormSpec_formSpec on FormSpec {
            ref
            data {
                testSubmissionJson
                fields {
                    ...EditFormSpec_formField
                }
            }
        }
        ${FRAGMENTS.EditFormSpec_formField}
    `;
    FRAGMENTS.EditFormSpec_contactField = gql`
        fragment EditFormSpec_contactField on ContactField {
            ref
            data {
                name
            }
        }
    `;
    FRAGMENTS.EditFormSpec_org = gql`
        fragment EditFormSpec_org on Org {
            ref
            data {
                contactFields {
                    ...EditFormSpec_contactField
                }
            }
        }
        ${FRAGMENTS.EditFormSpec_contactField}
    `;

    QUERIES.formSpec = gql`
        query formSpec($id: ID!) {
            findFormSpecByID(id: $id) {
                ...EditFormSpec_formSpec
            }
        }
        ${FRAGMENTS.EditFormSpec_formSpec}
    `;
    QUERIES.orgContactFields = gql`
        query orgContactFields($orgId: ID!) {
            findOrgByID(id: $orgId) {
                ...EditFormSpec_org
            }
        }
        ${FRAGMENTS.EditFormSpec_org}
    `;

    MUTATIONS.updateFormSpec = gql`
        mutation updateFormSpec($id: ID!, $data: UpdateFormSpecInput!) {
            updateFormSpec(id: $id, data: $data) {
                ...EditFormSpec_formSpec
            }
        }
        ${FRAGMENTS.EditFormSpec_formSpec}
    `;
    MUTATIONS.createContactField = gql`
        mutation createContactField($org: ID, $data: CreateContactFieldInput) {
            createContactField(org: $org, data: $data) {
                ...EditFormSpec_contactField
            }
        }
        ${FRAGMENTS.EditFormSpec_contactField}
    `;
    MUTATIONS.updateFormSpecWithNewFormField = gql`
        mutation updateFormSpecWithNewFormField(
            $formSpecId: ID
            $data: UpdateFormSpecWithNewFormFieldInput
        ) {
            updateFormSpecWithNewFormField(
                formSpecId: $formSpecId
                data: $data
            ) {
                ...EditFormSpec_formSpec
            }
        }
        ${FRAGMENTS.EditFormSpec_formSpec}
    `;
    MUTATIONS.updateFormField = gql`
        mutation updateFormField($formFieldId: ID!, $contactFieldId: ID!) {
            updateFormField(
                formFieldId: $formFieldId
                contactFieldId: $contactFieldId
            ) {
                ...EditFormSpec_formSpec
            }
        }
        ${FRAGMENTS.EditFormSpec_formSpec}
    `;

    MUTATIONS.deleteFormField = gql`
        mutation deleteFormField($id: ID!) {
            deleteFormField(id: $id) {
                ref
            }
        }
    `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getClient, mutation} from 'svelte-apollo';
    import Select from 'svelte-select';
    import _get from 'lodash/get';
    import _map from 'lodash/map';
    import _find from 'lodash/find';
    import {watchQuery} from '../svelte-apollo-watch-query';

    export let orgId;
    export let formSpecId;
    export let formEndpoint = 'displayed above';

    const client = getClient();
    const updateFormSpecMutation = mutation(MUTATIONS.updateFormSpec);
    const deleteFormFieldMutation = mutation(MUTATIONS.deleteFormField);
    const updateFormFieldMutation = mutation(MUTATIONS.updateFormField);
    const createContactFieldMutation = mutation(MUTATIONS.createContactField);
    const updateFormSpecWithNewFormFieldMutation = mutation(
        MUTATIONS.updateFormSpecWithNewFormField,
    );
    const formSpecQuery = watchQuery(client, {
        query: QUERIES.formSpec,
        variables: {id: formSpecId},
        pollInterval: undefined,
    });
    $: formSpecData = _get($formSpecQuery, 'data.findFormSpecByID', {});

    let startPolling = () => $formSpecQuery.observableQuery.startPolling(3000);
    let stopPolling = () => $formSpecQuery.observableQuery.stopPolling();
    $: isCapturingFields =
        formSpecData.ref && !formSpecData.data.testSubmissionJson;
    $: isCapturingFields ? startPolling() : stopPolling();
    let recaptureTestSubmission = () =>
        updateFormSpec(formSpecData.ref, {testSubmissionJson: null});
    $: testSubmissionBody = JSON.parse(
        _get(formSpecData, 'data.testSubmissionJson') || 'null',
    );
    $: testSubmissionFields = _map(
        testSubmissionBody,
        (val, key) =>
            _find(_get(formSpecData, 'data.fields', []), {
                data: {apiName: key},
            }) || {
                data: {apiName: key},
            },
    );
    const contactFieldsQuery = watchQuery(client, {
        query: QUERIES.orgContactFields,
        variables: {orgId: orgId, size: 1000},
        pollInterval: undefined,
    });
    $: contactFields = _get(
        $contactFieldsQuery,
        'data.findOrgByID.data.contactFields',
        [],
    );

    async function updateFormSpec(formSpecId, data) {
        try {
            // let response = await mutate(client, {
            //     mutation: MUTATIONS.updateFormSpec,
            //     variables: {
            //         id: formSpecId,
            //         data: data,
            //     },
            // });
            let response = await updateFormSpecMutation({
                variables: {
                    id: formSpecId,
                    data: data,
                },
            });
            formSpecData.Data = _get(
                response.data.updateFormSpec,
                'data',
                null,
            );
        } catch (error) {
            // FIXME handle errors better...
            alert(error.message);
        }
    }

    async function deleteFormField(fieldId) {
        try {
            // let response = await mutate(client, {
            //     mutation: MUTATIONS.deleteFormField,
            //     variables: {
            //         id: fieldId,
            //     },
            // });
            let response = await deleteFormFieldMutation({
                variables: {
                    id: fieldId,
                },
            });
            await $formSpecQuery.observableQuery.refetch();
        } catch (error) {
            // FIXME handle errors better...
            alert(error.message);
        }
    }

    const createLocalContactField = (name) => ({
        data: {name: name},
    });

    const savingFieldMappings = {};

    async function updateFieldMapping(formField, contactField) {
        if (savingFieldMappings[formField.data.apiName]) return;
        savingFieldMappings[formField.data.apiName] = true;

        let contactFieldRelationInput;
        if (contactField.ref) {
            contactFieldRelationInput = contactField.ref;
        } else {
            try {
                // const result = await mutate(client, {
                //     mutation: MUTATIONS.createContactField,
                //     variables: {
                //         org: orgId,
                //         data: contactField.data,
                //     },
                // });
                let result = await createContactFieldMutation({
                    variables: {
                        org: orgId,
                        data: contactField.data,
                    },
                });
                contactFieldRelationInput = result.data.createContactField.ref;
                contactFields = [...contactFields, result.data.createContactField];
                // let tempContactFields = contactFields
                // tempContactFields.push(result.data.createContactField);
                // contactFields = tempContactFields;
            } catch (error) {
                console.log(error);
            }
        }

        if (!formField.ref) {
            try {
                // const result = await mutate(client, {
                //     mutation: MUTATIONS.updateFormSpecWithNewFormField,
                //     variables: {
                //         formSpecId: formSpecData.ref,
                //         data: {
                //             apiName: formField.data.apiName,
                //             contactField: contactFieldRelationInput,
                //         },
                //     },
                // });
                const result = await updateFormSpecWithNewFormFieldMutation({
                    variables: {
                        formSpecId: formSpecData.ref,
                        data: {
                            apiName: formField.data.apiName,
                            contactField: contactFieldRelationInput,
                        },
                    },
                });
                formSpecData = result.data.updateFormSpecWithNewFormField;
            } catch (error) {
                console.log(error);
            }
        } else if (contactField.ref == 'ignore') {
            alert('Not Implemented');
        } else {
            try {
                // const result = await mutate(client, {
                //     mutation: MUTATIONS.updateFormField,
                //     variables: {
                //         formFieldId: formField.ref,
                //         contactFieldId: contactFieldRelationInput,
                //     },
                // });
                const result = await updateFormFieldMutation({
                    variables: {
                        formFieldId: formField.ref,
                        contactFieldId: contactFieldRelationInput,
                    },
                });
                formSpecData = result.data.updateFormField;
            } catch (error) {
                console.log(error);
            }
        }

        savingFieldMappings[formField.data.apiName] = false;
    }
    async function clearFieldMapping(formField) {
        if (savingFieldMappings[formField.data.apiName]) return;
        savingFieldMappings[formField.data.apiName] = true;

        await deleteFormField(formField.ref);

        savingFieldMappings[formField.data.apiName] = false;
    }
</script>

<section>
    <hgroup>
        <h1>Step 1</h1>
        <h2>Capture Form Fields from Test Submission</h2>
    </hgroup>
    {#if isCapturingFields}
        <p>
            Setup your custom form on your website with the fields that you want
            to send, then use your form to send a test submission to the Webhook
            URL (<span class="code-inline">{formEndpoint}</span>).
        </p>
        <p>
            <strong class="animate-fadeinout"
                >Waiting for you to send a test form submission...</strong
            >
        </p>
        <p>
            Once we receive a test submission, we'll help you connect the fields
            in your form to the fields in your Contact's profile.
        </p>
    {:else}
        <div class="cols">
            <h3 class="flexcol">Captured Test Submission:</h3>
            <div class="fixedcol">
                <button
                    type="button"
                    on:click|stopPropagation={recaptureTestSubmission}
                    class="button"
                >
                    <span class="btn-text"> Remove & Recapture </span>
                </button>
            </div>
        </div>
        <pre class="captured-json">
            {JSON.stringify(testSubmissionBody, null, 2)}
        </pre>
    {/if}
</section>
{#if _get(testSubmissionFields, 'length')}
    <section>
        <hgroup>
            <h1>Step 2</h1>
            <h2>Map the Form fields to the Contact fields</h2>
        </hgroup>
        <table>
            <thead>
                <tr>
                    <th class="formfield">Field Name (as sent via Webhook)</th>
                    <th class="examplevalue">Example Value</th>
                    <th class="connection"></th>
                    <th class="contactfield">
                        Contact Field
                        <!-- <a href="/orgs/{orgId}/contactfields" style="font-weight:300;">manage</a> -->
                    </th>
                </tr>
            </thead>
            <tbody>
                {#each testSubmissionFields as field (field.data.apiName)}
                    <tr>
                        <td class="formfield">
                            <input
                                readonly
                                disabled
                                type="text"
                                value={field.data.apiName}
                            />
                        </td>
                        <td class="examplevalue">
                            <input
                                readonly
                                disabled
                                type="text"
                                value={JSON.stringify(
                                    _get(
                                        testSubmissionBody,
                                        field.data.apiName,
                                    ),
                                )}
                            />
                        </td>
                        <td class="connection">
                            <div class="link-graphic">
                                <span class="link-start"></span>
                                <span class="link-bar"></span>
                                <span class="link-end"></span>
                            </div>
                        </td>
                        <td class="contactfield">
                            <Select
                                items={contactFields}
                                placeholder="Select existing or create new..."
                                optionIdentifier="ref"
                                selectedValue={_find(contactFields, {
                                    ref: _get(field, 'data.contactField.ref'),
                                })}
                                getOptionLabel={(option, filterText) =>
                                    option.isCreator
                                        ? `Create "${filterText}"`
                                        : option.data.name}
                                getSelectionLabel={(option) => option.data.name}
                                isCreatable={true}
                                isClearable={true}
                                createItem={createLocalContactField}
                                on:select={(event) =>
                                    updateFieldMapping(field, event.detail)}
                                on:clear={() => clearFieldMapping(field)}
                                isDisabled={savingFieldMappings[
                                    field.data.apiName
                                ]}
                                showIndicator={!_get(
                                    field,
                                    'data.contactField.ref',
                                )}
                            />
                        </td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </section>
{/if}

<style>
    @keyframes fadeInOut {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    .animate-fadeinout {
        animation: fadeInOut 1s infinite;
    }
    h1,
    h2,
    h3 {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        margin: 0 0 10px;
    }
    section {
        border-top: 1px solid var(--slate-o10);
        padding-top: 20px;
        margin-top: 20px;
    }
    hgroup h1 {
        margin: 0;
    }
    hgroup h2 {
        opacity: 0.5;
    }
    .code-inline {
        background: var(--slate-o10);
        border-radius: 2px;
        padding: 0 2px;
        font-family: monospace;
    }
    .cols {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
    }
    .cols h3 {
        padding: 0;
        margin: 0;
    }
    .fixedcol {
        flex-grow: 0;
        flex-shrink: 0;
    }
    .flexcol {
        flex-grow: 1;
        flex-shrink: 1;
    }
    table {
        margin-top: 20px;
        width: 100%;
    }
    table .formfield {
        width: 24%;
    }
    table .examplevalue {
        width: 36%;
    }
    table .contactfield {
        width: 30%;
    }
    table .formfield input,
    table .examplevalue input {
        width: 100%;
        font-family: monospace;
    }
    table .connection {
        width: 120px;
    }
    th,
    td {
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        vertical-align: middle;
        padding: 5px 0;
    }
    th {
        font-weight: 600;
    }
    .link-graphic {
        position: relative;
    }
    .link-bar {
        position: absolute;
        background: var(--slate-o10);
        background: var(--lightblue);
        left: 22px;
        right: 22px;
        top: 50%;
        margin-top: -1px;
        height: 2px;
        border-radius: 2px;
    }
    .link-start,
    .link-end {
        position: absolute;
        top: 50%;
        margin-top: -5px; /* half height + border-width */
        border-radius: 5px;
        width: 6px;
        height: 6px;
        border: 2px solid var(--slate-o10);
        border-color: var(--blue);
    }
    .link-start {
        left: 10px;
    }
    .link-end {
        right: 10px;
    }
    .captured-json {
        background: var(--lightblue);
        margin: 10px 0 0;
        color: var(--blue);
        padding: 10px;
    }
</style>
