<script>
    let isDropdownOpen = false;
    export let error;
    // let isOpen = false;
    const handleDropdownClick = () => {
        isDropdownOpen = !isDropdownOpen;
    };

    const handleDropdownFocusLoss = ({relatedTarget, currentTarget}) => {
        if (
            relatedTarget instanceof HTMLElement &&
            currentTarget.contains(relatedTarget)
        )
            return;
        isDropdownOpen = false;
    };
</script>

<svelte:head>
    <link
        href="https://cdn.jsdelivr.net/npm/daisyui@2.11.0/dist/full.css"
        rel="stylesheet"
        type="text/css"
    />
    <script src="https://cdn.tailwindcss.com"></script>
</svelte:head>

<div class="flex justify-between items-center example">
    {#if error.total == 0}
        <div>0</div>
    {:else}
    <div class="dropdown" on:focusout={handleDropdownFocusLoss}>
        <button class="error-button" on:click={handleDropdownClick}>
            <span class="error-text">{error.total}</span>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="4"
                viewBox="0 0 8 4"
                fill="none"
            >
                <path d="M8 0H0L4 4L8 0Z" fill="white" />
            </svg>
        </button>
        <div
            class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 error-list-box"
            style:visibility={ isDropdownOpen ? 'visible' : 'hidden' }
        >
            {#if error.emailFlagedAsSpam}
                <div class="error-list-item">
                    <div class="error-list-item-number-flag">1</div>
                    <div class="error-list-text">Emails flaged as spam</div>
                </div>
            {/if}
            {#if error.emailFlagedAsBounce}
                <div class="error-list-item">
                    <div class="error-list-item-number-flag">1</div>
                    <div class="error-list-text">
                        <span>Emails flaged as <br/>they bounced</span>
                    </div>
                </div>
            {/if}
            {#if error.emailDisabledAsSpam}
                <div class="error-list-item">
                    <div class="error-list-item-number-disable">1</div>
                    <div class="error-list-text">Emails disabled as spam</div>
                </div>
            {/if}
            {#if error.emailDisabledAsBounce}
                <div class="error-list-item">
                    <div class="error-list-item-number-disable">1</div>
                    <div class="error-list-text"><span>Emails disabled as <br/>they bounced</span></div>
                </div>
            {/if}
            {#if error.smsFlagedAsSpam}
                <div class="error-list-item">
                    <div class="error-list-item-number-flag">1</div>
                    <div class="error-list-text">SMS/MMS flaged as spam</div>
                </div>
            {/if}
            {#if error.smsFlagedAsUsage}
                <div class="error-list-item">
                    <div class="error-list-item-number-flag">1</div>
                    <div class="error-list-text">SMS/MMS flaged as usage</div>
                </div>
            {/if}
            {#if error.smsDisabledAsSpam}
                <div class="error-list-item">
                    <div class="error-list-item-number-disable">1</div>
                    <div class="error-list-text">SMS/MMS disabled as spam</div>
                </div>
            {/if}
            {#if error.smsDisabledAsUsage}
                <div class="error-list-item">
                    <div class="error-list-item-number">1</div>
                    <div class="error-list-text">SMS/MMS disabled as usage</div>
                </div>
            {/if}
            {#if error.webHookDisabeldAsUsage}
                <div class="error-list-item">
                    <div class="error-list-item-number-disable">1</div>
                    <div class="error-list-text">Webhook disabled as usage</div>
                </div>
            {/if}
        </div>
    </div>
    {/if}
</div>

<style>
    .error-button {
        display: flex;
        border-radius: 50px;
        padding: 3px 11px;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: #d65543;
        height: 20px !important;
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .error-text {
        margin-right: 4px;
    }
    .error-list-box {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        margin: 2px;
        display: flex;
        width: 220px;
        padding: 15px 20px 15px 12px;
        border-radius: 12px;
        background: #fff;
        box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.05);
    }
    .error-list-item {
        margin: 5px 0px;
        display: flex;
    }
    .error-list-item-number-flag {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px !important;
        height: 20px !important;
        /* margin-right: 7px; */
        border-radius: 30px;
        color: #fff;
        background-color: #ffa601;
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .error-list-item-number-disable {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px !important;
        height: 20px !important;
        /* margin-right: 7px; */
        border-radius: 30px;
        color: #fff;
        background-color: #d65543;
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .error-list-text {
        display: flex;
        padding-left: 7px;
        /* justify-content: center; */
        align-items: center;
        color: #9a9a9a;
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
</style>
