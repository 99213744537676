<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};
    FRAGMENTS.ContactStatusDropdown_contactStatus = gql`
        fragment ContactStatusDropdown_contactStatus on ContactStatus {
            ref
            data {
                name
                color
            }
        }
    `;
    FRAGMENTS.ContactStatusDropdown_contact = gql`
        fragment ContactStatusDropdown_contact on Contact {
            ref
            data {
                status {
                    ref
                    data {
                        ...ContactStatusDropdown_contactStatus
                    }
                }
                org {
                    ref
                    data {
                        contactStatuses {
                            data {
                                ref
                                data {
                                    ...ContactStatusDropdown_contactStatus
                                }
                            }
                        }
                    }
                }
            }
        }
        ${FRAGMENTS.ContactStatusDropdown_contactStatus}
    `;

    const QUERIES = {};

    const MUTATIONS = {};
    MUTATIONS.updateContactStatusOfContact = gql`
        mutation updateContactStatusOfContact(
            $contactId: ID!
            $contactStatusId: ID!
        ) {
            updateContactStatusOfContact(
                contactId: $contactId
                contactStatusId: $contactStatusId
            ) {
                ref
                data {
                    status {
                        ref
                        data {
                            name
                            color
                        }
                    }
                }
            }
        }
    `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getClient, mutation} from 'svelte-apollo';
    import _get from 'lodash/get';
    import Dropdown from './Dropdown.svelte';

    export let contactData;
    export let open = false;

    const client = getClient();
    const updateContactStatusOfContactMutation = mutation(
        MUTATIONS.updateContactStatusOfContact,
    );
    $: orgContactStatuses = _get(
        contactData,
        'data.org.data.contactStatuses',
        [],
    );
    let isLoading = false;
    $: isLoading = !!_get(contactData, 'ref');

    let isSaving = false;
    async function updateContactStatus(contactId, contactStatusId) {
        if (isSaving) return;
        isSaving = true;
        try {
            // let response = await mutate(client, {
            //     mutation: MUTATIONS.updateContactStatusOfContact,
            //     variables: {
            //         contactId: contactId,
            //         contactStatusId: contactStatusId,
            //     },
            // });
            let response = await updateContactStatusOfContactMutation({
                variables: {
                    contactId: contactId,
                    contactStatusId: contactStatusId,
                },
            });
            
            let tempContactData = contactData;
            tempContactData.data.status =
                response.data.updateContactStatusOfContact.data.status;
            contactData = tempContactData;
            isSaving = false;
            return response.data.updateContactStatusOfContact;
        } catch (error) {
            // FIXME handle errors better...
            alert(error.message);
            isSaving = false;
        }
    }
    async function selectContactStatus(statusData) {
        let result = await updateContactStatus(contactData.ref, statusData.ref);
        if (result) {
            open = false;
        }
    }
</script>

<Dropdown {open} on:cancel={() => (open = false)}>
    <button
        type="button"
        slot="trigger"
        class="contact-status-dropdown"
        on:click={() => (open = !open)}
    >
        <span
            class="contact-status-swatch"
            style="background-color:{_get(
                contactData,
                'data.status.data.color',
                'rgba(0,0,0,0.15)',
            )};">&nbsp;</span
        >
        <span class="value">
            {isLoading
                ? _get(contactData, 'data.status.data.name', '(no status)')
                : ''}
        </span>
        <ion-icon name="chevron-down-outline" class="btn-caret" />
    </button>
    <div slot="menu" class="status-menu">
        <ul class:muted={isSaving}>
            {#each orgContactStatuses as status (status.ref)}
                <li>
                    <button
                        type="button"
                        on:click={selectContactStatus(status)}
                        class="status-menu-item"
                        class:active={status.ref ==
                            _get(contactData, 'data.status.ref')}
                    >
                        <span
                            class="contact-status-swatch"
                            style="background-color:{status.data.color ||
                                'var(--offwhite)'};">&nbsp;</span
                        >
                        <span class="value">
                            {status.data.name || 'blank status'}
                        </span>
                    </button>
                </li>
            {/each}
        </ul>
    </div>
</Dropdown>

<style>
    .contact-status-swatch {
        display: inline-block;
        height: 8px;
        width: 8px;
        border-radius: 4px;
        /* box-shadow: inset 0 0 0 1px rgba(0,0,0,.2); */
        background: var(--slate-o20);
        vertical-align: middle;
    }
    .contact-status-dropdown {
        background: none;
        /* border: 1px solid var(--offoffwhite); */
        border-radius: 15px;
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 4px 0;
        align-items: center;
        width: 100%;
    }
    .contact-status-dropdown .contact-status-swatch {
        flex-grow: 0;
        flex-shrink: 0;
    }
    .contact-status-dropdown .value {
        flex-grow: 0;
        flex-shrink: 1;
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 5px;
        text-align: left;
    }
    .contact-status-dropdown ion-icon {
        flex-grow: 0;
        flex-shrink: 0;
        font-size: 12px;
        margin: 0;
        opacity: 0.6;
    }
    .status-menu {
        background: var(--slate);
        color: white;
        /* border: 1px solid var(--offoffwhite); */
        box-shadow: rgba(0, 0, 0, 0.2) 0 1px 2px;
        border-radius: 4px;
        padding: 4px 0;
        margin-top: 2px;
        min-width: 115%;
        overflow: hidden;
        transition: transform 150ms ease-in-out;
        /* transition: transform 200ms cubic-bezier(.47,1.64,.41,.8); */
        transform: scale(0.97) translateZ(0);
    }
    .status-menu:hover {
        transform: scale(1) translateZ(0);
    }
    .status-menu ul,
    .status-menu li {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .status-menu-item:hover {
        background: rgba(0, 0, 0, 0.2);
    }
    .status-menu-item.active {
        background: var(--blue);
    }
    .status-menu-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        padding: 5px 10px;
    }
    .status-menu-item .contact-status-swatch {
        flex-grow: 0;
        flex-shrink: 0;
    }
    .status-menu-item .value {
        flex-grow: 1;
        flex-shrink: 1;
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 5px;
    }
</style>
