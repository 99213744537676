<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};
    FRAGMENTS.DashboardEventListItem_contact = gql`
        fragment DashboardEventListItem_contact on ContactData {
            uids {
                data {
                    value
                    contactField {
                        data {
                            isEmail
                            isSms
                        }
                    }
                }
            }
            profile {
                data {
                    valueJson
                    contactField {
                        data {
                            isFirstName
                            isLastName
                        }
                    }
                }
            }
        }
    `;
    FRAGMENTS.NewDashboardEventListItem_dashboardEvent = gql`
        fragment NewDashboardEventListItem_dashboardEvent on NewDashboardEventData {
            lastUpdatedAt
            isHidden
            type
            relevantCount
            contact {
                ref
                data {
                    ...DashboardEventListItem_contact
                }
            }
            message {
                data {
                    strippedText
                    triggeredByInboundEmail {
                        messageId
                    }
                    triggeredByInboundSms {
                        messageId
                    }
                }
            }
            formSubmission {
                data {
                    form {
                        data {
                            name
                            title
                            isDeleted
                        }
                    }
                }
            }
        }
        ${FRAGMENTS.DashboardEventListItem_contact}
    `;

    const QUERIES = {};

    const MUTATIONS = {};
    MUTATIONS.updateNewDashboardEvent = gql`
        mutation updateNewDashboardEvent(
            $id: ID!
            $data: updateNewDashboardEventInput!
        ) {
            updateNewDashboardEvent(id: $id, data: $data) {
                ref
            }
        }
    `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getClient, mutation} from 'svelte-apollo';
    import _get from 'lodash/get';
    import _find from 'lodash/find';
    import _trim from 'lodash/trim';
    import {navigate} from 'svelte-routing';
    import moment from 'moment';
    import {parsePhoneNumberFromString} from 'libphonenumber-js';
    import Timestamp from './Timestamp.svelte';

    export let newDashboardEventData;
    export let newDashboardEventId;
    const client = getClient();
    const updateNewDashboardEventMutation = mutation(
        MUTATIONS.updateNewDashboardEvent,
    );
    $: contactData = _get(newDashboardEventData, 'contact', {});
    $: contactPath = `/contacts/${_get(contactData, 'ref')}`;
    let actionPath;
    $: switch (_get(newDashboardEventData, 'type')) {
        case 'MESSAGE_RECEIVED':
            actionPath = `${contactPath}/messages`;
            break;
        case 'FORM_SUBMITTED':
            actionPath = `${contactPath}/forms`;
            break;
    }

    const getProfile = (contact, where) => {
        let value = _get(
            _find(_get(contact, 'data.uids', []), {
                data: {contactField: {data: where}},
            }),
            'data.value',
        );
        if (value) {
            return value;
        }
        let valueJson = _get(
            _find(_get(contact, 'data.profile', []), {
                data: {contactField: {data: where}},
            }),
            'data.valueJson',
        );
        if (valueJson) {
            return JSON.parse(valueJson);
        }
    };

    $: profileFirstName = getProfile(contactData, {isFirstName: true}) || '';
    $: profileLastName = getProfile(contactData, {isLastName: true}) || '';
    $: fullName = _trim(`${profileFirstName} ${profileLastName}`);

    $: profileEmail = getProfile(contactData, {isEmail: true}) || '';

    $: profileSms = getProfile(contactData, {isSms: true}) || '';
    $: phoneNumber =
        parsePhoneNumberFromString(profileSms) ||
        parsePhoneNumberFromString('+1' + profileSms);
    $: phoneNumberDisplay = phoneNumber
        ? phoneNumber.formatNational()
        : profileSms;

    let displayName = '';
    $: {
        let bestName;
        if (fullName) {
            bestName = fullName;
        } else if (
            _get(newDashboardEventData, 'message.data.triggeredByInboundEmail')
        ) {
            bestName = profileEmail;
        } else if (
            _get(newDashboardEventData, 'message.data.triggeredByInboundSms')
        ) {
            bestName = phoneNumberDisplay;
        } else if (profileEmail) {
            bestName = profileEmail;
        } else if (phoneNumberDisplay) {
            bestName = phoneNumberDisplay;
        }
        displayName = bestName || 'no name';
    }

    let isSaving = false;
    async function updateNewDashboardEvent(data) {
        if (isSaving) return;
        isSaving = true;
        try {
            // let response = await mutate(client, {
            //     mutation: MUTATIONS.updateNewDashboardEvent,
            //     variables: {
            //         id: dashboardEventId,
            //         data: data,
            //     },
            // });
            let response = await updateNewDashboardEventMutation({
                variables: {
                    id: newDashboardEventId,
                    data: data,
                },
            });
            // client.writeFragment({
            //     id: dashboardEventId,
            //     fragment: FRAGMENTS.NewDashboardEventListItem_dashboardEvent,
            //     fragmentName: 'NewDashboardEventListItem_dashboardEvent',
            //     data: response.data.updateNewDashboardEvent,
            // });
            let tempNewDashboardEventData = {...newDashboardEventData};
            tempNewDashboardEventData.isHidden = data.isHidden;
            newDashboardEventData = tempNewDashboardEventData;
            isSaving = false;
            return response.data.updateNewDashboardEvent;
        } catch (error) {
            // FIXME handle errors better...
            isSaving = false;
            alert(error.message);
        }
    }
</script>

<div
    class="row"
    class:hidden={_get(newDashboardEventData, 'isHidden')}
    class:muted={isSaving}
>
    <div class="icon">
        {#if 'FORM_SUBMITTED' == _get(newDashboardEventData, 'type')}
            <ion-icon
                name="clipboard-outline"
                style="position:relative; top:1px;"
            />
        {:else if 'MESSAGE_RECEIVED' == _get(newDashboardEventData, 'type')}
            <ion-icon
                name="chatbubble-outline"
                style="position:relative; top:1px;"
            />
        {/if}
    </div>
    <div class="what">
        <a href="{contactPath}/sequences">
            {displayName}
        </a>
        {#if 'FORM_SUBMITTED' == _get(newDashboardEventData, 'type')}
            submitted
            <a href={actionPath}>
                ({_get(
                    newDashboardEventData,
                    'formSubmission.data.form.data.title',
                    'No Form Name',
                )})&nbsp;Lead Form&nbsp;&nbsp;{_get(
                    newDashboardEventData,
                    'relevantCount',
                    0,
                ) > 1
                    ? `( + ${_get(newDashboardEventData, 'relevantCount', 0) - 1} more submitted.)`
                    : ''}
            </a>
        {:else if 'MESSAGE_RECEIVED' == _get(newDashboardEventData, 'type')}
            said
            <a href={actionPath}>
                &quot;{_trim(
                    _get(newDashboardEventData, 'message.data.strippedText'),
                ) || '(empty message)'}&quot;&nbsp;&nbsp;{_get(
                    newDashboardEventData,
                    'relevantCount',
                    0,
                ) > 1
                    ? `( + ${_get(newDashboardEventData, 'relevantCount', 0) - 1} more received.)`
                    : ''}
            </a>
        {/if}
    </div>
    <div class="when">
        <Timestamp
            ts={_get(newDashboardEventData, 'lastUpdatedAt')}
            relative={true}
        />
    </div>
    <div class="controls">
        {#if _get(newDashboardEventData, 'isHidden')}
            <button
                type="button"
                title="restore"
                on:click|stopPropagation={() =>
                    updateNewDashboardEvent({isHidden: false})}
                disabled={isSaving}
            >
                <!-- <ion-icon name="refresh-outline" style="position:relative; top:1px;" /> -->
                <ion-icon
                    name="reload-outline"
                    style="position:relative; top:1px;"
                />
                <!-- <ion-icon name="sync-outline" style="position:relative; top:1px;" /> -->
            </button>
        {:else}
            <button
                type="button"
                title="clear"
                on:click|stopPropagation={() =>
                    updateNewDashboardEvent({isHidden: true})}
                disabled={isSaving}
            >
                <ion-icon
                    name="close-outline"
                    style="position:relative; top:1px;"
                />
            </button>
        {/if}
    </div>
</div>

<style>
    .row {
        border-top: 1px solid var(--offwhite);
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
    }
    .row.hidden {
        background: var(--slate-o10);
        background: var(--offwhite);
    }
    .icon {
        flex-grow: 0;
        flex-shrink: 0;
        font-size: 20px;
        padding-left: 15px;
    }
    .what {
        flex-grow: 1;
        flex-shrink: 1;
        padding: 20px 10px;
        font-size: 14px;
        line-height: 20px;
        font-weight: normal;
        color: var(--slate);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .hidden .what {
        text-decoration: line-through;
    }
    .what a {
        text-decoration: none;
        font-weight: 600;
        color: var(--black);
    }
    .what a:hover {
        text-decoration: underline;
    }
    .when {
        flex-grow: 0;
        flex-shrink: 0;
        padding: 20px 10px;
        text-align: right;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 160px;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
    }
    .controls {
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 15px;
    }
    .controls button {
        width: 30px;
        height: 30px;
        text-align: center;
    }
    .controls button ion-icon {
        font-size: 20px;
    }
</style>
