<script context="module">


    const FRAGMENTS = {};
    const QUERIES = {};
    const MUTATIONS = {};

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getClient, query} from 'svelte-apollo';
    import {navigate} from 'svelte-routing';
    import {scale, slide} from 'svelte/transition';
    import _get from 'lodash/get';
    import _map from 'lodash/map';
    import _isNumber from 'lodash/isNumber';
    import moment from 'moment';
    import HtmlHeadTitle from '../../components/HtmlHeadTitle.svelte';
    import AdminPageLayout from '../../components/AdminPageLayout.svelte';

    export let orgId;

    $: orgPath = `/admin/${orgId}`;
    let showHidden = false;

    const client = getClient();
    const pageTitle = 'Actions Sequence Templates';
</script>

<HtmlHeadTitle {pageTitle} />
<AdminPageLayout {orgId} title={pageTitle}>
    <section class="card listgroup">
        <header class="listgroup-head">
            <!-- <div class="listgroup-bulkaction">
                    <a>Select all</a>
                </div> -->
            <div class="listgroup-head-left" />
            <!-- <div class="listgroup-head-right">
                    <a>Sort Options</a>
                </div> -->
        </header>
        <ul />
    </section>
</AdminPageLayout>
<div>Actions Sequence Templates</div>

<style>
</style>
