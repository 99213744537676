<script>
  export let size = 25; // Default option
  export let handleChangeDropDown;
  export let disable = false;

  const options = [25, 50, 100];
  let isOpen = false;

  function toggleDropdown() {
    if (!disable) {
      isOpen = !isOpen;
    }
  }

  function selectOption(option) {
    if (!disable) {
      size = option;
      handleChangeDropDown(option);
      isOpen = false; // Close the dropdown
    }
  }
</script>

<div
  class="custom-dropdown {disable ? 'disabled' : ''}"
  on:click={toggleDropdown}
>
  <div class="dropdown-label">
    Display: {size} per page &nbsp;
    {#if isOpen}
      <ion-icon name="caret-up-outline" />
    {:else}
      <ion-icon name="caret-down-outline" />
    {/if}
  </div>

  {#if isOpen && !disable}
    <ul class="dropdown-menu">
      {#each options as option}
        <li
          class="dropdown-item"
          on:click|stopPropagation={() => selectOption(option)}
        >
          {option} per page
        </li>
      {/each}
    </ul>
  {/if}
</div>

<style>
  .custom-dropdown {
    display: inline-block;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 20px;
    padding: 8px 15px;
    font-size: 15px;
    background-color: white;
    font-family: "Arial", sans-serif;
    cursor: pointer;
    user-select: none;
    width: 180px;
    font-weight: 600;
  }

  .custom-dropdown.disabled {
    background-color: #f5f5f5;
    color: #888;
    pointer-events: none;
    cursor: not-allowed;
  }

  .dropdown-label {
    display: flex;
    align-items: center;
  }

  .arrow {
    margin-left: 5px;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 70px;
    width: 130px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 5px;
    z-index: 10;
    list-style: none;
    padding: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .dropdown-item {
    padding: 10px;
    cursor: pointer;
  }

  .dropdown-item:hover {
    background-color: #f0f0f0;
  }

  .dropdown-item:active {
    background-color: #e0e0e0;
  }

  .dropdown-item:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
</style>
