<script>
    // @ts-nocheck

    import {library} from '@fortawesome/fontawesome-svg-core';
    import {fas} from '@fortawesome/free-solid-svg-icons';
    import {FontAwesomeIcon} from '@fortawesome/svelte-fontawesome';
    import {icons} from './const/FontAwesomeIconList';

    export let initialIcon = 'fa-lightning';
    export let defaultIcon = 'fa-lightning';
    export let onChangeIcon;

    library.add(fas);

    $: selectedIcon = ['fas', initialIcon];
    let headerIcon = ['fas', initialIcon];
    let isShowIconPickerPanel = false;

    function selectIcon(icon) {
        selectedIcon = ['fas', icon];
        headerIcon = ['fas', icon];
        onChangeIcon(icon);
        isShowIconPickerPanel = false;
    }

    function handleDefaultButtonClick() {
        selectedIcon = ['fas', defaultIcon];
        headerIcon = ['fas', defaultIcon];
        onChangeIcon(defaultIcon);
        isShowIconPickerPanel = false;
    }

    function toggleIconPickerPanel() {
        isShowIconPickerPanel = !isShowIconPickerPanel;
    }
</script>

<div class="icon-picker-header">
    <div class="icon-picker-header-icon" on:click={toggleIconPickerPanel}>
        {#key headerIcon}
            <FontAwesomeIcon icon={headerIcon} />
        {/key}
    </div>
    <div class="icon-picker-header-label" on:click={toggleIconPickerPanel}>
        Select Icon
    </div>
    {#if isShowIconPickerPanel}
        <div class="default-button" on:click={handleDefaultButtonClick}>
            Default
        </div>
    {/if}
</div>

{#if isShowIconPickerPanel}
    <div class="icon-picker">
        {#each icons as icon (icon)}
            <button
                on:click={() => selectIcon(icon)}
                style="height:30px; width:30px; margin-bottom: 1px;"
                class:selected={selectedIcon[1] === icon}
            >
                <FontAwesomeIcon icon={['fas', icon]} />
            </button>
        {/each}
    </div>
{/if}

<style>
    .icon-picker {
        border: 1px solid #aaaaaa;
        border-radius: 4px;
        margin: 10px 0px;
        padding: 2px;
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;
        gap: 1px;
        width: 290px;
        height: 250px;
        &::-webkit-scrollbar {
            display: block;
            width: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #aaaaaa;
            border-radius: 6px;
        }

        &::-webkit-scrollbar-track {
            background-color: #f1f1f1;
        }
    }
    .icon-picker-header {
        display: flex;
        font-size: 12px;
        /* justify-content: center; */
        align-items: center;
        cursor: pointer;
    }
    .icon-picker-header-icon {
        width: 40px;
        height: 30px;
        border: 1px solid #aaaaaa;
        border-radius: 0px;
        background-color: #eeeeee;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .icon-picker-header-label {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;
        height: 30px;
        width: 70px;
        border: 1px solid #aaaaaa;
        border-radius: 0px;
        border-left: 0px solid white;
    }
    .default-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        padding: 0px 10px;
        width: 70px;
        cursor: pointer;
        border-radius: 2px;
        border: 1px solid #aaaaaa;
        margin-left: 4px;
    }
    .default-button:hover {
        color: #4286f5;
    }
    button {
        border: 1px solid #aaaaaa;
        background-color: transparent;
    }
    button.selected {
        border: 1px solid #007bff;
        background-color: #cce5ff;
    }
</style>
