<script context="module">
import gql from 'graphql-tag';
import {FRAGMENTS as OrgForm_FRAGMENTS} from '../../components/OrgForm.svelte';

const FRAGMENTS = {};
const QUERIES = {};
const MUTATIONS = {};

export {
    FRAGMENTS,
    QUERIES,
    MUTATIONS,
}
</script>

<script>
import { Router, Route, links, navigate } from "svelte-routing";
// import _get from 'lodash/get';
import Dashboard from './Dashboard.svelte';
import EditOrg from './EditOrg.svelte';
import Contacts from './contacts/Index.svelte';
import OrgMessages from './OrgMessages.svelte';
import Forms from './forms/Index.svelte';
import NewForm from './forms/NewForm.svelte';
import EditForm from './forms/EditForm.svelte';
import Sequences from './sequences/Index.svelte';
import EditSequence from './sequences/EditSequence.svelte';
import NewOrgSubscription from './subscription/NewOrgSubscription.svelte';
import EditOrgSubscription from './subscription/EditOrgSubscription.svelte';
import NotFound from '../NotFound.svelte';
import WebsiteIntegration from './WebsiteIntegration.svelte';

import SidebarLayout from '../../components/SidebarLayout.svelte';
import OrgSidebar from '../../components/OrgSidebar.svelte';
export let orgId;
export let location;
</script>

<SidebarLayout>
    <div slot="sidebar" style="height:100%;">
        <OrgSidebar orgId={orgId} location={location} />
    </div>
    <Router>
        <Route path="dashboard" component={Dashboard} orgId={orgId} />
        <Route path="contacts" component={Contacts} orgId={orgId} />
        <!-- <Route path="contacts/:contactId" component={Contact} orgId={orgId} /> -->
        <Route path="messages" component={OrgMessages} orgId={orgId} />
        <Route path="forms" component={Forms} orgId={orgId} />
        <!-- <Route path="forms/new" component={NewForm} orgId={orgId} /> -->
        <Route path="forms/:formId/*" component={EditForm} orgId={orgId} />
        <Route path="sequences" component={Sequences} orgId={orgId} />
        <Route path="sequences/:sequenceId" component={EditSequence} orgId={orgId} />
        <Route path="settings" component={EditOrg} orgId={orgId} />
        <Route path="plan/start" component={NewOrgSubscription} orgId={orgId} />
        <Route path="plan/manage" component={EditOrgSubscription} orgId={orgId} />
        <Route path="websiteintegration" component={WebsiteIntegration} orgId={orgId} />
        <Route path="" component={NotFound} />
    </Router>
</SidebarLayout>

<style>
</style>
