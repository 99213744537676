<script context="module">
import gql from 'graphql-tag';
import { FRAGMENTS as SearchListItemContact_FRAGMENTS } from '../components/SearchListItemContact.svelte';

const FRAGMENTS = {};
// FRAGMENTS.Search_org = gql`
//     fragment Search_org on Org {
//         _id
//         timeZone
//     }
// `;
FRAGMENTS.Search_contact = gql`
    fragment Search_contact on Contact {
        ...SearchListItemContact_contact
    }
    ${SearchListItemContact_FRAGMENTS.SearchListItemContact_contact}
`;

const QUERIES = {};
QUERIES.search = gql`
    query search($text: [String!]!, $orgId: String!, $size: Int!, $before: SearchFuzzactCursorInput, $after: SearchFuzzactCursorInput) {
        search(text: $text, orgId: $orgId, size: $size, before: $before, after: $after) {
            contacts {
                node {
                    ...Search_contact
                }
                score
            }
            before {
                first
                second
                third
                fourth
            }
            after {
                first
                second
                third
                fourth
            }
        }
    }
    ${FRAGMENTS.Search_contact}
`;

const MUTATIONS = {};

export {
    FRAGMENTS,
    QUERIES,
    MUTATIONS,
}
</script>

<script>
// import { getContext, beforeUpdate } from 'svelte';
import { getClient } from 'svelte-apollo';
// import { Router, Route } from 'svelte-routing';
import _get from 'lodash/get';
import _trim from 'lodash/trim';
import _concat from 'lodash/concat';
import _uniq from 'lodash/uniq';
import _omit from 'lodash/omit';
import _debounce from 'lodash/debounce';
import { watchQuery } from '../svelte-apollo-watch-query';
import HtmlHeadTitle from '../components/HtmlHeadTitle.svelte';
import Modal from '../components/Modal.svelte';
import SearchListItemContact from '../components/SearchListItemContact.svelte';
import { navigate } from 'svelte-routing';

// export let location;
export let orgId;

const client = getClient();
const TVALUE = 0.4;

$: orgPath = `/orgs/${orgId}`;

let searchText = '';
let navResultsIdx = 0;
let queryStatus = 'finished';

let prevSearchText;
let searchQuery;
let isInvalidSearchText = true;
let searchResults = [];
let isLoading = null;

function sortSearchWords(text) {
    if (_trim(text).length === 0) {
        return [];
    }

    return _trim(text).split(/\s+/).sort((a, b) => {
    	return b.length - a.length;	  
    })
}

function updateContactResults(fetchedContacts) {
    const contactIdVsNode = {};

    searchResults.forEach((data) => {
        if (!contactIdVsNode[data.node.ref]) {
            contactIdVsNode[data.node.ref] = true;
        }
    });


    const newData = fetchedContacts.filter((data) => {
        if (contactIdVsNode[data.node.ref]) {
            return false;
        } else {
            return true;
        }
    })
    searchResults = 
        _concat(searchResults, newData)
        .sort((a, b) => b.score - a.score)
        .slice(0, 50);
}

function updateSearchQuery(updateState) {
    if (isInvalidSearchText) {
        queryStatus = "finished";
        return;
    }

    let newSearchText = _trim(searchText);
    
    if (prevSearchText === newSearchText && updateState === "new") {
        return;
    }

    const cursor = {
        before: null,
        after: null,
    }

    if (updateState === "fetchMore") {
        if (searchResults[searchResults.length - 1] > 100 * TVALUE * searchText.length) {
            return;
        }
        cursor.after = _omit(_get($searchQuery, "data.search.after", null), ['__typename']);
        // console.log("cursor.after ==>", cursor.after)
    } else {
        searchResults = [];
    }

    searchQuery = newSearchText ? watchQuery(client, {
        query: QUERIES.search,
        variables: {
            text: sortSearchWords(newSearchText),
            orgId,
            size: 50,
            before: null,
            after: cursor.after,
        },
        
        pollInterval: null,
    }) : null;

    queryStatus = "fetching"

    prevSearchText = newSearchText;
}
const updateSearchQueryDebounced = _debounce(updateSearchQuery, 300);
$: pageTitle = `"${searchText}"`;
$: isInvalidSearchText = Math.max(
    ..._trim(searchText)
        .split(/\s+/)
        .map(str => str.length)
    ) < 3;
$: _trim(searchText) ? updateSearchQueryDebounced("new") : updateSearchQuery("new");
$: isLoading = _get($searchQuery, "loading", null);
$: {
    if (isLoading === false) {
        if (_get($searchQuery, "errors", false)) {
            queryStatus = 'finished';
        }
        if (_get($searchQuery, "data.search.after", null) === null) {
            queryStatus = "finished"
        }
    }
}
$: updateContactResults(_get($searchQuery, 'data.search.contacts', []));
$: {
    // console.log(queryStatus, $searchQuery);
    if (queryStatus === "fetching" && $searchQuery && !$searchQuery.loading && !$searchQuery.errors) {
        // console.log("fetch more")
        updateSearchQueryDebounced("fetchMore");
    }
}

function handleEnter() {
    let result = _get(searchResults, navResultsIdx, null);
    if (!result) return;
    if (_get(result, 'node')) {
        let contactId = _get(result, 'node.ref');
        navigate(`/contacts/${contactId}/sequences`);
    }
}
</script>

<HtmlHeadTitle {pageTitle} />

<Modal>
    <article class="search-layout" class:muted={searchQuery && !_get($searchQuery, 'data')}>
        <header class="search-header">
            <input type="search" autofocus bind:value={searchText}
                class="large"
                placeholder="Search contacts by name, email, phone..."
                on:keydown={event => searchText ? event.stopPropagation() : null}
                on:keypress={event => event.keyCode == 13 ? handleEnter() : null}
            />
            <p>{ isInvalidSearchText > 0 ? "Minimum 3 characters required to search." : ""}</p>
        </header>
        {#if searchQuery}
            {#if $searchQuery.errors}
                <div class="search-content">
                    errors: <pre>{JSON.stringify($searchQuery.errors, null, 4)}</pre>
                </div>
                
            {:else if !searchResults.length && !$searchQuery.loading}
                <div class="search-content">
                    <div class="empty-message">
                        <div class="iconcol">
                            <ion-icon name="skull" />
                        </div>
                        <div class="textcol">
                            <div class="text-primary">
                                No results for "{searchText}"
                            </div>
                            <!-- <div class="text-secondary">
                                Submit one of your lead forms on your website to see a contact appear here.
                                <br />
                                Or <a href={`/orgs/${orgId}/forms`}>create your first lead form</a> if you haven't already.
                            </div> -->
                        </div>
                    </div>
                </div>
            {:else if !isInvalidSearchText}
                {#if $searchQuery.loading && !searchResults.length}
                    <div class="spinner"></div>
                {/if}
                <div class="search-content">
                    {#each searchResults as result, idx}
                        {#if result.node}
                            <SearchListItemContact
                                contactData={result.node}
                                searchText={prevSearchText}
                                hasEnterAction={navResultsIdx == idx}
                            />
                        {/if}
                    {/each}
                </div>
            {/if}
        {/if}
    </article>
</Modal>

<style>
.search-layout {
    max-width: 600px;
    margin: 0 auto;
    /* display: flex;
    flex-direction: column;
    align-items: stretch;
    background: var(--white);
    color: var(--offblack);
    border-radius: 10px; */
    box-shadow: none;
}
.search-header {
    position: fixed;
    z-index: 10;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
    max-width: 80%;
    /* background: var(--white); */
    /* background: var(--slate); */
    /* color: var(--white); */
    /* flex-grow: 0;
    flex-shrink: 0; */
    /* border-radius: 10px 0 0 10px; */
    /* border-radius: 20px; */
    /* width: 240px; */
}
.search-header input {
    background: var(--white);
    box-shadow: 0 0 30px rgba(0,0,0,0.3);
}
.search-header input:focus {
    box-shadow: 0 0 0 4px var(--blue), 0 0 30px rgba(0,0,0,0.3);
}
.search-content {
    background: var(--white);
    color: var(--slate);
    border-radius: 20px;
    overflow: hidden;
    margin-top: 85px;
    /* margin-bottom: 100px; */
    box-shadow: 0 0 30px rgba(0,0,0,.15);
}

.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #fff;

  border-radius: 100%;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

.empty-message {
    /* margin: 20px; */
    padding: 20px;
    /* background: var(--lightblue); */
    color: var(--blue);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-around; */
    width: 100%;
    box-sizing: border-box;
}
.empty-message .iconcol {
    flex-grow: 0;
    flex-shrink: 0;
    line-height: 20px;
    font-size: 48px;
    margin-right: 10px;
}
.empty-message .textcol {
    flex-grow: 1;
    flex-shrink: 0;
}
.empty-message .text-primary {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
}
/* .empty-message .text-secondary {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    opacity: 0.8;
} */
</style>
