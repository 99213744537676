<script>
import { setContext } from 'svelte';

let rootElement;

setContext('rootElement', {
    getRootElement: () => ({rootElement}),
})

</script>

<div class="sidebar-layout">
    <div class="sidebar-layout-sidebar">
        <slot name="sidebar" />
    </div>
    <div class="sidebar-layout-content" bind:this={rootElement}>
        <slot />
    </div>
</div>

<style>
.sidebar-layout {
    /* flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch; */
    /* height: 100%; */
    /* overflow: auto; */
    /* overscroll-behavior: auto; */
    height: 100%;
}
.sidebar-layout-sidebar {
    background: linear-gradient(168deg, var(--offblack), var(--blue));
    background: var(--blue);
    color: #fff;
    /* border-radius: 3px 0 0 3px; */
    background-clip: border-box;
    /* flex-grow: 0;
    flex-shrink: 0;
    box-sizing: border-box; */
    width: 230px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
}
.sidebar-layout-content {
    /* flex-grow: 1; */
    overflow: auto;
    --webkit-overflow-scrolling: touch;
    padding: 0;
    margin: 0;
    margin-left: 230px;
    /* background: var(--offwhite); */
    color: var(--slate);
    height: 100%;
    /* border-radius: 0 3px 3px 0; */
}
</style>
