<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};
    FRAGMENTS.Home_user = gql`
        fragment Home_user on Org {
            ref
            data {
                adminStatus
                deletionStatus
            }
        }
    `;

    const QUERIES = {};
    QUERIES.getUserCredential = gql`
        query getUserCredential($auth0_id: String!) {
            getUserCredential(auth0_id: $auth0_id) {
                ...Home_user
            }
        }
        ${FRAGMENTS.Home_user}
    `;

    const MUTATIONS = {};

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import _get from 'lodash/get';
    import {getClient, query} from 'svelte-apollo';
    import {navigate} from 'svelte-routing';
    import HtmlHeadTitle from '../components/HtmlHeadTitle.svelte';
    import {onMount} from 'svelte';
    import {getUserCredentials} from '../apollo-client-app';
    export let location;
    const client = getClient();

    const pageTitle = 'Loading account...';

    let userAuth0_id = '';

    onMount(async () => {
        try {
            const response = await getUserCredentials();
            const responseData = await response.json();
            userAuth0_id = responseData.userAuth0_id;

            if (window.location.pathname == location.pathname) {
                try {
                    const result = await client.query({
                        query: QUERIES.getUserCredential,
                        variables: {auth0_id: userAuth0_id},
                        pollInterval: 0,
                    });

                    let userCredential = result.data.getUserCredential;

                    if (userCredential && userCredential.ref) {
                        if (!!userCredential.data.deletionStatus) {
                            alert('This account is temporary deleted.');
                        } else {
                            if (userCredential.data.adminStatus == true) {
                                navigate(`/admin/${userCredential.ref}`, {
                                    replace: true,
                                });
                            } else {
                                navigate(`/orgs/${userCredential.ref}`, {
                                    replace: true,
                                });
                            }
                        }
                    } else {
                        navigate('/orgs/new', {replace: true});
                    }
                } catch (error) {
                    console.log(error);
                    if (/Missing authorization/.test(error.message)) {
                        navigate('/login');
                    }
                }
            }
        } catch (error) {
            console.log(error);
            if (/Missing authorization/.test(error.message)) {
                navigate('/login');
            }
        }
    });
</script>

<HtmlHeadTitle {pageTitle} />

<style>
</style>
