<script>
import company from '../../../translations/company'

export let pageTitle = '';
export let hideBranding = false;
</script>

<svelte:head>
	<title>{pageTitle}{hideBranding ? '' : `• ${__APP_NAME__}`}</title>
</svelte:head>
