<script>
</script>

<div class="canceled-icon">
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
    >
        <g clip-path="url(#clip0_111_317)">
            <path
                d="M7.9377 2.01601C7.9573 2.00527 7.97935 1.99975 8.0017 2.00001C8.02372 1.99993 8.04539 2.00543 8.0647 2.01601C8.08757 2.02973 8.10624 2.04944 8.1187 2.07301L14.9757 13.74C15.0117 13.8 15.0107 13.864 14.9777 13.923C14.965 13.9472 14.9464 13.9679 14.9237 13.983C14.9039 13.9952 14.8809 14.0011 14.8577 14H1.1457C1.12249 14.0011 1.09948 13.9952 1.0797 13.983C1.05696 13.9679 1.03839 13.9472 1.0257 13.923C1.00922 13.8953 1.00069 13.8635 1.00105 13.8312C1.0014 13.799 1.01062 13.7674 1.0277 13.74L7.8837 2.07301C7.8962 2.04947 7.91487 2.02977 7.9377 2.01601ZM8.9817 1.56601C8.88271 1.39358 8.73998 1.25031 8.56791 1.15069C8.39584 1.05106 8.20053 0.998596 8.0017 0.998596C7.80287 0.998596 7.60756 1.05106 7.43549 1.15069C7.26343 1.25031 7.12069 1.39358 7.0217 1.56601L0.164702 13.233C-0.292298 14.011 0.255702 15 1.1447 15H14.8577C15.7467 15 16.2957 14.01 15.8377 13.233L8.9817 1.56601Z"
                fill="#D64366"
            />
            <path
                d="M7.00195 12C7.00195 11.8687 7.02782 11.7386 7.07807 11.6173C7.12833 11.496 7.20199 11.3857 7.29485 11.2929C7.38771 11.2 7.49794 11.1264 7.61927 11.0761C7.7406 11.0259 7.87063 11 8.00195 11C8.13328 11 8.26331 11.0259 8.38464 11.0761C8.50596 11.1264 8.6162 11.2 8.70906 11.2929C8.80192 11.3857 8.87558 11.496 8.92583 11.6173C8.97609 11.7386 9.00195 11.8687 9.00195 12C9.00195 12.2652 8.8966 12.5196 8.70906 12.7071C8.52152 12.8946 8.26717 13 8.00195 13C7.73674 13 7.48238 12.8946 7.29485 12.7071C7.10731 12.5196 7.00195 12.2652 7.00195 12ZM7.09995 5.995C7.08664 5.86884 7.09999 5.74129 7.13915 5.62062C7.17831 5.49996 7.24241 5.38888 7.32727 5.29458C7.41214 5.20029 7.51588 5.12489 7.63176 5.07328C7.74765 5.02167 7.87309 4.995 7.99995 4.995C8.12681 4.995 8.25226 5.02167 8.36814 5.07328C8.48403 5.12489 8.58777 5.20029 8.67263 5.29458C8.7575 5.38888 8.82159 5.49996 8.86075 5.62062C8.89991 5.74129 8.91327 5.86884 8.89995 5.995L8.54995 9.502C8.53819 9.63977 8.47516 9.76811 8.37331 9.86163C8.27146 9.95515 8.13823 10.007 7.99995 10.007C7.86168 10.007 7.72844 9.95515 7.6266 9.86163C7.52475 9.76811 7.46171 9.63977 7.44995 9.502L7.09995 5.995Z"
                fill="#D64366"
            />
        </g>
        <defs>
            <clipPath id="clip0_111_317">
                <rect width="15" height="15" fill="white" />
            </clipPath>
        </defs>
    </svg>
</div>

<style>
    .canceled-icon {
        width: 15px;
        height: 15px;
        margin-right: 4px;
    }
</style>
