<script context="module">
import gql from 'graphql-tag';

const FRAGMENTS = {};
const QUERIES = {};
const MUTATIONS = {};

export {
    FRAGMENTS,
    QUERIES,
    MUTATIONS,
}
</script>

<script>
import { get } from 'svelte/store';
import { getClient, query } from 'svelte-apollo';
import { navigate } from 'svelte-routing';
import HtmlHeadTitle from '../../components/HtmlHeadTitle.svelte';
import SidebarLayout from '../../components/SidebarLayout.svelte';
import FakeOrgSidebar from '../../components/FakeOrgSidebar.svelte';
import OrgForm from '../../components/OrgForm.svelte';
import {getUser} from '../../auth0'
import { onMount } from 'svelte';

const client = getClient();
const pageTitle = 'Create an Account for your Organization';

let user;
onMount(async () => {
    user = await getUser();
})

async function handleCreate(event) {
    navigate(`/orgs/${event.detail.ref}`);
}

</script>


<HtmlHeadTitle {pageTitle} />

<SidebarLayout>
    <div slot="sidebar" style="height:100%;">
        <FakeOrgSidebar fakeOrgName="New Org" />
    </div>
    <header>
        <div class="iconcol">
            <ion-icon name="bonfire" />
            <!-- <ion-icon name="sunny" /> -->
            <!-- <ion-icon name="trail-sign" /> -->
        </div>
        <div class="textcol">
            <h1>Welcome to LeadAction!</h1>
            <h2>Let's create an account for your organization.</h2>
        </div>
    </header>
    <div class="org-form">
        {#if user}
        <OrgForm
            isNewOrg={true}
            on:save={handleCreate}
            auth0_id = {user.sub}
        />
        {:else}
        <p>Loading User Infomation</p>
        {/if}

    </div>
</SidebarLayout>

<style>
header {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
    /* background: var(--lightblue); */
    color: var(--blue);
}
header .iconcol {
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 64px;
    margin-right: 20px;
    line-height: 48px;
}
header .textcol {
    flex-grow: 1;
    flex-shrink: 1;
}
header h1,
header h2 {
    margin: 0;
    padding: 0;
}
header h1 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
}
header h2 {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
}
.org-form {
    padding: 0 20px 50px;
}
</style>
