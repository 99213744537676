<script context="module">
    import gql from 'graphql-tag';
    import {FRAGMENTS as DashboardEventListItem_FRAGMENTS} from '../../components/DashboardEventListItem.svelte';

    const FRAGMENTS = {};
    FRAGMENTS.Dashboard_event = gql`
        fragment Dashboard_event on NewDashboardEventData {
            ...NewDashboardEventListItem_dashboardEvent
        }
        ${DashboardEventListItem_FRAGMENTS.NewDashboardEventListItem_dashboardEvent}
    `;

    FRAGMENTS.Dashboard_user = gql`
        fragment Dashboard_user on User {
            ref
            data {
                email
            }
        }
    `;

    const QUERIES = {};
    // QUERIES.recentDashboardEvents = gql`
    //     query recentDashboardEvents(
    //         $orgId: ID!
    //         $isHidden: Boolean
    //         $size: Int!
    //         $before: Time
    //         $after: Time
    //     ) {
    //         recentDashboardEvents(
    //             orgId: $orgId
    //             isHidden: $isHidden
    //             size: $size
    //             before: $before
    //             after: $after
    //         ) {
    //             page {
    //                 before
    //                 after
    //                 data {
    //                     node {
    //                         ref
    //                         data {
    //                             ...Dashboard_event
    //                         }
    //                     }
    //                 }
    //             }
    //             aggregate {
    //                 count
    //             }
    //         }
    //     }
    //     ${FRAGMENTS.Dashboard_event}
    // `;
    QUERIES.recentNewDashboardEvents = gql`
        query recentNewDashboardEvents(
            $orgId: ID!
            $isHidden: Boolean
            $size: Int!
            $before: Time
            $after: Time
        ) {
            recentNewDashboardEvents(
                orgId: $orgId
                isHidden: $isHidden
                size: $size
                before: $before
                after: $after
            ) {
                page {
                    before
                    after
                    data {
                        node {
                            ref
                            data {
                                ...Dashboard_event
                            }
                        }
                    }
                }
                aggregate {
                    count
                }
            }
        }
        ${FRAGMENTS.Dashboard_event}
    `;
    
    QUERIES.newDashboardEventsCount = gql`
        query newDashboardEventsCount(
            $orgId: ID!
            $isHidden: Boolean
            $sinceDate: Time
        ) {
            newDashboardEventsCount(
                orgId: $orgId
                isHidden: $isHidden
                sinceDate: $sinceDate
            )
        }
    `;
    QUERIES.orgContactsCount = gql`
        query orgContactsCount($orgId: ID!, $sinceDate: Time) {
            orgContactsCount(orgId: $orgId, sinceDate: $sinceDate)
        }
    `;
    QUERIES.orgInboundMessagesCount = gql`
        query orgInboundMessagesCount($orgId: ID!, $sinceDate: Time) {
            orgInboundMessagesCount(orgId: $orgId, sinceDate: $sinceDate)
        }
    `;
    QUERIES.org = gql`
        query org($id: ID!) {
            findOrgByID(id: $id) {
                ref
                data {
                    verificationStatus
                    brandRegistrationStatus {
                        brandStatus
                        brandId
                    }
                    campaignRegistrationStatus {
                        campaignId
                        campaignStatus
                    }
                    name
                    billingPhone
                    country
                    state
                    city
                    street
                    postalCode
                }
            }
        }
    `;

    QUERIES.getUserByOrg = gql`
        query getUserByOrg($orgId: ID!) {
            getUserByOrg(orgId: $orgId) {
                ...Dashboard_user
            }
        }
        ${FRAGMENTS.Dashboard_user}
    `;

    const MUTATIONS = {};
    // MUTATIONS.updateForm = gql`
    //     mutation updateForm($id: ID!, $data: PartialUpdateFormInput!) {
    //         partialUpdateForm(id: $id, data: $data) {
    //             ...form
    //         }
    //     }
    //     ${FRAGMENTS.form}
    // `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {onMount} from 'svelte';
    import {getClient, query} from 'svelte-apollo';
    import {navigate} from 'svelte-routing';
    import {scale, slide} from 'svelte/transition';
    import _get from 'lodash/get';
    import _map from 'lodash/map';
    import _isNumber from 'lodash/isNumber';
    import moment from 'moment';
    import {watchQuery} from '../../svelte-apollo-watch-query';
    import HtmlHeadTitle from '../../components/HtmlHeadTitle.svelte';
    import OrgPageLayout from '../../components/OrgPageLayout.svelte';
    import Toggle from '../../components/Toggle.svelte';
    import DashboardEventListItem from '../../components/DashboardEventListItem.svelte';
    import BrandRegistrationModal from '../../components/BrandRegistrationModal.svelte';
    import PhoneVerificationSvg from '../../components/PhoneVerificationSvg.svelte';
    export let orgId;

    $: orgPath = `/orgs/${orgId}`;
    let showHidden = false;

    const client = getClient();
    const pageTitle = 'Dashboard';

    $: dashboardEventsQuery = watchQuery(client, {
        query: QUERIES.recentNewDashboardEvents,
        variables: {
            orgId: orgId,
            isHidden: showHidden ? null : false,
            size: 100,
        },
        pollInterval: null,
    });
    let isBrandRegistering = false;
    let verificationStatus;
    let orgData;
    let userEmail;

    onMount(async () => {
        try {
            const {data, errors} = await client.query({
                query: QUERIES.org,
                variables: {id: orgId}, // replace 'your-org-id' with the actual orgId
            });

            const userData = await client.query({
                query: QUERIES.getUserByOrg,
                variables: {orgId: orgId},
            });

            userEmail = userData.data.getUserByOrg.data.email;
            if (!errors) {
                orgData = data.findOrgByID.data;
                verificationStatus = orgData.verificationStatus
                    ? orgData.verificationStatus
                    : 'Not Verified';
                if (
                    !orgData.brandRegistrationStatus ||
                    !orgData.brandRegistrationStatus.brandId ||
                    orgData.brandRegistrationStatus.brandStatus ==
                        'Not Verified'
                ) {
                    isBrandRegistering = true;
                }
            }
        } catch (error) {
            console.log(error);
        }
    });

    let dashboardEvents = [];
    $: dashboardEvents = $dashboardEventsQuery.loading
        ? dashboardEvents
        : _map(
              _get(
                  $dashboardEventsQuery,
                  'data.recentNewDashboardEvents.page.data',
                  [],
              ),
              'node',
          );
    $: dashboardEventsCount = _get(
        $dashboardEventsQuery,
        'data.recentNewDashboardEvents.aggregate.count',
    );

    const sevenDaysAgo = moment().subtract(7, 'days').startOf('day');
    $: newDashboardEventsCountQuery = watchQuery(client, {
        query: QUERIES.newDashboardEventsCount,
        variables: {
            orgId: orgId,
            isHidden: showHidden ? null : false,
            sinceDate: sevenDaysAgo.toISOString(),
        },
        pollInterval: null,
    });
    $: newDashboardEventsCount = _get(
        $newDashboardEventsCountQuery,
        'data.newDashboardEventsCount',
    );

    const orgContactsCountQuery = watchQuery(client, {
        query: QUERIES.orgContactsCount,
        variables: {
            orgId: orgId,
            sinceDate: sevenDaysAgo.toISOString(),
        },
        pollInterval: 30000,
    });
    $: newContactsCount = _get($orgContactsCountQuery, 'data.orgContactsCount');

    const orgInboundMessagesCountQuery = watchQuery(client, {
        query: QUERIES.orgInboundMessagesCount,
        variables: {
            orgId: orgId,
            sinceDate: sevenDaysAgo.toISOString(),
        },
        pollInterval: 30000,
    });
    $: newInboundMessagesCount = _get(
        $orgInboundMessagesCountQuery,
        'data.orgInboundMessagesCount',
    );

    function handleBrandModalClose() {
        isBrandRegistering = false;
    }
    function handleCampaignModalClose() {
        isCampaignRegistering = false;
    }
</script>

<HtmlHeadTitle {pageTitle} />
{#if isBrandRegistering}
    <BrandRegistrationModal
        {handleBrandModalClose}
        {orgData}
        {orgId}
        {userEmail}
    />
{:else}
    <OrgPageLayout {orgId} title={pageTitle}>
        <header>
            <nav class="infographs">
                <div class="infograph">
                    <span class="icon">
                        <PhoneVerificationSvg
                            {verificationStatus}
                            size="45px"
                        />
                    </span>
                    <span class="text-col">
                        <span class="label"> Verification Status </span>
                        <span class="value">
                            {#if !verificationStatus}
                                ...
                            {:else}{verificationStatus}
                            {/if}
                        </span>
                    </span>
                </div>
                <a href="{orgPath}/dashboard" class="infograph active">
                    <span class="icon">
                        <ion-icon
                            name="checkmark-done"
                            style="--ionicon-stroke-width:48px;"
                        />
                        <!-- <ion-icon name="notifications" /> -->
                        <!-- <ion-icon name="flash" /> -->
                    </span>
                    <span class="text-col">
                        <span class="label">
                            New Notifications (past 7 days)
                        </span>
                        <span class="value">
                            {_isNumber(newDashboardEventsCount)
                                ? newDashboardEventsCount
                                : '...'}
                        </span>
                    </span>
                </a>
                <a href="{orgPath}/contacts" class="infograph">
                    <span class="icon">
                        <ion-icon name="people" />
                    </span>
                    <span class="text-col">
                        <span class="label"> New Contacts (past 7 days) </span>
                        <span class="value">
                            {_isNumber(newContactsCount)
                                ? newContactsCount
                                : '...'}
                        </span>
                    </span>
                </a>
                <a href="{orgPath}/messages" class="infograph">
                    <span class="icon">
                        <ion-icon name="chatbubbles" />
                    </span>
                    <span class="text-col">
                        <span class="label"> New Messages (past 7 days) </span>
                        <span class="value">
                            {_isNumber(newInboundMessagesCount)
                                ? newInboundMessagesCount
                                : '...'}
                        </span>
                    </span>
                </a>
            </nav>
        </header>
        <section class="card listgroup">
            <header class="listgroup-head">
                <div class="listgroup-head-left">
                    {#if !_isNumber(dashboardEventsCount)}
                        <span
                            class="chip"
                            in:scale={{duration: 120, delay: 160}}
                            out:scale={{duration: 120}}
                        >
                            loading notifications
                        </span>
                    {:else if showHidden}
                        <span
                            class="chip"
                            in:scale={{duration: 120, delay: 160}}
                            out:scale={{duration: 120}}
                        >
                            {_isNumber(dashboardEventsCount)
                                ? dashboardEventsCount
                                : 'loading'}
                            total notification{dashboardEventsCount !== 1
                                ? 's'
                                : ''}
                        </span>
                    {:else}
                        <span
                            class="chip"
                            in:scale={{duration: 120, delay: 160}}
                            out:scale={{duration: 120}}
                        >
                            {_isNumber(dashboardEventsCount)
                                ? dashboardEventsCount
                                : 'loading'}
                            open notification{dashboardEventsCount !== 1
                                ? 's'
                                : ''}
                        </span>
                    {/if}
                </div>
                <div class="listgroup-head-right">
                    <label class="filter-hidden">
                        <span class="text">Show Hidden?</span>
                        <Toggle bind:checked={showHidden}/>
                    </label>
                </div>
            </header>
            <ul>
                {#if $dashboardEventsQuery.loading && !dashboardEvents.length}
                    <li></li>
                {:else if $dashboardEventsQuery.errors}
                    <li>
                        errors: <pre>{JSON.stringify(
                                $dashboardEventsQuery.errors,
                                null,
                                4,
                            )}</pre>
                    </li>
                {:else if dashboardEvents.length}
                    {#each dashboardEvents as event (event.ref)}
                        <li transition:slide|local>
                            <DashboardEventListItem
                                newDashboardEventData={event.data}
                                newDashboardEventId={event.ref}
                            />
                        </li>
                    {/each}
                {:else}
                    <li>
                        <div class="empty-message">
                            <div class="iconcol">
                                <ion-icon name="checkmark-done-circle" />
                            </div>
                            <div class="textcol">
                                <div class="text-primary">
                                    Dashboard is clear.
                                </div>
                                <div class="text-secondary">
                                    Check back later for new messages or form
                                    submissions.
                                </div>
                            </div>
                        </div>
                    </li>
                {/if}
            </ul>
        </section>
    </OrgPageLayout>
{/if}

<style>
    .listgroup {
        margin: 0 20px;
    }
    .listgroup-head,
    .listgroup li {
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }
    .listgroup-head-left {
        flex-grow: 1;
        flex-shrink: 1;
        padding: 12px 15px;
    }
    .listgroup-head-right {
        flex-grow: 0;
        flex-shrink: 0;
        padding: 12px 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .chip {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 700;
        line-height: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 15px;
        padding: 0 10px;
        background: var(--offwhite);
    }
    .filter-hidden {
        font-size: 14px;
        font-weight: 600;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .filter-hidden .text {
        margin-right: 6px;
    }
    ul,
    li {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .card {
        background: var(--white);
        border: 1px solid var(--offoffwhite);
        border-radius: 9px;
    }
    .infographs {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        flex-direction: row;
        align-items: stretch;
        padding: 0 20px 20px;
    }
    .infograph {
        flex-grow: 1;
        flex-shrink: 1;
        width: 30%;
        display: flex;
        flex-direction: row;
        padding: 10px;
        border: 2px solid var(--slate-o10);
        border-radius: 9px;
        text-decoration: none;
    }
    .infograph + .infograph {
        margin-left: 20px;
    }
    .infograph:hover {
        border-color: var(--slate);
    }
    .infograph.active {
        border-color: var(--blue);
        color: var(--blue);
    }
    .infograph .icon {
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 10px;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        background: var(--slate-o10);
        font-size: 32px;
        line-height: 56px;
        text-align: center;
    }
    .infograph.active .icon {
        background: var(--lightblue);
    }
    .infograph .text-col {
        flex-grow: 1;
        flex-shrink: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 5px;
    }
    .infograph .label {
        display: block;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }
    .infograph .verification {
        display: block;
        font-size: 20px;
        font-weight: 400;
        line-height: 16px;
    }
    .infograph .value {
        display: block;
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        margin-top: -3px;
    }
    .empty-message {
        margin: 20px;
        padding: 20px;
        background: var(--lightblue);
        color: var(--blue);
        border-radius: 5px;
        display: flex;
        flex-direciton: row;
        align-items: center;
        /* justify-content: space-around; */
        width: 100%;
        box-sizing: border-box;
    }
    .empty-message .iconcol {
        flex-grow: 0;
        flex-shrink: 0;
        line-height: 20px;
        font-size: 48px;
        margin-right: 10px;
    }
    .empty-message .textcol {
        flex-grow: 1;
        flex-shrink: 0;
    }
    .empty-message .text-primary {
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
    }
    .empty-message .text-secondary {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        opacity: 0.8;
    }
</style>
