<script context="module">
    import gql from 'graphql-tag';
    import {FRAGMENTS as MessageTemplateBlock_FRAGMENTS} from './MessageTemplateBlock.svelte';

    const FRAGMENTS = {};
    FRAGMENTS.MessageTemplateConnection_messageTemplate = gql`
        fragment MessageTemplateConnection_messageTemplate on MessageTemplateData {
            ...MessageTemplateBlock_messageTemplate
        }
        ${MessageTemplateBlock_FRAGMENTS.MessageTemplateBlock_messageTemplate}
    `;
    FRAGMENTS.MessageTemplateConnection_messageTemplateOption = gql`
        fragment MessageTemplateConnection_messageTemplateOption on MessageTemplateData {
            body
            subjectLine
        }
    `;
    FRAGMENTS.MessageTemplateConnection_org = gql`
        fragment MessageTemplateConnection_org on OrgData {
            messageTemplates {
                ref
                data {
                    ...MessageTemplateConnection_messageTemplateOption
                }
            }
        }
        ${FRAGMENTS.MessageTemplateConnection_messageTemplateOption}
    `;

    const QUERIES = {};

    const MUTATIONS = {};

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {createEventDispatcher} from 'svelte';
    import {getClient} from 'svelte-apollo';
    import _get from 'lodash/get';
    import Select from 'svelte-select';
    import MessageTemplateBlock from './MessageTemplateBlock.svelte';

    const client = getClient();
    const dispatch = createEventDispatcher();

    export let messageTemplateData;
    export let orgData;

    let isLoading = true;
    $: if (_get(orgData, 'ref')) {
        if (isLoading) {
            isLoading = false;
        }
    } else {
        if (!isLoading) {
            isLoading = true;
        }
    }

    let handleCreate = () => dispatch('create');
    let handleConnect = (id) => dispatch('connect', id);
    let handleDisconnect = () =>
        dispatch('disconnect', _get(messageTemplateData, 'ref'));
</script>

<div class="edit-message-template-connection" class:loading={isLoading}>
    {#if messageTemplateData}
        <header class="cols">
            <h1 class="flexcol">Selected Message Template</h1>
            <div class="fixedcol">
                <button
                    type="button"
                    on:click|stopPropagation={() => handleDisconnect()}
                    class="button"
                >
                    <span class="btn-text">
                        Choose a different message template
                    </span>
                </button>
            </div>
        </header>
        <div class="content">
            <MessageTemplateBlock {messageTemplateData} {orgData} />
        </div>
    {:else}
        <header class="cols">
            <div class="fixedcol">
                <button
                    type="button"
                    on:click|stopPropagation={() => handleCreate()}
                    class="button"
                >
                    <span class="btn-text">
                        Create a New Message Template
                    </span>
                </button>
            </div>
            <div class="fixedcol" style="padding:0 10px;">or</div>
            <div class="flexcol">
                <Select
                    items={_get(orgData, 'data.messageTemplates') || []}
                    placeholder="Select an existing message template..."
                    optionIdentifier="ref"
                    getOptionLabel={(option, filterText) =>
                        `${option.data.subjectLine || '(no subject)'}\n${
                            option.data.body || '(no content)'
                        }`}
                    getSelectionLabel={(option) =>
                        `${option.data.subjectLine || '(no subject)'}\n${
                            option.data.body || '(no content)'
                        }`}
                    isCreatable={false}
                    isClearable={false}
                    on:select={(event) => handleConnect(event.detail.ref)}
                    isDisabled={false}
                    showIndicator={true}
                />
            </div>
        </header>
    {/if}
</div>

<style>
    .edit-message-template-connection {
        /* background: var(--slate-o10);
    border-radius: 9px;
    border: 1px solid var(--slate-o10); */
    }
    .cols {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
    }
    .fixedcol {
        flex-grow: 0;
        flex-shrink: 0;
    }
    .flexcol {
        flex-grow: 1;
        flex-shrink: 1;
    }
    header {
        padding: 10px 0;
    }
    h1 {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .content {
        /* background: white;
    border-bottom-right-radius: 9px;
    border-bottom-left-radius: 9px;
    padding: 10px; */
    }
</style>
