<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};

    const QUERIES = {};
    QUERIES.isOrgSlugAvailable = gql`
        query isOrgSlugAvailable($slug: String!) {
            isOrgSlugAvailable(slug: $slug)
        }
    `;

    const MUTATIONS = {};

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getClient} from 'svelte-apollo';
    import _debounce from 'lodash/debounce';

    const client = getClient();

    export let slug;

    let isOrgSlugAvailablePromise;
    // const updateAvailabilityDebounced = _debounce(updateAvailability, 500);
    $: if (slug) {
        isOrgSlugAvailablePromise = null;
        // updateAvailabilityDebounced();
        updateAvailability();
    } else {
        isOrgSlugAvailablePromise = null;
    }

    function updateAvailability() {
        isOrgSlugAvailablePromise = client.query({
            query: QUERIES.isOrgSlugAvailable,
            variables: {
                slug: slug,
            },
            fetchPolicy: 'network-only',
        });
    }
</script>

<span class="unit">
    {#if isOrgSlugAvailablePromise}
        {#await isOrgSlugAvailablePromise}
            <span class="loading">checking...</span>
        {:then result}
            {#if result.data.isOrgSlugAvailable}
                <span class="available">available</span>
            {:else}
                <span class="unavailable">unavailable</span>
            {/if}
        {:catch error}
            <span class="error">error</span>
        {/await}
    {/if}
</span>

<style>
    .unit {
        font-size: 12px;
        line-height: 20px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 0 10px;
    }
    .loading {
        color: #778ca3;
    }
    .available {
        color: #20bf6b;
    }
    .unavailable {
        color: #eb3b5a;
    }
    .error {
        color: #4b6584;
    }
</style>
