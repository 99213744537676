<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};
    FRAGMENTS.MessageListItem_org = gql`
        fragment MessageListItem_org on Org {
            ref
            data {
                name
                timeZone
            }
        }
    `;
    // FRAGMENTS.MessageListItem_contact = gql`
    //     fragment MessageListItem_contact on Contact {
    //         _id
    //         profile {
    //             data {
    //                 _id
    //                 valueJson
    //                 contactField {
    //                     _id
    //                     isFirstName
    //                     isLastName
    //                 }
    //             }
    //         }
    //     }
    // `;
    FRAGMENTS.MessageListItem_messageRequest = gql`
        fragment MessageListItem_messageRequest on MessageRequest {
            ref
            data {
                createdAt
                subject
                strippedText
                strippedHtml
                org {
                    ...MessageListItem_org
                }
                fromContact {
                    ref
                }
                triggeredByUser {
                    ref
                    data {
                        displayName
                        email
                    }
                }
                triggeredByFormSubmission {
                    ref
                    data {
                        form {
                            ref
                            data {
                                name
                            }
                        }
                    }
                }
                triggeredByContactSequenceAction {
                    ref
                    data {
                        sequenceAction {
                            ref
                            data {
                                sequence {
                                    ref
                                    data {
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
                triggeredByInboundSms {
                    fromRaw
                }
                triggeredByInboundEmail {
                    fromRaw
                }
                outboundEmails {
                    ref
                    data {
                        sentAt
                        deliveredAt
                        openedAt
                        clickedAt
                        unsubscribedAt
                        complainedAt
                        bouncedAt
                        isHardBounce
                        permanentFailureReason
                    }
                }
                outboundTextMessages {
                    ref
                    data {
                        sentAt
                        deliveredAt
                        failedAt
                        permanentFailureReason
                    }
                }
            }
        }
        ${FRAGMENTS.MessageListItem_org}
    `;

    const QUERIES = {};
    const MUTATIONS = {};

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    // import { getContext } from 'svelte';
    import _get from 'lodash/get';
    import _find from 'lodash/find';
    import _trim from 'lodash/trim';
    import moment from 'moment';
    // import emojiRegex from 'emoji-regex';
    import emojiRegex from 'emoji-regex/text.js';
    import Timestamp from './Timestamp.svelte';
    import defaultSubjectLines from '../../defaultSubjectLines';

    export let messageData;
    export let prevMessageData;

    $: orgPath = `/orgs/${_get(messageData, 'org.ref')}`;

    $: isWithinOneMinute =
        prevMessageData &&
        moment(_get(messageData, 'data.createdAt'))
            .subtract({minutes: 1})
            .isBefore(moment(_get(prevMessageData, 'data.createdAt')));
    $: isWithinOneHour =
        prevMessageData &&
        moment(_get(messageData, 'data.createdAt'))
            .subtract({hours: 1})
            .isBefore(moment(_get(prevMessageData, 'data.createdAt')));
    $: isOutgoing = !_get(messageData, 'data.fromContact.ref');
    // We want to whitelist the non-automated sources so that by default we treat new sources as automated (which generally have additional safeguards).
    $: isAutomated = !(
        _get(messageData, 'data.triggeredByUser') ||
        _get(messageData, 'data.triggeredByInboundSms') ||
        _get(messageData, 'data.triggeredByInboundEmail')
    );

    $: showTimeHeading = _get(messageData, 'ref') && !isWithinOneHour;
    $: timeHeadingDate = getDateDisplay(
        moment(_get(messageData, 'data.createdAt')),
    );
    $: timeHeadingTime = moment(_get(messageData, 'data.createdAt')).format(
        'LT',
    );

    $: fromSameUserAsPrevMessage =
        _get(messageData, 'data.triggeredByUser.ref') &&
        _get(messageData, 'data.triggeredByUser.ref') ==
            _get(prevMessageData, 'data.triggeredByUser.ref');
    $: fromSameUserWithinOneHour = fromSameUserAsPrevMessage && isWithinOneHour;
    $: showSenderHeading = isOutgoing && !fromSameUserWithinOneHour;

    $: isDefaultSubject = defaultSubjectLines.isDerivedSubjectLine(
        _get(messageData, 'data.subject'),
    );
    let displaySubject;
    $: displaySubject =
        _get(messageData, 'data.triggeredByInboundEmail') &&
        !isDefaultSubject &&
        _get(messageData, 'data.subject');

    $: displayText = _trim(
        _get(messageData, 'data.strippedText') || '(empty message)',
    );
    // Avoid a bug where the emoji text regex thinks pure digits like '5' are emoji.
    $: isPureDigits = !displayText.replace(/^\d+$/, '').length;
    $: isPureEmoji =
        !displaySubject &&
        !displayText.replace(emojiRegex(), '').length &&
        !isPureDigits;

    // const getProfile = (contact, where) => {
    //     let valueJson = _get(
    //         _find(_get(contact, 'profile.data', []), {contactField: where}),
    //         'valueJson'
    //     );
    //     return valueJson ? JSON.parse(valueJson) : undefined;
    // };

    // $: contactData = _get(messageData, 'fromContact', {});
    // $: profileFirstName = getProfile(contactData, {isFirstName: true}) || '';
    // $: profileLastName = getProfile(contactData, {isLastName: true}) || '';
    // $: nameDisplay = _trim(`${profileFirstName} ${profileLastName}`);

    let SENDING = {};
    let SENT = {};
    let FAILED = {};
    let DELIVERED = {};
    let OPENED = {};
    let CLICKED = {};
    let UNSUBSCRIBED = {};
    let COMPLAINED = {};
    function getMessageDisplayStatus(messageData) {
        let hasOutbound =
            !!_get(messageData, 'data.outboundEmails.length', 0) ||
            !!_get(messageData, 'data.outboundTextMessages.length', 0);
        if (!hasOutbound) return SENDING;

        // Now we start from the highest-priority states and work our way back.
        let isComplained = !!_get(
            messageData,
            'data.outboundEmails.0.data.complainedAt',
        );
        if (isComplained) return COMPLAINED;

        let isUnsubscribed = !!_get(
            messageData,
            'data.outboundEmails.0.data.unsubscribedAt',
        );
        if (isUnsubscribed) return UNSUBSCRIBED;

        let isClicked = !!_get(
            messageData,
            'data.outboundEmails.0.data.clickedAt',
        );
        if (isClicked) return CLICKED;

        let isOpened = !!_get(
            messageData,
            'data.outboundEmails.0.data.openedAt',
        );
        if (isOpened) return OPENED;

        let isDelivered = !!(
            _get(messageData, 'data.outboundEmails.0.data.deliveredAt') ||
            _get(
                messageData,
                'data.outboundTextMessages.0.data.deliveredAt',
            )
        );
        if (isDelivered) return DELIVERED;

        // Do this last! Because one of the messages might fail and the other might succeed,
        // in which case we should favor the successful message.
        // let hasOutboundEmail = !!_get(messageData, 'outboundEmails.data.0');
        // let hasOutboundSms = !!_get(messageData, 'outboundTextMessages.data.0');
        let emailFailed = !!_get(
            messageData,
            'data.outboundEmails.0.data.isHardBounce',
        );
        let smsFailed = !!_get(
            messageData,
            'data.outboundTextMessages.0.data.failedAt',
        );
        let isFailed = emailFailed && smsFailed;
        if (isFailed) return FAILED;

        return SENDING;
    }
    let displayStatus;
    $: displayStatus = getMessageDisplayStatus(messageData);
    // $: console.log('displayStatus', displayStatus, messageData)

    function getMessageFailureReason(messageData) {
        let reasons = [];
        if (
            _get(
                messageData,
                'data.outboundEmails.0.data.permanentFailureReason',
            )
        ) {
            reasons.push(
                _get(
                    messageData,
                    'data.outboundEmails.0.data.permanentFailureReason',
                ),
            );
        }
        if (
            _get(
                messageData,
                'data.outboundTextMessages.0.data.permanentFailureReason',
            )
        ) {
            reasons.push(
                _get(
                    messageData,
                    'data.outboundTextMessages.0.data.permanentFailureReason',
                ),
            );
        }
        return reasons.join(', ');
    }
    let failureReason;
    $: failureReason = getMessageFailureReason(messageData);

    function getIconForStatus(status) {
        switch (status) {
            case SENDING:
                return 'ellipsis-horizontal-circle-outline';
            case SENT:
                return 'checkmark-circle-outline';
            case FAILED:
                return 'close-circle';
            case DELIVERED:
                return 'checkmark-circle';
            case OPENED:
                // return 'mail-open-outline';
                return 'person-circle-outline';
            case CLICKED:
                return 'flash';
            case UNSUBSCRIBED:
                // return 'notifications-off-circle';
                return 'remove-circle';
            case COMPLAINED:
                // return 'thumbs-down';
                return 'alert-circle';
            default:
                return '';
        }
    }
    let statusIcon;
    $: statusIcon = getIconForStatus(displayStatus);

    function getMessageStatusText(messageData, displayStatus) {
        let when;
        switch (displayStatus) {
            case SENDING:
                return 'Sending now. Waiting for confirmation...';
            case SENT:
                when = moment(
                    _get(
                        messageData,
                        'data.outboundEmails.0.data.sentAt',
                        '',
                    ) ||
                        _get(
                            messageData,
                            'data.outboundTextMessages.0.data.sentAt',
                            '',
                        ),
                );
                return `Sent ${getDateDisplay(when)} ${when.format('LT')}`;
            case FAILED:
                when = moment(
                    _get(
                        messageData,
                        'data.outboundEmails.0.data.bouncedAt',
                        '',
                    ) ||
                        _get(
                            messageData,
                            'data.outboundTextMessages.0.data.failedAt',
                            '',
                        ),
                );
                let reason = getMessageFailureReason(messageData);
                return `Failed ${getDateDisplay(when)} ${when.format(
                    'LT',
                )}: ${reason}`;
            case DELIVERED:
                when = moment(
                    _get(
                        messageData,
                        'data.outboundEmails.0.data.deliveredAt',
                        '',
                    ) ||
                        _get(
                            messageData,
                            'data.outboundTextMessages.0.data.deliveredAt',
                            '',
                        ),
                );
                return `Delivered ${getDateDisplay(when)} ${when.format('LT')}`;
            case OPENED:
                when = moment(
                    _get(
                        messageData,
                        'data.outboundEmails.0.data.openedAt',
                        '',
                    ),
                );
                return `Opened ${getDateDisplay(when)} ${when.format('LT')}`;
            case CLICKED:
                when = moment(
                    _get(
                        messageData,
                        'data.outboundEmails.0.data.clickedAt',
                        '',
                    ),
                );
                return `Clicked ${getDateDisplay(when)} ${when.format('LT')}`;
            case UNSUBSCRIBED:
                when = moment(
                    _get(
                        messageData,
                        'data.outboundEmails.0.data.unsubscribedAt',
                        '',
                    ),
                );
                return `Unsubscribed ${getDateDisplay(when)} ${when.format(
                    'LT',
                )}`;
            case COMPLAINED:
                when = moment(
                    _get(
                        messageData,
                        'data.outboundEmails.0.data.complainedAt',
                        '',
                    ),
                );
                return `Reported as spam ${getDateDisplay(when)} ${when.format(
                    'LT',
                )}`;
            default:
                return '';
        }
    }
    let statusText;
    $: statusText = getMessageStatusText(messageData, displayStatus);

    function getDateDisplay(momentObj) {
        if (momentObj.isAfter(moment().startOf('day'))) {
            return momentObj.format('[Today]');
        } else if (
            momentObj.isAfter(moment().subtract({days: 1}).startOf('day'))
        ) {
            return momentObj.format('[Yesterday]');
        } else if (
            momentObj.isAfter(moment().endOf('day').subtract({weeks: 1}))
        ) {
            return momentObj.format('dddd');
        } else if (
            momentObj.isAfter(moment().endOf('day').subtract({years: 1}))
        ) {
            return momentObj.format('ddd, MMM D,');
        } else {
            return momentObj.format('ddd, MMM D, YYYY,');
        }
    }
</script>

<div
    class="container"
    class:outgoing={isOutgoing}
    class:incoming={!isOutgoing}
    class:tight-layout={isWithinOneMinute}
>
    {#if showTimeHeading}
        <header class="time-heading muted">
            <strong>{timeHeadingDate}</strong>
            {timeHeadingTime}
        </header>
    {/if}
    <!-- <div class="byline">
        {#if isOutgoing}
            {_get(org, 'name')}
        {:else}
            {nameDisplay || 'unknown'}
        {/if}
        <span class="when muted">
            <Timestamp ts={_get(messageData, 'createdAt')} />
        </span>
    </div> -->
    <!-- <span class="messages-count">
        {messagesCount}
    </span> -->
    {#if showSenderHeading}
        <header class="sender-heading">
            {#if _get(messageData, 'data.triggeredByUser')}
                {_get(messageData, 'data.triggeredByUser.data.displayName') ||
                    _get(messageData, 'data.triggeredByUser.data.email') ||
                    _get(messageData, 'data.org.data.name') + ' Staff'}
            {:else if _get(messageData, 'data.triggeredByFormSubmission')}
                <a
                    href="{orgPath}/forms/{_get(
                        messageData,
                        'data.triggeredByFormSubmission.data.form.ref',
                    )}"
                >
                    {_get(
                        messageData,
                        'data.triggeredByFormSubmission.data.form.data.name',
                    )}
                </a>
            {:else if _get(messageData, 'triggeredByContactSequenceAction')}
                <a
                    href="{orgPath}/sequences/{_get(
                        messageData,
                        'data.triggeredByContactSequenceAction.data.sequenceAction.data.sequence.ref',
                    )}"
                >
                    {_get(
                        messageData,
                        'data.triggeredByContactSequenceAction.data.sequenceAction.data.sequence.data.name',
                    )}
                </a>
            {:else if _get(messageData, 'data.triggeredByInboundSms')}
                {_get(messageData, 'data.triggeredByInboundSms.fromRaw')}
            {:else if _get(messageData, 'data.triggeredByInboundEmail')}
                {_get(messageData, 'data.triggeredByInboundEmail.fromRaw')}
            {/if}
        </header>
    {/if}
    <div class="message-row">
        {#if isOutgoing}
            <div class="status-icon">
                <ion-icon name={statusIcon} />
                <span class="status-text">
                    {statusText}
                </span>
            </div>
        {/if}
        <div class="bubble" class:pure-emoji={isPureEmoji}>
            <!-- {#if isOutgoing} -->
            <!-- <ion-icon name={isAutomated ? "sync-outline" : "arrow-undo"} class="muted" /> -->
            <!-- <ion-icon name={isAutomated ? "sync-circle" : "arrow-undo"} class="muted" /> -->
            <!-- <ion-icon name={isAutomated ? "cloud-done" : "arrow-undo"} class="muted" /> -->
            <!-- <ion-icon name={isAutomated ? "flash" : "arrow-undo"} class="muted" /> -->
            <!-- <ion-icon name={isAutomated ? "play-forward" : "arrow-undo"} style={isAutomated ? 'margin-top:2px;' : ''} /> -->
            <!-- {/if} -->
            {#if displaySubject}
                <strong>{displaySubject}</strong>
            {/if}
            <span class="pre-wrap">
                {displayText}
            </span>
        </div>
    </div>
</div>

<style>
    .time-heading {
        text-align: center;
        font-size: 12px;
        line-height: 24px;
        font-weight: 400;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .time-heading strong {
        font-weight: 600;
    }
    .sender-heading {
        font-size: 11px;
        padding: 0 20px 0 0;
        font-weight: 600;
        line-height: 16px;
        color: var(--slate);
        opacity: 0.5;
    }
    .outgoing .sender-heading {
        text-align: right;
    }
    .sender-heading a {
        text-decoration: none;
    }
    .sender-heading a:hover {
        text-decoration: underline;
    }
    .container {
        padding: 10px 20px 2px;
    }
    .container.tight-layout {
        padding-top: 0;
    }
    /* .byline {
    font-size: 12px;
    line-height: 18px;
    font-weight: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 10px;
    opacity: 0;
    transition: 200ms opacity;
} */
    /* .container:hover .byline {
    opacity: 1;
} */
    /* .when {
    text-transform: lowercase;
} */
    .message-row {
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;
        align-items: flex-end;
    }
    .outgoing .message-row {
        flex-direction: row-reverse;
    }
    .bubble {
        flex-shrink: 1;
        max-width: 86%;
        background: var(--offwhite);
        border-radius: 10px;
        padding: 9px 12px;
        text-align: left;
        font-size: 14px;
        line-height: 1.4;
        font-weight: 400;
        box-shadow: var(--box-shadow-raised-level1);
        transition: 1s opacity;
    }
    .outgoing .bubble {
        background: var(--blue);
        color: #fff; /* Intentionally not var(--white) so that it doesn't flip in dark mode. */
    }
    .bubble.pure-emoji {
        background: none;
        color: var(--offblack);
        font-size: 40px;
        line-height: 44px;
        height: 44px;
        padding: 0;
        box-shadow: none;
        letter-spacing: 0.09em;
    }
    .bubble strong {
        font-weight: 600;
    }
    .pre-wrap {
        white-space: pre-wrap;
    }
    .status-icon {
        flex-grow: 0;
        flex-shrink: 0;
        margin-bottom: -2px;
        position: relative;
        z-index: 1;
        width: 20px;
    }
    .status-icon ion-icon {
        font-size: 20px;
        vertical-align: bottom;
        color: var(--blue);
        position: relative;
        z-index: 2;
    }
    .status-text {
        display: none;
        position: absolute;
        white-space: nowrap;
        right: 0;
        bottom: 0;
        font-size: 11px;
        font-weight: 400;
        line-height: 20px;
        padding: 0 23px 0 9px;
        border-radius: 100px;
        color: white;
        background: var(--offblack);
        z-index: 1;
    }
    .status-icon:hover ion-icon {
        color: var(--white);
    }
    .status-icon:hover .status-text {
        display: block;
    }
    .status-icon:hover + .bubble {
        opacity: 0.5;
    }
    ion-icon :global(svg) {
        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
    }
</style>
