<script>
  export let totalPages = 10;
  export let currentPage = 1;
  export let handleChangeCurrentPage;
  export let disabled = false;
  let showbar = [];
  function handleChange(newValue) {
    if (!disabled) {
      handleChangeCurrentPage(newValue);
    }
  }

  $: {
    if (totalPages <= 5) {
      showbar = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      if (currentPage <= 3) {
        showbar = [1, 2, 3, 4, 5];
      } else if (currentPage >= totalPages - 2) {
        showbar = [
          totalPages - 4,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        ];
      } else {
        showbar = [
          currentPage - 2,
          currentPage - 1,
          currentPage,
          currentPage + 1,
          currentPage + 2,
        ];
      }
    }
  }
</script>

<nav class="pagination">
  <button
    class="nav-btn"
    on:click={() => handleChange(currentPage - 1)}
    disabled={currentPage === 1 || disabled}
  >
    <ion-icon name="chevron-back-outline"></ion-icon>
  </button>

  {#each showbar as perPage, index}
    <button
      class="{currentPage === perPage ? 'active' : ''} {totalPages === 1
        ? 'one-page'
        : index === 0
          ? 'first'
          : index === showbar.length - 1
            ? 'last'
            : ''}"
      on:click={() => handleChange(perPage)}
      disabled={currentPage === index + 1 || disabled}
    >
      {perPage}
    </button>
  {/each}

  <button
    class="nav-btn"
    on:click={() => handleChange(currentPage + 1)}
    disabled={currentPage === totalPages || disabled}
  >
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </button>
</nav>

<style>
  .pagination {
    display: flex;
    align-items: center;
  }

  .nav-btn,
  button {
    background-color: white;
    border: 1px solid #ddd;
    color: black;
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    height: 35px;
    width: 35px;
  }

  .nav-btn {
    border-radius: 10px;
    font-weight: 600;
  }

  .nav-btn:disabled {
    opacity: 0.5;
    cursor: default;
  }

  button.active {
    background-color: var(--blue);
    color: white;
    border: 1px solid var(--blue);
  }

  button.first {
    border-radius: 10px 0 0 10px;
    margin-left: 20px;
  }

  button.last {
    border-radius: 0 10px 10px 0;
    margin-right: 20px;
  }

  button.one-page {
    border-radius: 10px;
    margin: 0 20px;
  }

  button:not(.nav-btn):not(.first) {
    border-left: none;
  }

  button:hover:not(.active):not(:disabled) {
    background-color: #f0f0f0;
  }
</style>
