<script context="module">
    import gql from 'graphql-tag';
    import {FRAGMENTS as EditSequenceActions_FRAGMENTS} from '../../../components/EditSequenceActions.svelte';

    const FRAGMENTS = {};
    FRAGMENTS.EditSequence_form = gql`
        fragment EditSequence_form on Form {
            ref
            data {
                name
                title
                isDeleted
            }
        }
    `;
    FRAGMENTS.EditSequence_sequence = gql`
        fragment EditSequence_sequence on Sequence {
            ref
            data {
                name
                pauseOnReply
                isDeleted
                org {
                    ref
                    data {
                        forms {
                            ...EditSequence_form
                        }
                    }
                }
                forms {
                    ...EditSequence_form
                }
                ...EditSequenceActions_sequence
            }
        }
        ${FRAGMENTS.EditSequence_form}
        ${EditSequenceActions_FRAGMENTS.EditSequenceActions_sequence}
    `;

    const QUERIES = {};
    QUERIES.sequence = gql`
        query sequence($sequenceId: ID!) {
            findSequenceByID(id: $sequenceId) {
                ...EditSequence_sequence
            }
        }
        ${FRAGMENTS.EditSequence_sequence}
    `;

    const MUTATIONS = {};
    MUTATIONS.updateSequence = gql`
        mutation updateSequence($id: ID!, $data: PartialUpdateSequenceInput!) {
            partialUpdateSequence(id: $id, data: $data) {
                ...EditSequence_sequence
            }
        }
        ${FRAGMENTS.EditSequence_sequence}
    `;
    MUTATIONS.addSequenceOnForm = gql`
        mutation addSequenceOnForm($formIDs: [ID!]!, $sequenceId: ID!) {
            addSequenceOnForm(formIDs: $formIDs, sequenceId: $sequenceId) {
                ...EditSequence_sequence
            }
        }
        ${FRAGMENTS.EditSequence_sequence}
    `;
    MUTATIONS.removeSequenceFromForm = gql`
        mutation removeSequenceFromForm($formIDs: [ID!]!, $sequenceId: ID!) {
            removeSequenceFromForm(formIDs: $formIDs, sequenceId: $sequenceId) {
                ...EditSequence_sequence
            }
        }
        ${FRAGMENTS.EditSequence_sequence}
    `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getClient, mutation} from 'svelte-apollo';
    import _debounce from 'lodash/debounce';
    import _get from 'lodash/get';
    import _map from 'lodash/map';
    import _intersectionBy from 'lodash/intersectionBy';
    import _differenceBy from 'lodash/differenceBy';
    import _has from 'lodash/has';
    import _keys from 'lodash/keys';
    import _filter from 'lodash/filter'
    import Select from 'svelte-select';
    import {watchQuery} from '../../../svelte-apollo-watch-query';
    import HtmlHeadTitle from '../../../components/HtmlHeadTitle.svelte';
    import OrgPageLayout from '../../../components/OrgPageLayout.svelte';
    import EditSequenceActions from '../../../components/EditSequenceActions.svelte';
    import MutationInput from '../../../components/MutationInput.svelte';
    import Toggle from '../../../components/Toggle.svelte';
    import {onMount} from 'svelte';

    export let orgId;
    export let sequenceId;

    const client = getClient();
    const updateSequenceMutation = mutation(MUTATIONS.updateSequence);
    const addSequenceOnFormMutation = mutation(MUTATIONS.addSequenceOnForm);
    const removeSequenceFromFormMutation = mutation(
        MUTATIONS.removeSequenceFromForm,
    );
    const pageTitle = 'Edit Sequence';
    let sequenceData = {};
    onMount(async () => {
        const result = await client.query({
            query: QUERIES.sequence,
            variables: {sequenceId: sequenceId},
            pollInterval: 0,
        });
        sequenceData = result.data.findSequenceByID;

        initFormFields();
    });
    const sequenceQuery = watchQuery(client, {
        query: QUERIES.sequence,
        variables: {sequenceId: sequenceId},
        pollInterval: 0,
    });
    // let sequenceData = _get($sequenceQuery, 'data.findSequenceByID', {});
    $: orgForms = _filter(_get(sequenceData, 'data.org.data.forms', []), (form) => !form.data.isDeleted);

    let nameEntry;
    let pauseOnReplyEntry = true;

    let hasInitName = false;
    let hasInitPauseOnReply = false;
    function initFormFields() {
        if (!_get(sequenceData, 'ref')) return;
        if (_has(sequenceData, 'data.name') && !hasInitName) {
            nameEntry = sequenceData.data.name;
            hasInitName = true;
        }
        if (_has(sequenceData, 'data.pauseOnReply') && !hasInitPauseOnReply) {
            pauseOnReplyEntry = sequenceData.data.pauseOnReply;
            hasInitPauseOnReply = true;
        }
    }
    // $: initFormFields();

    let nameSaving = null;
    let pauseOnReplySaving = null;

    let UNSAVED = {};
    let SAVED = {};
    let SAVING = {};
    // Treat falsey values as equal.
    const entriesEqual = (a, b) => (a || '') == (b || '');
    $: formStatus =
        !entriesEqual(
            nameEntry,
            nameSaving === null ? _get(sequenceData, 'data.name') : nameSaving,
        ) ||
        !entriesEqual(
            pauseOnReplyEntry,
            pauseOnReplySaving === null
                ? _get(sequenceData, 'data.pauseOnReply')
                : pauseOnReplySaving,
        )
            ? UNSAVED
            : entriesEqual(nameEntry, _get(sequenceData, 'data.name')) &&
                entriesEqual(
                    pauseOnReplyEntry,
                    _get(sequenceData, 'data.pauseOnReply'),
                )
              ? SAVED
              : SAVING;

    const saveFormDebounced = _debounce(saveForm, 1000);
    $: if (formStatus == UNSAVED && _has(sequenceData, 'ref'))
        saveFormDebounced();
    // $: console.log(formStatus == UNSAVED ? 'UNSAVED' : formStatus == SAVED ? 'SAVED' : 'SAVING')

    let savingData;
    async function saveForm() {
        if (formStatus != UNSAVED) return;
        let updates = {};
        savingData = updates;

        let optimisticName =
            nameSaving === null ? sequenceData.data.name : nameSaving;
        let nameDirty = hasInitName && nameEntry != optimisticName;
        if (nameDirty) updates.name = nameEntry;
        nameSaving = nameDirty ? nameEntry : null;

        let optimisticPauseOnReply =
            pauseOnReplySaving === null
                ? sequenceData.data.pauseOnReply
                : pauseOnReplySaving;
        let pauseOnReplyDirty =
            hasInitPauseOnReply && pauseOnReplyEntry != optimisticPauseOnReply;
        if (pauseOnReplyDirty) updates.pauseOnReply = pauseOnReplyEntry;
        pauseOnReplySaving = pauseOnReplyDirty ? pauseOnReplyEntry : null;

        let maybeClearSavingState = () => {
            if (savingData === updates) {
                nameSaving = null;
                pauseOnReplySaving = null;
                savingData = null;
            }
        };

        if (!_keys(updates).length) {
            maybeClearSavingState();
            return;
        }

        await updateSequence(updates);

        maybeClearSavingState();
    }

    async function updateSequence(data) {
        try {
            // let response = await mutate(client, {
            //     mutation: MUTATIONS.updateSequence,
            //     variables: {
            //         id: sequenceId,
            //         data: data,
            //     },
            // });
            let tempSequenceData = {...sequenceData};

            let response = await updateSequenceMutation({
                variables: {
                    id: sequenceId,
                    data: data,
                },
            });
            tempSequenceData.data = response.data.partialUpdateSequence.data;
            // tempSequenceData.data.pauseOnReply =
            //     response.data.partialUpdateSequence.data.pauseOnReply;
            sequenceData = tempSequenceData;
            return response;
        } catch (error) {
            // FIXME handle errors better...
            alert(error.message);
        }
    }

    async function udpateSequenceOnForm(formIDs, sequenceId, add) {
        try {
            if (add) {
                // let response = await mutate(client, {
                //     mutation: MUTATIONS.addSequenceOnForm,
                //     variables: {
                //         formIDs: formIDs,
                //         sequenceId: sequenceId,
                //     },
                // });
                let response = await addSequenceOnFormMutation({
                    variables: {
                        formIDs: formIDs,
                        sequenceId: sequenceId,
                    },
                });
                return response;
            } else {
                // let response = await mutate(client, {
                //     mutation: MUTATIONS.removeSequenceFromForm,
                //     variables: {
                //         formIDs: formIDs,
                //         sequenceId: sequenceId,
                //     },
                // });
                let response = await removeSequenceFromFormMutation({
                    variables: {
                        formIDs: formIDs,
                        sequenceId: sequenceId,
                    },
                });
                return response;
            }
        } catch (error) {
            // FIXME handle errors better...
            alert(error.message);
        }
    }

    let savingSequenceForms = false;
    async function updateSequenceFormConnections(forms) {
        if (savingSequenceForms) return;
        savingSequenceForms = true;
        let previousForms = _get(sequenceData, 'data.forms', []);
        let addedForms = _differenceBy(forms, previousForms, 'ref');
        let removedForms = _differenceBy(previousForms, forms, 'ref');
        if (removedForms.length) {
            if (
                !confirm(
                    `Confirm that you want to disconnect ${removedForms.length} form(s) from this sequence.`,
                )
            ) {
                savingSequenceForms = false;
                return;
            }
        }
        if (addedForms.length || removedForms.length) {
            if (addedForms.length) {
                const result = await udpateSequenceOnForm(
                    _map(addedForms, 'ref'),
                    sequenceId,
                    true,
                );
                let tempSequenceData = {...sequenceData};
                tempSequenceData.data = result.data.addSequenceOnForm.data;
                // result.data.addSequenceOnForm.map((data) =>
                //     tempSequenceData.data.forms.push(data),
                // );
                sequenceData = tempSequenceData;
            }
            if (removedForms.length) {
                let tempSequenceData = {...sequenceData};

                const result = await udpateSequenceOnForm(
                    _map(removedForms, 'ref'),
                    sequenceId,
                    false,
                );
                // tempSequenceData.data.forms = tempSequenceData.data.forms.filter(
                //     (form) =>
                //         !result.data.removeSequenceFromForm.some(
                //             (data) => data.ref === form.ref,
                //         ),
                // );
                tempSequenceData.data = result.data.removeSequenceFromForm.data;
                sequenceData = tempSequenceData;
            }
        }
        savingSequenceForms = false;
    }
</script>

<HtmlHeadTitle {pageTitle} />

<OrgPageLayout {orgId} title={pageTitle}>
    <div class="sequence-form">
        <section class="card">
            {#if $sequenceQuery.errors}
                errors: <pre>{JSON.stringify(
                        $sequenceQuery.errors,
                        null,
                        4,
                    )}</pre>
            {:else if !sequenceData.ref}
                Loading...
            {:else}
                <h1>Basics</h1>
                <div class="fieldrow">
                    <label class="fieldlabel" for="sequenceName">
                        Sequence Title (not seen by contacts)
                    </label>
                    <MutationInput
                        type="text"
                        id="sequenceName"
                        placeholder="Pricing Lead Follow-up"
                        bind:value={nameEntry}
                        savingValue={nameSaving}
                        savedValue={_get(sequenceData, 'data.name')}
                        disabled={_get(sequenceData, 'data.isDeleted', false)}
                    />
                </div>
                <div class="fieldrow" class:muted={pauseOnReplySaving != null}>
                    <label class="fieldlabel"> Pause on Reply </label>
                    <div class="fieldhelp">
                        <Toggle
                            bind:checked={pauseOnReplyEntry}
                            disabled={_get(
                                sequenceData,
                                'data.isDeleted',
                                false,
                            )}
                        />
                        Pause the sequence automatically when a contact replies to
                        your sequence or sends you any message
                    </div>
                </div>
                <div class="fieldrow">
                    <label class="fieldlabel" for="sequenceForms">
                        Triggers
                    </label>
                    <Select
                        isMulti={true}
                        items={orgForms}
                        placeholder="Select a form..."
                        optionIdentifier="ref"
                        selectedValue={_intersectionBy(
                            orgForms,
                            _get(sequenceData, 'data.forms', []),
                            'ref',
                        )}
                        getOptionLabel={(option, filterText) =>
                            option.isCreator
                                ? `Create "${filterText}"`
                                : `Submits (${option.data.title}) Lead Form`}
                        getSelectionLabel={(option) =>
                            `Submit <strong>(${option.data.title}) Lead Form</strong>`}
                        isCreatable={false}
                        isClearable={false}
                        createItem={() => {}}
                        on:select={(event) =>
                            updateSequenceFormConnections(event.detail)}
                        isDisabled={savingSequenceForms ||
                            _get(sequenceData, 'data.isDeleted', false)}
                        id="sequenceForms"
                        showIndicator={true}
                    />
                </div>
            {/if}
        </section>
        <div class="sequence-actions">
            {#if sequenceData.ref}
                <h1>Actions</h1>
                <EditSequenceActions {sequenceData} />
            {/if}
        </div>
    </div>
</OrgPageLayout>

<style>
    .sequence-form {
        padding: 0 20px 20px;
    }
    .card {
        background: var(--white);
        border: 1px solid var(--offoffwhite);
        border-radius: 9px;
        padding: 20px;
    }
    h1 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        margin: 0;
        padding: 0 0 10px;
    }
    .sequence-actions {
        margin-top: 20px;
    }
    .sequence-actions h1 {
        margin-left: 21px;
    }
</style>
