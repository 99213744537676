<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};
    FRAGMENTS.formData = gql`
        fragment formData on Form {
            ref
            data {
                name
                title
                isHosted
                isDeleted
                deletedAt
            }
        }
    `;
    const QUERIES = {};

    QUERIES.orgData = gql`
        query orgData($orgId: ID!) {
            findOrgByID(id: $orgId) {
                ref
                data {
                    billingFirstName
                    billingLastName
                    billingPhone
                    callForwardStatus {
                        status
                        forwardingNumber
                    }
                    forms {
                        ...formData
                    }
                    willEnableStickyBar
                    stickyBarColor
                    stickyBarTextColor
                    willShowTextPhoneNumber
                    willShowCallPhoneNumber
                    willFirstButtonFormConnection
                    willSecondButtonFormConnection
                    firstButtonFormConnectionTitle
                    firstButtonFormConnectionForm {
                        ref
                        data {
                            title
                        }
                    }
                    secondButtonFormConnectionTitle
                    secondButtonFormConnectionForm {
                        ref
                        data {
                            title
                        }
                    }
                    firstFormConnectionButtonDetail {
                        backgroundColor
                        textColor
                        icon
                    }
                    secondFormConnectionButtonDetail {
                        backgroundColor
                        textColor
                        icon
                    }
                    smsLocal {
                        number
                    }
                    smsTollFree {
                        number
                    }
                }
            }
        }
        ${FRAGMENTS.formData}
    `;

    const MUTATIONS = {};

    MUTATIONS.updateOrg = gql`
        mutation updateOrg($orgId: ID!, $data: PartialUpdateOrgInput!) {
            updateOrg(orgId: $orgId, data: $data) {
                ref
                data {
                    billingFirstName
                    billingLastName
                    billingPhone
                    callForwardStatus {
                        status
                        forwardingNumber
                    }
                    forms {
                        ...formData
                    }
                    willEnableStickyBar
                    stickyBarColor
                    stickyBarTextColor
                    willShowTextPhoneNumber
                    willShowCallPhoneNumber
                    willFirstButtonFormConnection
                    willSecondButtonFormConnection
                    firstButtonFormConnectionTitle
                    firstButtonFormConnectionForm {
                        ref
                        data {
                            title
                        }
                    }
                    secondButtonFormConnectionTitle
                    secondButtonFormConnectionForm {
                        ref
                        data {
                            title
                        }
                    }
                    firstFormConnectionButtonDetail {
                        backgroundColor
                        textColor
                        icon
                    }
                    secondFormConnectionButtonDetail {
                        backgroundColor
                        textColor
                        icon
                    }
                    smsLocal {
                        number
                    }
                    smsTollFree {
                        number
                    }
                }
            }
        }
        ${FRAGMENTS.formData}
    `;
    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getClient, query, mutation} from 'svelte-apollo';
    import _get from 'lodash/get';
    import _map from 'lodash/map';
    import _has from 'lodash/has';
    import _find from 'lodash/find';
    import _trim from 'lodash/trim';
    import _keys from 'lodash/keys';
    import _forEach from 'lodash/forEach';
    import _isNumber from 'lodash/isNumber';
    import _debounce from 'lodash/debounce';
    import HtmlHeadTitle from '../../components/HtmlHeadTitle.svelte';
    import OrgPageLayout from '../../components/OrgPageLayout.svelte';
    import SendingEmailToDeveloperModal from '../../components/SendingEmailToDeveloperModal.svelte';
    import Toggle from '../../components/Toggle.svelte';
    import ColorPicker from '../../components/ColorPicker.svelte';
    import {fly} from 'svelte/transition';
    import {autoheight} from '../../actions/autoheight.js';
    import MutationInput from '../../components/MutationInput.svelte';
    import Select from 'svelte-select';
    import {watchQuery} from '../../svelte-apollo-watch-query';
    import IconPicker from '../../components/IconPicker.svelte';

    export let orgId;

    const client = getClient();

    let isSendingEmail = false;

    const updateOrgMutation = mutation(MUTATIONS.updateOrg);

    const pageTitle = 'Website Integration';

    const embedScriptSrc =
        _trim(__APP_ORIGIN__, '/') + `/embed/sticky-magic.js?q=${btoa(orgId)}`;
    const embedScriptTag = `[script src="${embedScriptSrc}"][/script]`
        .replace(/\[/g, '<')
        .replace(/\]/g, '>');
    let codeSnippet = `<!--Start of LeadAction Magic Script Embed Code-->\n${embedScriptTag}\n<!-- End of LeadAction Magic Script Embed Code -->`;

    async function handleCopyButtonClick() {
        const textarea = document.querySelector('.notes-entry');
        try {
            await navigator.clipboard.writeText(textarea.value);
            alert('Code snippet copied to clipboard!');
        } catch (err) {
            console.error('Failed to copy: ', err);
            alert('Failed to copy code snippet. Please try again.');
        }
    }

    const orgDataQuery = watchQuery(client, {
        query: QUERIES.orgData,
        variables: {
            orgId: orgId,
        },
        pollInterval: 0,
    });

    $: orgData = _get($orgDataQuery, 'data.findOrgByID', {});
    $: orgFormData = _get(orgData, 'data.forms', []);
    $: isLoading = $orgDataQuery.loading;

    let enableStickyBarEntry = false;
    let willShowTextPhoneNumberEntry = false;
    let willShowCallPhoneNumberEntry = false;
    let willFirstButtonFormConnectionEntry = false;
    let willSecondButtonFormConnectionEntry = false;
    let firstConnectionTitleEntry = '';
    let secondConnectionTitleEntry = '';

    let hasInitSticky = false;
    let hasInitTextPhone = false;
    let hasInitCallPhone = false;
    let hasInitFirstButton = false;
    let hasInitSecondButton = false;
    let hasInitFirstButtonTitle = false;
    let hasInitSecondButtonTitle = false;

    function initStickyBarSettingFields() {
        if (!_get(orgData, 'ref')) return;
        if (_has(orgData, 'data.willEnableStickyBar') && !hasInitSticky) {
            enableStickyBarEntry = orgData.data.willEnableStickyBar;
            hasInitSticky = true;
        }
        if (
            _has(orgData, 'data.willShowTextPhoneNumber') &&
            !hasInitTextPhone
        ) {
            willShowTextPhoneNumberEntry = orgData.data.willShowTextPhoneNumber;
            hasInitTextPhone = true;
        }
        if (
            _has(orgData, 'data.willShowCallPhoneNumber') &&
            !hasInitCallPhone
        ) {
            willShowCallPhoneNumberEntry = orgData.data.willShowCallPhoneNumber;
            hasInitCallPhone = true;
        }
        if (
            _has(orgData, 'data.willFirstButtonFormConnection') &&
            !hasInitFirstButton
        ) {
            willFirstButtonFormConnectionEntry =
                orgData.data.willFirstButtonFormConnection;
            hasInitFirstButton = true;
        }
        if (
            _has(orgData, 'data.willSecondButtonFormConnection') &&
            !hasInitSecondButton
        ) {
            willSecondButtonFormConnectionEntry =
                !!orgData.data.willSecondButtonFormConnection;
            hasInitSecondButton = true;
        }
        if (
            _has(orgData, 'data.firstButtonFormConnectionTitle') &&
            !hasInitFirstButtonTitle
        ) {
            firstConnectionTitleEntry =
                orgData.data.firstButtonFormConnectionTitle;
            hasInitFirstButtonTitle = true;
        }
        if (
            _has(orgData, 'data.secondButtonFormConnectionTitle') &&
            !hasInitSecondButtonTitle
        ) {
            secondConnectionTitleEntry =
                orgData.data.secondButtonFormConnectionTitle;
            hasInitSecondButtonTitle = true;
        }
    }

    let stickyBarSaving = null;
    let textPhoneNumberSaving = null;
    let callPhoneNumberSaving = null;
    let firstConnectionSaving = null;
    let secondConnectionSaving = null;
    let firstConnectionTitleSaving = null;
    let secondConnectionTitleSaving = null;

    async function updateOrg(id, data) {
        try {
            let response = await updateOrgMutation({
                variables: {
                    orgId: id,
                    data: data,
                },
            });
            orgData.data = response.data.updateOrg.data;
            return response.data.updateOrg;
            // return response;
        } catch (error) {
            alert(error.message);
            throw error;
        }
    }

    $: orgData, initStickyBarSettingFields();

    const saveStickyBarSettingsDebounced = _debounce(
        saveStickyBarSettings,
        1000,
    );

    $: firstConnectionTitleEntry,
        secondConnectionTitleEntry,
        saveStickyBarSettingsDebounced();

    $: enableStickyBarEntry,
        willShowTextPhoneNumberEntry,
        willShowCallPhoneNumberEntry,
        willFirstButtonFormConnectionEntry,
        willSecondButtonFormConnectionEntry,
        saveStickyBarSettings();

    let savingData;
    async function saveStickyBarSettings() {
        if (!orgData || Object.keys(orgData).length === 0) {
            return;
        }
        let updates = {};
        savingData = updates;
        let optimisticSitcky =
            stickyBarSaving === null
                ? orgData.data.willEnableStickyBar
                : stickyBarSaving;
        let stickyBarDirty =
            hasInitSticky && enableStickyBarEntry != optimisticSitcky;
        if (stickyBarDirty) updates.willEnableStickyBar = enableStickyBarEntry;
        stickyBarSaving = stickyBarDirty ? enableStickyBarEntry : null;

        let optimisticTextPhone =
            textPhoneNumberSaving === null
                ? orgData.data.willShowTextPhoneNumber
                : textPhoneNumberSaving;
        let textPhoneDirty =
            hasInitTextPhone &&
            willShowTextPhoneNumberEntry != optimisticTextPhone;
        if (textPhoneDirty)
            updates.willShowTextPhoneNumber = willShowTextPhoneNumberEntry;
        textPhoneNumberSaving = textPhoneDirty
            ? willShowTextPhoneNumberEntry
            : null;

        let optimisticCallPhone =
            callPhoneNumberSaving === null
                ? orgData.data.willShowCallPhoneNumber
                : callPhoneNumberSaving;
        let callPhoneDirty =
            hasInitCallPhone &&
            willShowCallPhoneNumberEntry != optimisticCallPhone;
        if (callPhoneDirty)
            updates.willShowCallPhoneNumber = willShowCallPhoneNumberEntry;
        callPhoneNumberSaving = callPhoneDirty
            ? willShowCallPhoneNumberEntry
            : null;

        let optimisticFirstConnection =
            firstConnectionSaving === null
                ? orgData.data.willFirstButtonFormConnection
                : firstConnectionSaving;
        let firstConnectionDirty =
            hasInitFirstButton &&
            willFirstButtonFormConnectionEntry != optimisticFirstConnection;
        if (firstConnectionDirty)
            updates.willFirstButtonFormConnection =
                willFirstButtonFormConnectionEntry;
        firstConnectionSaving = firstConnectionDirty
            ? willFirstButtonFormConnectionEntry
            : null;

        let optimisticSecondConnection =
            secondConnectionSaving === null
                ? orgData.data.willSecondButtonFormConnection
                : secondConnectionSaving;
        let secondConnectionDirty =
            hasInitSecondButton &&
            willSecondButtonFormConnectionEntry != optimisticSecondConnection;
        if (secondConnectionDirty)
            updates.willSecondButtonFormConnection =
                willSecondButtonFormConnectionEntry;
        secondConnectionSaving = secondConnectionDirty
            ? willSecondButtonFormConnectionEntry
            : null;

        let optimisticFirstConnectionTitle =
            firstConnectionTitleSaving === null
                ? orgData.data.firstButtonFormConnectionTitle
                : firstConnectionTitleSaving;
        let firstConnectionTitleDirty =
            hasInitFirstButtonTitle &&
            firstConnectionTitleEntry != optimisticFirstConnectionTitle;
        if (firstConnectionTitleDirty) {
            updates.firstButtonFormConnectionTitle = firstConnectionTitleEntry;
        }
        firstConnectionSaving = firstConnectionTitleDirty
            ? firstConnectionTitleEntry
            : null;
        let optimisticSecondConnectionTitle =
            secondConnectionTitleSaving === null
                ? orgData.data.secondButtonFormConnectionTitle
                : secondConnectionTitleSaving;
        let secondConnectionTitleDirty =
            hasInitSecondButtonTitle &&
            secondConnectionTitleEntry != optimisticSecondConnectionTitle;
        if (secondConnectionTitleDirty)
            updates.secondButtonFormConnectionTitle =
                secondConnectionTitleEntry;
        secondConnectionSaving = secondConnectionTitleDirty
            ? secondConnectionTitleEntry
            : null;

        let maybeClearSavingState = () => {
            // titleChanging = false;
            if (savingData === updates) {
                stickyBarSaving = null;
                textPhoneNumberSaving = null;
                callPhoneNumberSaving = null;
                firstConnectionSaving = null;
                secondConnectionSaving = null;
                firstConnectionTitleSaving = null;
                secondConnectionTitleSaving = null;
            }
        };

        if (!_keys(updates).length) {
            maybeClearSavingState();
            return;
        }

        await updateOrg(_get(orgData, 'ref'), updates);

        maybeClearSavingState();
        // dispatch('save');

        // updateHostedFormPreview();
    }

    function handleEmailSendingModalClose() {
        isSendingEmail = false;
    }

    function handleEmailSendingModalOpen() {
        isSendingEmail = true;
    }
</script>

<HtmlHeadTitle {pageTitle} />
{#if isSendingEmail}<SendingEmailToDeveloperModal
        {codeSnippet}
        {handleEmailSendingModalClose}
        {orgData}
    />{:else}
    <OrgPageLayout {orgId} title={pageTitle}>
        <div class="cols">
            <article class="main-content growcol">
                <section class="card listgroup">
                    <header class="cols">
                        <h1 class="flexcol">Add Magic Script To Website</h1>
                    </header>
                    <p>
                        To Install LeadAction Magic Script, place this script at
                        the top of the &lt;head&gt; section just after any
                        &lt;meta&gt; tags.
                    </p>
                    <textarea
                        bind:value={codeSnippet}
                        use:autoheight
                        class="notes-entry"
                        placeholder="Use this space however you like..."
                        savedValue={codeSnippet}
                        readonly
                        disabled={true}
                    ></textarea>
                    <div class="button-group">
                        <button
                            type="button"
                            on:click|stopPropagation={handleCopyButtonClick}
                            class="button"
                        >
                            <ion-icon name="copy-outline" class="btn-icon" />
                            <span class="btn-text"> Copy Code </span>
                        </button>
                        <button
                            type="button"
                            on:click|stopPropagation={handleEmailSendingModalOpen}
                            class="button"
                            disabled={isLoading}
                        >
                            <ion-icon name="send-outline" class="btn-icon" />
                            <span class="btn-text">
                                Email to my web developer
                            </span>
                        </button>
                    </div>
                </section>
                <section class="card listgroup">
                    <header class="cols">
                        <h1 class="flexcol">Website Sticky Bar</h1>
                    </header>
                    <div class="toggle-group">
                        {#if isLoading}
                            <div></div>
                        {:else if $orgDataQuery.error}
                            <div></div>
                        {:else}
                            <label class="fieldlabel">
                                <Toggle bind:checked={enableStickyBarEntry} />
                                Enable Sticky Bar
                                <span class="muted"
                                    >Places bar at top of your website with
                                    phone number and links to Lead Form pop-ups</span
                                >
                            </label>
                            {#if enableStickyBarEntry}
                                <div
                                    transition:fly|local={{
                                        y: -20,
                                        duration: 200,
                                    }}
                                    style="padding: 5px 0 0 20px;"
                                >
                                    <div class="color-icon-selector-container">
                                        <div class="color-selectors">
                                            <span class="color-selectors-header"
                                                >Sticky Bar Background Color</span
                                            >
                                            <ColorPicker
                                                initialColor={_get(
                                                    orgData,
                                                    'data.stickyBarColor',
                                                    '',
                                                )
                                                    ? _get(
                                                          orgData,
                                                          'data.stickyBarColor',
                                                          '',
                                                      )
                                                    : '#000000'}
                                                defaultColor="#000000"
                                                onChangeColorPicker={(
                                                    color,
                                                ) => {
                                                    updateOrg(
                                                        _get(orgData, 'ref'),
                                                        {stickyBarColor: color},
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div class="color-selectors">
                                            <span class="color-selectors-header"
                                                >Sticky Bar Text Color</span
                                            >
                                            <ColorPicker
                                                initialColor={_get(
                                                    orgData,
                                                    'data.stickyBarTextColor',
                                                    '',
                                                )
                                                    ? _get(
                                                          orgData,
                                                          'data.stickyBarTextColor',
                                                          '',
                                                      )
                                                    : '#FFFFFF'}
                                                defaultColor="#FFFFFF"
                                                onChangeColorPicker={(
                                                    color,
                                                ) => {
                                                    updateOrg(
                                                        _get(orgData, 'ref'),
                                                        {
                                                            stickyBarTextColor:
                                                                color,
                                                        },
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <label class="fieldlabel">
                                        <Toggle
                                            bind:checked={willShowTextPhoneNumberEntry}
                                            disabled={!_get(
                                                orgData,
                                                'data.smsTollFree.number',
                                                '',
                                            )}
                                        />
                                        <span>Show Text Phone Number</span>
                                        {#if !_get(orgData, 'data.smsTollFree.number', '')}
                                            <span class="warning"
                                                >Business Phone Number must be
                                                issued under org settings.</span
                                            >{/if}
                                    </label>
                                    <label class="fieldlabel">
                                        <Toggle
                                            bind:checked={willShowCallPhoneNumberEntry}
                                            disabled={!_get(
                                                orgData,
                                                'data.smsTollFree.number',
                                                '',
                                            )}
                                        />
                                        <span>Show Call Phone Number</span>
                                        {#if !_get(orgData, 'data.smsTollFree.number', '')}
                                            <span class="warning"
                                                >Business Phone Number must be
                                                issued under org settings.</span
                                            >{/if}
                                    </label>
                                    <label class="fieldlabel">
                                        <Toggle
                                            bind:checked={willFirstButtonFormConnectionEntry}
                                        />
                                        Enable Lead Form Button 1
                                    </label>
                                    {#if willFirstButtonFormConnectionEntry}
                                        <table class="form-button-connection">
                                            <thead>
                                                <tr>
                                                    <th class="formfield"
                                                        >Button Title</th
                                                    >
                                                    <th class="connection"></th>
                                                    <th class="contactfield">
                                                        Linked Lead Form
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="formfield">
                                                        <MutationInput
                                                            type="text"
                                                            id="formName"
                                                            placeholder="Pricing Request Form"
                                                            bind:value={firstConnectionTitleEntry}
                                                            savingValue={firstConnectionTitleSaving}
                                                            savedValue={_get(
                                                                orgData,
                                                                'data.firstButtonFormConnectionTitle',
                                                                'Free 3 Day Pass',
                                                            )}
                                                            maxLength="30"
                                                        />
                                                    </td>
                                                    <td class="connection">
                                                        <div
                                                            class="link-graphic"
                                                        >
                                                            <span
                                                                class="link-start"
                                                            ></span>
                                                            <span
                                                                class="link-bar"
                                                            ></span>
                                                            <span
                                                                class="link-end"
                                                            ></span>
                                                        </div>
                                                    </td>
                                                    <td class="contactfield">
                                                        <Select
                                                            items={orgFormData.filter(
                                                                (item) =>
                                                                    !item.data
                                                                        .isDeleted &&
                                                                    item.data
                                                                        .isHosted,
                                                            )}
                                                            selectedValue={_find(
                                                                orgFormData,
                                                                {
                                                                    ref: _get(
                                                                        orgData,
                                                                        'data.firstButtonFormConnectionForm.ref',
                                                                    ),
                                                                },
                                                            )}
                                                            isClearable={true}
                                                            on:select={(
                                                                event,
                                                            ) => {
                                                                updateOrg(
                                                                    orgId,
                                                                    {
                                                                        firstButtonFormConnectionForm:
                                                                            event
                                                                                .detail
                                                                                .ref,
                                                                    },
                                                                );
                                                            }}
                                                            on:clear={() =>
                                                                updateOrg(
                                                                    orgId,
                                                                    {
                                                                        firstButtonFormConnectionForm:
                                                                            null,
                                                                    },
                                                                )}
                                                            placeholder="None selected."
                                                            optionIdentifier="ref"
                                                            getSelectionLabel={(
                                                                option,
                                                            ) =>
                                                                option.data
                                                                    .title}
                                                            getOptionLabel={(
                                                                option,
                                                            ) =>
                                                                option.data
                                                                    .title}
                                                        />

                                                        <!-- optionIdentifier="ref"
                                                selectedValue={}
                                                getOptionLabel={(
                                                    option,
                                                    filterText,
                                                ) =>
                                                    option.isCreator
                                                        ? `Create "${filterText}"`
                                                        : option.data.name}
                                                getSelectionLabel={(option) =>
                                                    option.data.name}
                                                isCreatable={false}
                                                isClearable={true}
                                                createItem={() => {}}
                                                on:select={(event) =>
                                                    updateFormSequence(
                                                        event.detail,
                                                    )}
                                                on:clear={(event) =>
                                                    updateFormSequence(null)}
                                                isDisabled={savingFormSequence ||
                                                    _get(
                                                        orgData,
                                                        'data.isDeleted',
                                                        false,
                                                    )}
                                                showIndicator={!_get(
                                                    orgData,
                                                    'data.mightAddSequence.ref',
                                                )} -->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div
                                            class="color-icon-selector-container"
                                        >
                                            <div class="color-selectors">
                                                <span
                                                    class="color-selectors-header"
                                                    >Button Background Color &
                                                    Icon</span
                                                >
                                                <ColorPicker
                                                    initialColor={_get(
                                                        orgData,
                                                        'data.firstFormConnectionButtonDetail.backgroundColor',
                                                        '',
                                                    )
                                                        ? _get(
                                                              orgData,
                                                              'data.firstFormConnectionButtonDetail.backgroundColor',
                                                              '',
                                                          )
                                                        : '#f4c00d'}
                                                    defaultColor="#f4c00d"
                                                    onChangeColorPicker={(
                                                        color,
                                                    ) => {
                                                        updateOrg(
                                                            _get(
                                                                orgData,
                                                                'ref',
                                                            ),
                                                            {
                                                                firstFormConnectionButtonDetail:
                                                                    {
                                                                        backgroundColor:
                                                                            color,
                                                                    },
                                                            },
                                                        );
                                                    }}
                                                />
                                                <IconPicker
                                                    initialIcon={_get(
                                                        orgData,
                                                        'data.firstFormConnectionButtonDetail.icon',
                                                        '',
                                                    )
                                                        ? _get(
                                                              orgData,
                                                              'data.firstFormConnectionButtonDetail.icon',
                                                              '',
                                                          )
                                                        : 'fa-bolt-lightning'}
                                                    defaultIcon="fa-bolt-lightning"
                                                    onChangeIcon={(icon) => {
                                                        updateOrg(
                                                            _get(
                                                                orgData,
                                                                'ref',
                                                            ),
                                                            {
                                                                firstFormConnectionButtonDetail:
                                                                    {
                                                                        icon: icon,
                                                                    },
                                                            },
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div class="color-selectors">
                                                <span
                                                    class="color-selectors-header"
                                                    >Button Text Color</span
                                                >
                                                <ColorPicker
                                                    initialColor={_get(
                                                        orgData,
                                                        'data.firstFormConnectionButtonDetail.textColor',
                                                        '',
                                                    )
                                                        ? _get(
                                                              orgData,
                                                              'data.firstFormConnectionButtonDetail.textColor',
                                                              '',
                                                          )
                                                        : '#FFFFFF'}
                                                    defaultColor="#FFFFFF"
                                                    onChangeColorPicker={(
                                                        color,
                                                    ) => {
                                                        updateOrg(
                                                            _get(
                                                                orgData,
                                                                'ref',
                                                            ),
                                                            {
                                                                firstFormConnectionButtonDetail:
                                                                    {
                                                                        textColor:
                                                                            color,
                                                                    },
                                                            },
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    {/if}
                                    <label class="fieldlabel">
                                        <Toggle
                                            bind:checked={willSecondButtonFormConnectionEntry}
                                        />
                                        Enable Lead Form Button 2
                                    </label>
                                    {#if willSecondButtonFormConnectionEntry}
                                        <table class="form-button-connection">
                                            <thead>
                                                <tr>
                                                    <th class="formfield"
                                                        >Button Title</th
                                                    >
                                                    <th class="connection"></th>
                                                    <th class="contactfield">
                                                        Linked Lead Form
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="formfield">
                                                        <MutationInput
                                                            type="text"
                                                            id="formName"
                                                            placeholder="Pricing Request Form"
                                                            bind:value={secondConnectionTitleEntry}
                                                            savingValue={secondConnectionTitleSaving}
                                                            savedValue={_get(
                                                                orgData,
                                                                'data.secondButtonFormConnectionTitle',
                                                                'Pricing',
                                                            )}
                                                            maxLength="30"
                                                        />
                                                    </td>
                                                    <td class="connection">
                                                        <div
                                                            class="link-graphic"
                                                        >
                                                            <span
                                                                class="link-start"
                                                            ></span>
                                                            <span
                                                                class="link-bar"
                                                            ></span>
                                                            <span
                                                                class="link-end"
                                                            ></span>
                                                        </div>
                                                    </td>
                                                    <td class="contactfield">
                                                        <Select
                                                            items={orgFormData.filter(
                                                                (item) =>
                                                                    !item.data
                                                                        .isDeleted &&
                                                                    item.data
                                                                        .isHosted,
                                                            )}
                                                            selectedValue={_find(
                                                                orgFormData,
                                                                {
                                                                    ref: _get(
                                                                        orgData,
                                                                        'data.secondButtonFormConnectionForm.ref',
                                                                    ),
                                                                },
                                                            )}
                                                            isClearable={true}
                                                            on:select={(
                                                                event,
                                                            ) => {
                                                                updateOrg(
                                                                    orgId,
                                                                    {
                                                                        secondButtonFormConnectionForm:
                                                                            event
                                                                                .detail
                                                                                .ref,
                                                                    },
                                                                );
                                                            }}
                                                            on:clear={() =>
                                                                updateOrg(
                                                                    orgId,
                                                                    {
                                                                        secondButtonFormConnectionForm:
                                                                            null,
                                                                    },
                                                                )}
                                                            placeholder="None selected."
                                                            optionIdentifier="ref"
                                                            getSelectionLabel={(
                                                                option,
                                                            ) =>
                                                                option.data
                                                                    .title}
                                                            getOptionLabel={(
                                                                option,
                                                            ) =>
                                                                option.data
                                                                    .title}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div
                                            class="color-icon-selector-container"
                                        >
                                            <div class="color-selectors">
                                                <span
                                                    class="color-selectors-header"
                                                    >Button Background Color &
                                                    Icon</span
                                                >
                                                <ColorPicker
                                                    initialColor={_get(
                                                        orgData,
                                                        'data.secondFormConnectionButtonDetail.backgroundColor',
                                                        '',
                                                    )
                                                        ? _get(
                                                              orgData,
                                                              'data.secondFormConnectionButtonDetail.backgroundColor',
                                                              '',
                                                          )
                                                        : '#717678'}
                                                    defaultColor="#717678"
                                                    onChangeColorPicker={(
                                                        color,
                                                    ) => {
                                                        updateOrg(
                                                            _get(
                                                                orgData,
                                                                'ref',
                                                            ),
                                                            {
                                                                secondFormConnectionButtonDetail:
                                                                    {
                                                                        backgroundColor:
                                                                            color,
                                                                    },
                                                            },
                                                        );
                                                    }}
                                                />
                                                <IconPicker
                                                    initialIcon={_get(
                                                        orgData,
                                                        'data.secondFormConnectionButtonDetail.icon',
                                                        '',
                                                    )
                                                        ? _get(
                                                              orgData,
                                                              'data.secondFormConnectionButtonDetail.icon',
                                                              '',
                                                          )
                                                        : 'fa-dollar-sign'}
                                                    defaultIcon="fa-dollar-sign"
                                                    onChangeIcon={(icon) => {
                                                        updateOrg(
                                                            _get(
                                                                orgData,
                                                                'ref',
                                                            ),
                                                            {
                                                                secondFormConnectionButtonDetail:
                                                                    {
                                                                        icon: icon,
                                                                    },
                                                            },
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div class="color-selectors">
                                                <span
                                                    class="color-selectors-header"
                                                    >Button Text Color</span
                                                >
                                                <ColorPicker
                                                    initialColor={_get(
                                                        orgData,
                                                        'data.secondFormConnectionButtonDetail.textColor',
                                                        '',
                                                    )
                                                        ? _get(
                                                              orgData,
                                                              'data.secondFormConnectionButtonDetail.textColor',
                                                              '',
                                                          )
                                                        : '#FFFFFF'}
                                                    defaultColor="#FFFFFF"
                                                    onChangeColorPicker={(
                                                        color,
                                                    ) => {
                                                        updateOrg(
                                                            _get(
                                                                orgData,
                                                                'ref',
                                                            ),
                                                            {
                                                                secondFormConnectionButtonDetail:
                                                                    {
                                                                        textColor:
                                                                            color,
                                                                    },
                                                            },
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    {/if}
                                </div>
                            {/if}
                        {/if}
                    </div>
                </section>
                <section class="card listgroup"></section>
            </article>
        </div>
    </OrgPageLayout>
{/if}

<style>
    .cols h1 {
        padding: 0;
    }
    .cols {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
    }
    h1 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        margin: 0;
        padding: 0 0 10px;
    }
    .main-content {
        margin: 0 20px;
    }
    .growcol {
        flex-grow: 1;
        flex-shrink: 0;
    }
    .flexcol {
        flex-grow: 1;
        flex-shrink: 1;
    }
    .card {
        background: var(--white);
        border: 1px solid var(--offoffwhite);
        border-radius: 9px;
        padding: 20px;
    }
    .card + .card {
        margin-top: 20px;
    }
    textarea.notes-entry {
        font-family: monospace;
        /* min-height: 100px; */
        padding: 15px 10px;
        display: flex;
        align-items: center;
        resize: none;
        font-size: 14px;
        line-height: 1.5;
    }
    .button {
        margin: 20px 0 20px 20px;
    }
    .button:hover {
        color: var(--blue);
    }
    .button-group {
        display: flex;
    }
    table {
        width: 70%;
    }
    table .formfield,
    table .contactfield {
        width: 40%;
    }
    table .connection {
        width: 120px;
    }
    th,
    td {
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        vertical-align: middle;
        padding: 5px 0;
    }
    th {
        font-weight: 600;
    }
    .link-graphic {
        position: relative;
    }
    .link-bar {
        position: absolute;
        background: var(--slate-o10);
        background: var(--lightblue);
        left: 23px;
        right: 23px;
        top: 50%;
        margin-top: -1px;
        height: 2px;
        border-radius: 2px;
    }
    .link-start,
    .link-end {
        position: absolute;
        top: 50%;
        margin-top: -5px; /* half height + border-width */
        border-radius: 5px;
        width: 6px;
        height: 6px;
        border: 2px solid var(--slate-o10);
        border-color: var(--blue);
    }
    .link-start {
        left: 10px;
    }
    .link-end {
        right: 10px;
    }
    .form-button-connection {
        padding: 0px 40px;
        margin-bottom: 10px;
    }
    .toggle-group {
        padding: 20px 0px;
    }
    .warning {
        color: var(--red);
        margin-left: 10px;
    }
    .color-selectors {
        display: flex;
        flex-direction: column;
        padding-left: 40px;
        margin-bottom: 10px;
        gap: 10px;
    }
    .color-selectors-header {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
    }
    .color-icon-selector-container {
        display: flex;
    }
</style>
