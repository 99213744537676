<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};
    FRAGMENTS.OrgSubscription_subscription = gql`
        fragment OrgSubscription_subscription on StripeSubscription {
            status
            trial_start
            trial_end
            current_period_end
            cancel_at
            canceled_at
            cancel_at_period_end
            ended_at
            items {
                quantity
                price {
                    unit_amount
                    currency
                    product {
                        name
                    }
                }
            }
        }
    `;

    const QUERIES = {};
    const MUTATIONS = {};

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getClient} from 'svelte-apollo';
    import _get from 'lodash/get';
    import _map from 'lodash/map';
    import _lowerCase from 'lodash/lowerCase';
    import _startCase from 'lodash/startCase';
    import _toUpper from 'lodash/toUpper';
    import getSymbolFromCurrency from 'currency-symbol-map';
    import moment from 'moment';
    import {updateOrgStripeSubscriptions, rechargeSms} from '../api.js';

    const client = getClient();

    export let orgId;
    export let subscriptionData = {};

    let orgPath;
    $: orgPath = `/orgs/${orgId}`;

    let statusDisplayText;
    $: statusDisplayText = _startCase(
        _lowerCase(_get(subscriptionData, 'status')),
    );

    const statusToClass = {
        trialing: 'success',
        active: 'primary',
        past_due: 'warning',
        unpaid: 'warning',
        incomplete: 'warning',
        incomplete_expired: 'danger',
        canceled: 'danger',
    };
    let statusDisplayClass = '';
    $: statusDisplayClass =
        statusToClass[_get(subscriptionData, 'status')] || '';

    // const statusToHelp = {
    //     trialing: 'success',
    //     active: 'Your subscription is up-to-date. You\'re awesome!',
    //     past_due: 'We had trouble billing your subscription',
    //     unpaid: 'warning',
    //     incomplete: 'warning',
    //     incomplete_expired: 'danger',
    //     canceled: 'danger',
    // };
    // let statusDisplayHelp = '';
    // $: statusDisplayHelp = statusToHelp[_get(subscriptionData, 'status')] || '';

    let periodEndDisplay = '';
    $: periodEndDisplay = moment(
        _get(subscriptionData, 'current_period_end'),
        moment.ISO_8601,
    ).format('dddd, ll');
    let trialStartDisplay = '';
    $: trialStartDisplay = _get(subscriptionData, 'trial_start')
        ? moment(_get(subscriptionData, 'trial_start'), moment.ISO_8601).format(
              'dddd, ll',
          )
        : '';

    let isRefreshing = false;
    async function refreshSubscriptionStatus() {
        if (isRefreshing) return;
        isRefreshing = true;
        try {
            await updateOrgStripeSubscriptions(orgId);
            await client.reFetchObservableQueries();
        } catch (error) {
            alert(
                'Unable to refresh subscription status. Please contact us if you believe there is an error in your subscription status. ' +
                    error.message,
            );
        }
        isRefreshing = false;
    }

    let isCharging = false;

    async function rechargeRemainingSms() {
        if (isCharging) {
            alert(
                'We are currently in the process of charging your SMS. Please kindly wait for a moment.',
            );
            return;
        }
        isCharging = true;
        try {
            const paymentStatus = await rechargeSms(orgId);
            let responseData = await paymentStatus.json();
            if (responseData.status == 'succeeded') {
                alert('You have successfully purchased 500 SMS.');
            } else {
                alert(
                    'We regret to inform you that the payment for 500 SMS has not been successfully processed. We kindly request that you attempt the payment again.',
                );
            }
        } catch (err) {
            alert(err);
        }

        isCharging = false;
    }
</script>

<article>
    <div class="subscription-card cols">
        <div class="growcol" class:muted={isRefreshing}>
            <ul class="product-list">
                {#if _get(subscriptionData, 'items.length')}
                    {#each _get(subscriptionData, 'items') as item}
                        <li>
                            <div class="product-name">
                                {_get(item, 'price.product.name')}
                                <span class="chip {statusDisplayClass}"
                                    >{statusDisplayText}</span
                                >
                            </div>
                            <div class="product-lineitem">
                                {#if item.quantity > 1}
                                    <span class="product-quantity">
                                        {item.quantity}
                                    </span>
                                    <span class="muted">at</span>
                                {/if}
                                <span class="product-price">
                                    {getSymbolFromCurrency(
                                        _toUpper(item.price.currency),
                                    ) || ''}{(item.price.unit_amount / 100)
                                        .toFixed(2)
                                        .replace('.00', '')}
                                </span>
                                <span class="product-frequency">
                                    per month
                                </span>
                            </div>
                        </li>
                    {/each}
                {/if}
            </ul>
            <aside class="next-steps">
                {#if _get(subscriptionData, 'status') == 'trialing'}
                    <p>
                        Your trial started on <strong
                            >{trialStartDisplay}</strong
                        >.
                    </p>
                    <p>
                        After your free trial ends on <strong
                            >{periodEndDisplay}</strong
                        >, this plan will continue automatically.
                    </p>
                    <p>
                        We'll email you before your trial ends. No stress on
                        missing the deadline — cancel anytime during the first
                        billing period for a full refund.
                    </p>
                {:else if _get(subscriptionData, 'status') == 'active'}
                    Your plan is paid through <strong>{periodEndDisplay}</strong
                    >, and will continue automatically.
                {:else if _get(subscriptionData, 'status') == 'past_due'}
                    We were unable to charge the credit card on file. Your
                    account will remain active for a grace period to give you a
                    chance to resolve the billing issue. Please reach out to us
                    if you need any assistance.
                {:else if _get(subscriptionData, 'status') == 'unpaid'}
                    We were unable to charge the credit card on file. Your
                    account will remain active for a grace period to give you a
                    chance to resolve the billing issue. Please reach out to us
                    if you need any assistance.
                {:else if _get(subscriptionData, 'status') == 'canceled'}
                    Your subscription has been canceled. Your account will
                    remain active through <strong>{periodEndDisplay}</strong>.
                {:else if _get(subscriptionData, 'status') == 'incomplete'}
                    We were unable to charge the credit card on file. Your
                    account will remain active for a grace period to give you a
                    chance to resolve the billing issue. Please reach out to us
                    if you need any assistance.
                {:else if _get(subscriptionData, 'status') == 'incomplete_expired'}
                    We were unable to charge the credit card on file. The grace
                    period has expired, and your subscription has been canceled.
                    If this was unintentional, you can begin a new subscription
                    to get back on track. Please reach out to us if you need any
                    assistance.
                {/if}
            </aside>
        </div>
        <div class="subscription-tools">
            <a href="{orgPath}/plan/manage" class="button">
                <ion-icon name="card-outline" class="btn-icon" />
                <span class="btn-text"> Manage Subscription </span>
            </a>
            <button
                type="button"
                class="button"
                on:click={refreshSubscriptionStatus}
            >
                <ion-icon
                    name="sync"
                    class="btn-icon"
                    class:spin={isRefreshing}
                />
                <span class="btn-text"> Refresh Status </span>
            </button>
            <button type="button" class="button" on:click={rechargeRemainingSms}>
                <ion-icon
                    name="sync"
                    class="battery-charging"
                    class:spin={isCharging}
                />
                <span class="btn-text"> Buy 500 SMS/MMS for $25 </span>
            </button>
        </div>
    </div>
</article>

<style>
    ul,
    li {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    ul {
        /* width: 320px; */
    }
    li + li {
        margin-top: 10px;
    }
    .subscription-card {
        background: var(--slate);
        color: white;
        border-radius: 6px;
        padding: 0 20px 20px;
    }
    .product-list,
    .subscription-tools {
        margin: 20px 0 0;
    }
    .subscription-tools {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    .subscription-tools .button {
        background: rgba(0, 0, 0, 0.2);
        color: #fff;
    }
    .subscription-tools .button + .button {
        margin: 10px 0 0;
    }
    .product-name {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.2;
    }
    .product-lineitem {
        font-size: 20px;
        font-weight: 400;
        line-height: 1.2;
    }
    .chip {
        display: inline-block;
        vertical-align: top;
        margin-top: 1px;
        margin-left: 5px;
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 3px;
        padding: 0 5px;
        background: rgba(0, 0, 0, 0.1);
    }
    .chip.primary {
        background: var(--lightblue);
        color: var(--blue);
    }
    .chip.success {
        background: var(--lightgreen);
        color: var(--green);
    }
    .chip.warning {
        background: var(--lightgold);
        color: var(--gold);
    }
    .chip.danger {
        background: var(--lightred);
        color: var(--red);
    }
    .cols {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .growcol {
        flex-grow: 1;
    }
    .next-steps {
        /* opacity: 0.8; */
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.3;
        max-width: 480px;
        margin-right: auto;
    }
    .next-steps strong {
        font-weight: 600;
    }
    .next-steps p {
        margin: 0;
        padding: 0;
    }
    .next-steps p + p {
        margin-top: 10px;
    }
    .spin {
        animation: spin-360 infinite 2s linear;
    }
    @keyframes spin-360 {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>
