<script context="module">
    const modalPaddingPx = 50;
    export {modalPaddingPx};
</script>

<script>
    import {onMount} from 'svelte';
    import {fly, fade} from 'svelte/transition';
    import Select from 'svelte-select';
    import MutationInput from './MutationInput.svelte';

    import {brandRegistrationOfOrg} from '../api';
    // export let isCreatingContact;
    export let handleBrandModalClose;
    export let orgId;
    export let orgData;
    export let userEmail;
    let modalNode;
    function handleContainerClick(event) {
        let isClickInsideModal =
            modalNode.contains(event.target) && modalNode != event.target;
        if (!isClickInsideModal) {
            handleBrandModalClose();
        }
    }
    function handleEscape(event) {
        if (event.keyCode == 27) {
            handleBrandModalClose();
        }
    }
    const countryList = ['US', 'CA'];
    let stateList = [];
    const entityTypeList = [
        'PRIVATE_PROFIT',
        'PUBLIC_PROFIT',
        'NON_PROFIT',
        'GOVERNMENT',
    ];
    let verticalList = [
        'REAL_ESTATE',
        'ENERGY',
        'HEALTHCARE',
        'ENTERTAINMENT',
        'RETAIL',
        'AGRICULTURE',
        'INSURANCE',
        'EDUCATION',
        'HOSPITALITY',
        'FINANCIAL',
        'GAMBLING',
        'CONSTRUCTION',
        'NGO',
        'MANUFACTURING',
        'GOVERNMENT',
        'TECHNOLOGY',
        'COMMUNICATION',
    ];
    let StockExchangeList = [
        'NONE',
        'NASDAQ',
        'NYSE',
        'AMEX',
        'AMX',
        'ASX',
        'B3',
        'BME',
        'BSE',
        'FRA',
        'ICEX',
        'JPX',
        'JSE',
        'KRX',
        'LON',
        'NSE',
        'OMX',
        'SEHK',
        'SSE',
        'STO',
        'SWX',
        'SZSE',
        'TSX',
        'TWSE',
        'VSE',
    ];

    let entityType = '';
    let brandRelationShip = 'MEDIUM_ACCOUNT';
    let city = '';
    let companyName = '';
    let country = '';
    let displayName = '';
    let ein = '';
    let vertical = '';
    let email = '';
    let phone = '';
    let postalCode = '';
    let state = '';
    let street = '';
    let stockExchange = '';
    let stockSymbol = '';
    let website = '';
    onMount(() => {
        country = orgData.country ? orgData.country : '';
        state = orgData.state ? orgData.state : '';
        city = orgData.city ? orgData.city : '';
        street = orgData.street ? orgData.street : '';
        postalCode = orgData.postalCode ? orgData.postalCode : '';
        displayName = orgData.name ? orgData.name : '';
        email = userEmail ? userEmail : '';
        phone = orgData.billingPhone ? orgData.billingPhone : '';
        updateStateList(country);
    });

    function updateStateList(country) {
        if (country === 'US') {
            stateList = [
                'AL',
                'AK',
                'AZ',
                'AR',
                'AS',
                'CA',
                'CO',
                'CT',
                'DE',
                'DC',
                'FL',
                'GA',
                'GU',
                'HI',
                'ID',
                'IL',
                'IN',
                'IA',
                'KS',
                'KY',
                'LA',
                'ME',
                'MD',
                'MA',
                'MI',
                'MN',
                'MS',
                'MO',
                'MT',
                'NE',
                'NV',
                'NH',
                'NJ',
                'NM',
                'NY',
                'NC',
                'ND',
                'MP',
                'OH',
                'OK',
                'OR',
                'PA',
                'PR',
                'RI',
                'SC',
                'SD',
                'TN',
                'TX',
                'TT',
                'UT',
                'VT',
                'VA',
                'VI',
                'WA',
                'WV',
                'WI',
                'WY',
            ];
        } else if (country === 'CA') {
            stateList = [
                'AB',
                'BC',
                'MB',
                'NB',
                'NL',
                'NT',
                'NS',
                'NU',
                'ON',
                'PE',
                'QC',
                'SK',
                'YT',
            ];
        } else {
            stateList = [];
        }
    }
    let isSending = false;
    async function sendVerificationData() {
        isSending = true;
        let invalidFields = [];

        const submissionData = {
            EntityType: entityType,
            BrandRelationShip: brandRelationShip,
            City: city,
            CompanyName: companyName,
            Country: country,
            DisplayName: displayName,
            Ein: ein,
            Email: email,
            Phone: phone,
            PostalCode: postalCode,
            State: state,
            Street: street,
            StockExchange: stockExchange,
            StockSymbol: stockSymbol,
            Vertical: vertical,
            IsMain: false,
            Website: website,
        };
        submissionData.Phone = validatePhoneNumber(submissionData.Phone);
        if (submissionData.Phone.length != 12) {
            alert(
                'Entered phone number is not valid. Please check and try to input again.',
            );
            isSending = false;
            return;
        }
        if (
            !submissionData.EntityType ||
            submissionData.EntityType === '' ||
            submissionData.EntityType === undefined ||
            submissionData.EntityType === null
        ) {
            invalidFields.push('EntityType');
        } else if (submissionData.EntityType !== 'PUBLIC_PROFIT') {
            for (const field in submissionData) {
                if (field !== 'StockExchange' && field !== 'StockSymbol') {
                    if (
                        submissionData[field] === '' ||
                        submissionData[field] === undefined ||
                        submissionData[field] === null
                    ) {
                        invalidFields.push(field);
                    }
                }
            }
        } else {
            for (const field in submissionData) {
                if (
                    submissionData[field] === '' ||
                    submissionData[field] === undefined ||
                    submissionData[field] === null
                ) {
                    invalidFields.push(field);
                }
            }
        }

        if (invalidFields.length > 0) {
            let errorMessage = 'The ';

            if (invalidFields.length === 1) {
                errorMessage += `${invalidFields[0]} field can't be blank. Please fill this form.`;
            } else {
                errorMessage += `${invalidFields.slice(0, -1).join(', ')} and ${
                    invalidFields.slice(-1)[0]
                } fields can't be blank. Please fill these forms.`;
            }
            isSending = false;
            alert(errorMessage);
        } else {
            const response = await brandRegistrationOfOrg(
                orgId,
                submissionData,
            );
            let responseData = await response.json();
            alert(responseData.resText);

            handleBrandModalClose();
        }
    }

    function isValidPhoneNumber(phoneNumber) {
        return /^\+\d{11}$/.test(phoneNumber);
    }

    function validatePhoneNumber(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
            let normalizedNumber = phoneNumber.replace(/\D/g, '');
            if (normalizedNumber.length === 11) {
                normalizedNumber = '+' + normalizedNumber;
            } else {
                normalizedNumber = '+1' + normalizedNumber;
            }
            return normalizedNumber;
        }
        return phoneNumber;
    }
</script>

<svelte:window on:keydown={handleEscape} />

<div
    class="modal-container"
    on:click={handleContainerClick}
    style="padding:{modalPaddingPx}px;"
>
    <div class="modal-backdrop" transition:fade={{duration: 200}} />
    <div class="modal-close">
        <ion-icon name="close-outline" />
        <span class="keyboard-shortcut"> esc </span>
    </div>
    <div
        class="modal"
        bind:this={modalNode}
        transition:fly={{y: 100, duration: 200}}
    >
        <slot />
        <div class="sidebar-content">
            <h1>Brand Registration</h1>
            <section class="section">
                <div class="field-section">
                    <h3 class="field-name">Company Info</h3>
                    <div class="field-values">
                        <div class="field-item">
                            <label class="tag-title" for="entityType"
                                >Entity Type
                            </label>
                            <Select
                                placeholder="Select the entity type here..."
                                items={entityTypeList}
                                selectedValue={entityType}
                                isCreatable={false}
                                isClearable={false}
                                on:select={(event) => {
                                    entityType = event.detail.value;
                                }}
                                showIndicator={true}
                            />
                        </div>
                        <div class="field-item">
                            <label class="tag-title" for="companyName"
                                >Legal Company Name</label
                            >
                            <MutationInput
                                type="text"
                                id="companyName"
                                placeholder="The Avengers"
                                bind:value={companyName}
                                on:change={(event) => {
                                    companyName = event.detail.value;
                                }}
                            />
                        </div>
                        <div class="field-item">
                            <label class="tag-title" for="displayName"
                                >DBA or Brand Name</label
                            >
                            <MutationInput
                                type="text"
                                id="displayName"
                                placeholder="The Avengers"
                                bind:value={displayName}
                                savedValue={displayName}
                                on:change={(event) => {
                                    displayName = event.detail.value;
                                }}
                            />
                            <!-- savingValue={nameSaving} -->
                            <!-- on:keydown={isNewOrg ? blockEnterKey : null} -->
                        </div>
                    </div>
                    <div class="field-values">
                        <div class="field-item">
                            <label class="tag-title" for="ein"
                                >Employer Identification Number</label
                            >
                            <MutationInput
                                type="text"
                                id="ein"
                                placeholder="12-3456789"
                                bind:value={ein}
                                on:change={(event) => {
                                    ein = event.detail.value;
                                }}
                            />
                            <span class="description"
                                >Enter a 9-digit number e.g. 123456789 or
                                12-3456789.</span
                            >
                        </div>
                        <div class="field-item">
                            <label class="tag-title" for="vertical"
                                >Industry Vertical</label
                            >

                            <Select
                                placeholder="Select the entity type here..."
                                items={verticalList}
                                selectedValue={vertical}
                                isCreatable={false}
                                isClearable={false}
                                on:select={(event) => {
                                    vertical = event.detail.value;
                                }}
                                showIndicator={true}
                            />
                            <!-- savingValue={nameSaving} -->
                            <!-- on:keydown={isNewOrg ? blockEnterKey : null} -->
                        </div>
                        <div class="field-item">
                            <label class="tag-title" for="website"
                                >Website(Optional, But recommended)</label
                            >
                            <MutationInput
                                type="text"
                                id="website"
                                placeholder="https://supercrossfit.com"
                                bind:value={website}
                                on:change={(event) => {
                                    website = event.detail.value;
                                }}
                            />
                        </div>
                    </div>
                    {#if entityType == 'PUBLIC_PROFIT'}
                        <div class="field-values">
                            <div class="field-item">
                                <label class="tag-title" for="stockSymbol"
                                    >StockSymbol(Required for public)</label
                                >
                                <MutationInput
                                    type="text"
                                    id="stockSymbol"
                                    placeholder="BAND"
                                    bind:value={stockSymbol}
                                    on:change={(event) => {
                                        stockSymbol = event.detail.value;
                                    }}
                                />
                            </div>
                            <div class="field-item">
                                <label class="tag-title" for="stockExchange"
                                    >StockExchange(Required for public)</label
                                >
                                <Select
                                    placeholder="Select the Stock Exchange here..."
                                    items={StockExchangeList}
                                    selectedValue={stockExchange}
                                    isCreatable={false}
                                    isClearable={false}
                                    on:select={(event) => {
                                        stockExchange = event.detail.value;
                                    }}
                                    showIndicator={true}
                                />
                            </div>
                        </div>
                    {/if}
                </div>
                <div class="field-section">
                    <h3 class="field-name">Company Addresses</h3>
                    <div class="field-values">
                        <div class="field-item">
                            <label class="tag-title" for="country"
                                >Country</label
                            >
                            <Select
                                placeholder="Select the Country here..."
                                items={countryList}
                                selectedValue={country}
                                isCreatable={false}
                                isClearable={false}
                                on:select={(event) => {
                                    country = event.detail.value;
                                    updateStateList(country);
                                }}
                                showIndicator={true}
                            />
                        </div>
                        <div class="field-item">
                            <label class="tag-title" for="country">State</label>
                            <Select
                                placeholder="Select the state here..."
                                items={stateList}
                                selectedValue={state}
                                isCreatable={false}
                                isClearable={false}
                                on:select={(event) => {
                                    state = event.detail.value;
                                }}
                                showIndicator={true}
                            />
                        </div>
                        <div class="field-item">
                            <label class="tag-title" for="city">City</label>
                            <MutationInput
                                type="text"
                                id="city"
                                placeholder="Austin"
                                bind:value={city}
                                savedValue={orgData.city}
                            />
                            <!-- savingValue={nameSaving} -->
                            <!-- on:keydown={isNewOrg ? blockEnterKey : null} -->
                        </div>
                    </div>
                    <div class="field-values">
                        <div class="field-item">
                            <label class="tag-title" for="street">Street</label>
                            <MutationInput
                                type="text"
                                id="street"
                                placeholder="Clark Street"
                                bind:value={street}
                                savedValue={orgData.street}
                            />
                        </div>
                        <div class="field-item">
                            <label class="tag-title" for="postalCode"
                                >Postal Code</label
                            >
                            <MutationInput
                                type="text"
                                id="postalCode"
                                placeholder="12345"
                                bind:value={postalCode}
                                savedValue={orgData.postalCode}
                            />
                        </div>
                    </div>
                </div>
                <div class="field-section">
                    <h3 class="field-name">Contact Info</h3>
                    <div class="field-values">
                        <div class="field-item">
                            <label class="tag-title" for="email">Email</label>
                            <MutationInput
                                type="text"
                                id="email"
                                placeholder="abc@xyz.gmail"
                                bind:value={email}
                                savedValue={userEmail}
                            />
                            <!-- savedValue={orgData.email} -->
                        </div>
                        <div class="field-item">
                            <label class="tag-title" for="phone"
                                >Phone Number</label
                            >
                            <MutationInput
                                type="text"
                                id="phone"
                                placeholder="+15005005000"
                                bind:value={phone}
                                savedValue={orgData.billingPhoneNumber}
                            />
                            <span class="description"
                                >Notes : Please enter the phone number as
                                +15005005000 formats.</span
                            >
                        </div>
                    </div>
                </div>
            </section>
            <div class="controll-button">
                <button
                    type="button"
                    class="button"
                    disabled={isSending}
                    on:click|stopPropagation={sendVerificationData}
                >
                    <ion-icon name="add" class="btn-icon" />
                    <span class="btn-text"> Comfirm Registration </span>
                </button>
            </div>
        </div>
    </div>
</div>

<style>
    .modal-container {
        /* overscroll-behavior: contain; */
        background: rgba(0, 0, 0, 0.01); /* fix scrollbar contrast */
        position: absolute;
        overflow: auto;
        --webkit-overflow-scrolling: touch;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        /* padding: 80px; */
    }
    .modal-backdrop {
        background: var(--offblack-backdrop);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    @supports (backdrop-filter: blur(20px)) {
        .modal-backdrop {
            background: var(--offblack-backdrop-blur);
            backdrop-filter: blur(20px);
            backdrop-filter: saturate(180%) blur(20px);
        }
    }
    .modal-close {
        color: white;
        position: fixed;
        top: 0;
        right: 0;
        width: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }
    .modal-close ion-icon {
        font-size: 48px;
        padding-top: 2px;
    }
    .modal-close .keyboard-shortcut {
        /* border: 1px solid; */
        border-radius: 3px;
        font-size: 12px;
        /* font-family: monospace; */
        font-weight: 400;
        line-height: 14px;
        padding: 0 4px 1px;
        margin-top: -8px;
        opacity: 0.62;
    }
    .modal {
        position: relative;
    }
    .modal > :global(*) {
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    }
    .sidebar-content {
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        background: #ffffff;
        color: var(--offblack);
        border-radius: 4px;
        padding: 50px;
        /* overflow: hidden; */
    }
    .section {
        padding: 30px 20px 45px;
    }
    .field-name {
        font-size: 18px;
        line-height: 1.4;
        font-weight: 600;
        margin: 0 0 10px;
        padding: 0;
    }
    .field-section + .field-section {
        margin-top: 20px;
        border-top: 1px solid var(--slate-o10);
        padding-top: 20px;
    }
    .field-values {
        display: flex;
        /* flex-direction: column; */
        padding: 20px;
        margin: 0 0 20px;
    }
    .field-item {
        width: 30%;
        margin: 10px;
    }
    .tag-title {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 6px;
    }
    .description {
        margin: 5px 10px;
        color: gray;
    }
</style>
