<script context="module">
    import gql from 'graphql-tag';
    import {FRAGMENTS as EditMessageTemplate_FRAGMENTS} from './EditMessageTemplate.svelte';

    const FRAGMENTS = {};
    FRAGMENTS.MessageTemplateBlock_org = gql`
        fragment MessageTemplateBlock_org on OrgData {
            ...EditMessageTemplate_org
        }
        ${EditMessageTemplate_FRAGMENTS.EditMessageTemplate_org}
    `;
    FRAGMENTS.MessageTemplateBlock_messageTemplate = gql`
        fragment MessageTemplateBlock_messageTemplate on MessageTemplateData {
            body
            subjectLine
            email
            sms
            smsViaLocal
            draft {
                ref
                data {
                    body
                    subjectLine
                    email
                    sms
                    smsViaLocal
                }
            }
            org {
                ref
                data {
                    name
                }
            }
        }
    `;
    FRAGMENTS.MessageTemplateBlock_sequenceAction = gql`
        fragment MessageTemplateBlock_sequenceAction on SequenceAction {
            _id
            type
            message {
                _id
                ...MessageTemplateBlock_messageTemplate
            }
        }
        ${FRAGMENTS.MessageTemplateBlock_messageTemplate}
    `;

    const QUERIES = {};
    // QUERIES.sequence = gql`
    //     query sequence($id: ID!) {
    //         findSequenceByID(id: $id) {
    //             ...editSeqActsSequence
    //         }
    //     }
    //     ${FRAGMENTS.editSeqActsSequence}
    // `;

    const MUTATIONS = {};
    MUTATIONS.updateSequenceAction = gql`
        mutation updateSequenceAction(
            $id: ID!
            $data: [PartialUpdateSequenceActionInput!]!
        ) {
            partialUpdateSequenceAction(id: $id, data: $data) {
                ...MessageTemplateBlock_sequenceAction
            }
        }
        ${FRAGMENTS.MessageTemplateBlock_sequenceAction}
    `;
    MUTATIONS.updateMessageTemplate = gql`
        mutation updateMessageTemplate(
            $id: ID!
            $data: [PartialUpdateMessageTemplateInput!]!
        ) {
            partialUpdateMessageTemplate(id: $id, data: $data) {
                ...MessageTemplateBlock_messageTemplate
            }
        }
        ${FRAGMENTS.MessageTemplateBlock_messageTemplate}
    `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import _get from 'lodash/get';
    import _filter from 'lodash/filter';
    import _find from 'lodash/find';
    import Select from 'svelte-select';
    import {parsePhoneNumberFromString} from 'libphonenumber-js';
    import Flipper from './Flipper.svelte';
    import Toggle from './Toggle.svelte';
    import EditMessageTemplate from './EditMessageTemplate.svelte';
    import {
        getTemplateFields,
        wrapTemplateFieldsWithMarkup,
    } from '../../templateFields';

    export let messageTemplateData;
    export let orgData;

    let isEditing = false;

    $: isMessageValid = !!_get(messageTemplateData, 'data.body');
    $: if (_get(messageTemplateData, 'ref') && !isMessageValid) {
        isEditing = true;
    }

    let editMessageTemplate;
    let messageFormStatus;

    const sanitizeHtmlTags = (text) =>
        text.replace(/</g, '&lsaquo;').replace(/>/g, '&rsaquo;');
    $: templateFields = getTemplateFields({
        org: _get(messageTemplateData, 'data.org'),
    });
    $: subjectWithTemplateTags = wrapTemplateFieldsWithMarkup(
        sanitizeHtmlTags(
            _get(messageTemplateData, 'data.subjectLine') || '(no subject)',
        ),
        templateFields,
        '<span class="template-field">',
        '</span>',
    );
    $: bodyWithTemplateTags = wrapTemplateFieldsWithMarkup(
        sanitizeHtmlTags(
            _get(messageTemplateData, 'data.body') || '(nothing here yet)',
        ),
        templateFields,
        '<span class="template-field">',
        '</span>',
    );

    // We would typically assign an object as a unique value, but that doesn't play nicely
    // with svelte-select <Select> because they JSON.stringify() the values before comparing them.
    const SMS_VIA_TOLL_FREE = 'SMS_VIA_TOLL_FREE';
    const SMS_VIA_LOCAL = 'SMS_VIA_LOCAL';
    let outboundSmsNumberOptions;
    let formatPhoneNumber = (number) => {
        number = number || '';
        const parsed =
            parsePhoneNumberFromString(number) ||
            parsePhoneNumberFromString('+1' + number);
        return parsed ? parsed.formatNational() : number;
    };
    $: outboundSmsNumberOptions = _filter(
        [
            {
                number: _get(orgData, 'data.smsTollFree.number'),
                label: `Business SMS Number: ${formatPhoneNumber(
                    _get(orgData, 'data.smsTollFree.number'),
                )}`,
                value: SMS_VIA_TOLL_FREE,
            },
            {
                number: _get(orgData, 'data.smsLocal.number'),
                label: `Personal SMS Number: ${formatPhoneNumber(
                    _get(orgData, 'data.smsLocal.number'),
                )}`,
                value: SMS_VIA_LOCAL,
            },
        ],
        'number',
    );

    async function publishDraft() {
        const result = await editMessageTemplate.publishDraft();
        messageTemplateData = result;
    }
</script>

<Flipper flipped={isEditing}>
    <div slot="front" class="action-message">
        <header class="cols">
            <h1 class="flexcol">
                Message Template
                <span class="chip">
                    <span class="activity online" />
                    live
                </span>
            </h1>
            <div class="fixedcol tools">
                <ion-icon
                    name="lock-closed-outline"
                    class="muted"
                    style="margin-right:10px; font-size:20px;"
                />
                <button
                    type="button"
                    on:click|stopPropagation={() => {
                        isEditing = true;
                    }}
                    class="button"
                >
                    <span class="btn-text"> Edit this message template </span>
                </button>
            </div>
        </header>
        <div class="fieldrow">
            <label class="fieldlabel"> Subject Line </label>
            <div class="fakeinput">
                {@html subjectWithTemplateTags}
            </div>
        </div>
        <div class="fieldrow">
            <label class="fieldlabel"> Message Body </label>
            <div class="faketextarea">
                {@html bodyWithTemplateTags}
            </div>
        </div>
        <div class="smsrow">
            <div class="fieldrow smscol">
                <label class="fieldlabel">
                    <Toggle
                        checked={_get(messageTemplateData, 'data.sms')}
                        disabled={true}
                    />
                    Send via SMS
                    <span class="muted">if we have a contact phone number.</span
                    >
                </label>
            </div>
            <div class="fieldrow smscol">
                <div class="fieldlabel">
                    <Select
                        isDisabled={true}
                        items={outboundSmsNumberOptions}
                        noOptionsMessage="No SMS numbers available."
                        optionIdentifier="value"
                        getOptionLabel={(option, filterText) => option.label}
                        getSelectionLabel={(option) => option.label}
                        selectedValue={_find(outboundSmsNumberOptions, {
                            value: _get(messageTemplateData, 'data.smsViaLocal')
                                ? SMS_VIA_LOCAL
                                : SMS_VIA_TOLL_FREE,
                        })}
                        isClearable={false}
                        showIndicator={true}
                    />
                </div>
            </div>
        </div>
        <div class="fieldrow">
            <label class="fieldlabel">
                <Toggle
                    checked={_get(messageTemplateData, 'data.email')}
                    disabled={true}
                />
                Send via Email
                <span class="muted">if we have a contact email address.</span>
            </label>
        </div>
    </div>
    <div slot="back" class="action-message">
        <header class="cols">
            <h1 class="flexcol">
                Message Template
                <span class="chip"> draft </span>
            </h1>
            <div class="fixedcol tools">
                <span class="muted" style="margin-right:10px;">
                    {#if messageFormStatus == _get(editMessageTemplate, 'UNSAVED')}
                        Not saved.
                    {:else if messageFormStatus == _get(editMessageTemplate, 'SAVING')}
                        Saving...
                    {:else if messageFormStatus == _get(editMessageTemplate, 'SAVED')}
                        All changes saved.
                    {/if}
                </span>
                <button
                    type="button"
                    on:click|stopPropagation={() =>
                        editMessageTemplate.saveForm()}
                    class="button"
                >
                    <span class="btn-text"> Save draft </span>
                </button>
                <button
                    type="button"
                    on:click|stopPropagation={publishDraft}
                    class="button primary"
                >
                    <span class="btn-text"> Publish draft </span>
                </button>
                <button
                    type="button"
                    on:click|stopPropagation={() =>
                        editMessageTemplate.deleteDraft()}
                    class="button danger"
                >
                    <span class="btn-text"> Delete draft </span>
                </button>
                <button
                    type="button"
                    on:click|stopPropagation={() => {
                        isEditing = false;
                    }}
                    class="button"
                >
                    <!-- <ion-icon class="btn-icon" name="chevron-back" /> -->
                    <span class="btn-text"> View Live Version </span>
                </button>
            </div>
        </header>
        <EditMessageTemplate
            {messageTemplateData}
            {orgData}
            bind:this={editMessageTemplate}
            bind:formStatus={messageFormStatus}
            on:publishDraft={() => {
                isEditing = false;
            }}
            on:deleteDraft={() => {
                isEditing = false;
            }}
        />
    </div>
</Flipper>

<style>
    .action-message {
        background: #fff;
        border: 1px solid var(--slate-o10);
        border-radius: 9px;
        padding: 20px;
    }
    .cols {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
    }
    .fixedcol {
        flex-grow: 0;
        flex-shrink: 0;
    }
    .flexcol {
        flex-grow: 1;
        flex-shrink: 1;
    }
    .chip {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 600;
        line-height: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 15px;
        padding: 0 10px;
        margin-left: 10px;
        background: var(--slate-o10);
    }
    .activity {
        display: inline-block;
        width: 9px;
        height: 9px;
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.25);
        /* box-shadow: var(--box-shadow-raised-level1); */
        margin-right: 2px;
    }
    .activity.online {
        background: var(--green);
    }
    h1 {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .tools {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .fakeinput,
    .faketextarea {
        cursor: default;
    }
    .fakeinput :global(.template-field),
    .faketextarea :global(.template-field) {
        background: var(--slate-o20);
        color: var(--slate);
        padding: 0 2px;
        border-radius: 2px;
        cursor: default;
        font-weight: 600;
    }
    .smsrow {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .smscol {
        flex-grow: 0;
        flex-shrink: 0;
        /* width: 200px; */
    }
    .smscol + .smscol {
        margin-left: 20px;
        /* flex-grow: 1;
    flex-shrink: 0; */
    }
</style>
