export const icons = [
    'fa-address-book',
    'fa-address-card',
    'fa-adjust',
    'fa-american-sign-language-interpreting',
    'fa-anchor',
    'fa-archive',
    'fa-area-chart',
    'fa-arrows',
    'fa-arrows-h',
    'fa-arrows-v',
    'fa-asl-interpreting',
    'fa-assistive-listening-systems',
    'fa-asterisk',
    'fa-at',
    'fa-austral-sign',
    'fa-automobile',
    'fa-audio-description',
    'fa-baht-sign',
    'fa-balance-scale',
    'fa-ban',
    'fa-bangladeshi-taka-sign',
    'fa-bank',
    'fa-bar-chart',
    'fa-barcode',
    'fa-bars',
    'fa-bath',
    'fa-bathtub',
    'fa-battery-empty',
    'fa-battery-full',
    'fa-battery-half',
    'fa-battery-quarter',
    'fa-battery-three-quarters',
    'fa-bed',
    'fa-beer',
    'fa-bell',
    'fa-bell-slash',
    'fa-bicycle',
    'fa-binoculars',
    'fa-birthday-cake',
    'fa-bitcoin-sign',
    'fa-blender-phone',
    'fa-blind',
    'fa-bolt',
    'fa-bolt-lightning',
    'fa-poo-storm',
    'fa-cloud-bolt',
    'fa-bomb',
    'fa-book',
    'fa-bookmark',
    'fa-braille',
    'fa-brazilian-real-sign',
    'fa-briefcase',
    'fa-bug',
    'fa-building',
    'fa-bullhorn',
    'fa-bullseye',
    'fa-bus',
    'fa-cab',
    'fa-calculator',
    'fa-calendar',
    'fa-calendar-check',
    'fa-calendar-minus',
    'fa-calendar-plus',
    'fa-calendar-times',
    'fa-camera',
    'fa-camera-retro',
    'fa-car',
    'fa-cash-register',
    'fa-square-caret-down',
    'fa-square-caret-left',
    'fa-square-caret-right',
    'fa-square-caret-up',
    'fa-stamp',
    'fa-cart-arrow-down',
    'fa-cart-plus',
    'fa-certificate',
    'fa-chart-line',
    'fa-chart-pie',
    'fa-check',
    'fa-check-circle',
    'fa-check-square',
    'fa-cedi-sign',
    'fa-cent-sign',
    'fa-child',
    'fa-circle',
    'fa-circle-dollar-to-slot',
    'fa-circle-notch',
    'fa-clock',
    'fa-clone',
    'fa-close',
    'fa-cloud',
    'fa-cloud-download',
    'fa-cloud-upload',
    'fa-code',
    'fa-code-fork',
    'fa-coffee',
    'fa-cog',
    'fa-cogs',
    'fa-coins',
    'fa-colon-sign',
    'fa-comment',
    'fa-comment-dollar',
    'fa-comment-dots',
    'fa-comment-medical',
    'fa-comment-sms',
    'fa-comment-slash',
    'fa-comments',
    'fa-comments-dollar',
    'fa-commenting',
    'fa-compass',
    'fa-copyright',
    'fa-credit-card',
    'fa-credit-card-alt',
    'fa-crop',
    'fa-crosshairs',
    'fa-cruzeiro-sign',
    'fa-cube',
    'fa-cubes',
    'fa-cutlery',
    'fa-dashboard',
    'fa-database',
    'fa-deaf',
    'fa-deafness',
    'fa-desktop',
    'fa-diamond',
    'fa-dot-circle',
    'fa-dollar-sign',
    'fa-dong-sign',
    'fa-download',
    'fa-drivers-license',
    'fa-edit',
    'fa-ellipsis-h',
    'fa-ellipsis-v',
    'fa-envelope',
    'fa-envelope-open',
    'fa-envelope-square',
    'fa-eraser',
    'fa-exchange',
    'fa-exclamation',
    'fa-exclamation-circle',
    'fa-exclamation-triangle',
    'fa-external-link',
    'fa-external-link-square',
    'fa-eye',
    'fa-eye-slash',
    'fa-eyedropper',
    'fa-euro-sign',
    'fa-fax',
    'fa-female',
    'fa-fighter-jet',
    'fa-file-archive',
    'fa-file-audio',
    'fa-file-code',
    'fa-file-excel',
    'fa-file-image',
    'fa-file-invoice-dollar',
    'fa-file-pdf',
    'fa-file-powerpoint',
    'fa-file-video',
    'fa-file-word',
    'fa-film',
    'fa-filter',
    'fa-filter-circle-dollar',
    'fa-fire',
    'fa-fire-extinguisher',
    'fa-flag',
    'fa-flag-checkered',
    'fa-flask',
    'fa-florin-sign',
    'fa-folder',
    'fa-folder-open',
    'fa-franc-sign',
    'fa-frown',
    'fa-futbol',
    'fa-gamepad',
    'fa-gavel',
    'fa-gear',
    'fa-gears',
    'fa-genderless',
    'fa-gift',
    'fa-globe',
    'fa-graduation-cap',
    'fa-guarani-sign',
    'fa-hard-of-hearing',
    'fa-hdd',
    'fa-hand-holding-dollar',
    'fa-handshake',
    'fa-hashtag',
    'fa-headphones',
    'fa-headphones-simple',
    'fa-heart',
    'fa-heartbeat',
    'fa-history',
    'fa-home',
    'fa-hotel',
    'fa-hourglass',
    'fa-hourglass-1',
    'fa-hourglass-2',
    'fa-hourglass-3',
    'fa-hourglass-end',
    'fa-hourglass-half',
    'fa-hourglass-start',
    'fa-house-chimney',
    'fa-house-chimney-window',
    'fa-house-chimney-user',
    'fa-house-chimney-medical',
    'fa-house-chimney-crack',
    'fa-hryvnia-sign',
    'fa-i-cursor',
    'fa-id-badge',
    'fa-id-card',
    'fa-image',
    'fa-inbox',
    'fa-indian-rupee-sign',
    'fa-industry',
    'fa-info',
    'fa-info-circle',
    'fa-institution',
    'fa-key',
    'fa-keyboard',
    'fa-kip-sign',
    'fa-landmark',
    'fa-language',
    'fa-laptop',
    'fa-lari-sign',
    'fa-leaf',
    'fa-legal',
    'fa-lemon',
    'fa-level-down',
    'fa-level-up',
    'fa-life-ring',
    'fa-lightbulb',
    'fa-line-chart',
    'fa-lira-sign',
    'fa-litecoin-sign',
    'fa-location-arrow',
    'fa-location-dot',
    'fa-location-pin',
    'fa-lock',
    'fa-low-vision',
    'fa-magic',
    'fa-magnet',
    'fa-magnifying-glass-dollar',
    'fa-mail-forward',
    'fa-mail-reply',
    'fa-mail-reply-all',
    'fa-male',
    'fa-manat-sign',
    'fa-map',
    'fa-map-location-dot',
    'fa-map-pin',
    'fa-map-signs',
    'fa-map-marker',
    'fa-meh',
    'fa-message',
    'fa-microchip',
    'fa-microphone',
    'fa-microphone-lines',
    'fa-microphone-lines-slash',
    'fa-microphone-slash',
    'fa-mill-sign',
    'fa-minus',
    'fa-minus-circle',
    'fa-minus-square',
    'fa-mobile',
    'fa-mobile-button',
    'fa-mobile-phone',
    'fa-mobile-retro',
    'fa-mobile-screen-button',
    'fa-mobile-screen',
    'fa-money-bill',
    'fa-money-bill-1',
    'fa-money-bill-1-wave',
    'fa-money-bill-transfer',
    'fa-money-bill-trend-up',
    'fa-money-bill-wheat',
    'fa-money-bill-wave',
    'fa-money-bills',
    'fa-money-check',
    'fa-money-check-dollar',
    'fa-moon',
    'fa-mortar-board',
    'fa-motorcycle',
    'fa-mouse-pointer',
    'fa-naira-sign',
    'fa-navicon',
    'fa-newspaper',
    'fa-object-group',
    'fa-object-ungroup',
    'fa-pager',
    'fa-paint-brush',
    'fa-paper-plane',
    'fa-paw',
    'fa-pencil',
    'fa-peseta-sign',
    'fa-peso-sign',
    'fa-pencil-square',
    'fa-percent',
    'fa-phone',
    'fa-phone-flip',
    'fa-phone-slash',
    'fa-phone-square',
    'fa-phone-volume',
    'fa-pie-chart',
    'fa-piggy-bank',
    'fa-plane',
    'fa-plug',
    'fa-plus',
    'fa-plus-circle',
    'fa-plus-square',
    'fa-podcast',
    'fa-power-off',
    'fa-print',
    'fa-puzzle-piece',
    'fa-qrcode',
    'fa-question',
    'fa-question-circle',
    'fa-quote-left',
    'fa-quote-right',
    'fa-random',
    'fa-record-vinyl',
    'fa-receipt',
    'fa-recycle',
    'fa-refresh',
    'fa-registered',
    'fa-remove',
    'fa-reorder',
    'fa-reply',
    'fa-reply-all',
    'fa-retweet',
    'fa-road',
    'fa-rocket',
    'fa-rss',
    'fa-rss-square',
    'fa-ruble-sign',
    'fa-rupee-sign',
    'fa-rupiah-sign',
    'fa-sack-dollar',
    'fa-sack-xmark',
    'fa-scale-balanced',
    'fa-scale-unbalanced',
    'fa-scale-unbalanced-flip',
    'fa-search-minus',
    'fa-search-plus',
    'fa-server',
    'fa-share',
    'fa-share-alt',
    'fa-share-alt-square',
    'fa-share-square',
    'fa-shekel-sign',
    'fa-shield',
    'fa-ship',
    'fa-shopping-bag',
    'fa-shopping-basket',
    'fa-shopping-cart',
    'fa-shower',
    'fa-sign-in',
    'fa-sign-out',
    'fa-sign-language',
    'fa-signal',
    'fa-signing',
    'fa-sitemap',
    'fa-sliders',
    'fa-smile',
    'fa-snowflake',
    'fa-soccer-ball',
    'fa-sort',
    'fa-sort-alpha-asc',
    'fa-sort-alpha-desc',
    'fa-sort-amount-asc',
    'fa-sort-amount-desc',
    'fa-sort-asc',
    'fa-sort-desc',
    'fa-sort-down',
    'fa-sort-numeric-asc',
    'fa-sort-numeric-desc',
    'fa-sort-up',
    'fa-space-shuttle',
    'fa-spinner',
    'fa-spoon',
    'fa-square',
    'fa-square-phone-flip',
    'fa-star',
    'fa-star-half',
    'fa-sterling-sign',
    'fa-sticky-note',
    'fa-street-view',
    'fa-suitcase',
    'fa-sun',
    'fa-tablet',
    'fa-tachometer',
    'fa-tag',
    'fa-tags',
    'fa-tasks',
    'fa-taxi',
    'fa-television',
    'fa-tenge-sign',
    'fa-terminal',
    'fa-thermometer',
    'fa-thermometer-0',
    'fa-thermometer-1',
    'fa-thermometer-2',
    'fa-thermometer-3',
    'fa-thermometer-4',
    'fa-thermometer-empty',
    'fa-thermometer-full',
    'fa-thermometer-half',
    'fa-thermometer-quarter',
    'fa-thermometer-three-quarters',
    'fa-thumb-tack',
    'fa-thumbs-down',
    'fa-thumbs-up',
    'fa-ticket',
    'fa-times',
    'fa-times-circle',
    'fa-times-rectangle',
    'fa-tint',
    'fa-toggle-off',
    'fa-toggle-on',
    'fa-trademark',
    'fa-trash',
    'fa-tree',
    'fa-trophy',
    'fa-truck',
    'fa-tty',
    'fa-turkish-lira-sign',
    'fa-tv',
    'fa-umbrella',
    'fa-universal-access',
    'fa-university',
    'fa-unlock',
    'fa-unlock-alt',
    'fa-unsorted',
    'fa-upload',
    'fa-user',
    'fa-user-circle',
    'fa-user-plus',
    'fa-user-minus',
    'fa-user-secret',
    'fa-user-times',
    'fa-users',
    'fa-users-viewfinder',
    'fa-users-slash',
    'fa-users-rectangle',
    'fa-users-rays',
    'fa-users-line',
    'fa-users-gear',
    'fa-users-between-lines',
    'fa-vault',
    'fa-vcard',
    'fa-video-camera',
    'fa-voicemail',
    'fa-volume-control-phone',
    'fa-volume-down',
    'fa-volume-off',
    'fa-volume-up',
    'fa-wallet',
    'fa-warning',
    'fa-wheelchair',
    'fa-wheelchair-alt',
    'fa-window-close',
    'fa-window-maximize',
    'fa-window-minimize',
    'fa-window-restore',
    'fa-wifi',
    'fa-won-sign',
    'fa-wrench',
    'fa-yen-sign',
    'fa-music',
    'fa-search',
    'fa-th-large',
    'fa-th',
    'fa-th-list',
    'fa-repeat',
    'fa-list-alt',
    'fa-font',
    'fa-bold',
    'fa-italic',
    'fa-text-height',
    'fa-text-width',
    'fa-align-left',
    'fa-align-center',
    'fa-align-right',
    'fa-align-justify',
    'fa-list',
    'fa-outdent',
    'fa-indent',
    'fa-step-backward',
    'fa-fast-backward',
    'fa-backward',
    'fa-play',
    'fa-pause',
    'fa-stop',
    'fa-forward',
    'fa-fast-forward',
    'fa-step-forward',
    'fa-eject',
    'fa-chevron-left',
    'fa-chevron-right',
    'fa-arrow-left',
    'fa-arrow-right',
    'fa-arrow-up',
    'fa-arrow-down',
    'fa-expand',
    'fa-compress',
    'fa-chevron-up',
    'fa-chevron-down',
    'fa-arrow-circle-left',
    'fa-arrow-circle-right',
    'fa-arrow-circle-up',
    'fa-arrow-circle-down',
    'fa-arrows-alt',
    'fa-link',
    'fa-scissors',
    'fa-paperclip',
    'fa-list-ul',
    'fa-list-ol',
    'fa-strikethrough',
    'fa-underline',
    'fa-table',
    'fa-caret-down',
    'fa-caret-up',
    'fa-caret-left',
    'fa-caret-right',
    'fa-columns',
    'fa-undo',
    'fa-clipboard',
    'fa-user-md',
    'fa-stethoscope',
    'fa-ambulance',
    'fa-medkit',
    'fa-h-square',
    'fa-angle-double-left',
    'fa-angle-double-right',
    'fa-angle-double-up',
    'fa-angle-double-down',
    'fa-angle-left',
    'fa-angle-right',
    'fa-angle-up',
    'fa-angle-down',
    'fa-chain-broken',
    'fa-superscript',
    'fa-subscript',
    'fa-chevron-circle-left',
    'fa-chevron-circle-right',
    'fa-chevron-circle-up',
    'fa-chevron-circle-down',
    'fa-play-circle',
    'fa-eur',
    'fa-gbp',
    'fa-usd',
    'fa-inr',
    'fa-jpy',
    'fa-rub',
    'fa-krw',
    'fa-file',
    'fa-file-text',
    'fa-long-arrow-down',
    'fa-long-arrow-up',
    'fa-long-arrow-left',
    'fa-long-arrow-right',
    'fa-try',
    'fa-header',
    'fa-paragraph',
    'fa-ils',
    'fa-venus',
    'fa-mars',
    'fa-mercury',
    'fa-transgender',
    'fa-transgender-alt',
    'fa-venus-double',
    'fa-mars-double',
    'fa-venus-mars',
    'fa-mars-stroke',
    'fa-mars-stroke-v',
    'fa-mars-stroke-h',
    'fa-neuter',
    'fa-train',
    'fa-subway',
];
