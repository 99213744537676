const _defaults = require('lodash/defaults');
const _includes = require('lodash/includes');
const moment = require('moment');
require('moment-timezone');
const {setDatelessTime} = require('./datelessTime.js');

function computeNextDelayCompletionAfter(delayData, startMoment, timeZone) {
    startMoment = startMoment.clone();
    timeZone = timeZone || 'America/Denver';

    startMoment.tz(timeZone);
    let delaySatisfiedAt = computeMinDelaySatisfiedAt(delayData, startMoment);
    let now = moment().subtract(1, 'seconds');
    now.tz(timeZone);
    let computeNextWindowFrom = delaySatisfiedAt.isBefore()
        ? now
        : delaySatisfiedAt;
    let nextDelayWindowAt = computeNextDelayWindowAfter(
        computeNextWindowFrom,
        delayData,
        // sequence.org.timeZone,
    );
    return nextDelayWindowAt;
}

function delayDataWithDefaults(sequenceDelayData) {
    return _defaults(sequenceDelayData, {
        minDelay: 0,
        minDelayUnits: 'NIGHTS',
        allowedDays: 'ANY',
    });
}

function computeMinDelaySatisfiedAt(sequenceDelayData, delayStartMoment) {
    delayStartMoment = delayStartMoment.clone();
    let clonedSequenceDelayData = { ...sequenceDelayData };
    clonedSequenceDelayData = delayDataWithDefaults(clonedSequenceDelayData);
    if ('NIGHTS' == clonedSequenceDelayData.minDelayUnits) {
        // "NIGHTS" unit is interpreted as "midnights", as in "wait 3 midnights".
        clonedSequenceDelayData.minDelayUnits = 'DAYS';
        delayStartMoment.endOf('day');
        clonedSequenceDelayData.minDelay -= 1;
        if (clonedSequenceDelayData.minDelay < 0) {
            clonedSequenceDelayData.minDelay = 0;
        }
    }
    return delayStartMoment.add(
        clonedSequenceDelayData.minDelay,
        clonedSequenceDelayData.minDelayUnits.toLowerCase(),
    );
}

const daysOfWeek2IsoDayNumbers = {
    ANY: [1, 2, 3, 4, 5, 6, 7],
    WEEKDAY: [1, 2, 3, 4, 5],
    WEEKEND: [6, 7],
    MONDAY: [1],
    TUESDAY: [2],
    WEDNESDAY: [3],
    THURSDAY: [4],
    FRIDAY: [5],
    SATURDAY: [6],
    SUNDAY: [7],
};
function isValidDay(testMoment, enumDaysOfWeek) {
    testMoment = testMoment.clone();
    return _includes(
        daysOfWeek2IsoDayNumbers[enumDaysOfWeek],
        testMoment.isoWeekday(),
    );
}
// function matchesExactTime(testMoment, datelessTime) {
//     testMoment = testMoment.clone();
//     let windowStart = setDatelessTime(testMoment, datelessTime);
//     let windowEnd = setDatelessTime(testMoment, datelessTime).add(postTolerance);
//     return testMoment.isBetween(windowStart, windowEnd);
// }
// function isInDelayWindow(testMoment, sequenceDelayData, orgTimeZone) {
//     let exactTimeGracePeriod = {minutes: 3};
//     sequenceDelayData = delayDataWithDefaults(sequenceDelayData);
//     let tzTestMoment = testMoment.tz(orgTimeZone);
//     if (isValidDay(tzTestMoment, sequenceDelayData.allowedDays)) {
//         if (sequenceDelayData.allowedStartTime) {
//             let startAt = setDatelessTime(tzTestMoment, sequenceDelayData.allowedStartTime);
//             if (sequenceDelayData.allowedEndTime) {
//                 let endAt = setDatelessTime(tzTestMoment, sequenceDelayData.allowedEndTime);
//                 if (startAt.isBefore(endAt)) {
//                     return tzTestMoment.isBetween(startAt, endAt);
//                 } else {
//                     // If end < start then it's an "overnight" window.
//                     return tzTestMoment.isBefore(endAt) || tzTestMoment.isAfter(startAt);
//                 }
//             } else {
//                 return tzTestMoment.isBetween(
//                     startAt.subtract(exactTimeGracePeriod),
//                     startAt.add(exactTimeGracePeriod),
//                 );
//             }
//         } else {
//             return true;
//         }
//     } else {
//         return false;
//     }
// }

// MAKE SURE testMoment is in the correct timezone!
function computeNextDelayWindowAfter(testMoment, sequenceDelayData) {
    testMoment = testMoment.clone();
    let exactTimeGracePeriod = {minutes: 5};
    sequenceDelayData = delayDataWithDefaults(sequenceDelayData);

    if (!testMoment.isValid()) {
        // Protect against infinite loops in `while` statement below.
        throw new Error('Invalid testMoment');
    }
    while (!isValidDay(testMoment, sequenceDelayData.allowedDays)) {
        testMoment.startOf('day').add(1, 'days');
    }

    // If no specified time range, return the current or next valid day we found.
    if (!sequenceDelayData.allowedStartTime) {
        return testMoment;
    }

    // Otherwise, we need to check the time range.
    let rangeStart = setDatelessTime(
        testMoment,
        sequenceDelayData.allowedStartTime,
    );
    let rangeEnd;
    if (sequenceDelayData.allowedEndTime) {
        rangeEnd = setDatelessTime(
            testMoment,
            sequenceDelayData.allowedEndTime,
        );
    } else {
        rangeEnd = rangeStart.clone().add(exactTimeGracePeriod);
        // rangeStart.subtract(exactTimeGracePeriod);
    }
    // Range End should end at the "end" of the specified minute.
    rangeStart.startOf('minute');
    rangeEnd.endOf('minute');

    if (rangeStart.isBefore(rangeEnd)) {
        // It's a "daytime" window (starts and ends in same day).
        if (testMoment.isBetween(rangeStart, rangeEnd, 'minute', '[]')) {
            // '[]' = inclusive
            return testMoment;
        } else if (testMoment.isBefore(rangeStart)) {
            return rangeStart;
        } else {
            // We're past the window today.  Compute the next valid time starting tomorrow.
            testMoment.add(1, 'days').startOf('day');
            return computeNextDelayWindowAfter(
                testMoment,
                sequenceDelayData,
                // orgTimeZone
            );
        }
    } else {
        // It's an "overnight" window (starts one day, continues overnight, ends next day).
        if (testMoment.isBetween(rangeEnd, rangeStart, 'minute')) {
            return rangeStart;
        } else {
            return testMoment;
        }
    }
}

module.exports = {
    computeNextDelayCompletionAfter: computeNextDelayCompletionAfter,
};
