<script>
    import _get from 'lodash/get';
    import _find from 'lodash/find';
    import _debounce from 'lodash/debounce';
    import {getClient} from 'svelte-apollo';
    import {tribute} from '../actions/tribute.js';

    export let value;
    export let savingValue;
    // export let promise;
    // export let promisedValue;
    export let savedValue;
    export let saveErrorMessage = null;
    export let networkErrorMessage = null;
    export let style = '';
    export let tributeConfig = null;
    export let kind = '';
    export let maxLength = null;
    // For inputs, treat falsey values as equal.
    const entriesEqual = (a, b) => (a || '') == (b || '');
    let showSavedSuccess = false;
    let showSavedSuccessTimout;
    function animateSavedSuccess() {
        clearTimeout(showSavedSuccessTimout);
        showSavedSuccess = true;
        showSavedSuccessTimout = setTimeout(() => {
            showSavedSuccess = false;
        }, 3000);
    }
    let wasSaving = false;
    function maybeAnimateSavedSuccess() {
        let isSaving = savingValue !== null;
        if (wasSaving && !isSaving) {
            // null is not a valid value for an <input>
            if (entriesEqual(value, savedValue)) {
                animateSavedSuccess();
            } else {
                // There must have been an error?
            }
        }
        wasSaving = isSaving;
    }
    $: savingValue, maybeAnimateSavedSuccess();

    function retry() {
        value = value;
    }

    let lengthError = false;
    function handleInput(event) {
        value = event.target.value;
        if (maxLength !== null && value.length > maxLength) {
            lengthError = true;
        } else {
            lengthError = false;
        }
    }

    let statusWidth = 0;
</script>

<div class="container">
    {#if tributeConfig !== null}
        <input
            bind:value
            style="{style}; padding-right:{statusWidth}px;"
            use:tribute={tributeConfig}
            maxlength={maxLength}
            {...$$restProps}
            on:input={handleInput}
            on:keydown
        />
    {:else}
        <input
            bind:value
            style="{style}; padding-right:{statusWidth}px;"
            {...$$restProps}
            maxlength = {maxLength}
            on:input={handleInput}
            on:keydown
        />
    {/if}
    <div class="status" bind:offsetWidth={statusWidth}>
        {#if savingValue && value === savingValue}
            <span class="mutation-status busy"> Saving... </span>
        {:else if showSavedSuccess}
            <span class="mutation-status success"> Saved. </span>
        {:else if networkErrorMessage}
            <button
                type="button"
                class="mutation-status error"
                on:click|stopPropagation={retry}
            >
                Not saved
            </button>
        {:else if saveErrorMessage}
            <button
                type="button"
                class="mutation-status error"
                on:click|stopPropagation={retry}
            >
                Error
            </button>
        {:else if lengthError}
            <span class="mutation-status error">Length error</span>
        {/if}
    </div>
</div>
{#if saveErrorMessage || networkErrorMessage}
    <div class="help-block error">
        {#if saveErrorMessage}
            <strong>Failed to save.</strong>
            {(saveErrorMessage.length &&
                `A contact of the same ${kind} already exists.`) ||
                'Invalid entry or unauthorized user.'}
            <button type="button" on:click|stopPropagation={retry}>Retry</button
            >
        {:else}
            <strong>Failed to connect.</strong>
            {(networkErrorMessage.length && networkErrorMessage) || ''}
            Check internet connection or
            <button type="button" on:click|stopPropagation={retry}>Retry</button
            >
        {/if}
    </div>
{/if}

<style>
    .container {
        position: relative;
    }
    .status {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        padding-right: 8px;
        z-index: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .mutation-status {
        flex-grow: 0;
        flex-shrink: 0;
        border: none;
        display: block;
        /* top: 50%; */
        /* margin: -11px 0 0; */
        /* right: 13px; */
        line-height: 22px;
        font-size: 12px;
        font-weight: 400;
        color: var(--slate);
        background: var(--slate-o10);
        border-radius: 11px;
        padding: 0 10px;
        user-select: none;
        transition: all 120ms;
    }
    .mutation-status.success {
        background-color: var(--blue);
        color: #fff;
    }
    .mutation-status.error {
        background-color: var(--red);
        color: #fff;
    }
    .help-block {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.4;
        padding: 4px 0 0 20px;
    }
    .help-block.error {
        color: var(--red);
    }
    .help-block button {
        text-decoration: underline;
    }
</style>
