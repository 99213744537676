<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};
    FRAGMENTS.OrgSidebar_subscription = gql`
        fragment OrgSidebar_subscription on StripeSubscription {
            status
        }
    `;
    FRAGMENTS.OrgSidebar_org = gql`
        fragment OrgSidebar_org on OrgData {
            name
            subscriptions {
                ...OrgSidebar_subscription
            }
        }
        ${FRAGMENTS.OrgSidebar_subscription}
    `;

    const QUERIES = {};
    QUERIES.org = gql`
        query org($id: ID!) {
            findOrgByID(id: $id) {
                ref
                data {
                    ...OrgSidebar_org
                }
            }
        }
        ${FRAGMENTS.OrgSidebar_org}
    `;

    const MUTATIONS = {};

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getClient, query} from 'svelte-apollo';
    import {navigate} from 'svelte-routing';
    import _get from 'lodash/get';
    import _has from 'lodash/has';
    import _trimEnd from 'lodash/trimEnd';
    import {watchQuery} from '../svelte-apollo-watch-query';
    import {getUser, login, signup, logout} from '../auth0';
    import Dropdown from './Dropdown.svelte';
    import SidebarFooter from './SidebarFooter.svelte';

    export let location;
    export let orgId;
    // export let cache;

    const client = getClient();

    const orgQuery = watchQuery(client, {
        query: QUERIES.org,
        variables: {id: orgId},
        pollInterval: 0,
    });
    $: orgData = _get($orgQuery, 'data.findOrgByID', {});

    const userPromise = getUser();
    let isAccountDropdownOpen = false;

    let isPartiallyCurrentPath = (location, href) =>
        location.pathname.substring(0, href.length) === href;
    let isCurrentPath = (location, href) =>
        _trimEnd(location.pathname, '/') === _trimEnd(href);
    let isActivePath = (location, href) =>
        isCurrentPath(location, href) || isPartiallyCurrentPath(location, href);
    let paths = {
        root: `/orgs/${orgId}`,
        dashboard: `/orgs/${orgId}/dashboard`,
        contacts: `/orgs/${orgId}/contacts`,
        messages: `/orgs/${orgId}/messages`,
        forms: `/orgs/${orgId}/forms`,
        sequences: `/orgs/${orgId}/sequences`,
        settings: `/orgs/${orgId}/settings`,
        activateTrial: `/orgs/${orgId}/plan/start`,
        manageSubscription: `/orgs/${orgId}/plan/manage`,
        webstieIntegration: `/orgs/${orgId}/websiteintegration`,
    };
    $: if (isCurrentPath(location, paths.root)) {
        navigate(paths.dashboard, {replace: true});
    }

    $: needsToActivateTrial =
        _has(orgData, 'data.subscriptions') &&
        !_get(orgData, 'data.subscriptions.length');

    $: needsToManageSubscription =
        _has(orgData, 'data.subscriptions') &&
        _get(orgData, 'data.subscriptions.length') &&
        orgData.data.subscriptions[0].status == 'past_due';

    $: if (needsToActivateTrial) {
        alert(
            'Please add a credit card to verify your identity and activate your trial.',
        );
        navigate(paths.activateTrial);
    }
    $: if (needsToManageSubscription) {
        alert(
            'Your subscription has expired, so please check your subscription status and adjust.',
        );
        navigate(paths.manageSubscription);
    }
</script>

<div class="layout">
    <div class="layout-main">
        <div class="org-header">
            <Dropdown
                open={isAccountDropdownOpen}
                on:cancel={() => (isAccountDropdownOpen = false)}
            >
                <button
                    type="button"
                    slot="trigger"
                    class="account-dropdown"
                    on:click={() =>
                        (isAccountDropdownOpen = !isAccountDropdownOpen)}
                >
                    {#await userPromise}
                        <span class="org-name">
                            Loading...
                            <ion-icon
                                class="caret"
                                name="chevron-down-outline"
                            />
                        </span>
                        <span class="user-name">
                            <span class="user-activity loading"></span>
                            Identifying...
                        </span>
                    {:then user}
                        <span class="org-name">
                            {_get(orgData, 'ref')
                                ? _get(orgData, 'data.name') || 'Untitled Org'
                                : 'Loading...'}
                            <ion-icon
                                class="caret"
                                name="chevron-down-outline"
                            />
                        </span>
                        <span class="user-name">
                            {#if user}
                                <span class="user-activity online"></span>
                                {_get(user, 'name') || 'Logged In'}
                            {:else}
                                <span class="user-activity offline"></span>
                                Anonymous
                            {/if}
                        </span>
                    {:catch error}
                        <span class="org-name"> Error </span>
                        <span class="user-name">
                            {error.message}
                        </span>
                    {/await}
                </button>
                <div slot="menu" class="account-menu">
                    <ul>
                        <li>
                            <a
                                class="account-menu-item"
                                href={paths.settings}
                                on:click={() =>
                                    (isAccountDropdownOpen =
                                        !isAccountDropdownOpen)}
                            >
                                <!-- <ion-icon name="options-outline" /> -->
                                <ion-icon name="settings-outline" />
                                <span class="text"> Org Settings </span>
                            </a>
                        </li>
                        {#await userPromise}
                            <!-- nothing -->
                        {:then user}
                            {#if user}
                                <li>
                                    <a
                                        class="account-menu-item"
                                        href="/logout"
                                        on:click|preventDefault={() => logout()}
                                    >
                                        <ion-icon name="power-outline" />
                                        <span class="text"> Sign Out </span>
                                    </a>
                                </li>
                            {:else}
                                <li>
                                    <a
                                        class="account-menu-item"
                                        href="/login"
                                        on:click|preventDefault={() => login()}
                                    >
                                        <ion-icon name="finger-print-outline" />
                                        <span class="text"> Sign In </span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="account-menu-item"
                                        href="/signup"
                                        on:click|preventDefault={() => signup()}
                                    >
                                        👋
                                        <span class="text"> Sign Up </span>
                                    </a>
                                </li>
                            {/if}
                        {/await}
                    </ul>
                </div>
            </Dropdown>
        </div>
        <nav>
            <a
                href={paths.dashboard}
                class="nav-item"
                class:active={isActivePath(location, paths.dashboard)}
            >
                <ion-icon name="home-outline" />
                Dashboard
            </a>
            <a
                href={paths.contacts}
                class="nav-item"
                class:active={isActivePath(location, paths.contacts)}
            >
                <ion-icon name="people-outline" />
                <!-- <ion-icon name="body-outline" /> -->
                Contacts
            </a>
            <a
                href={paths.messages}
                class="nav-item"
                class:active={isActivePath(location, paths.messages)}
            >
                <ion-icon name="chatbubbles-outline" />
                <!-- <ion-icon name="mail-outline" /> -->
                Messages
            </a>
            <a
                href={paths.forms}
                class="nav-item"
                class:active={isActivePath(location, paths.forms)}
            >
                <ion-icon name="clipboard-outline" />
                <!-- <ion-icon name="download-outline" /> -->
                <!-- <ion-icon name="enter-outline" /> -->
                <!-- <ion-icon name="log-in-outline" /> -->
                Lead Forms
            </a>
            <a
                href={paths.sequences}
                class="nav-item"
                class:active={isActivePath(location, paths.sequences)}
            >
                <ion-icon name="play-forward-outline" />
                <!-- <ion-icon name="git-merge-outline" /> -->
                Action Sequences
            </a>
            <a
                href={paths.webstieIntegration}
                class="nav-item"
                class:active={isActivePath(location, paths.webstieIntegration)}
            >
                <ion-icon name="desktop-outline" />
                <!-- <ion-icon name="mail-outline" /> -->
                Website Integration
            </a>
        </nav>
    </div>
    <div class="layout-footer">
        <SidebarFooter />
    </div>
</div>

<style>
    .layout {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        height: 100%;
    }
    .layout-main {
        flex-grow: 1;
        flex-shrink: 1;
        overflow: auto;
        overflow-y: visible;
        --webkit-overflow-scrolling: touch;
    }
    .layout-footer {
        flex-grow: 0;
        flex-shrink: 0;
    }
    .org-header {
        padding: 0;
        margin: 0;
    }
    .account-dropdown {
        padding: 11px 15px 14px;
        text-align: left;
        width: 100%;
    }
    .account-dropdown:hover {
        background: rgba(0, 0, 0, 0.18);
    }
    @supports (backdrop-filter: saturate(120%) brightness(84%)) {
        .account-dropdown:hover {
            background: transparent;
            backdrop-filter: saturate(120%) brightness(84%);
        }
    }
    .account-dropdown .caret {
        font-size: 16px;
        vertical-align: baseline;
        margin-left: 3px;
    }
    .org-name {
        display: block;
        font-size: 20px;
        line-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
        white-space: nowrap;
    }
    .user-name {
        display: block;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .user-activity {
        display: inline-block;
        width: 9px;
        height: 9px;
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.5);
        box-shadow: var(--box-shadow-raised-level1);
        margin-right: 2px;
    }
    .user-activity.online {
        background: var(--green);
    }
    .user-activity.offline {
        background: var(--red);
    }
    .account-menu {
        display: block;
        background: var(--slate);
        color: #fff;
        /* border: 1px solid var(--offoffwhite); */
        box-shadow: rgba(0, 0, 0, 0.2) 0 1px 2px;
        border-radius: 4px;
        padding: 4px 0;
        margin-top: 2px;
        width: 90%;
        margin-left: 5%;
        box-sizing: border-box;
        margin-top: -6px;
        overflow: hidden;
        transition: transform 150ms ease-in-out;
        transform: scale(1) translateZ(0);
        /* transition: transform 200ms cubic-bezier(.47,1.64,.41,.8); */
    }
    .account-menu:hover {
        transform: scale(1.02) translateZ(0);
    }
    .account-menu ul,
    .account-menu li {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .account-menu-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: left;
        padding: 8px 10px;
        text-decoration: none;
    }
    .account-menu-item:hover {
        background: rgba(0, 0, 0, 0.2);
    }
    .account-menu-item ion-icon {
        font-size: 16px;
        margin-right: 2px;
        flex-grow: 0;
        flex-shrink: 0;
        --ionicon-stroke-width: 48px;
    }
    .account-menu-item .text {
        flex-grow: 1;
        flex-shrink: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 5px;
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
    }
    nav {
        padding: 10px 0;
    }
    .nav-item {
        display: block;
        text-decoration: none;
        border: none;
        padding: 8px 15px;
        color: inherit;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.04em;
    }
    .nav-item ion-icon {
        font-size: 20px;
        vertical-align: text-bottom;
        padding-bottom: 0px;
        line-height: 24px;
        margin-right: 7px;
        --ionicon-stroke-width: 40px;
    }
    .nav-item:hover {
        /* background: var(--white); */
        /* color: var(--black); */
        /* box-shadow: rgba(0,0,0,.16) 0 1px 2px; */
        background: rgba(0, 0, 0, 0.18);
    }
    @supports (backdrop-filter: saturate(120%) brightness(84%)) {
        .nav-item:hover {
            background: transparent;
            backdrop-filter: saturate(120%) brightness(84%);
        }
    }
    .nav-item.active {
        background: var(--white);
        color: var(--blue);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    }
    @supports (backdrop-filter: saturate(120%) blur(20px)) {
        .nav-item.active {
            background: rgba(255, 255, 255, 0.9);
            backdrop-filter: saturate(120%) blur(20px);
        }
    }
</style>
