<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};
    FRAGMENTS.EditContactNotes_contact = gql`
        fragment EditContactNotes_contact on Contact {
            ref
            data {
                notes
            }
        }
    `;

    const QUERIES = {};

    const MUTATIONS = {};
    MUTATIONS.updateContact = gql`
        mutation updateContact($id: ID!, $data: UpdateContactInput!) {
            updateContact(id: $id, data: $data) {
                ...EditContactNotes_contact
            }
        }
        ${FRAGMENTS.EditContactNotes_contact}
    `;

    MUTATIONS.retrieveContact = gql`
        mutation retrieveContact($contactId: ID!) {
            retrieveContact(contactId: $contactId) {
                ref
            }
        }
    `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import _get from 'lodash/get';
    import _has from 'lodash/has';
    import _keys from 'lodash/keys';
    import _debounce from 'lodash/debounce';
    import {getClient, mutation} from 'svelte-apollo';
    // import { watchQuery } from '../svelte-apollo-watch-query';
    import {autoheight} from '../actions/autoheight.js';

    export let contactId;
    export let contactData;

    let notesEntry;

    let hasInitNotes = false;
    function initFormFields() {
        if (!_get(contactData, 'ref')) return;
        if (_has(contactData, 'data.notes') && !hasInitNotes) {
            notesEntry = contactData.data.notes || '';
            hasInitNotes = true;
        }
    }
    $: contactData, initFormFields();

    let notesSaving = null;

    let UNSAVED = {};
    let SAVED = {};
    let SAVING = {};
    // Treat falsey values as equal.
    const entriesEqual = (a, b) => (a || '') == (b || '');
    $: formStatus = !entriesEqual(
        notesEntry,
        notesSaving === null ? _get(contactData, 'data.notes') : notesSaving,
    ) // || ...
        ? UNSAVED
        : entriesEqual(notesEntry, _get(contactData, 'data.notes')) // && ...
          ? SAVED
          : SAVING;

    const saveFormDebounced = _debounce(saveForm, 1000);
    // $: console.log('formStatus', formStatus == UNSAVED ? 'UNSAVED' : (formStatus == SAVED ? 'SAVED' : 'SAVING'));
    $: if (formStatus == UNSAVED && _has(contactData, 'ref'))
        saveFormDebounced();

    let savingData;
    async function saveForm() {
        if (formStatus != UNSAVED) return;
        let updates = {};
        savingData = updates;

        let optimisticNotes =
            notesSaving === null ? contactData.data.notes : notesSaving;
        let notesDirty = hasInitNotes && notesEntry != optimisticNotes;
        if (notesDirty) updates.notes = notesEntry;
        notesSaving = notesDirty ? notesEntry : null;

        let maybeClearSavingState = () => {
            if (savingData === updates) {
                notesSaving = null;
                savingData = null;
            }
        };

        if (!_keys(updates).length) {
            maybeClearSavingState();
            return;
        }

        await updateContact(contactId, updates.notes);

        maybeClearSavingState();
    }

    const client = getClient();
    const updateContactMutation = mutation(MUTATIONS.updateContact);
    const retrieveContactMutation = mutation(MUTATIONS.retrieveContact);

    async function updateContact(id, data) {
        try {
            // let response = await mutate(client, {
            //     mutation: MUTATIONS.updateContact,
            //     variables: {
            //         id: id,
            //         data: {
            //             notes: data
            //         },
            //     },
            // });
            let response = await updateContactMutation({
                variables: {
                    id: id,
                    data: {
                        notes: data,
                    },
                },
            });
            client.writeFragment({
                id: id,
                fragment: FRAGMENTS.EditContactNotes_contact,
                fragmentName: 'EditContactNotes_contact',
                data: response.data.updateContact,
            });
            return response.data.updateContact;
        } catch (error) {
            // FIXME handle errors better...
            alert(error.message);
        }
    }

    async function handleClickRetrieveButton(contactId) {
        try {
            const response = await retrieveContactMutation({
                variables: {
                    contactId: contactId,
                },
            });
            let tempContactData = contactData;
            tempContactData.data.isDeleted = false;
            tempContactData.data.deletedAt = null;
            contactData = tempContactData;
        } catch (error) {
            console.log(error);
            alert(error);
        }
    }
</script>

<section>
    {#if !contactData.ref}
        Loading...
    {:else if !_get(contactData, 'data.isDeleted', false)}
        <header class="cols">
            <h1 class="col grow">
                Internal Notes
                <small class="muted">(never seen by contacts)</small>
            </h1>
            <div class="col" style="font-weight:400; font-size:14px;">
                <span class="muted">
                    {#if formStatus == UNSAVED}
                        Not saved.
                    {:else if formStatus == SAVING}
                        Saving...
                    {:else if formStatus == SAVED}
                        All changes saved.
                    {/if}
                </span>
                <span style="display:inline-block; margin-left:10px;">
                    <button type="button" class="button" on:click={saveForm}>
                        <span class="btn-text"> Save Changes </span>
                    </button>
                </span>
            </div>
        </header>
        <textarea
            bind:value={notesEntry}
            use:autoheight
            autofocus
            class="notes-entry"
            placeholder="Use this space however you like..."
            disabled={_get(contactData, 'data.isDeleted', false)}
        ></textarea>
    {:else}
        <p class="disalbed-alert">
            This contact's record has been deleted and will be permanently
            deleted within 30 days. To modify or message this contact you will
            need to first restore the contact's record.
        </p>
        <div class="deleted-info">
            <div class="deleted-style">
                <div class="status-text">
                    <ion-icon name="trash-outline" class="status-icon" />
                    <span>Deleted At</span>
                </div>
                <span class="date-text"
                    >{contactData.data.deletedAt
                        ? contactData.data.deletedAt.split('T')[0]
                        : ''}</span
                >
            </div>

            <button
                type="button"
                on:click|stopPropagation={() =>
                    handleClickRetrieveButton(contactData.ref)}
                class="button"
            >
                <ion-icon name="reload-outline" class="btn-icon" />
                <span class="btn-text"> Restore </span>
            </button>
        </div>
    {/if}
</section>

<style>
    .cols {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .col {
        flex-grow: 0;
        flex-shrink: 0;
    }
    .col + .col {
        margin-left: 10px;
    }
    .grow {
        flex-grow: 1;
    }
    .shrink {
        flex-shrink: 1;
    }
    section {
        padding: 30px 20px 45px;
    }
    header {
        margin: 0 0 10px;
    }
    h1 {
        font-size: 18px;
        line-height: 1.4;
        font-weight: 600;
        padding: 0;
    }
    h1 small {
        font-weight: 400;
    }
    textarea.notes-entry {
        min-height: 100px;
        resize: none; /* We are trying out a new auto-size feature. Please contact us if this is painful for you. */
    }
    .disalbed-alert {
        padding: 0px 30px;
        color: red;
        font-size: 24px;
    }
    .deleted-info {
        display: flex;
        padding-left: 30px;
        align-items: center;
    }
    .deleted-style {
        display: flex;
        flex-direction: column;
        width: 115px;
        height: 45px;
        padding: 4px 15px;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 5px;
        color: #d65543;
        background: rgba(214, 67, 67, 0.12);
    }
    .status-text {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px 0px 2px 0px;
    }
    .date-text {
        margin-top: -10px;
        text-decoration: none !important;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
    .status-icon {
        width: 15px;
        height: 15px;
        margin-right: 4px;
    }
    .button {
        margin: 0px 0px 0px 20px;
        padding: 10px 15px !important;
    }
    .button:hover {
        color: var(--blue);
    }
    .btn-text {
        font-size: 16px !important;
    }
</style>
