<script>
import { getClient, query } from 'svelte-apollo';
import HtmlHeadTitle from '../components/HtmlHeadTitle.svelte';

const client = getClient();
const pageTitle = 'URL Not Found';
</script>

<HtmlHeadTitle {pageTitle} />

<style>
	h1 {
		color: purple;
	}
</style>

<section>
	<h1>This is not the page you're looking for.</h1>
	<p>Please double-check the URL, or contact us if you think something's broken.</p>
</section>
