import _throttle from 'lodash/throttle';

function resize(el) {
    el.style.height = "1px";
	el.style.height = (+el.scrollHeight)+"px";
}

export function autoheight(el) {
    el.style.overflow = 'hidden';
    resize(el);
    let intervalId = setInterval(() => {
        if (document.contains(el)) {
            resize(el);
            clearInterval(intervalId);
        }
    }, 320);

    let unlisten = () => {};
    if ('INPUT TEXTAREA'.indexOf(el.tagName) >= 0) {
        let handler = () => resize(el);
        el.addEventListener('input', handler);
        el.addEventListener('cut', handler);
        el.addEventListener('paste', handler);
        el.addEventListener('scroll', handler);
        el.addEventListener('change', handler);
        el.addEventListener('focus', handler);
        el.addEventListener('blur', handler);
        unlisten = () => {
            el.removeEventListener('input', handler);
            el.removeEventListener('cut', handler);
            el.removeEventListener('paste', handler);
            el.removeEventListener('scroll', handler);
            el.removeEventListener('change', handler);
            el.removeEventListener('focus', handler);
            el.removeEventListener('blur', handler);
        }
    } else if (window.MutationObserver) {
        let observer = new MutationObserver(_throttle(() => resize(el), 500));
        observer.observe(el, { attributes: true, childList: true, subtree: true });
        unlisten = () => observer.disconnect();
    }

	return {
		destroy: unlisten,
	}
}
