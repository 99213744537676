<script context="module">
    import gql from 'graphql-tag';
    import {FRAGMENTS as EditSequenceActionItem_FRAGMENTS} from './EditSequenceActionItem.svelte';

    const FRAGMENTS = {};
    FRAGMENTS.EditSequenceActions_sequence = gql`
        fragment EditSequenceActions_sequence on SequenceData {
            actionsOrder
            actions {
                ...EditSequenceActionItem_sequenceAction
            }
        }
        ${EditSequenceActionItem_FRAGMENTS.EditSequenceActionItem_sequenceAction}
    `;

    const QUERIES = {};
    // QUERIES.sequence = gql`
    //     query sequence($id: ID!) {
    //         findSequenceByID(id: $id) {
    //             ...EditSequenceActions_sequence
    //         }
    //     }
    //     ${FRAGMENTS.EditSequenceActions_sequence}
    // `;

    const MUTATIONS = {};
    MUTATIONS.createSequenceActionsAfter = gql`
        mutation createSequenceActionsAfter(
            $sequenceId: ID!
            $afterActionId: ID
            $newActions: [CreateSequenceActionInput!]!
        ) {
            createSequenceActionsAfter(
                sequenceId: $sequenceId
                afterActionId: $afterActionId
                newActions: $newActions
            ) {
                ref
                data {
                    name
                    pauseOnReply
                    isDeleted
                    org {
                        ref
                        data {
                            forms {
                                ref
                                data {
                                    name
                                }
                            }
                        }
                    }
                    forms {
                        ref
                        data {
                            name
                        }
                    }
                    ...EditSequenceActions_sequence
                }
            }
        }
        ${FRAGMENTS.EditSequenceActions_sequence}
    `;
    MUTATIONS.moveSequenceActionsAfter = gql`
        mutation moveSequenceActionsAfter(
            $sequenceId: ID!
            $afterActionId: ID
            $moveActionIds: [ID!]!
        ) {
            moveSequenceActionsAfter(
                sequenceId: $sequenceId
                afterActionId: $afterActionId
                moveActionIds: $moveActionIds
            ) {
                ref
                data {
                    actionsOrder
                    name
                    pauseOnReply
                    isDeleted
                }
            }
        }
    `;
    MUTATIONS.removeSequenceActions = gql`
        mutation removeSequenceActions(
            $sequenceId: ID!
            $removeActionIds: [ID!]!
        ) {
            removeSequenceActions(
                sequenceId: $sequenceId
                removeActionIds: $removeActionIds
            ) {
                ref
                data {
                    name
                    pauseOnReply
                    isDeleted
                    org {
                        ref
                        data {
                            forms {
                                ref
                                data {
                                    name
                                }
                            }
                        }
                    }
                    forms {
                        ref
                        data {
                            name
                        }
                    }
                    ...EditSequenceActions_sequence
                }
            }
        }
        ${FRAGMENTS.EditSequenceActions_sequence}
    `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getClient, mutation} from 'svelte-apollo';
    import Select from 'svelte-select';
    import _get from 'lodash/get';
    import _reduce from 'lodash/reduce';
    import _map from 'lodash/map';
    import _keyBy from 'lodash/keyBy';
    import _includes from 'lodash/includes';
    import {fly} from 'svelte/transition';
    import {flip} from 'svelte/animate';
    import Flipper from './Flipper.svelte';
    import EditSequenceActionItem from './EditSequenceActionItem.svelte';

    const client = getClient();
    const createSequenceActionsAfterMutation = mutation(
        MUTATIONS.createSequenceActionsAfter,
    );
    const moveSequenceActionsAfterMutation = mutation(
        MUTATIONS.moveSequenceActionsAfter,
    );
    const removeSequenceActionsMutation = mutation(
        MUTATIONS.removeSequenceActions,
    );
    export let sequenceData;

    $: tempSequenceData = sequenceData;

    let isEditing = false;

    $: sequenceActions = _get(tempSequenceData, 'data.actions', []);
    $: sequenceActionsById = _keyBy(sequenceActions, 'ref');
    $: sortedSequenceActions = _map(
        tempSequenceData.data.actionsOrder || [],
        (actionId) => sequenceActionsById[actionId],
    );

    // const ensureDelayBeforeActions = (actions) => _reduce(actions, (accumulator, action) => {
    //     let prev = accumulator[accumulator.length-1];
    //     let hasPrevDelay = prev && (prev.type == 'DELAY');
    //     let hasNextDelay = action.type == 'DELAY';
    //     if (!hasPrevDelay && !hasNextDelay) {
    //         accumulator.push({
    //             type: 'DELAY',
    //             minDelay: 0,
    //             minDelayUnits: 'NIGHTS',
    //             allowedDays: 'ANY',
    //         });
    //     }
    //     accumulator.push(action);
    //     return accumulator;
    // }, []);
    // $: sequenceDisplayActions = ensureDelayBeforeActions(sortedSequenceActions);

    async function createSequenceActionsAfter(
        sequenceId,
        afterActionId,
        newActions,
    ) {
        try {
            // let response = await mutate(client, {
            //     mutation: MUTATIONS.createSequenceActionsAfter,
            //     variables: {
            //         sequenceId: sequenceId,
            //         afterActionId: afterActionId,
            //         newActions: newActions,
            //     }
            // });
            let response = await createSequenceActionsAfterMutation({
                variables: {
                    sequenceId: sequenceId,
                    afterActionId: afterActionId,
                    newActions: newActions,
                },
            });
            // client.writeFragment({
            //     id: sequenceId,
            //     fragment: FRAGMENTS.EditSequenceActions_sequence,
            //     fragmentName: 'EditSequenceActions_sequence',
            //     data: response.data.createSequenceActionsAfter,
            // });
            tempSequenceData = response.data.createSequenceActionsAfter;
            return response.data.createSequenceActionsAfter;
        } catch (error) {
            // FIXME handle errors better...
            alert(error.message);
        }
    }
    async function moveSequenceActionsAfter(
        sequenceId,
        afterActionId,
        moveActionIds,
    ) {
        try {
            // let response = await mutate(client, {
            //     mutation: MUTATIONS.moveSequenceActionsAfter,
            //     variables: {
            //         sequenceId: sequenceId,
            //         afterActionId: afterActionId,
            //         moveActionIds: moveActionIds,
            //     },
            // });
            let response = await moveSequenceActionsAfterMutation({
                variables: {
                    sequenceId: sequenceId,
                    afterActionId: afterActionId,
                    moveActionIds: moveActionIds,
                },
            });
            // client.writeFragment({
            //     id: sequenceId,
            //     fragment: FRAGMENTS.EditSequenceActions_sequence,
            //     fragmentName: 'EditSequenceActions_sequence',
            //     data: response.data.moveSequenceActionsAfter,
            // });
        } catch (error) {
            // FIXME handle errors better...
            alert(error.message);
        }
    }
    async function removeSequenceActions(sequenceId, removeActionIds) {
        try {
            // let response = await mutate(client, {
            //     mutation: MUTATIONS.removeSequenceActions,
            //     variables: {
            //         sequenceId: sequenceId,
            //         removeActionIds: removeActionIds,
            //     },
            // });
            let response = await removeSequenceActionsMutation({
                variables: {
                    sequenceId: sequenceId,
                    removeActionIds: removeActionIds,
                },
            });
            // client.writeFragment({
            //     id: sequenceId,
            //     fragment: FRAGMENTS.EditSequenceActions_sequence,
            //     fragmentName: 'EditSequenceActions_sequence',
            //     data: response.data.removeSequenceActions,
            // });
            tempSequenceData = response.data.removeSequenceActions;
            return response.data.removeSequenceActions;
        } catch (error) {
            // FIXME handle errors better...
            alert(error.message);
        }
    }

    let createdSequenceActionIds = [];
    let addingActionAfter = 'none';
    function handleAddAfter(sequenceAction) {
        addingActionAfter = sequenceAction;
    }
    function cancelAddAfter() {
        addingActionAfter = 'none';
    }
    let savingActionAfter = 'none';
    async function handleAddDelayAfter(sequenceAction) {
        savingActionAfter = sequenceAction;
        let result = await createSequenceActionsAfter(
            tempSequenceData.ref,
            sequenceAction && sequenceAction.ref,
            [
                {
                    type: 'DELAY',
                },
            ],
        );
        let newSequenceActionsOrder = _get(result, 'actionsOrder', []);
        let newSequenceActionIdx =
            1 +
            (sequenceAction
                ? newSequenceActionsOrder.indexOf(sequenceAction.ref)
                : -1);
        let newSequenceActionId = newSequenceActionsOrder[newSequenceActionIdx];
        createdSequenceActionIds = createdSequenceActionIds.concat([
            newSequenceActionId,
        ]);
        savingActionAfter = 'none';
        addingActionAfter = 'none';
    }
    async function handleAddMessageAfter(sequenceAction) {
        savingActionAfter = sequenceAction;
        let result = await createSequenceActionsAfter(
            tempSequenceData.ref,
            sequenceAction && sequenceAction.ref,
            [
                {
                    type: 'MESSAGE',
                },
            ],
        );
        let newSequenceActionsOrder = _get(result, 'actionsOrder', []);
        let newSequenceActionIdx =
            1 +
            (sequenceAction
                ? newSequenceActionsOrder.indexOf(sequenceAction.ref)
                : -1);
        let newSequenceActionId = newSequenceActionsOrder[newSequenceActionIdx];
        createdSequenceActionIds = createdSequenceActionIds.concat([
            newSequenceActionId,
        ]);
        savingActionAfter = 'none';
        addingActionAfter = 'none';
    }
    async function handleAddReminder() {
        alert('Coming soon!');
        savingActionAfter = 'none';
        addingActionAfter = 'none';
    }
    async function handleCreateDelayAction() {
        alert(
            'There might be a problem. Please contact us and send us the error code: handleCreateDelayAction_does_not_exist',
        );
    }
    async function handleDeleteAction(sequenceAction) {
        await removeSequenceActions(tempSequenceData.ref, [sequenceAction.ref]);
    }
</script>

<section class="timeline">
    <span class="timeline-path"></span>
    <ol>
        {#if sortedSequenceActions.length}
            <li>
                <div
                    class="action placeholder"
                    class:active={addingActionAfter == null}
                    class:muted={savingActionAfter == null}
                >
                    <span class="action-marker-positioner">
                        <span class="action-marker">
                            <span class="halo"></span>
                            <ion-icon
                                name="add"
                                style="--ionicon-stroke-width:64px;"
                            />
                        </span>
                    </span>
                    <div class="controls">
                        {#if addingActionAfter == null}
                            <button
                                type="button"
                                on:click|stopPropagation={() =>
                                    handleAddDelayAfter(null)}
                                disabled={_get(
                                    sequenceData,
                                    'data.isDeleted',
                                    false,
                                )}
                                class="button"
                            >
                                <ion-icon class="btn-icon" name="time" />
                                <span class="btn-text">
                                    Insert a Wait Period
                                </span>
                            </button>
                            <button
                                type="button"
                                on:click|stopPropagation={() =>
                                    handleAddMessageAfter(null)}
                                class="button"
                                disabled={_get(
                                    sequenceData,
                                    'data.isDeleted',
                                    false,
                                )}
                            >
                                <ion-icon class="btn-icon" name="chatbubble" />
                                <span class="btn-text"> Send a Message </span>
                            </button>
                            <!-- <button type="button" on:click|stopPropagation={handleAddReminder} class="button muted" disabled>
                                <ion-icon class="btn-icon" name="alarm" />
                                <span class="btn-text">
                                    Trigger a Reminder (coming soon)
                                </span>
                            </button> -->
                            <button
                                type="button"
                                on:click|stopPropagation={cancelAddAfter}
                                class="button"
                                disabled={_get(
                                    sequenceData,
                                    'data.isDeleted',
                                    false,
                                )}
                            >
                                <span class="btn-text"> Cancel </span>
                            </button>
                        {:else}
                            <button
                                type="button"
                                class="button"
                                on:click|stopPropagation={() =>
                                    handleAddAfter(null)}
                                disabled={_get(
                                    sequenceData,
                                    'data.isDeleted',
                                    false,
                                )}
                            >
                                <span class="btn-text">
                                    Insert Action Here
                                </span>
                            </button>
                        {/if}
                    </div>
                </div>
            </li>
            {#each sortedSequenceActions as sequenceAction (sequenceAction.ref)}
                <li
                    transition:fly={{x: 200, duration: 500}}
                    animate:flip={{duration: 350, delay: 150}}
                >
                    <div class="action">
                        <span class="action-marker-positioner">
                            <span class="action-marker"></span>
                        </span>
                        <EditSequenceActionItem
                            sequenceActionData={sequenceAction}
                            on:requestCreateDelay={() =>
                                handleCreateDelayAction(sequenceAction)}
                            on:requestDelete={() =>
                                handleDeleteAction(sequenceAction)}
                            isNew={_includes(
                                createdSequenceActionIds,
                                _get(sequenceAction, 'ref'),
                            )}
                        />
                    </div>
                    <div
                        class="action placeholder"
                        class:active={addingActionAfter == sequenceAction}
                        class:muted={savingActionAfter == sequenceAction}
                    >
                        <span class="action-marker-positioner">
                            <span class="action-marker">
                                <span class="halo"></span>
                                <ion-icon
                                    name="add"
                                    style="--ionicon-stroke-width:64px;"
                                />
                            </span>
                        </span>
                        <div class="controls">
                            {#if addingActionAfter == sequenceAction}
                                <button
                                    type="button"
                                    on:click|stopPropagation={() =>
                                        handleAddDelayAfter(sequenceAction)}
                                    class="button"
                                    disabled={_get(
                                        sequenceData,
                                        'data.isDeleted',
                                        false,
                                    )}
                                >
                                    <ion-icon class="btn-icon" name="time" />
                                    <span class="btn-text">
                                        Insert a Wait Period
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    on:click|stopPropagation={() =>
                                        handleAddMessageAfter(sequenceAction)}
                                    class="button"
                                    disabled={_get(
                                        sequenceData,
                                        'data.isDeleted',
                                        false,
                                    )}
                                >
                                    <ion-icon
                                        class="btn-icon"
                                        name="chatbubble"
                                    />
                                    <span class="btn-text">
                                        Send a Message
                                    </span>
                                </button>
                                <!-- <button type="button" on:click|stopPropagation={handleAddReminder} class="button muted" disabled>
                                    <ion-icon class="btn-icon" name="alarm" />
                                    <span class="btn-text">
                                        Trigger a Reminder (coming soon)
                                    </span>
                                </button> -->
                                <button
                                    type="button"
                                    on:click|stopPropagation={cancelAddAfter}
                                    class="button"
                                    disabled={_get(
                                        sequenceData,
                                        'data.isDeleted',
                                        false,
                                    )}
                                >
                                    <span class="btn-text"> Cancel </span>
                                </button>
                            {:else}
                                <button
                                    type="button"
                                    class="button"
                                    on:click|stopPropagation={() =>
                                        handleAddAfter(sequenceAction)}
                                    disabled={_get(
                                        sequenceData,
                                        'data.isDeleted',
                                        false,
                                    )}
                                >
                                    <span class="btn-text">
                                        Insert Action Here
                                    </span>
                                </button>
                            {/if}
                        </div>
                    </div>
                </li>
            {/each}
        {:else}
            <li class="action empty">
                <span class="action-marker-positioner">
                    <span class="action-marker"></span>
                </span>
                <div class="actions-empty-message">(No actions yet.)</div>
            </li>
            <li>
                <div
                    class="action placeholder"
                    class:active={addingActionAfter == null}
                    class:muted={savingActionAfter == null}
                >
                    <span class="action-marker-positioner">
                        <span class="action-marker">
                            <span class="halo"></span>
                            <ion-icon
                                name="add"
                                style="--ionicon-stroke-width:64px;"
                            />
                        </span>
                    </span>
                    <div class="controls">
                        {#if addingActionAfter == null}
                            <button
                                type="button"
                                on:click|stopPropagation={() =>
                                    handleAddDelayAfter(null)}
                                class="button"
                                disabled={_get(
                                    sequenceData,
                                    'data.isDeleted',
                                    false,
                                )}
                            >
                                <ion-icon class="btn-icon" name="time" />
                                <span class="btn-text">
                                    Insert a Wait Period
                                </span>
                            </button>
                            <button
                                type="button"
                                on:click|stopPropagation={() =>
                                    handleAddMessageAfter(null)}
                                class="button"
                                disabled={_get(
                                    sequenceData,
                                    'data.isDeleted',
                                    false,
                                )}
                            >
                                <ion-icon class="btn-icon" name="chatbubble" />
                                <span class="btn-text"> Send a Message </span>
                            </button>
                            <!-- <button type="button" on:click|stopPropagation={handleAddReminder} class="button muted" disabled>
                                <ion-icon class="btn-icon" name="alarm" />
                                <span class="btn-text">
                                    Trigger a Reminder (coming soon)
                                </span>
                            </button> -->
                            <button
                                type="button"
                                on:click|stopPropagation={cancelAddAfter}
                                class="button"
                                disabled={_get(
                                    sequenceData,
                                    'data.isDeleted',
                                    false,
                                )}
                            >
                                <span class="btn-text"> Cancel </span>
                            </button>
                        {:else}
                            <button
                                type="button"
                                class="button"
                                on:click|stopPropagation={() =>
                                    handleAddAfter(null)}
                                disabled={_get(
                                    sequenceData,
                                    'data.isDeleted',
                                    false,
                                )}
                            >
                                <span class="btn-text">
                                    Insert Action Here
                                </span>
                            </button>
                        {/if}
                    </div>
                </div>
            </li>
        {/if}
    </ol>
</section>

<style>
    .actions-empty-message {
        display: inline-block;
        padding: 10px 15px;
        font-size: 14px;
        line-height: 26px;
        font-weight: 400;
    }
    ol,
    li {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .timeline {
        padding: 0 0 0 30px;
        position: relative;
    }
    .timeline-path {
        position: absolute;
        left: 30px; /* equal to .timeline padding-left */
        top: 0;
        bottom: 0;
        width: 2px;
        margin-left: -1px; /* half the width of this element */
        background: var(--lightblue);
        border-radius: 1px;
    }
    .action {
        padding: 20px 0 20px 30px;
        color: var(--slate);
    }
    .action-marker-positioner {
        position: relative;
        overflow: visible;
    }
    .action .action-marker {
        position: absolute;
        left: -37px; /* half the width of this element + border-width */
        border-radius: 7px;
        width: 10px;
        height: 10px;
        top: 15px; /* custom to center with first line of text */
        background: var(--white);
        border: 2px solid var(--blue);
        box-shadow: 0 0 0 3px white;
    }
    /* .action.placeholder {
    background: var(--slate-o10);
    padding-top: 0;
    padding-bottom: 0;
    transition: all 120ms ease-in-out;
} */
    .action.placeholder .controls {
        margin: -20px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    @media (hover: hover) {
        .action.placeholder .controls {
            visibility: hidden;
        }
        .action.placeholder:hover .controls,
        .action.placeholder.active .controls {
            visibility: visible;
        }
        .action.placeholder .button {
            transition: 240ms all ease-in;
            opacity: 0;
            transform: translateX(-11px) translateZ(0);
        }
        .action.placeholder:hover .button,
        .action.placeholder.active .button {
            transition: 120ms all ease-out;
            opacity: 1;
            transform: translateX(0px) translateZ(0);
        }
    }
    .action.placeholder .button + .button {
        margin-left: 10px;
    }
    /* .action.placeholder .action-marker {
    border-color: var(--lightblue);
    top: 33px;
} */
    .action.placeholder .action-marker {
        border: none;
        position: absolute;
        left: -40px; /* half the width of this element */
        top: -10px; /* custom to center with first line of text */
        width: 20px;
        height: 20px;
        border-radius: 10px;
        text-align: center;
        color: white;
        color: var(--blue);
        font-size: 14px;
        line-height: 24px;
    }
    .action.placeholder .action-marker .halo {
        background: var(--blue);
        opacity: 0.1;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 22px;
        height: 22px;
        margin-left: -11px;
        margin-top: -11px;
        border-radius: 11px;
        transition: 240ms all ease-in;
        transform: scale(0) translateZ(0);
    }
    .action.placeholder.active .action-marker .halo {
        transition: 120ms all ease-out;
        transform: scale(1) translateZ(0);
    }
    @media (hover: hover) {
        .action.placeholder:hover .action-marker .halo {
            transition: 120ms all ease-out;
            transform: scale(1) translateZ(0);
        }
    }
</style>
