<script context="module">
import gql from 'graphql-tag';

const FRAGMENTS = {};
// FRAGMENTS.OrgHeader_org = gql`
//     fragment OrgHeader_org on Org {
//         _id
//         name
//     }
// `;
// FRAGMENTS.OrgHeader_user = gql`
//     fragment OrgHeader_user on User {
//         _id
//         auth0_id
//         orgs {
//             data {
//                 ...OrgHeader_org
//             }
//         }
//     }
//     ${FRAGMENTS.OrgHeader_org}
// `;

const QUERIES = {};
// QUERIES.whoami = gql`
//     query whoami {
//         whoami {
//             ...OrgHeader_user
//         }
//     }
//     ${FRAGMENTS.OrgHeader_user}
// `;

const MUTATIONS = {};

export {
    FRAGMENTS,
    QUERIES,
    MUTATIONS,
}
</script>

<script>
import { getClient, query } from 'svelte-apollo';
import { navigate } from 'svelte-routing';

export let orgId;
export let title = '';

$: orgPath = `/orgs/${orgId}`
const client = getClient();

let searchText;
const search = () => {console.log(`TODO: Search org ${orgId} for '${searchText}'...`)};

const openSearch = () => navigate(`${orgPath}/search`);
</script>

<header>
    <div class="header-left">
        {#if title}
            <h1 class="title">
                {title}
            </h1>
        {/if}
    </div>
    <div class="header-right">
        <div class="search-container" on:submit|preventDefault={search}>
            <label class="search-box">
                <ion-icon name="search-outline" class="search-icon" />
                <input bind:value={searchText} type="search" placeholder="Search contacts" class="search-input" readonly on:focus={openSearch} />
            </label>
            <!-- <button type="submit" style="display:none;"> -->
        </div>
    </div>
</header>


<style>
header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.header-left {
    padding: 15px 20px;
    flex-grow: 0;
    flex-shrink: 0;
}
.header-right {
    padding: 15px 20px;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-end;
}
.title {
    margin: 0;
    padding: 0;
    font-size: 20px;
    line-height: 40px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.search-box {
    display: block;
    position: relative;
    cursor: pointer;
}
.search-icon {
    position: absolute;
    left: 12px;
    top: 11px;
    font-size: 20px;
    /* outline: none; */
    /* border: none; */
    /* background: rgba(255,255,255,.5); */
    /* border-radius: 0; */
    /* box-shadow: none; */
    --ionicon-stroke-width: 40px;
}
.search-input {
    padding-left: 37px;
    /* background: var(--white); */
}
/* .avatar {
    /*display: inline-block;* /
    background: #eee no-repeat center center;
    background-size: 100% 100%;
    background-size: cover;
    border-radius: 20px;
    text-align: center;
}
.avatar ion-icon {
    font-size: 20px;
    padding-top: 5px;
    opacity: 0.8;
} */
</style>
