<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};
    FRAGMENTS.ConversationListItem_contact = gql`
        fragment ConversationListItem_contact on ContactData {
            uids {
                ref
                data {
                    value
                    contactField {
                        ref
                        data {
                            isEmail
                            isSms
                        }
                    }
                }
            }
            profile {
                ref
                data {
                    valueJson
                    contactField {
                        ref
                        data {
                            isFirstName
                            isLastName
                        }
                    }
                }
            }
        }
    `;
    FRAGMENTS.ConversationListItem_conversation = gql`
        fragment ConversationListItem_conversation on Conversation {
            contact {
                ref
                data {
                    ...ConversationListItem_contact
                }
            }
            recentMessages {
                page {
                    data {
                        node {
                            ref
                            data {
                                createdAt
                                subject
                                strippedText
                                fromContact {
                                    ref # only to decide from vs. to
                                }
                                toContact {
                                    ref # only to decide from vs. to
                                }
                                triggeredByUser {
                                    ref
                                }
                                triggeredByInboundSms {
                                    messageId
                                }
                                triggeredByInboundEmail {
                                    messageId
                                }
                                # outboundEmails {
                                #     data {
                                #         sentAt
                                #         deliveredAt
                                #         bouncedAt
                                #     }
                                # }
                                # outboundTextMessages {
                                #     data {
                                #         sentAt
                                #         deliveredAt
                                #         failedAt
                                #     }
                                # }
                            }
                        }
                    }
                }
                aggregate {
                    count
                }
            }
        }
        ${FRAGMENTS.ConversationListItem_contact}
    `;

    const QUERIES = {};
    const MUTATIONS = {};

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    // import { getContext } from 'svelte';
    import _get from 'lodash/get';
    import _find from 'lodash/find';
    import _trim from 'lodash/trim';
    import _map from 'lodash/map';
    import _reduce from 'lodash/reduce';
    import {navigate} from 'svelte-routing';
    import moment from 'moment';
    import {parsePhoneNumberFromString} from 'libphonenumber-js';
    import Timestamp from './Timestamp.svelte';

    export let conversationData;

    $: contactPath = `/contacts/${contactData.ref}`;
    $: contactData = _get(conversationData, 'contact');
    $: messagesCount = _get(conversationData, 'recentMessages.aggregate.count');
    $: latestMessage = _get(
        conversationData,
        'recentMessages.page.data.0.node',
    );

    // $: lastActionAt = _get(contactData, 'sequence.lastActionAt') ?
    //     moment(_get(contactData, 'sequence.lastActionAt'), moment.ISO_8601) :
    //     moment(_get(contactData, 'sequence.createdAt'), moment.ISO_8601);
    // $: nextActionAndWhen = computeNextNonDelayActionAndWhen(
    //     _map(_get(contactData, 'sequence.nextActions', []), 'node'),
    //     lastActionAt,
    // );
    // $: nextActionFromNow = _get(nextActionAndWhen, 'when') ? (
    //     nextActionAndWhen.when.clone().subtract(3, 'minutes').isBefore() ? 'in a few mins' : nextActionAndWhen.when.fromNow()
    // ) : null;
    // $: nextActionDisplay = _get(nextActionAndWhen, 'nextAction') ? (
    //     `Next ${(nextActionAndWhen.nextAction.type || 'event').toLowerCase()} ${nextActionFromNow || ''}`
    // ) : null;

    const getProfile = (contact, where) => {
        let value = _get(
            _find(_get(contact, 'data.uids', []), {
                data: {contactField: {data: where}},
            }),
            'data.value',
        );
        if (value) {
            return value;
        }
        let valueJson = _get(
            _find(_get(contact, 'data.profile', []), {
                data: {contactField: {data: where}},
            }),
            'data.valueJson',
        );
        if (valueJson) {
            return JSON.parse(valueJson);
        }
    };

    $: profileFirstName = getProfile(contactData, {isFirstName: true}) || '';
    $: profileLastName = getProfile(contactData, {isLastName: true}) || '';
    $: nameDisplay = _trim(`${profileFirstName} ${profileLastName}`);

    $: profileEmail = getProfile(contactData, {isEmail: true}) || '';

    $: profileSms = getProfile(contactData, {isSms: true}) || '';
    $: phoneNumber =
        parsePhoneNumberFromString(profileSms) ||
        parsePhoneNumberFromString('+1' + profileSms);
    $: phoneNumberDisplay = phoneNumber ? phoneNumber.formatNational() : '';
    $: phoneNumberUri = phoneNumber ? phoneNumber.getURI() : '';

    $: isOutgoing = !!_get(latestMessage, 'data.toContact.ref');
    $: isAutomated = !(
        _get(latestMessage, 'data.triggeredByUser') ||
        _get(latestMessage, 'data.triggeredByInboundSms') ||
        _get(latestMessage, 'data.triggeredByInboundEmail')
    );
</script>

<a href="{contactPath}/messages" class="row" class:outgoing={isOutgoing}>
    <span class="contact-id">
        <span class="name">
            {nameDisplay || 'unknown'}
        </span>
        <span class="email-phone">
            {profileEmail || ''}
            {#if profileEmail && phoneNumberDisplay}
                •
            {/if}
            {phoneNumberDisplay || ''}
        </span>
    </span>
    <!-- <span class="messages-count">
        {messagesCount}
    </span> -->
    <span class="latest-message-text">
        {#if isOutgoing}
            <!-- <ion-icon name={isAutomated ? "sync-outline" : "arrow-undo"} class="muted" /> -->
            <!-- <ion-icon name={isAutomated ? "sync-circle" : "arrow-undo"} class="muted" /> -->
            <!-- <ion-icon name={isAutomated ? "cloud-done" : "arrow-undo"} class="muted" /> -->
            <!-- <ion-icon name={isAutomated ? "flash" : "arrow-undo"} class="muted" /> -->
            <ion-icon
                name={isAutomated ? 'play-forward' : 'arrow-undo'}
                style={isAutomated ? 'margin-top:2px;' : ''}
            />
        {/if}
        {#if _get(latestMessage, 'data.subject')}
            <strong>{_get(latestMessage, 'data.subject')}</strong>
        {/if}
        {_get(latestMessage, 'data.strippedText') || '(empty message)'}
    </span>
    <span class="latest-message-time">
        <Timestamp ts={_get(latestMessage, 'data.createdAt')} />
    </span>
</a>

<style>
    .row {
        border-top: 1px solid var(--offwhite);
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        text-decoration: none;
        min-height: 71px;
    }
    .row.outgoing {
        background: var(--offwhite);
        border-top-color: var(--offoffwhite);
    }
    .row > * {
        padding-left: 10px;
        padding-right: 10px;
    }
    .row > :first-child {
        padding-left: 15px;
    }
    .row > :last-child {
        padding-right: 15px;
    }
    .contact-id {
        flex-grow: 0;
        flex-shrink: 0;
        font-size: 14px;
        line-height: 20px;
        padding-top: 15px;
        padding-bottom: 15px;
        width: 220px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .name {
        display: block;
        font-weight: 600;
    }
    .email-phone {
        display: block;
        opacity: 0.6;
        font-weight: 400;
        font-size: 86%;
    }
    .latest-message-text {
        position: relative;
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        padding-left: 30px;
    }
    .latest-message-text ion-icon {
        position: absolute;
        left: 10px;
        top: 0px;
        font-size: 15px;
        opacity: 0.3;
    }
    .latest-message-text strong {
        font-weight: 600;
    }
    .latest-message-time {
        flex-shrink: 0;
        flex-grow: 0;
        text-align: right;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 150px;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
    }
</style>
