import _trim from 'lodash/trim';

function getFilestackUrl(fileHandle, transformKey) {
    let fileUrl = _trim(__FILESTACK_CDN_ORIGIN__, '/');
    if (transformKey == 'HOSTED_FORM_HEADER') {
        fileUrl = `${fileUrl}/${__FILESTACK_API_KEY__}/resize=height:160`;
    }
    fileUrl = `${fileUrl}/${fileHandle}`;
    return fileUrl;
}

export {
    getFilestackUrl,
};
