function setDatelessTime(baseMoment, datelessTime) {
    let tempDatelessTime = {...datelessTime};
    baseMoment = baseMoment.clone();
    tempDatelessTime.hour = tempDatelessTime.hour || 0;
    tempDatelessTime.minute = tempDatelessTime.minute || 0;

    let hour24 = (tempDatelessTime.hour % 12) + (tempDatelessTime.pm ? 12 : 0);
    return baseMoment
        .set({
            hour: hour24,
            minute: tempDatelessTime.minute,
        })
        .startOf('minute');
}

module.exports = {
    setDatelessTime: setDatelessTime,
};
