<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};

    const QUERIES = {};

    const MUTATIONS = {};

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getClient, query} from 'svelte-apollo';
    import {navigate} from 'svelte-routing';
    import {createEventDispatcher} from 'svelte';

    export let orgId;
    export let title = '';

    $: adminPath = `/admin/${orgId}`;
    const client = getClient();
    const dispatch = createEventDispatcher();
    let searchTip;
    const search = () => {
        console.log(`TODO: Search org ${orgId} for '${searchText}'...`);
    };

    let searchText = '';

  function handleEnterKey(event) {
    if (event.key === 'Enter') {
      // Forward the event to the parent component
      dispatch('enterkey', { searchText });
    }
  }
</script>

<header>
    <div class="header-left">
        {#if title}
            <h1 class="title">
                {title}
            </h1>
        {/if}
    </div>
    <div class="header-right">
        <div class="search-container" on:submit|preventDefault={search}>
            <label class="search-box">
                <ion-icon name="search-outline" class="search-icon" />
                <input
                    type="search"
                    placeholder="Search orgs"
                    class="search-input"
                    on:keydown={(event) =>
                        searchText ? event.stopPropagation() : null}
                    bind:value={searchText} 
                    on:keydown={handleEnterKey}
                />
            </label>
            <!-- <button type="submit" style="display:none;"> -->
        </div>
    </div>
</header>

<style>
    header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 120px;
    }
    .header-left {
        padding: 37px 37px;
        flex-grow: 0;
        flex-shrink: 0;
    }
    .header-right {
        padding: 30px 40px;
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        justify-content: flex-end;
    }
    .title {
        margin: 0;
        padding: 0;
        font-size: 20px;
        line-height: 40px;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .search-box {
        display: block;
        position: relative;
        cursor: pointer;
    }
    .search-icon {
        position: absolute;
        left: 12px;
        top: 11px;
        font-size: 20px;
        /* outline: none; */
        /* border: none; */
        /* background: rgba(255,255,255,.5); */
        /* border-radius: 0; */
        /* box-shadow: none; */
        --ionicon-stroke-width: 40px;
    }
    .search-input {
        padding-left: 37px;
        /* background: var(--white); */
    }
    /* .avatar {
        /*display: inline-block;* /
        background: #eee no-repeat center center;
        background-size: 100% 100%;
        background-size: cover;
        border-radius: 20px;
        text-align: center;
    }
    .avatar ion-icon {
        font-size: 20px;
        padding-top: 5px;
        opacity: 0.8;
    } */
</style>
