<script context="module">
    import gql from 'graphql-tag';
    import {FRAGMENTS as ContactListItem_FRAGMENTS} from '../../../components/ContactListItem.svelte';

    const FRAGMENTS = {};
    FRAGMENTS.Contacts_contact = gql`
        fragment Contacts_contact on Contact {
            ...ContactListItem_contact
        }
        ${ContactListItem_FRAGMENTS.ContactListItem_contact}
    `;
    FRAGMENTS.CreateContactProfile_contactField = gql`
        fragment CreateContactProfile_contactField on ContactField {
            ref
            data {
                isFirstName
                isLastName
                isEmail
                isSms
            }
        }
    `;
    FRAGMENTS.CreateContactProfile_profileField = gql`
        fragment CreateContactProfile_profileField on ProfileField {
            ref
            data {
                valueJson
                contactField {
                    ...CreateContactProfile_contactField
                }
                contact {
                    ref
                }
            }
        }
        ${FRAGMENTS.CreateContactProfile_contactField}
    `;
    FRAGMENTS.CreateContactProfile_contactUid = gql`
        fragment CreateContactProfile_contactUid on ContactUid {
            ref
            data {
                value
                contactField {
                    ...CreateContactProfile_contactField
                }
            }
        }
        ${FRAGMENTS.CreateContactProfile_contactField}
    `;
    FRAGMENTS.CreateContactProfile_contact = gql`
        fragment CreateContactProfile_contact on Contact {
            ref
            data {
                uids {
                    ...CreateContactProfile_contactUid
                }
                profile {
                    ...CreateContactProfile_profileField
                }
            }
        }
        ${FRAGMENTS.CreateContactProfile_contactUid}
        ${FRAGMENTS.CreateContactProfile_profileField}
    `;
    const QUERIES = {};
    QUERIES.orgContactsByCreatedAt = gql`
        query orgContactsByCreatedAt(
            $orgId: ID!
            $size: Int!
            $before: ContactCustomPageCursorInput
            $after: ContactCustomPageCursorInput
        ) {
            orgContactsByCreatedAt(
                orgId: $orgId
                size: $size
                before: $before
                after: $after
            ) {
                page {
                    before {
                        time
                        ref
                    }
                    after {
                        time
                        ref
                    }
                    data {
                        node {
                            ...Contacts_contact
                        }
                        createdAt
                    }
                }
                aggregate {
                    count
                }
            }
        }
        ${FRAGMENTS.Contacts_contact}
    `;
    QUERIES.org = gql`
        query org($id: ID!) {
            findOrgByID(id: $id) {
                ref
                data {
                    contactFields {
                        ref
                        data {
                            name
                            isUid
                            isEmail
                            isSms
                            isFirstName
                            isLastName
                        }
                    }
                }
            }
        }
    `;
    QUERIES.orgContactsCount = gql`
        query orgContactsCount($orgId: ID!, $sinceDate: Time) {
            orgContactsCount(orgId: $orgId, sinceDate: $sinceDate)
        }
    `;
    //     QUERIES.orgContactFields = gql`
    //     query orgContactFields($orgId: ID!) {
    //         contactFieldsByOrgAndIsProfile(orgId: $orgId, isProfile: true) {
    //             _id
    //             ...CreateContactProfile_contactField
    //         }
    //     }
    //     ${FRAGMENTS.CreateContactProfile_contactField}
    // `;
    QUERIES.getUserByOrg = gql`
        query getUserByOrg($orgId: ID!) {
            getUserByOrg(orgId: $orgId) {
                ref
            }
        }
    `;
    const MUTATIONS = {};
    MUTATIONS.createOrgContact = gql`
        mutation createOrgContact($orgId: ID!) {
            createOrgContact(orgId: $orgId) {
                ref
            }
        }
    `;
    MUTATIONS.createContactUid = gql`
        mutation createContactUid(
            $contactId: ID!
            $contactFieldId: ID!
            $value: String!
        ) {
            createContactUid(
                contactId: $contactId
                contactFieldId: $contactFieldId
                value: $value
            ) {
                ref
            }
        }
    `;
    MUTATIONS.createProfileField = gql`
        mutation createProfileField(
            $contactId: ID!
            $contactFieldId: ID!
            $data: ProfileFieldInput!
        ) {
            createProfileField(
                contactId: $contactId
                contactFieldId: $contactFieldId
                data: $data
            ) {
                ref
            }
        }
    `;
    MUTATIONS.temporarilyDeleteContacts = gql`
        mutation temporarilyDeleteContacts($contactIds: [ID!]) {
            temporarilyDeleteContacts(contactIds: $contactIds) {
                ref
            }
        }
    `;
    // MUTATIONS.updateForm = gql`
    //     mutation updateForm($id: ID!, $data: PartialUpdateFormInput!) {
    //         partialUpdateForm(id: $id, data: $data) {
    //             ...form
    //         }
    //     }
    //     ${FRAGMENTS.form}
    // `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getContext, onMount} from 'svelte';
    import {getClient, mutation} from 'svelte-apollo';
    import {navigate} from 'svelte-routing';

    import _get from 'lodash/get';
    import _map from 'lodash/map';
    import _concat from 'lodash/concat';
    import _isNumber from 'lodash/isNumber';
    import _omit from 'lodash/omit';
    import _debounce from 'lodash/debounce';
    import _slice from 'lodash/slice';

    import {watchQuery} from '../../../svelte-apollo-watch-query';

    // import { runImportContactsDialog } from '../../../importContacts';
    import HtmlHeadTitle from '../../../components/HtmlHeadTitle.svelte';
    import ContactListItem from '../../../components/ContactListItem.svelte';
    import OrgPageLayout from '../../../components/OrgPageLayout.svelte';
    import CreateContactModal from '../../../components/CreateContactModal.svelte';
    // import {createContactUid, createProfileField} from '../../../api';
    import {flip} from 'svelte/animate';
    // import Route from 'svelte-routing/src/Route.svelte';
    // import CreateContact from '../../../components/CreateContact.svelte';
    // import { Router, Route } from 'svelte-routing';
    export let orgId;
    import Modal from '../../../components/Modal.svelte';
    import Toggle from '../../../components/Toggle.svelte';
    const client = getClient();

    let showDeleted = true;

    const createContactUidMutation = mutation(MUTATIONS.createContactUid);
    const createProfileFieldMutation = mutation(MUTATIONS.createProfileField);
    const createOrgContactMutation = mutation(MUTATIONS.createOrgContact);
    const temporarilyDeleteContactsMutation = mutation(
        MUTATIONS.temporarilyDeleteContacts,
    );

    let getUserByOrg;
    client
        .query({query: QUERIES.getUserByOrg, variables: {orgId: orgId}})
        .then((result) => {
            getUserByOrg = result.data.getUserByOrg;
        });

    const pageTitle = 'Contacts';

    const LOADING_PAGE_SIZE = 50;
    const UPDATE_PAGE_SIZE = 20;
    const UPDATE_INTERVAL_TIME = 30000;

    const orgQuery = watchQuery(client, {
        query: QUERIES.org,
        variables: {id: orgId},
        pollInterval: 300e3, // 5 minutes
    });
    $: orgData = _get($orgQuery, 'data.findOrgByID.data', {});
    // $: orgContacts = _get(orgData, 'contacts.data', []);
    $: orgContactFields = _get(orgData, 'contactFields', []);

    // const orgContactsCountQuery = watchQuery(client, {
    //     query: QUERIES.orgContactsCount,
    //     variables: {orgId: orgId},
    //     pollInterval: 30000,
    // });
    // $: orgContactsCount = _get($orgContactsCountQuery, 'data.orgContactsCount');

    // const orgContactsQuery = watchQuery(client, {
    //     query: QUERIES.orgContactsByCreatedAt,
    //     variables: {orgId: orgId, size: 50},
    //     pollInterval: 30000,
    // });

    // const orgContactsQuery = watchQuery(client, {
    //     query: QUERIES.orgContactsByCreatedAt,
    //     variables: {orgId: orgId, size: 50},
    //     pollInterval: null,
    // });

    const {getRootElement} = getContext('rootElement');

    let contactUpdateQuery;
    let isLoading = false;
    let isError = false;
    let isLastPage = false;

    let rootElement;
    let screenRate = 0;
    let startIndex = 0;
    let fetchedStartIndex = 0;
    let clickedCount = 0;

    let orgContacts = [];

    function getCursor(contact) {
        if (
            contact === undefined ||
            contact.node === undefined ||
            contact.createdAt === undefined
        ) {
            return null;
        }

        if (startIndex < UPDATE_PAGE_SIZE) {
            return null;
        }

        return {
            time: contact.createdAt,
            ref: contact.node.ref,
        };
    }

    function updateContacts(mode, startContact) {
        let after = getCursor(startContact);

        let pageSize;
        let intervalTime;

        if (mode === 'loadMore') {
            pageSize = LOADING_PAGE_SIZE;
            intervalTime = null;
        } else if (mode === 'update') {
            pageSize = UPDATE_PAGE_SIZE;
            intervalTime = UPDATE_INTERVAL_TIME;
        }
        fetchedStartIndex = startIndex;
        contactUpdateQuery = watchQuery(client, {
            query: QUERIES.orgContactsByCreatedAt,
            variables: {
                orgId: orgId,
                size: pageSize,
                before: null,
                after: after,
            },
            pollInterval: intervalTime,
        });
    }

    function mergeContact(fetchedContacts) {
        const contactIdVsNode = {};

        fetchedContacts.forEach((data) => {
            if (!contactIdVsNode[data.node.ref]) {
                contactIdVsNode[data.node.ref] = true;
            }
        });

        const partA = _slice(orgContacts, 0, fetchedStartIndex).filter(
            (data) => {
                if (contactIdVsNode[data.node.ref]) {
                    return false;
                } else {
                    return true;
                }
            },
        );
        const partB = (
            fetchedStartIndex + fetchedContacts.length < orgContacts.length
                ? _slice(
                      orgContacts,
                      fetchedStartIndex + fetchedContacts.length,
                      orgContacts.length,
                  )
                : []
        ).filter((data) => {
            if (contactIdVsNode[data.node.ref]) {
                return false;
            } else {
                return true;
            }
        });

        orgContacts = _concat(partA, fetchedContacts, partB);
        orgContacts = showDeleted
            ? orgContacts
            : orgContacts.filter((contact) => !contact.node.data.isDeleted);
    }

    function addClickCount() {
        clickedCount = clickedCount + 1;
    }

    const addClickCountDebounced = _debounce(addClickCount, 1000);
    const updateContactsDebounced = _debounce(updateContacts, 1000);

    function gettingRootElement() {
        const obj = getRootElement();
        if (!obj.rootElement) {
            setTimeout(gettingRootElement, 500);
        } else {
            rootElement = obj.rootElement;
            rootElement.addEventListener('scroll', () => {
                const scrollHeight = rootElement.scrollHeight;
                const scrollTop = rootElement.scrollTop;
                const clientHeight = rootElement.clientHeight;

                screenRate =
                    scrollHeight - clientHeight !== 0
                        ? scrollTop / (scrollHeight - clientHeight)
                        : 0;
            });
            window.addEventListener('click', () => {
                addClickCountDebounced();
            });
        }
    }

    onMount(async () => {
        updateContacts('loadMore');

        setTimeout(gettingRootElement, 500);
    });

    function runUpdateContactsDebounced(startIndex) {
        if (!isLoading) {
            if (
                orgContacts.length - startIndex < UPDATE_PAGE_SIZE * 2 &&
                !isLastPage
            ) {
                updateContactsDebounced('loadMore', orgContacts[startIndex]);
            } else {
                updateContactsDebounced('update', orgContacts[startIndex]);
            }
        }
    }

    $: isLoading = contactUpdateQuery ? $contactUpdateQuery.loading : false;
    $: isError = contactUpdateQuery ? $contactUpdateQuery.errors : false;
    $: {
        startIndex = Math.max(
            Math.floor(orgContacts.length * screenRate) - UPDATE_PAGE_SIZE / 2,
            0,
        );
        runUpdateContactsDebounced(startIndex);
    }
    $: clickedCount
        ? setTimeout(() => runUpdateContactsDebounced(startIndex), 1000)
        : 0;
    $: {
        const newFetchedContact = _get(
            $contactUpdateQuery,
            'data.orgContactsByCreatedAt.page.data',
            [],
        );
        mergeContact(newFetchedContact);
    }
    $: orgContactsCount = _get(
        $contactUpdateQuery,
        'data.orgContactsByCreatedAt.aggregate.count',
    );
    $: isLastPage =
        _get(
            $contactUpdateQuery,
            'data.orgContactsByCreatedAt.page.before',
            null,
        ) &&
        !_get(
            $contactUpdateQuery,
            'data.orgContactsByCreatedAt.page.after',
            null,
        )
            ? true
            : false;

    let isCreatingContact = false;

    function handleModalClose() {
        isCreatingContact = false;
        firstName = '';
        lastName = '';
        emailAddress = '';
        phoneNumber = '';
    }

    function handleModalOpen() {
        isCreatingContact = true;
    }
    let firstName = '';
    let lastName = '';
    let emailAddress = '';
    let phoneNumber = '';

    function handleInputChange(event) {
        switch (event.target.name) {
            case 'firstName':
                firstName = event.target.value;
                break;
            case 'lastName':
                lastName = event.target.value;
                break;
            case 'emailAddress':
                emailAddress = event.target.value;
                break;
            case 'phoneNumber':
                phoneNumber = event.target.value;
                break;
            default:
                break;
        }
    }

    async function createContactUid(contactId, contactFieldId, value) {
        try {
            // let response = await mutate(client, {
            //     mutation: MUTATIONS.createContactUid,
            //     variables: {
            //         contactId: contactId,
            //         contactFieldId: contactFieldId,
            //         value: value,
            //     },
            //     refetchQueries: ['contact'],
            // });
            let response = await createContactUidMutation({
                variables: {
                    contactId: contactId,
                    contactFieldId: contactFieldId,
                    value: value,
                },
            });
            orgData = _get($orgQuery, 'data.findOrgByID.data', {});
        } catch (error) {
            console.log(error);
        }
    }
    async function createProfileField(contactId, contactFieldId, data) {
        try {
            // let response = await mutate(client, {
            //     mutation: MUTATIONS.createProfileField,
            //     variables: {
            //         contactId: contactId,
            //         contactFieldId,
            //         data,
            //     },
            // });
            let response = await createProfileFieldMutation({
                variables: {
                    contactId: contactId,
                    contactFieldId,
                    data,
                },
            });
        } catch (error) {
            console.log(error);
        }
    }
    // async function updateContact(id, data) {
    //     let response = await mutate(client, {
    //         mutation: MUTATIONS.updateContact,
    //         variables: {
    //             id: id,
    //             data: data,
    //         },
    //     });
    //     client.writeFragment({
    //         id: id,
    //         fragment: FRAGMENTS.CreateContactProfile_contact,
    //         fragmentName: 'CreateContactProfile_contact',
    //         data: response.data.partialUpdateContact,
    //     });
    //     return response.data.partialUpdateContact;
    // }
    async function createContact() {
        // if (isCreatingContact) return;
        // isCreatingContact = true;

        try {
            const totalContactCount = orgContactsCount;
            // Check if the total contact count exceeds 100,000
            if (totalContactCount >= 100000) {
                alert(
                    'You have reached the contact limit. No more contacts can be added.',
                );
                isCreatingContact = false;
                return totalContactCount;
            }
            let data = {
                firstName,
                lastName,
                emailAddress,
                phoneNumber,
            };
            if (
                data.firstName === '' &&
                data.lastName === '' &&
                data.emailAddress === '' &&
                data.phoneNumber === ''
            ) {
                alert(
                    'A contact cannot be created if all fields are empty. Please fill in one or more fields.',
                );
                return;
            }

            // let response = await mutate(client, {
            //     mutation: MUTATIONS.createOrgContact,
            //     variables: {
            //         orgId: orgId,
            //     },
            //     refetchQueries: ['orgContactsByCreatedAt'],
            // });
            let response = await createOrgContactMutation({
                variables: {
                    orgId: orgId,
                },
            });

            let contactId = response.data.createOrgContact.ref;
            // console.log(response);
            if (data.emailAddress != '') {
                const contactFieldForEmail = orgContactFields.filter(
                    (contactField) =>
                        contactField.data.isUid == true &&
                        contactField.data.isEmail == true,
                );
                const contactFieldIdForEmail = contactFieldForEmail[0].ref;
                await createContactUid(
                    contactId,
                    contactFieldIdForEmail,
                    data.emailAddress,
                );
            }
            if (data.phoneNumber != '') {
                const contactFieldForPhone = orgContactFields.filter(
                    (contactField) =>
                        contactField.data.isUid == true &&
                        contactField.data.isSms == true,
                );
                const contactFieldIdForPhone = contactFieldForPhone[0].ref;
                await createContactUid(
                    contactId,
                    contactFieldIdForPhone,
                    data.phoneNumber,
                );
            }
            if (data.firstName != '') {
                const contactFieldForFirstName = orgContactFields.filter(
                    (contactField) =>
                        !contactField.data.isUid &&
                        contactField.data.isFirstName == true,
                );
                let profileFieldData = {
                    org: orgId,
                    updatedBy: getUserByOrg.ref,
                    valueJson: JSON.stringify(data.firstName),
                };
                const contactFieldIfForFirstName =
                    contactFieldForFirstName[0].ref;
                await createProfileField(
                    contactId,
                    contactFieldIfForFirstName,
                    profileFieldData,
                );
            }
            if (data.lastName != '') {
                const contactFieldForLastName = orgContactFields.filter(
                    (contactField) =>
                        !contactField.data.isUid &&
                        contactField.data.isLastName == true,
                );
                let profileFieldData = {
                    org: orgId,
                    updatedBy: getUserByOrg.ref,
                    valueJson: JSON.stringify(data.lastName),
                };
                const contactFieldIfForLastName =
                    contactFieldForLastName[0].ref;
                await createProfileField(
                    contactId,
                    contactFieldIfForLastName,
                    profileFieldData,
                );
            }

            handleModalClose();
        } catch (error) {
            // FIXME handle errors better...
            alert(error.message);
        }
        // isCreatingContact = false;
    }

    // let isImportingContacts = false;
    // async function importContacts() {
    //     if (isImportingContacts) return;
    //     isImportingContacts = true;
    //     await runImportContactsDialog(orgId, orgContactFields, client);
    //     isImportingContacts = false;
    // }

    // $: isImportDisabled = isImportingContacts || !_get(orgContactFields, 'length');
    let selectedContactIds = [];
    let isDeletingContacts = false;

    function selectContacts(contactId, checkboxStatus) {
        if (checkboxStatus) selectedContactIds.push(contactId);
        else
            selectedContactIds = selectedContactIds.filter(
                (id) => id != contactId,
            );
    }

    function handleClickDeleteButton() {
        isDeletingContacts = true;
    }

    async function handleConfirmButtonClick() {
        if (selectedContactIds.length == 0) {
            alert('There are no contacts selected for deletion!');
            return;
        }

        if (
            confirm(
                'The selected contacts will be permanently deleted. Are you sure you want to proceed?',
            )
        ) {
            try {
                const response = await temporarilyDeleteContactsMutation({
                    variables: {
                        contactIds: selectedContactIds,
                    },
                });
                // let tempOrgContacts = orgContacts;
                orgContacts = orgContacts.map((contact) => {
                    if (selectedContactIds.includes(contact.node.ref)) {
                        const contactData = contact.node.data;
                        const now = new Date();
                        const dateNow = now.toISOString().split('T')[0];
                        return {
                            node: {
                                ref: contact.node.ref,
                                data: {
                                    ...contactData,
                                    isDeleted: true,
                                    deletedAt: dateNow,
                                },
                            },
                        };
                    }
                    return contact;
                });
                orgContactsCount -= selectedContactIds.length;
            } catch (error) {
                console.log(error);
                alert(error);
            }
            isDeletingContacts = false;
            selectedContactIds = [];
        } else {
            return;
        }
    }

    function handleCancelButtonClick() {
        isDeletingContacts = false;
        selectedContactIds = [];
    }
</script>

<HtmlHeadTitle {pageTitle} />
{#if isCreatingContact}
    <CreateContactModal {isCreatingContact} {handleModalClose}>
        <div class="sidebar-content">
            <h1>Create Contact</h1>
            <section class="section">
                <div class="field-section">
                    <h3 class="field-name">Contact Name</h3>
                    <div class="name-row">
                        <div class="name-col">
                            <label>First name</label>
                            <input
                                type="text"
                                placeholder="Bruce"
                                value={firstName}
                                name="firstName"
                                on:input={handleInputChange}
                            />
                        </div>
                        <div class="name-col">
                            <label>Last name</label>
                            <input
                                type="text"
                                placeholder="Wayne"
                                name="lastName"
                                value={lastName}
                                on:input={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
                <div class="field-section">
                    <h3 class="field-name">Email Addresses</h3>
                    <input
                        type="email"
                        placeholder="name@example.com"
                        name="emailAddress"
                        value={emailAddress}
                        on:input={handleInputChange}
                    />
                </div>
                <div class="field-section">
                    <h3 class="field-name">Phone Numbers (SMS)</h3>
                    <input
                        type="tel"
                        value={phoneNumber}
                        name="phoneNumber"
                        placeholder="555-321-1111"
                        on:input={handleInputChange}
                    />
                </div>
            </section>
            <div class="controll-button">
                <button
                    type="button"
                    on:click|stopPropagation={createContact}
                    class="button"
                >
                    <!-- class:muted={isCreatingContact}
                    disabled={isCreatingContact} -->
                    <ion-icon name="add" class="btn-icon" />
                    <span class="btn-text"> Create Contact </span>
                </button>
            </div>
        </div>
    </CreateContactModal>
{:else}
    <OrgPageLayout {orgId} title={pageTitle}>
        <section class="card listgroup">
            <header class="listgroup-head">
                <!-- <div class="listgroup-bulkaction">
                <a>Select all</a>
            </div> -->
                <div class="listgroup-head-left">
                    <span class="chip">
                        {_isNumber(orgContactsCount)
                            ? orgContactsCount
                            : 'loading'}
                        contacts
                    </span>
                    <span class="chip"> newest first </span>
                </div>
                <div class="listgroup-head-right">
                    {#if !isDeletingContacts}
                        <button
                            type="button"
                            title="Delete"
                            on:click|stopPropagation={handleClickDeleteButton}
                            class="button danger"
                        >
                            <ion-icon name="trash" class="btn-icon" />
                            <span class="btn-text"> Delete Contacts </span>
                        </button>
                        <button
                            type="button"
                            on:click|stopPropagation={handleModalOpen}
                            class="button"
                            class:muted={isCreatingContact}
                            disabled={isCreatingContact}
                        >
                            <ion-icon name="add" class="btn-icon" />
                            <span class="btn-text"> Add a New Contact </span>
                        </button>
                    {:else}
                        <button
                            type="button"
                            title="Delete"
                            on:click|stopPropagation={handleConfirmButtonClick}
                            class="button danger"
                        >
                            <ion-icon name="trash" class="btn-icon" />
                            <span class="btn-text"> Confirm Deletion </span>
                        </button>
                        <button
                            type="button"
                            on:click|stopPropagation={handleCancelButtonClick}
                            class="button cancel"
                        >
                            <ion-icon
                                name="close-circle-outline"
                                class="btn-icon"
                            />
                            <span class="btn-text"> Cancel Deletion </span>
                        </button>
                    {/if}
                    <label class="filter-deleted">
                        <span class="text">Show Deleted Contacts?</span>
                        <Toggle bind:checked={showDeleted} />
                        <!-- on:change={handleToggleChange} -->
                    </label>
                </div>
            </header>
            <ul>
                {#if isLoading && !orgContacts.length}
                    <li class="load-more-content" />
                {:else if isError}
                    <li>
                        errors: <pre>{JSON.stringify(
                                $contactUpdateQuery.errors,
                                null,
                                4,
                            )}</pre>
                    </li>
                {:else if orgContacts.length}
                    {#each orgContacts as data (data.node.ref)}
                        <li animate:flip={{duration: 500}}>
                            <ContactListItem
                                contactData={data.node}
                                {selectContacts}
                                {isDeletingContacts}
                            />
                        </li>
                    {/each}
                {:else}
                    <li>
                        <div class="empty-message">
                            <div class="iconcol">
                                <ion-icon name="skull" />
                            </div>
                            <div class="textcol">
                                <div class="text-primary">No contacts yet!</div>
                                <div class="text-secondary">
                                    Submit one of your lead forms on your
                                    website to see a contact appear here.
                                    <br />
                                    Or
                                    <a href={`/orgs/${orgId}/forms`}
                                        >create your first lead form</a
                                    > if you haven't already.
                                </div>
                            </div>
                        </div>
                    </li>
                {/if}
            </ul>
        </section>
        {#if isLoading}
            <div class="load-more-content">
                <div class="spinner" />
            </div>
        {/if}
        <!-- <div class="load-more-content"> -->
        <!-- <button type="submit" class="button primary raised icon-only" disabled={isLastPage || loading} on:click={loadMoreContacts}> -->
        <!-- {#if isLoading} -->
        <!-- <ion-icon name="reload-outline" /> -->
        <!-- Loading... -->
        <!-- {:else} -->
        <!-- Load More -->
        <!-- {/if} -->
        <!-- </button> -->
        <!-- </div> -->
    </OrgPageLayout>
{/if}

<style>
    .listgroup {
        margin: 0 20px;
    }
    .listgroup-head,
    .listgroup li {
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }
    .listgroup-head-left {
        flex-grow: 1;
        flex-shrink: 1;
        padding: 12px 15px;
    }
    .listgroup-head-right {
        flex-grow: 0;
        flex-shrink: 0;
        padding: 12px 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .listgroup-head-right .button {
        height: 30px;
        width: 180px;
        margin: 0 10px 0 10px;
    }
    .listgroup-head-right .button ion-icon {
        --ionicon-stroke-width: 48px;
    }
    .chip {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 700;
        line-height: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 15px;
        padding: 0 10px;
        background: var(--offwhite);
    }
    .chip + .chip {
        margin-left: 5px;
    }
    ul,
    li {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .card {
        background: var(--white);
        border: 1px solid var(--offoffwhite);
        border-radius: 9px;
    }
    .empty-message {
        margin: 20px;
        padding: 20px;
        background: var(--lightblue);
        color: var(--blue);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        /* justify-content: space-around; */
        width: 100%;
        box-sizing: border-box;
    }
    .empty-message .iconcol {
        flex-grow: 0;
        flex-shrink: 0;
        line-height: 20px;
        font-size: 48px;
        margin-right: 10px;
    }
    .empty-message .textcol {
        flex-grow: 1;
        flex-shrink: 0;
    }
    .empty-message .text-primary {
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
    }
    .empty-message .text-secondary {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        opacity: 0.8;
    }
    .load-more-content {
        display: flex;
        justify-content: center;
        margin: 5px;
    }
    .spinner {
        width: 40px;
        height: 40px;
        margin: 100px auto;
        background-color: #000;

        border-radius: 100%;
        animation: sk-scaleout 1s infinite ease-in-out;
    }
    @keyframes sk-scaleout {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 0;
        }
    }
    .sidebar-content {
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        background: #ffffff;
        color: var(--offblack);
        border-radius: 4px;
        padding: 50px;
        /* overflow: hidden; */
    }
    .section {
        padding: 30px 20px 45px;
    }
    .field-name {
        font-size: 18px;
        line-height: 1.4;
        font-weight: 600;
        margin: 0 0 10px;
        padding: 0;
    }
    .field-section + .field-section {
        margin-top: 20px;
        border-top: 1px solid var(--slate-o10);
        padding-top: 20px;
    }
    .name-row {
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }
    .name-col {
        flex-grow: 1;
        flex-shrink: 0;
        width: 200px;
    }
    .name-col + .name-col {
        margin-left: 20px;
    }
    .button.danger {
        color: var(--red);
    }

    .button.cancel {
        color: var(--blue);
    }
    .filter-deleted {
        font-size: 14px;
        font-weight: 600;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 5px;
    }
    .filter-deleted .text {
        margin-right: 6px;
    }
</style>
