<script>
import moment from 'moment';
import _isString from 'lodash/isString';

export let ts;
export let relative = false;

// 1e12 millis is September 2001, 1e13 millis is November 2286
$: when = _isString(ts) ? moment(ts) : moment(ts > 1e13 ? ts/1000 : ts < 1e12 ? ts * 1000 : ts);
$: locale = when.format('LLLL')
let calendar = '';
// $: {
//     let dayOfWeek = moment().day();
//     let isWeekend = dayOfWeek < 1 || dayOfWeek > 5;
//     let lastWeekend = when.clone().startOf('day').subtract(dayOfWeek, 'days');
//     let nextWeekend = when.clone().startOf('day').add(7 - dayOfWeek, 'days');
//     calendar = when.calendar(null, {
//         nextWeek: isWeekend || when.isAfter(nextWeekend) ? '[Next] ddd [At]' : 'ddd [At]',
//         lastWeek: isWeekend || when.isBefore(lastWeekend) ? '[Last] ddd [At]' : 'ddd [At]',
//     });
// }
$: calendar = when.calendar();
$: fromNow = when.fromNow();
</script>

<span class="has-title" title={locale}>
    {relative ? fromNow : calendar}
</span>

<style>
.has-title {
    /* border-bottom: 1px dotted; */
}
</style>
