<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};
    FRAGMENTS.ContactSequence_sequenceAction = gql`
        fragment ContactSequence_sequenceAction on SequenceAction {
            ref
            data {
                type
                message {
                    ref
                    data {
                        subjectLine
                        body
                    }
                }
                delay {
                    minDelay
                    minDelayUnits
                    allowedDays
                    allowedStartTime {
                        hour
                        minute
                        pm
                    }
                    allowedEndTime {
                        hour
                        minute
                        pm
                    }
                }
            }
        }
    `;
    FRAGMENTS.ContactSequence_org = gql`
        fragment ContactSequence_org on Org {
            _id
            name
            timeZone
        }
    `;
    FRAGMENTS.ContactSequence_contact = gql`
        fragment ContactSequence_contact on Contact {
            _id
            uids {
                data {
                    _id
                    value
                    contactField {
                        _id
                        isEmail
                        isSms
                    }
                }
            }
            profile {
                data {
                    _id
                    valueJson
                    contactField {
                        _id
                        name
                        isFirstName
                        isLastName
                    }
                }
            }
        }
    `;
    FRAGMENTS.ContactSequence_contactSequence = gql`
        fragment ContactSequence_contactSequence on ContactSequence {
            ref
            data {
                createdAt
                actions {
                    ref
                    data {
                        completedAt
                        messages {
                            ref
                            data {
                                subject
                                strippedText
                            }
                        }
                        sequenceAction {
                            ...ContactSequence_sequenceAction
                        }
                    }
                }
                nextActions {
                    node {
                        ...ContactSequence_sequenceAction
                    }
                }
                sequence {
                    ref
                    data {
                        name
                    }
                }
            }
        }
        ${FRAGMENTS.ContactSequence_sequenceAction}
    `;

    const QUERIES = {};
    QUERIES.whoami = gql`
        query getUserByOrg($orgId: ID!) {
            getUserByOrg(orgId: $orgId) {
                ref
            }
        }
    `;
    const MUTATIONS = {};
    MUTATIONS.updateContactSequence = gql`
        mutation updateContactSequence(
            $id: ID!
            $data: UpdateContactSequenceInput!
        ) {
            updateContactSequence(id: $id, data: $data) {
                ref
                ...ContactSequence_contactSequence
            }
        }
        ${FRAGMENTS.ContactSequence_contactSequence}
    `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import _get from 'lodash/get';
    import _map from 'lodash/map';
    import _reduce from 'lodash/reduce';
    import _find from 'lodash/find';
    import moment from 'moment';
    import {getClient, mutation} from 'svelte-apollo';
    import {fly} from 'svelte/transition';
    import {computeNextDelayCompletionAfter} from '../../sequenceDelay';
    import {watchQuery} from '../svelte-apollo-watch-query';
    import {replaceTemplateFields} from '../../templateFields';
    import {setDatelessTime} from '../../datelessTime';
    import Timestamp from './Timestamp.svelte';

    export let contactSequenceData;
    export let contactData;

    export let isCollapsed = true;
    export let isCollapsible = true;

    $: contactSequenceId = contactSequenceData.ref;
    const client = getClient();
    const updateContactSequenceMutation = mutation(
                MUTATIONS.updateContactSequence,
            );
    // let whoami;
    // client.query({ query: QUERIES.whoami }).then(result => {
    //     whoami = result.data.whoami;
    // });

    $: orgPath = `/orgs/${_get(contactData, 'data.org.ref')}`;

    $: sequenceName = _get(contactSequenceData, 'data.sequence.data.name', '');

    $: nextActions = _map(
        _get(contactSequenceData, 'data.nextActions', []),
        'node',
    );
    $: lastActionMoment = _get(contactSequenceData, 'data.lastActionAt')
        ? moment(
              _get(contactSequenceData, 'data.lastActionAt'),
              moment.ISO_8601,
          )
        : moment(_get(contactSequenceData, 'data.createdAt'), moment.ISO_8601);
    $: nextActionTimings = _reduce(
        nextActions,
        (accumulator, sequenceAction) => {
            if (sequenceAction.data.type == 'DELAY') {
                // Handle case where delay is null....
                if (sequenceAction.data.delay) {
                    accumulator._lastActionMoment =
                        computeNextDelayCompletionAfter(
                            sequenceAction.data.delay,
                            accumulator._lastActionMoment,
                            _get(
                                contactSequenceData,
                                'data.contact.org.data.timeZone',
                            ),
                        );
                }
            }
            accumulator[sequenceAction.ref] = accumulator._lastActionMoment;
            return accumulator;
        },
        {
            _lastActionMoment: lastActionMoment,
        },
    );

    const getProfile = (contact, where) => {
        let value = _get(
            _find(_get(contact, 'data.uids', []), {
                data: {contactField: {data: where}},
            }),
            'data.value',
        );
        if (value) {
            return value;
        }
        let valueJson = _get(
            _find(_get(contact, 'data.profile', []), {
                data: {contactField: {data: where}},
            }),
            'data.valueJson',
        );
        if (valueJson) {
            return JSON.parse(valueJson);
        }
    };
    $: contactFirstName = getProfile(contactData, {isFirstName: true}) || '';
    $: contactLastName = getProfile(contactData, {isLastName: true}) || '';

    $: templateFieldData = {
        org: _get(contactData, 'data.org'),
        contact: {
            firstName: contactFirstName,
            lastName: contactLastName,
        },
    };
    const previewTemplateFields = (text) =>
        replaceTemplateFields(text, templateFieldData);

    async function updateContactSequence(id, data) {
        try {
            
            // let response = await mutate(client, {
            //     mutation: MUTATIONS.updateContactSequence,
            //     variables: {
            //         id: id,
            //         data: data,
            //     },
            // });
            let response = await updateContactSequenceMutation({
                variables: {
                    id: id,
                    data: data,
                },
            });
            client.writeFragment({
                id: id,
                fragment: FRAGMENTS.ContactSequence_contactSequence,
                fragmentName: 'ContactSequence_contactSequence',
                data: response.data.partialUpdateContactSequence,
            });
            return response.data.updateContactSequence;
        } catch (error) {
            // FIXME handle errors better...;
            alert(error.message);
        }
    }

    let isSaving = false;
    async function pauseSequence() {
        if (isSaving) return;
        isSaving = true;
        try {
            await updateContactSequence(_get(contactSequenceData, 'ref'), {
                isPaused: true,
            });
        } catch (error) {
            alert(error.message);
        }
        isSaving = false;
    }
    async function unpauseSequence() {
        if (isSaving) return;
        isSaving = true;
        try {
            await updateContactSequence(_get(contactSequenceData, 'ref'), {
                isPaused: false,
            });
        } catch (error) {
            alert(error.message);
        }
        isSaving = false;
    }
    async function cancelSequence() {
        if (isSaving) return;
        if (
            !confirm('You are about to cancel this sequence for this contact.')
        ) {
            return;
        }
        isSaving = true;
        try {
            const result = await client.query({
                query: QUERIES.whoami,
                variables: {
                    orgId: contactData.data.org.ref,
                },
            });
            const userId = result.data.getUserByOrg.ref;
            await updateContactSequence(_get(contactSequenceData, 'ref'), {
                isCompleted: true,
                canceledBy: userId,
            });
        } catch (error) {
            alert(error.message);
        }
        isSaving = false;
    }
</script>

{#if !_get(contactSequenceData, 'ref')}
    Loading...
{:else}
    <article
        class="sequence"
        class:paused={_get(contactSequenceData, 'data.isPaused') &&
            !_get(contactSequenceData, 'data.isCompleted')}
        class:completed={_get(contactSequenceData, 'data.isCompleted')}
    >
        <header>
            <span
                class="icon"
                class:primary={!_get(contactSequenceData, 'data.isCompleted')}
            >
                <ion-icon
                    name="play-forward"
                    style="position:relative; left:1px;"
                />
            </span>
            <hgroup>
                <h1>
                    <a
                        href="{orgPath}/sequences/{_get(
                            contactSequenceData,
                            'data.sequence.ref',
                        )}"
                    >
                        {sequenceName || 'Untitled Sequence'}
                    </a>
                    {#if _get(contactSequenceData, 'data.isCompleted')}
                        <span class="chip">
                            <!-- <span class="activity primary"></span> -->
                            {#if _get(contactSequenceData, 'data.canceledBy')}
                                canceled
                            {:else}
                                completed
                            {/if}
                        </span>
                    {/if}
                </h1>
                <h2 class="muted">
                    Started <Timestamp
                        ts={_get(contactSequenceData, 'data.createdAt')}
                    />
                </h2>
            </hgroup>
            <div class="controls">
                {#if isCollapsible}
                    {#if isCollapsed}
                        <button
                            type="button"
                            on:click|stopPropagation={() => {
                                isCollapsed = !isCollapsed;
                            }}
                            class="button"
                        >
                            <ion-icon
                                class="btn-icon"
                                name="add"
                                style="--ionicon-stroke-width:48px;"
                            />
                            <span class="btn-text">
                                expand {_get(
                                    contactSequenceData,
                                    'data.actions.length',
                                ) || ''} actions
                            </span>
                        </button>
                    {:else}
                        <button
                            type="button"
                            on:click|stopPropagation={() => {
                                isCollapsed = !isCollapsed;
                            }}
                            class="button"
                        >
                            <ion-icon
                                class="btn-icon"
                                name="remove"
                                style="--ionicon-stroke-width:48px;"
                            />
                            <span class="btn-text">
                                collapse {_get(
                                    contactSequenceData,
                                    'data.actions.length',
                                ) || ''} actions
                            </span>
                        </button>
                    {/if}
                {/if}
                {#if !_get(contactSequenceData, 'data.isCompleted')}
                    <button
                        type="button"
                        on:click|stopPropagation={unpauseSequence}
                        class="button primary"
                        class:active={!_get(
                            contactSequenceData,
                            'data.isPaused',
                        )}
                        disabled={isSaving}
                    >
                        <span class="btn-text">
                            <ion-icon name="play" />
                            play
                        </span>
                    </button>
                    <button
                        type="button"
                        on:click|stopPropagation={pauseSequence}
                        class="button warning"
                        class:active={_get(
                            contactSequenceData,
                            'data.isPaused',
                        )}
                        disabled={isSaving}
                    >
                        <span class="btn-text">
                            <ion-icon name="pause" />
                            pause
                        </span>
                    </button>
                    <button
                        type="button"
                        on:click|stopPropagation={cancelSequence}
                        class="button danger"
                        disabled={isSaving}
                    >
                        <span class="btn-text">
                            <ion-icon name="close" />
                            cancel
                        </span>
                    </button>
                {/if}
            </div>
        </header>
        <section class="timeline">
            <span class="timeline-path"></span>
            {#if !_get(contactSequenceData, 'data.actions.length') && !_get(contactSequenceData, 'data.isCompleted')}
                <ol transition:fly|local={{y: -20, duration: 200}}>
                    <li class="action pending computing-explanation">
                        <!-- <span class="action-marker raised">
                            <ion-icon name="information" />
                        </span> -->
                        <div class="what">
                            <strong>We're working on it.</strong>
                            {#if _get(contactSequenceData, 'data.isPaused')}
                                The next actions will be computed within a few
                                minutes.
                            {:else}
                                The next actions will be computed within a few
                                minutes and the sequence will begin shortly
                                after.
                            {/if}
                        </div>
                    </li>
                </ol>
            {:else if isCollapsed}
                <ol
                    in:fly|local={{y: -20, duration: 200, delay: 200}}
                    out:fly|local={{y: 20, duration: 200}}
                >
                    <li class="action completed collapsed-explanation">
                        <span class="action-marker raised">
                            <ion-icon name="ellipsis-horizontal" />
                        </span>
                        <div class="what">
                            {_get(contactSequenceData, 'data.actions.length') ||
                                ''} completed actions.
                            <button
                                type="button"
                                on:click|stopPropagation={() => {
                                    isCollapsed = false;
                                }}
                            >
                                Expand
                            </button>
                        </div>
                    </li>
                </ol>
            {:else}
                <ol
                    in:fly|local={{y: -20, duration: 200, delay: 200}}
                    out:fly|local={{y: -20, duration: 200}}
                >
                    {#each _get(contactSequenceData, 'data.actions', []) as action, i (_get(action, 'ref'))}
                        <li class="action completed">
                            {#if 'DELAY' == _get(action, 'data.sequenceAction.data.type')}
                                <span class="action-marker raised">
                                    <ion-icon name="checkmark" />
                                </span>
                                <div class="icon">
                                    <ion-icon
                                        name="time"
                                        style="font-size:26px;"
                                    />
                                </div>
                                <div class="what">
                                    Wait {_get(
                                        action,
                                        'data.sequenceAction.data.delay.minDelay',
                                        0,
                                    )}
                                    {_get(
                                        action,
                                        'data.sequenceAction.data.delay.minDelayUnits',
                                        'minutes',
                                    ).toLowerCase()}
                                    <div class="delay-window">
                                        Continue on
                                        {#if 'ANY' != _get(action, 'data.sequenceAction.data.delay.allowedDays', 'ANY')}
                                            a
                                            <strong>
                                                {_get(
                                                    action,
                                                    'data.sequenceAction.data.delay.allowedDays',
                                                    '_',
                                                ).toLowerCase()}
                                            </strong>
                                        {:else}
                                            any day
                                        {/if}
                                        {#if _get(action, 'data.sequenceAction.data.delay.allowedStartTime')}
                                            {#if _get(action, 'data.sequenceAction.data.delay.allowedEndTime')}
                                                between
                                                <strong>
                                                    {setDatelessTime(
                                                        moment(),
                                                        _get(
                                                            action,
                                                            'data.sequenceAction.data.delay.allowedStartTime',
                                                            {},
                                                        ),
                                                    ).format('LT')}
                                                </strong>
                                                and
                                                <strong>
                                                    {setDatelessTime(
                                                        moment(),
                                                        _get(
                                                            action,
                                                            'data.sequenceAction.data.delay.allowedEndTime',
                                                            {},
                                                        ),
                                                    ).format('LT')}
                                                </strong>
                                            {:else}
                                                at
                                                <strong>
                                                    {setDatelessTime(
                                                        moment(),
                                                        _get(
                                                            action,
                                                            'data.sequenceAction.data.delay.allowedStartTime',
                                                            {},
                                                        ),
                                                    ).format('LT')}
                                                </strong>
                                            {/if}
                                        {/if}
                                    </div>
                                </div>
                                <div class="when">
                                    Completed <Timestamp
                                        ts={_get(action, 'data.completedAt')}
                                    />
                                </div>
                            {:else if 'MESSAGE' == _get(action, 'data.sequenceAction.data.type')}
                                <span class="action-marker raised">
                                    <ion-icon name="checkmark" />
                                </span>
                                <div class="icon">
                                    <ion-icon
                                        name="chatbubble"
                                        style="margin-top:1px;"
                                    />
                                </div>
                                <div class="what">
                                    Send a message
                                    <div class="message-container">
                                        <div class="message">
                                            <strong>
                                                {_get(
                                                    action,
                                                    'data.messages.0.data.subject',
                                                ) || ''}
                                            </strong>
                                            {_get(
                                                action,
                                                'data.messages.0.data.strippedText',
                                            ) || '(empty message)'}
                                        </div>
                                    </div>
                                </div>
                                <div class="when">
                                    Completed <Timestamp
                                        ts={_get(action, 'data.completedAt')}
                                    />
                                </div>
                            {/if}
                        </li>
                    {/each}
                </ol>
            {/if}
            {#if _get(contactSequenceData, 'data.isCompleted')}
                {#if _get(contactSequenceData, 'data.canceledBy')}
                    <ol
                        in:fly|local={{y: -20, duration: 200, delay: 200}}
                        out:fly|local={{y: -20, duration: 200}}
                    >
                        <li class="action completed completed-explanation">
                            <span
                                class="action-marker raised"
                                style="line-height:22px;"
                            >
                                <ion-icon name="stop" style="font-size:12px;" />
                            </span>
                            <div class="what">
                                Canceled by
                                <strong
                                    >{_get(
                                        contactSequenceData,
                                        'data.canceledBy.data.displayName',
                                    ) ||
                                        _get(
                                            contactSequenceData,
                                            'data.canceledBy.data.email',
                                        ) ||
                                        '(no name)'}</strong
                                >
                            </div>
                        </li>
                    </ol>
                {:else}
                    <ol
                        in:fly|local={{y: -20, duration: 200, delay: 200}}
                        out:fly|local={{y: -20, duration: 200}}
                    >
                        <li class="action completed completed-explanation">
                            <span class="action-marker raised">
                                <ion-icon name="checkmark-done" />
                            </span>
                            <div class="what">
                                <strong>All actions completed.</strong>
                            </div>
                        </li>
                    </ol>
                {/if}
            {/if}
            {#if !isCollapsed && !_get(contactSequenceData, 'data.isCompleted')}
                <ol
                    in:fly|local={{y: -20, duration: 200, delay: 200}}
                    out:fly|local={{y: -20, duration: 200}}
                >
                    {#each nextActions as action, i (_get(action, 'ref'))}
                        <li class="action pending">
                            {#if 'DELAY' == _get(action, 'data.type')}
                                <span class="action-marker"></span>
                                <div class="icon">
                                    <ion-icon
                                        name="time"
                                        style="font-size:26px;"
                                    />
                                </div>
                                <div class="what">
                                    Wait {_get(
                                        action,
                                        'data.delay.minDelay',
                                        0,
                                    )}
                                    {_get(
                                        action,
                                        'data.delay.minDelayUnits',
                                        'minutes',
                                    ).toLowerCase()}
                                    {#if _get(action, 'data.delay')}
                                        <div class="delay-window">
                                            Continue on
                                            {#if 'ANY' != _get(action, 'data.delay.allowedDays', 'ANY')}
                                                a
                                                <strong>
                                                    {_get(
                                                        action,
                                                        'data.delay.allowedDays',
                                                        '_',
                                                    ).toLowerCase()}
                                                </strong>
                                            {:else}
                                                any day
                                            {/if}
                                            {#if _get(action, 'data.delay.allowedStartTime')}
                                                {#if _get(action, 'data.delay.allowedEndTime')}
                                                    between
                                                    <strong>
                                                        {setDatelessTime(
                                                            moment(),
                                                            _get(
                                                                action,
                                                                'data.delay.allowedStartTime',
                                                                {},
                                                            ),
                                                        ).format('LT')}
                                                    </strong>
                                                    and
                                                    <strong>
                                                        {setDatelessTime(
                                                            moment(),
                                                            _get(
                                                                action,
                                                                'data.delay.allowedEndTime',
                                                                {},
                                                            ),
                                                        ).format('LT')}
                                                    </strong>
                                                {:else}
                                                    at
                                                    <strong>
                                                        {setDatelessTime(
                                                            moment(),
                                                            _get(
                                                                action,
                                                                'data.delay.allowedStartTime',
                                                                {},
                                                            ),
                                                        ).format('LT')}
                                                    </strong>
                                                {/if}
                                            {/if}
                                        </div>
                                    {/if}
                                </div>
                                <div class="when">
                                    {#if _get(contactSequenceData, 'data.isPaused')}
                                        paused
                                    {:else if moment(nextActionTimings[_get(action, 'ref')])
                                        .subtract(3, 'minutes')
                                        .isBefore()}
                                        in a few mins
                                    {:else}
                                        <Timestamp
                                            ts={nextActionTimings[
                                                _get(action, 'ref')
                                            ] * 1000}
                                        />
                                    {/if}
                                </div>
                            {:else if 'MESSAGE' == _get(action, 'data.type')}
                                <span class="action-marker"></span>
                                <div class="icon">
                                    <ion-icon
                                        name="chatbubble"
                                        style="margin-top:1px;"
                                    />
                                </div>
                                <div class="what">
                                    Send a message
                                    <div class="message-container">
                                        <div class="message">
                                            <strong>
                                                {previewTemplateFields(
                                                    _get(
                                                        action,
                                                        'data.message.data.subjectLine',
                                                    ) || '',
                                                )}
                                            </strong>
                                            {previewTemplateFields(
                                                _get(
                                                    action,
                                                    'data.message.data.body',
                                                ) || '(empty message)',
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div class="when">
                                    {#if _get(contactSequenceData, 'data.isPaused')}
                                        paused
                                    {:else if moment(nextActionTimings[_get(action, 'ref')])
                                        .subtract(3, 'minutes')
                                        .isBefore()}
                                        in a few mins
                                    {:else}
                                        <Timestamp
                                            ts={nextActionTimings[
                                                _get(action, 'ref')
                                            ] * 1000}
                                        />
                                    {/if}
                                </div>
                            {/if}
                        </li>
                    {/each}
                </ol>
            {/if}
        </section>
    </article>
{/if}

<style>
    .sequence {
        padding: 20px;
    }
    .sequence header {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--slate);
        padding: 0 0 5px;
        margin: 0;
        border-radius: 10px;
    }
    .sequence header .icon {
        display: inline-block;
        flex-grow: 0;
        flex-shrink: 0;
        text-align: center;
        width: 40px;
        height: 40px;
        margin-right: 10px;
        font-size: 24px;
        line-height: 46px;
        border-radius: 20px;
        background: var(--slate);
        color: white;
    }
    .sequence header .icon.primary {
        background: var(--blue);
    }
    .sequence.paused header .icon.primary {
        background: var(--gold);
    }
    .sequence header hgroup {
        flex-grow: 0;
        flex-shrink: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 30px;
    }
    .sequence header h1 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        margin: 0;
    }
    .sequence header h1 a {
        text-decoration: none;
    }
    .sequence header h1 a:hover {
        text-decoration: underline;
    }
    .sequence header h2 {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        margin: 0;
    }
    .sequence .controls {
        flex-grow: 1;
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
    .timeline {
        padding: 0 0 0 20px;
        position: relative;
    }
    .timeline-path {
        position: absolute;
        left: 20px; /* half the width of the .icon */
        top: 0;
        bottom: 0;
        width: 2px;
        margin-left: -1px; /* half the width of this element */
        background: var(--lightblue);
        border-radius: 1px;
    }
    .paused .timeline-path {
        background: var(--lightgold);
    }
    .completed .timeline-path {
        background: var(--slate-o10);
    }
    .action {
        position: relative;
        padding: 20px 0 20px 30px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        color: var(--slate);
    }
    .action.pending .action-marker {
        position: absolute;
        left: -5px; /* half the width of this element */
        width: 10px;
        height: 10px;
        border-radius: 5px;
        top: 28px; /* custom to center with first line of text */
        background: var(--white);
        box-shadow:
            0 0 0 2px var(--blue),
            0 0 0 5px white;
    }
    .sequence.paused .action.pending .action-marker {
        box-shadow:
            0 0 0 2px var(--gold),
            0 0 0 5px white;
    }
    .sequence.completed .action.pending .action-marker {
        box-shadow:
            0 0 0 2px var(--offoffwhite),
            0 0 0 5px white;
    }
    /* .action.completed .action-marker, */
    /* .action.skipped .action-marker { */
    .action.completed .action-marker {
        position: absolute;
        left: -10px; /* half the width of this element */
        top: 22px; /* custom to center with first line of text */
        width: 20px;
        height: 20px;
        border-radius: 10px;
        text-align: center;
        color: white;
        font-size: 14px;
        line-height: 24px;
    }
    .action.completed .action-marker {
        background: var(--blue);
    }
    .sequence.completed .action.completed .action-marker {
        background: var(--slate);
    }
    /* .action.skipped .action-marker {
    opacity: 0.5;
} */
    .action.completed .action-marker ion-icon {
        --ionicon-stroke-width: 64px;
    }
    .action .icon {
        flex-grow: 0;
        flex-shrink: 0;
        font-size: 24px;
        text-align: center;
    }
    .action .what,
    .action .when {
        padding: 3px 10px;
    }
    .action .what {
        flex-grow: 1;
        flex-shrink: 1;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
    }
    .action .what .delay-window {
        font-size: 12px;
        opacity: 0.75;
        line-height: 16px;
        font-weight: 400;
    }
    .action .what .delay-window strong {
        font-weight: 600;
    }
    .action .what .message-container {
        margin: 10px 0 0;
    }
    .action .when {
        flex-shrink: 0;
        flex-grow: 0;
        font-size: 12px;
        line-height: 20px;
        font-weight: 600;
        opacity: 0.75;
    }
    ol,
    li {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .button {
        /* background: var(--offoffwhite); */
        color: var(--slate);
        /* box-shadow: var(--box-shadow-raised-level1), 0 0 0 2px var(--slate-o10); */
        height: 32px;
    }
    .button[disabled] {
        opacity: 0.5;
    }
    .button.primary.active {
        color: var(--blue);
        box-shadow: inset 0 0 0 2px var(--blue);
    }
    .button.warning.active {
        color: var(--gold);
        box-shadow: inset 0 0 0 2px var(--gold);
    }
    .btn-text {
        font-weight: 600;
    }
    .btn-text ion-icon {
        vertical-align: top;
        font-size: 18px;
        margin-left: -3px;
    }
    /*
<div class="completed-bar">
    <span class="completed-icon">
        <ion-icon name="checkmark-done" />
    </span>
    <div class="completed-text">
        <div class="completed-text-primary">
            Sequence is completed
        </div>
        {#if _get(contactSequenceData, 'canceledBy')}
            <div class="completed-text-secondary">
                Canceled by
                <strong>{
                    _get(contactSequenceData, 'canceledBy.displayName') ||
                    _get(contactSequenceData, 'canceledBy.email') ||
                    '(no name)'
                }</strong>
            </div>
        {/if}
    </div>
</div>
.completed-bar {
    background: var(--lightblue);
    color: var(--blue);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.completed-icon {
    flex-grow: 0;
    flex-shrink: 0;
    background: var(--blue);
    display: inline-block;
    margin-right: 8px;
    color: white;
    font-size: 20px;
    line-height: 38px;
    text-align: center;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    --ionicon-stroke-width: 48px;
}
.completed-text {
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 14px;
    line-height: 16px;
    margin-right: 5px;
}
.completed-text-primary {
    font-weight: 600;
}
.completed-text-secondary {
    margin-top: 2px;
    opacity: 0.65;
    font-size: 12px;
    line-height: 14px;
} */
    .action.completed.completed-explanation .action-marker {
        top: 29px;
        background: var(--slate);
    }
    .completed-explanation .what {
        flex-grow: 0;
        background: var(--slate-o10);
        color: var(--slate);
        padding: 10px 20px;
        border-radius: 4px;
        font-weight: 400;
    }
    .action.completed.collapsed-explanation .what {
        padding-left: 0;
        font-weight: 400;
    }
    .action.completed.collapsed-explanation .what button {
        font-weight: 600;
    }
    .action.completed.collapsed-explanation .what button:hover {
        text-decoration: underline;
    }
    .computing-explanation .what {
        flex-grow: 0;
        background: var(--lightblue);
        color: var(--blue);
        padding: 10px 20px;
        border-radius: 4px;
        font-weight: 400;
    }
    .computing-explanation .what strong {
        display: block;
    }
    .chip {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 600;
        line-height: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 15px;
        padding: 0 10px;
        margin: -5px 10px; /* Negative to avoid affecting vertical spacing */
        background: var(--slate-o10);
    }
    /* .activity {
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 5px;
    background: rgba(0,0,0,0.25);
    margin-right: 2px;
}
.activity.online {
    background: var(--green);
}
.activity.primary {
    background: var(--blue);
} */
    .action .message {
        padding: 10px;
        background: var(--slate-o10);
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        white-space: pre-wrap;
        max-width: 500px;
        border-radius: 5px;
        display: inline-block;
    }
    .action .message strong {
        font-weight: 600;
    }
</style>
