<script>
    export let totalPages;
    export let currentPage;
    import {createEventDispatcher} from 'svelte';
    const dispatch = createEventDispatcher();
    let showbar = [];

    function handleChange(newValue) {
        currentPage = newValue;
        dispatch('change', currentPage);
    }

    $: {
        if (totalPages <= 5) {
            showbar = [1, 2, 3, 4, 5];
        } else if (currentPage < 3) {
            showbar = [1, 2, 3, 4, 5];
        } else if (currentPage > totalPages - 2) {
            showbar = [
                totalPages - 4,
                totalPages - 3,
                totalPages - 2,
                totalPages - 1,
                totalPages,
            ];
        } else {
            showbar = [
                currentPage - 2,
                currentPage - 1,
                currentPage,
                currentPage + 1,
                currentPage + 2,
            ];
        }
    }
</script>

<nav class="pagination">
    <div class="pagination-title">Page</div>
    {#each showbar as perPage}
        <div
            class="pagination-page"
            on:click={perPage <= totalPages ? handleChange(perPage) : null}
            class:active={currentPage == perPage}
            class:disabled={perPage > totalPages}
            aria-disabled={perPage > totalPages}
        >
            {perPage.toString()}
        </div>
    {/each}
</nav>

<style>
    .pagination {
        display: flex;
        width: 280px;
        height: 20px;
    }
    .pagination-title {
        width: 32px;
        color: #121212;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        margin-left: 20px;
    }
    .pagination-page {
        width: 32px;
        color: var(--gray-5, #e0e0e0);
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        margin: 0px 20px;
    }
    .pagination-page:hover {
        cursor: pointer;
    }
    .pagination-page.active {
        color: #121212;
    }
    .pagination-page.disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.5;
    }
    .pagination-page.disabled:hover {
        cursor: default;
    }
</style>
