<script context="module">
    import gql from 'graphql-tag';
    import {FRAGMENTS as DashboardEventListItem_FRAGMENTS} from '../../components/DashboardEventListItem.svelte';

    const FRAGMENTS = {};
    const QUERIES = {};
    const MUTATIONS = {};

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getClient, query} from 'svelte-apollo';
    import {navigate} from 'svelte-routing';
    import {scale, slide} from 'svelte/transition';
    import _get from 'lodash/get';
    import _map from 'lodash/map';
    import _isNumber from 'lodash/isNumber';
    import moment from 'moment';
    import {watchQuery} from '../../svelte-apollo-watch-query';
    import HtmlHeadTitle from '../../components/HtmlHeadTitle.svelte';
    import AdminPageLayout from '../../components/AdminPageLayout.svelte';
    import Toggle from '../../components/Toggle.svelte';
    import DashboardEventListItem from '../../components/DashboardEventListItem.svelte';
    export let orgId;

    $: orgPath = `/admin/${orgId}`;
    let showHidden = false;

    const client = getClient();
    const pageTitle = 'Users';
</script>

<HtmlHeadTitle {pageTitle} />

<AdminPageLayout {orgId} title={pageTitle}>
    <section class="card listgroup">
        <header class="listgroup-head">
            <!-- <div class="listgroup-bulkaction">
                    <a>Select all</a>
                </div> -->
            <div class="listgroup-head-left" />
            <!-- <div class="listgroup-head-right">
                    <a>Sort Options</a>
                </div> -->
        </header>
        <ul />
    </section>
</AdminPageLayout>

<style>
</style>
