import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './performance-tracking.js';
import 'shim-keyboard-event-key';

import App from './App.svelte';
import './auth0';

const app = new App({
    target: document.body,
});

export default app;
