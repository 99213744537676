const _get = require('lodash/get');
const _reduce = require('lodash/reduce');

function getTemplateFields(templateFieldData) {
    return [
        {
            key: 'ORG_NAME',
            value: '###ORG_NAME',
            exampleValue:
                getTemplateReplacements(templateFieldData)['###ORG_NAME'] ||
                'Your Org Name',
        },
        {
            key: 'CONTACT_FIRSTNAME',
            value: '###CONTACT_FIRSTNAME',
            exampleValue:
                getTemplateReplacements(templateFieldData)[
                    '###CONTACT_FIRSTNAME'
                ] || 'Arnold',
        },
        {
            key: 'CONTACT_LASTNAME',
            value: '###CONTACT_LASTNAME',
            exampleValue:
                getTemplateReplacements(templateFieldData)[
                    '###CONTACT_LASTNAME'
                ] || 'Schwarzenegger',
        },
    ];
}

function getTemplateReplacements(templateFieldData) {
    return {
        '###ORG_NAME': _get(templateFieldData, 'org.name') || '',
        '###CONTACT_FIRSTNAME':
            _get(templateFieldData, 'contact.firstName') || '',
        '###CONTACT_LASTNAME':
            _get(templateFieldData, 'contact.lastName') || '',
    };
}

function wrapTemplateFieldWithMarkup(
    text,
    templateField,
    markupBefore,
    markupAfter,
) {
    return text.replace(
        new RegExp(templateField.value, 'ig'),
        '' + markupBefore + templateField.key + markupAfter,
    );
}

function wrapTemplateFieldsWithMarkup(
    text,
    templateFields,
    markupBefore,
    markupAfter,
) {
    return _reduce(
        templateFields,
        (result, templateField) =>
            wrapTemplateFieldWithMarkup(
                result,
                templateField,
                markupBefore,
                markupAfter,
            ),
        text,
    );
}

function replaceTemplateFields(text, templateFieldData) {
    return _reduce(
        getTemplateReplacements(templateFieldData),
        (result, replacement, replaceText) =>
            result.replace(new RegExp(replaceText, 'ig'), replacement),
        text,
    );
}

module.exports = {
    replaceTemplateFields: replaceTemplateFields,
    getTemplateFields: getTemplateFields,
    wrapTemplateFieldsWithMarkup: wrapTemplateFieldsWithMarkup,
};
