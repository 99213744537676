<script context="module">
    import gql from 'graphql-tag';
    import {FRAGMENTS as DashboardEventListItem_FRAGMENTS} from '../../components/DashboardEventListItem.svelte';

    const FRAGMENTS = {};
    const QUERIES = {};
    const MUTATIONS = {};

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getClient, query} from 'svelte-apollo';
    import {navigate} from 'svelte-routing';
    import {scale, slide} from 'svelte/transition';
    import _get from 'lodash/get';
    import _map from 'lodash/map';
    import _isNumber from 'lodash/isNumber';
    import moment from 'moment';
    import {watchQuery} from '../../svelte-apollo-watch-query';
    import HtmlHeadTitle from '../../components/HtmlHeadTitle.svelte';
    import AdminPageLayout from '../../components/AdminPageLayout.svelte';
    import Toggle from '../../components/Toggle.svelte';
    import DashboardEventListItem from '../../components/DashboardEventListItem.svelte';

    export let orgId;

    $: orgPath = `/admin/${orgId}`;
    let showHidden = false;

    const client = getClient();
    const pageTitle = 'Dashboard';
</script>

<HtmlHeadTitle {pageTitle} />
<AdminPageLayout {orgId} title={pageTitle} />

<style>
    
</style>
