<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};
    FRAGMENTS.EditContactProfileField_contactField = gql`
        fragment EditContactProfileField_contactField on ContactField {
            ref
            data {
                isUid
                isFirstName
                isLastName
                isEmail
                isSms
            }
        }
    `;
    FRAGMENTS.EditContactProfileField_profileField = gql`
        fragment EditContactProfileField_profileField on ProfileField {
            ref
            data {
                valueJson
            }
        }
    `;
    // contactField {
    //                 _id
    //                 ...EditContactProfileField_contactField
    //             }
    //             contact {
    //                 _id
    //             }
    // ${FRAGMENTS.EditContactProfileField_contactField}

    FRAGMENTS.EditContactProfileField_contactUid = gql`
        fragment EditContactProfileField_contactUid on ContactUid {
            ref
            data {
                value
            }
        }
    `;
    FRAGMENTS.EditContactProfileField_contact = gql`
        fragment EditContactProfileField_contact on Contact {
            _id
            uids {
                data {
                    _id
                    ...EditContactProfileField_contactUid
                }
            }
            profile {
                data {
                    _id
                    ...EditContactProfileField_profileField
                }
            }
        }
        ${FRAGMENTS.EditContactProfileField_contactUid}
        ${FRAGMENTS.EditContactProfileField_profileField}
    `;

    const QUERIES = {};
    QUERIES.getUserByOrg = gql`
        query getUserByOrg($orgId: ID!) {
            getUserByOrg(orgId: $orgId) {
                ref
                data {
                    email
                }
            }
        }
    `;

    const MUTATIONS = {};
    MUTATIONS.updateContact = gql`
        mutation updateContact($id: ID!, $data: PartialUpdateContactInput!) {
            partialUpdateContact(id: $id, data: $data) {
                _id
                ...EditContactProfileField_contact
            }
        }
        ${FRAGMENTS.EditContactProfileField_contact}
    `;
    MUTATIONS.updateProfileField = gql`
        mutation updateProfileField($id: ID!, $data: ProfileFieldInput!) {
            updateProfileField(id: $id, data: $data) {
                ...EditContactProfileField_profileField
            }
        }
        ${FRAGMENTS.EditContactProfileField_profileField}
    `;
    MUTATIONS.createContactUid = gql`
        mutation createContactUid(
            $contactId: ID!
            $contactFieldId: ID!
            $value: String!
        ) {
            createContactUid(
                contactId: $contactId
                contactFieldId: $contactFieldId
                value: $value
            ) {
                ref
            }
        }
    `;
    MUTATIONS.updateContactUid = gql`
        mutation updateContactUid($contactUidId: ID!, $value: String!) {
            updateContactUid(contactUidId: $contactUidId, value: $value) {
                ref
            }
        }
    `;
    MUTATIONS.deleteContactUid = gql`
        mutation deleteContactUid($id: ID!) {
            deleteContactUid(id: $id) {
                ref
            }
        }
    `;
    MUTATIONS.createProfileField = gql`
        mutation createProfileField(
            $contactId: ID!
            $contactFieldId: ID!
            $data: ProfileFieldInput!
        ) {
            createProfileField(
                contactId: $contactId
                contactFieldId: $contactFieldId
                data: $data
            ) {
                ref
            }
        }
    `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import _get from 'lodash/get';
    import _find from 'lodash/find';
    import _debounce from 'lodash/debounce';
    import {getClient, mutation} from 'svelte-apollo';
    import MutationInput from './MutationInput.svelte';

    export let orgId;
    export let contactId;
    export let profileFieldData;
    export let contactFieldData;
    export let isDeleted;
    export let labelText = '';
    export let placeholder = '';
    export let kind = '';
    $: contactFieldData;

    const defaultLabels = {
        isFirstName: 'First name',
        isLastName: 'Last name',
        isEmail: 'Email address',
        isSms: 'Phone number (SMS)',
    };
    $: defaultLabelText =
        _find(defaultLabels, (v, k) => _get(contactFieldData, `data.${k}`)) ||
        '';

    const defaultPlaceholders = {
        isFirstName: 'Bruce',
        isLastName: 'Wayne',
        isEmail: 'name@example.com',
        isSms: '555 321 1111',
    };
    $: defaultPlaceholder =
        _find(defaultPlaceholders, (v, k) =>
            _get(contactFieldData, `data.${k}`),
        ) || '';

    let fieldValue;
    let fieldValueIsInitialized = false;
    function initFieldValue() {
        if (fieldValueIsInitialized) return;
        if (_get(profileFieldData, 'data.valueJson')) {
            fieldValue = JSON.parse(
                _get(profileFieldData, 'data.valueJson') || '""',
            );
            fieldValueIsInitialized = true;
        } else if (_get(profileFieldData, 'data.value')) {
            fieldValue = _get(profileFieldData, 'data.value') || '';
            fieldValueIsInitialized = true;
        }
    }
    $: profileFieldData, initFieldValue();

    const client = getClient();

    const createProfileFieldMutation = mutation(MUTATIONS.createProfileField);
    const updateProfileFieldMutation = mutation(MUTATIONS.updateProfileField);
    const createContactUidMutation = mutation(MUTATIONS.createContactUid);
    const updateContactUidMutation = mutation(MUTATIONS.updateContactUid);
    const deleteContactUidMutation = mutation(MUTATIONS.deleteContactUid);

    let whoami;
    client
        .query({query: QUERIES.getUserByOrg, variables: {orgId: orgId}})
        .then((result) => {
            whoami = result.data.getUserByOrg;
        });

    async function createProfileField(contactId, contactFieldId, data) {
        try {
            let response = await createProfileFieldMutation({
                variables: {
                    contactId: contactId,
                    contactFieldId,
                    data,
                },
            });
        } catch (error) {
            console.log(error);
        }
    }
    async function updateProfileField(id, data) {
        try {
            let response = await updateProfileFieldMutation({
                variables: {
                    id: id,
                    data: data,
                },
            });
            return response.data.partialUpdateProfileField;
        } catch (error) {
            console.log(error);
        }
    }

    async function createContactUid(contactId, contactFieldId, value) {
        try {
            let response = await createContactUidMutation({
                variables: {
                    contactId: contactId,
                    contactFieldId: contactFieldId,
                    value: value,
                },
            });
            return response.data.createContactUid;
        } catch (error) {
            console.log(error);
        }
    }
    async function updateContactUid(contactUidId, value) {
        try {
            let response = await updateContactUidMutation({
                variables: {
                    contactUidId: contactUidId,
                    value: value,
                },
            });
            return response.data.updateContactUid;
        } catch (error) {
            console.log(error);
        }
    }
    let isDeleting = false;
    async function deleteContactUid(contactUidId) {
        if (isDeleting) return;
        isDeleting = true;
        try {
            let response = await deleteContactUidMutation({
                variables: {
                    id: contactUidId,
                },
            });
            return response.data.deleteContactUid;
        } catch (error) {
            alert(error.message);
        }
        isDeleting = false;
    }
    function confirmDeleteContactUid(contactUidId) {
        let value = _get(profileFieldData, 'data.value');
        if (confirm(`Delete "${value}" from this contact?`)) {
            deleteContactUid(_get(profileFieldData, 'ref'));
        }
    }

    let savingValue;
    $: savedValue =
        _get(profileFieldData, 'data.value') ||
        JSON.parse(_get(profileFieldData, 'data.valueJson') || 'null');
    let saveError;
    let saveNetworkError;
    async function save() {
        if (!fieldValue) return;

        // Always clear errors when someone tries to save.
        let hadError = !!(saveError || saveNetworkError);
        saveError = undefined;
        saveNetworkError = undefined;

        if (fieldValue === (savingValue != null ? savingValue : savedValue)) {
            return;
        }

        savingValue = fieldValue;
        try {
            if (_get(contactFieldData, 'data.isUid')) {
                if (_get(profileFieldData, 'ref')) {
                    await updateContactUid(profileFieldData.ref, fieldValue);
                } else {
                    await createContactUid(
                        contactId,
                        contactFieldData.ref,
                        fieldValue,
                    );
                    fieldValue = '';
                }
            } else {
                let data = {
                    org: orgId,
                    updatedBy: whoami.ref,
                    valueJson: JSON.stringify(fieldValue),
                };
                if (_get(profileFieldData, 'ref')) {
                    await updateProfileField(profileFieldData.ref, data);
                } else {
                    await createProfileField(
                        contactId,
                        contactFieldData.ref,
                        data,
                    );
                }
            }
        } catch (error) {
            console.log('error', error);
            if (error.networkError) {
                saveNetworkError = error;
            } else {
                saveError = error;
            }
        } finally {
            savingValue = undefined;
        }
    }

    $: inputType = (function () {
        switch (defaultLabelText) {
            case defaultLabels.isEmail:
                return 'email';
            case defaultLabels.isSms:
                return 'tel';
            default:
                return 'text';
        }
    })();
    const autosave = _debounce(save, 1000);
    $: shouldAutosave =
        _get(profileFieldData, 'ref') || !_get(contactFieldData, 'data.isUid');
    $: if (fieldValue && shouldAutosave) autosave();
</script>

<form on:submit|preventDefault={save} class="fieldrow" class:muted={isDeleting}>
    <label class="fieldlabel">
        {labelText || defaultLabelText}
    </label>
    <div class="field-with-tools">
        <div class="field">
            <MutationInput
                type={inputType}
                bind:value={fieldValue}
                placeholder={placeholder || defaultPlaceholder}
                {savingValue}
                {savedValue}
                saveErrorMessage={_get(saveError, 'message') || null}
                networkErrorMessage={_get(saveNetworkError, 'message') || null}
                {kind}
                disabled={isDeleted}
            />
        </div>
        {#if _get(contactFieldData, 'data.isUid')}
            <div class="tools">
                {#if _get(profileFieldData, 'ref')}
                    <button
                        type="button"
                        on:click|stopPropagation={confirmDeleteContactUid}
                        disabled={isDeleted}
                    >
                        <ion-icon name="trash-outline" />
                    </button>
                {:else}
                    <button type="submit" disabled={isDeleted}>
                        <ion-icon name="add-outline" />
                    </button>
                {/if}
            </div>
        {/if}
    </div>
</form>

<style>
    .field-with-tools {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }
    .field-with-tools .field {
        flex-grow: 1;
        flex-shrink: 0;
    }
    .field-with-tools .tools {
        flex-grow: 0;
        flex-shrink: 0;
    }
    .tools button {
        height: 40px;
        width: 40px;
        border-radius: 25px;
        text-align: center;
        padding: 8px 0;
        line-height: 24px;
        font-size: 16px;
        font-weight: 400;
    }
    .tools ion-icon {
        font-size: 24px;
    }
</style>
