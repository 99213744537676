<script context="module">
  import gql from "graphql-tag";
</script>

<script>
  import { fly, fade, slide } from "svelte/transition";
  import _get from "lodash/get";
  export let handleModalClose;
  export let orgData;
  export let handleBultEditConfirmButtonClick;
  console.log(
    "orgData => ",
    orgData,
    _get(orgData, "sequences", []),
    _get(orgData, "sequences", []).length
  );
  let modalNode;

  const modalPaddingPx = 50;

  function handleEscape(event) {
    if (event.keyCode == 27) {
      handleModalClose();
    }
  }

  let bulkActions = [
    {
      name: "Add To Action Sequence",
      options: orgData.sequences,
      type: "radio",
    },
    {
      name: "Change Status",
      options: orgData.contactStatuses,
      type: "radio",
    },
    {
      name: "Add/Remove Tag",
      options: ["Female Class", "Kids Class"],
      type: "radio",
    },
    {
      name: "Delete Contacts",
      options: [],
      type: "radio",
    },
    {
      name: "Restore Contacts",
      options: [],
      type: "radio",
    },
  ];

  let selectedOption = "addToActionSequence";
  let selectedSubOption = "";

  function handleOptionButtonClick(event) {
    selectedOption = selectedOption === event.target.id ? "" : event.target.id;
    console.log(selectedOption);
  }
</script>

<svelte:window on:keydown={handleEscape} />

<div class="modal-container" style="padding:{modalPaddingPx}px;">
  <div class="modal-backdrop" transition:fade={{ duration: 200 }} />
  <div class="modal-close" on:click={handleModalClose}>
    <ion-icon name="close-outline" />
    <span class="keyboard-shortcut"> esc </span>
  </div>
  <div class="modal">
    <div
      class="action-modal"
      bind:this={modalNode}
      transition:fly={{ y: 100, duration: 200 }}
    >
      <div class="action-header">
        <span>Choose Actions For Selected Contacts</span>
        <button class="close-btn" on:click={handleModalClose}>×</button>
      </div>
      <div class="action-container">
        <div class="action-container-body">
          <button
            id="addToActionSequence"
            class="action-select-button"
            class:selected={selectedOption === "addToActionSequence"}
            on:click={handleOptionButtonClick}
          >
            Add To Action Sequence {#if selectedOption === "addToActionSequence"}
              <ion-icon name="caret-up-outline" />
            {:else}
              <ion-icon name="caret-down-outline" />
            {/if}</button
          >

          {#if selectedOption === "addToActionSequence" && Array.isArray(_get(orgData, "sequences", [])) && _get(orgData, "sequences", []).length > 0}
            <div class="sub-options" transition:slide={{ duration: 300 }}>
              {#each _get(orgData, "sequences", []) as sequence}
                <label>
                  <input
                    type="radio"
                    name="sequenceSub"
                    value={sequence.ref}
                    on:change={() => {
                      selectedSubOption = sequence.ref;
                    }}
                  />
                  {sequence.data.name}
                </label>
              {/each}
            </div>
          {/if}

          <button
            id="contactStatus"
            class="action-select-button"
            class:selected={selectedOption === "contactStatus"}
            on:click={handleOptionButtonClick}
          >
            Contact Status {#if selectedOption === "contactStatus"}
              <ion-icon name="caret-up-outline" />
            {:else}
              <ion-icon name="caret-down-outline" />
            {/if}</button
          >
          {#if selectedOption === "contactStatus" && Array.isArray(_get(orgData, "contactStatuses", [])) && _get(orgData, "contactStatuses", []).length > 0}
            <div class="sub-options" transition:slide={{ duration: 300 }}>
              {#each _get(orgData, "contactStatuses", []) as status}
                <label>
                  <input
                    type="radio"
                    name="contactStatusSub"
                    value={status.ref}
                    color={status.data.color}
                    on:change={() => {
                      selectedSubOption = status.ref;
                    }}
                  />
                  {status.data.name}
                </label>
              {/each}
            </div>
          {/if}

          <button
            id="deleteContacts"
            class="action-select-button"
            class:selected={selectedOption === "deleteContacts"}
            on:click={handleOptionButtonClick}
          >
            Delete Contacts</button
          >

          <button
            id="restoreContacts"
            class="action-select-button"
            class:selected={selectedOption === "restoreContacts"}
            on:click={handleOptionButtonClick}
          >
            Restore Contacts</button
          >
        </div>
      </div>
      <div class="action-footer">
        <button
          on:click={() =>
            handleBultEditConfirmButtonClick(selectedOption, selectedSubOption)}
        >
          OK
        </button>
        <button on:click={handleModalClose}> Cancel </button>
      </div>
    </div>
  </div>
</div>

<style>
  .modal-container {
    background: rgba(0, 0, 0, 0.01);
    position: absolute;
    overflow: auto;
    --webkit-overflow-scrolling: touch;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .modal-backdrop {
    background: var(--offblack-backdrop);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  @supports (backdrop-filter: blur(20px)) {
    .modal-backdrop {
      background: var(--offblack-backdrop-blur);
      backdrop-filter: blur(20px);
      backdrop-filter: saturate(180%) blur(20px);
    }
  }
  .modal-close {
    color: white;
    position: fixed;
    top: 0;
    right: 0;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  .modal-close ion-icon {
    font-size: 48px;
    padding-top: 2px;
  }
  .modal-close .keyboard-shortcut {
    border-radius: 3px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    padding: 0 4px 1px;
    margin-top: -8px;
    opacity: 0.62;
  }
  .modal {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal > :global(*) {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  }
  .action-modal {
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 40%;
    max-width: 100%;
  }
  .action-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--blue);
    color: white;
    border-radius: 8px 8px 0 0;
    font-weight: 600;
  }

  .close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .action-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    align-items: center;
    justify-content: center;
  }
  .action-container-body {
    display: flex;
    width: 60%;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
  }

  .action-footer {
    text-align: center;
    padding: 40px 20px 20px 20px;
    border-radius: 0px 0px 8px 8px;
    background-color: white;
    font-weight: 600;
  }

  .action-footer button {
    background-color: var(--blue);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }

  .action-footer button:hover {
    background-color: #2e59d9;
  }
  .sub-options {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    gap: 5px;
  }
  .action-select-button {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  .action-select-button.selected {
    background-color: #f1f1f1;
    color: var(--blue);
  }
</style>
