<script context="module">
import gql from 'graphql-tag';

const FRAGMENTS = {};
const QUERIES = {};
const MUTATIONS = {};

export {
    FRAGMENTS,
    QUERIES,
    MUTATIONS,
}
</script>

<script>
// import { getClient } from 'svelte-apollo';
import _get from 'lodash/get';
// import { watchQuery } from '../../svelte-apollo-watch-query';
import HtmlHeadTitle from '../../../components/HtmlHeadTitle.svelte';
import OrgPageLayout from '../../../components/OrgPageLayout.svelte';
import { createStripePortalSessionForExistingSubscription } from '../../../api.js';
import { navigate } from 'svelte-routing';

// const client = getClient();
const pageTitle = 'Launching subscription portal...';

export let orgId;

async function launchStripePortal() {
    try {
        let response = await createStripePortalSessionForExistingSubscription(
            orgId,
        );
        let sessionResult = await response.json();
        navigate(sessionResult.redirectUrl);
    } catch (error) {
        console.log(error);
        alert(error.message);
        throw error;
    }
}
launchStripePortal();
</script>

<HtmlHeadTitle {pageTitle} />

<OrgPageLayout orgId={orgId} title={pageTitle}>
    <div class="org-plan">
        <div class="spinner"></div>
    </div>
</OrgPageLayout>

<style>
.org-plan {
    padding: 0 20px 20px;
}

.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: var(--slate);

  border-radius: 100%;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}
@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}
</style>
