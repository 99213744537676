<script context="module">
    const modalPaddingPx = 50;
    export {
        modalPaddingPx,
    }
    </script>
    
    <script>
    import { getContext } from 'svelte';
    import { fly, fade } from 'svelte/transition';
    import { contextKey } from './ModalController.svelte';
    export let isCreatingContact;
    export let handleModalClose;
    // const modalController = getContext(contextKey);
    let modalNode;
    function handleContainerClick(event) {
        let isClickInsideModal = modalNode.contains(event.target) && modalNode != event.target;
        if (!isClickInsideModal) {
            handleModalClose();
        }
    }
    function handleEscape(event) {
        if (event.keyCode == 27) {
            handleModalClose();
        }
    }
    </script>
    
    <svelte:window on:keydown={handleEscape} />
    
    <div class="modal-container" on:click={handleContainerClick} style="padding:{modalPaddingPx}px;">
        <div class="modal-backdrop" transition:fade={{duration: 200}} />
        <div class="modal-close">
            <ion-icon name="close-outline" />
            <span class="keyboard-shortcut">
                esc
            </span>
        </div>
        <div class="modal" bind:this={modalNode} transition:fly={{y: 100, duration: 200}}>
    
            <slot />
    
        </div>
    </div>
    
    <style>
    .modal-container {
        /* overscroll-behavior: contain; */
        background: rgba(0,0,0,0.01); /* fix scrollbar contrast */
        position: absolute;
        overflow: auto;
        --webkit-overflow-scrolling: touch;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        /* padding: 80px; */
    }
    .modal-backdrop {
        background: var(--offblack-backdrop);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    @supports (backdrop-filter: blur(20px)) {
        .modal-backdrop {
            background: var(--offblack-backdrop-blur);
            backdrop-filter: blur(20px);
            backdrop-filter: saturate(180%) blur(20px);
        }
    }
    .modal-close {
        color: white;
        position: fixed;
        top: 0;
        right: 0;
        width: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }
    .modal-close ion-icon {
        font-size: 48px;
        padding-top: 2px;
    }
    .modal-close .keyboard-shortcut {
        /* border: 1px solid; */
        border-radius: 3px;
        font-size: 12px;
        /* font-family: monospace; */
        font-weight: 400;
        line-height: 14px;
        padding: 0 4px 1px;
        margin-top: -8px;
        opacity: 0.62;
    }
    .modal {
        position: relative;
    }
    .modal > :global(*) {
        box-shadow: 0 0 30px rgba(0,0,0,0.15);
    }
    </style>
    