<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};
    FRAGMENTS.FormsIndex_form = gql`
        fragment form on Form {
            ref
            data {
                name
                title
                isHosted
                isDeleted
                deletedAt
                spec {
                    ref
                    data {
                        fields {
                            ref
                            data {
                                contactField {
                                    ref
                                    data {
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `;

    const QUERIES = {};
    QUERIES.orgForms = gql`
        query orgForms($orgId: ID!) {
            findOrgByID(id: $orgId) {
                ref
                data {
                    forms {
                        ...form
                    }
                }
            }
        }
        ${FRAGMENTS.FormsIndex_form}
    `;

    const MUTATIONS = {};
    MUTATIONS.createForm = gql`
        mutation createForm($form: FormInput!) {
            createForm(data: $form) {
                ref
            }
        }
    `;
    MUTATIONS.createHostedForm = gql`
        mutation createHostedForm(
            $orgId: ID!
            $formName: String!
            $formTitle: String!
        ) {
            createHostedForm(
                orgId: $orgId
                formName: $formName
                formTitle: $formTitle
            ) {
                ref
            }
        }
    `;
    MUTATIONS.temporarilyDeleteForms = gql`
        mutation temporarilyDeleteForms($formIds: [ID!]) {
            temporarilyDeleteForms(formIds: $formIds) {
                ref
            }
        }
    `;
    MUTATIONS.retrieveForm = gql`
        mutation retrieveForm($formId: ID!) {
            retrieveForm(formId: $formId) {
                ref
            }
        }
    `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getClient, query, mutation} from 'svelte-apollo';
    import {navigate} from 'svelte-routing';
    import _get from 'lodash/get';
    import _take from 'lodash/take';
    import _map from 'lodash/map';
    import {watchQuery} from '../../../svelte-apollo-watch-query';
    import HtmlHeadTitle from '../../../components/HtmlHeadTitle.svelte';
    import OrgPageLayout from '../../../components/OrgPageLayout.svelte';
    import Toggle from '../../../components/Toggle.svelte';

    export let orgId;

    let showDeleted = true;

    const client = getClient();
    const createFormMutation = mutation(MUTATIONS.createForm);
    const createHostedFormMutation = mutation(MUTATIONS.createHostedForm);
    const temporarilyDeleteFormsMutation = mutation(
        MUTATIONS.temporarilyDeleteForms,
    );
    const retrieveFormMutation = mutation(MUTATIONS.retrieveForm);

    const pageTitle = 'Lead Forms';

    const orgFormsQuery = watchQuery(client, {
        query: QUERIES.orgForms,
        variables: {
            orgId: orgId,
        },
        pollInterval: 30000,
    });
    $: totalOrgForms = _get($orgFormsQuery, 'data.findOrgByID.data.forms', []);
    $: orgForms = showDeleted
        ? totalOrgForms
        : totalOrgForms.filter((form) => !form.data.isDeleted);
    $: isLoading = $orgFormsQuery.loading && !orgForms.length;

    let isCreatingForm = false;
    async function createForm(options) {
        if (isCreatingForm) return;
        isCreatingForm = true;
        try {
            let createdFormId;
            if (options && options.isHosted) {
                // let response = await mutate(client, {
                //     mutation: MUTATIONS.createHostedForm,
                //     variables: {
                //         orgId: orgId,
                //         formName: 'Where should we send our pricing details?', // FIXME better name generation
                //     },
                // });
                let response = await createHostedFormMutation({
                    variables: {
                        orgId: orgId,
                        formName: 'Where should we send our pricing details?', // FIXME better name generation,
                        formTitle: 'Pricing Request Form',
                    },
                });
                createdFormId = response.data.createHostedForm.ref;
            } else {
                // let response = await mutate(client, {
                //     mutation: MUTATIONS.createForm,
                //     variables: {
                //         org: orgId,
                //         name: 'New Webhook', // FIXME better name generation
                //         // draftSpec: {create: {}},
                //     },
                // });
                let response = await createFormMutation({
                    variables: {
                        org: orgId,
                        name: 'New Webhook', // FIXME better name generation
                        // draftSpec: {create: {}},
                    },
                });
                createdFormId = response.data.createForm.ref;
            }
            navigate(`/orgs/${orgId}/forms/${createdFormId}`);
        } catch (error) {
            // FIXME handle errors better...
            alert(error.message);
        }
        isCreatingForm = false;
    }
    function createHostedForm() {
        return createForm({isHosted: true});
    }
    let selectedFormIds = [];
    let isDeletingForms = false;

    function handleCheckboxChange(event) {
        if (event.target.checked) selectedFormIds.push(event.target.id);
        else
            selectedFormIds = selectedFormIds.filter(
                (id) => id != event.target.id,
            );
    }

    function handleClickDeleteButton() {
        isDeletingForms = true;
    }

    async function handleConfirmButtonClick() {
        if (selectedFormIds.length == 0) {
            alert('There are no forms selected for deletion!');
            return;
        }

        if (
            confirm(
                'Temporarily deletes the selected form. They will be permanently removed after 30 days. Their functionality will be closed temporarily until then. Also, you cannot change the settings. Do you want to proceed?',
            )
        ) {
            try {
                const response = await temporarilyDeleteFormsMutation({
                    variables: {
                        formIds: selectedFormIds,
                    },
                });

                totalOrgForms = totalOrgForms.map((form) => {
                    if (selectedFormIds.includes(form.ref)) {
                        const formData = form.data;
                        // const formFromResponse = response.data.temporarilyDeleteForms.find((item) => {
                        //     item.ref == form.ref
                        // })
                        const now = new Date();
                        const dateNow = now.toISOString().split('T')[0];
                        return {
                            ref: form.ref,
                            data: {
                                ...formData,
                                isDeleted: true,
                                deletedAt: dateNow,
                            },
                        };
                    }
                    return form;
                });
            } catch (error) {
                console.log(error);
                alert(error);
            }
            isDeletingForms = false;
            selectedFormIds = [];
        } else {
            return;
        }
    }

    function handleCancelButtonClick() {
        isDeletingForms = false;
        selectedFormIds = [];
    }

    async function handleClickRetrieveButton(formId) {
        try {
            const response = await retrieveFormMutation({
                variables: {
                    formId: formId,
                },
            });
            totalOrgForms = totalOrgForms.map((form) => {
                if (form.ref == response.data.retrieveForm.ref) {
                    const formData = form.data;
                    return {
                        ref: form.ref,
                        data: {
                            ...formData,
                            isDeleted: false,
                        },
                    };
                }
                return form;
            });
        } catch (error) {
            console.log(error);
            alert(error);
        }
    }
</script>

<HtmlHeadTitle {pageTitle} />

<OrgPageLayout {orgId} title={pageTitle}>
    <section class="card listgroup">
        <header class="listgroup-head">
            <!-- <div class="listgroup-bulkaction">
                <a>Select all</a>
            </div> -->
            <div class="listgroup-head-left">
                <span class="chip">
                    {isLoading ? 'loading' : orgForms.length} forms
                </span>
            </div>
            <div class="listgroup-head-right">
                <!-- <a href="/orgs/{orgId}/forms/new" class="button"> -->
                {#if !isDeletingForms}
                    <button
                        type="button"
                        title="Delete"
                        on:click|stopPropagation={handleClickDeleteButton}
                        class="button danger"
                    >
                        <ion-icon name="trash" class="btn-icon" />
                        <span class="btn-text"> Delete Forms </span>
                    </button>
                    <button
                        type="button"
                        on:click={createHostedForm}
                        class="button"
                        disabled={isCreatingForm}
                        class:muted={isCreatingForm}
                    >
                        <ion-icon name="add" class="btn-icon" />
                        <span class="btn-text"> Create a New Form </span>
                    </button>
                {:else}
                    <button
                        type="button"
                        title="Delete"
                        on:click|stopPropagation={handleConfirmButtonClick}
                        class="button danger"
                    >
                        <ion-icon name="trash" class="btn-icon" />
                        <span class="btn-text"> Confirm Deletion </span>
                    </button>
                    <button
                        type="button"
                        on:click|stopPropagation={handleCancelButtonClick}
                        class="button cancel"
                    >
                        <ion-icon
                            name="close-circle-outline"
                            class="btn-icon"
                        />
                        <span class="btn-text"> Cancel Deletion </span>
                    </button>
                {/if}
                <label class="filter-deleted">
                    <span class="text">Show Deleted Forms?</span>
                    <Toggle bind:checked={showDeleted} />
                    <!-- on:change={handleToggleChange} -->
                </label>
            </div>
        </header>
        <ul>
            {#if isLoading}
                <!-- <li>Loading Forms...</li> -->
            {:else if $orgFormsQuery.errors}
                <li>
                    errors: <pre>{JSON.stringify(
                            $orgFormsQuery.errors,
                            null,
                            4,
                        )}</pre>
                </li>
            {:else if !orgForms.length}
                <li>
                    <div class="empty-message">
                        <div class="iconcol">
                            <ion-icon name="clipboard-outline" />
                            <!-- shield
                            thunderstorm
                            shuffle
                            sad
                            reader
                            clipboard
                            layers
                            file-tray
                            create
                            enter -->
                        </div>
                        <div class="textcol">
                            <div class="text-primary">No forms yet!</div>
                            <div class="text-secondary">
                                Forms allow you to capture new leads on your
                                website and trigger action sequences.
                            </div>
                        </div>
                    </div>
                </li>
            {:else}
                {#each orgForms as form (form.ref)}
                    {#if form.data.isDeleted}
                        <li>
                            <div class="row">
                                <div class="row-deleted">
                                    <a
                                        href="/orgs/{orgId}/forms/{form.ref}"
                                        class="form-link"
                                    >
                                        <span class="icon-col">
                                            {#if _get(form, 'data.isHosted')}
                                                <!-- <ion-icon name="rocket" /> -->
                                                <ion-icon
                                                    name="clipboard-outline"
                                                />
                                            {:else if _get(form, 'ref')}
                                                <ion-icon name="radio" />
                                            {/if}
                                        </span>
                                        <span class="text-col">
                                            <span class="title">
                                                {#if form.data.title}
                                                    {form.data.title}
                                                {:else}
                                                    (No Form Name)
                                                {/if}
                                            </span>
                                            <span class="name">
                                                {#if form.data.name}
                                                    ({form.data.name})
                                                {:else}
                                                    (Untitled Form)
                                                {/if}
                                            </span>
                                            <span class="subtitle">
                                                {#if _get(form, 'data.isHosted')}
                                                    Hosted Form •
                                                {:else if _get(form, 'ref')}
                                                    Webhook Form •
                                                {/if}
                                                {#if !_get(form, 'data.spec.data.fields.length')}
                                                    No fields yet
                                                {:else if _get(form, 'data.spec.data.fields.length') == 1}
                                                    Only {_get(
                                                        form,
                                                        'data.spec.data.fields.0.data.contactField.data.name',
                                                    )}
                                                {:else if _get(form, 'data.spec.data.fields.length') <= 5}
                                                    {_map(
                                                        _get(
                                                            form,
                                                            'data.spec.data.fields',
                                                            [],
                                                        ),
                                                        'data.contactField.data.name',
                                                    ).join(', ')}
                                                {:else}
                                                    <!-- We use 4 here instead of 5 to specifically avoid "and 1 other field" -->
                                                    {_map(
                                                        _take(
                                                            _get(
                                                                form,
                                                                'data.spec.data.fields',
                                                                [],
                                                            ),
                                                            4,
                                                        ),
                                                        'contactField.name',
                                                    ).join(', ')}
                                                    and {_get(
                                                        form,
                                                        'data.spec.data.fields.length',
                                                    ) - 4} other fields
                                                {/if}
                                            </span>
                                        </span>
                                    </a>
                                </div>
                                <div class="deleted-info">
                                    <div class="deleted-style">
                                        <div class="status-text">
                                            <ion-icon
                                                name="trash-outline"
                                                class="status-icon"
                                            />
                                            <span>Deleted At</span>
                                        </div>
                                        <span class="date-text"
                                            >{form.data.deletedAt
                                                ? form.data.deletedAt.split(
                                                      'T',
                                                  )[0]
                                                : ''}</span
                                        >
                                    </div>

                                    <button
                                        type="button"
                                        title="restore"
                                        class="form-retrieve-button"
                                        on:click|stopPropagation={() =>
                                            handleClickRetrieveButton(form.ref)}
                                        disabled={isDeletingForms}
                                    >
                                        <!-- <ion-icon name="refresh-outline" style="position:relative; top:1px;" /> -->
                                        <ion-icon
                                            name="reload-outline"
                                            style="position:relative; top:1px;"
                                            class="form-retrieve-icon"
                                        />
                                        <!-- <ion-icon name="sync-outline" style="position:relative; top:1px;" /> -->
                                    </button>
                                </div>
                            </div>
                        </li>
                    {:else}
                        <li>
                            <div class="row">
                                <a
                                    href="/orgs/{orgId}/forms/{form.ref}"
                                    class="form-link"
                                >
                                    <span class="icon-col">
                                        {#if _get(form, 'data.isHosted')}
                                            <!-- <ion-icon name="rocket" /> -->
                                            <ion-icon
                                                name="clipboard-outline"
                                            />
                                        {:else if _get(form, 'ref')}
                                            <ion-icon name="radio" />
                                        {/if}
                                    </span>
                                    <span class="text-col">
                                        <span class="title">
                                            {#if form.data.title}
                                                {form.data.title}
                                            {:else}
                                                (No Form Name)
                                            {/if}
                                        </span>
                                        <span class="name">
                                            {#if form.data.name}
                                                ({form.data.name})
                                            {:else}
                                                (Untitled Form)
                                            {/if}
                                        </span>
                                        <span class="subtitle">
                                            {#if _get(form, 'data.isHosted')}
                                                Hosted Form •
                                            {:else if _get(form, 'ref')}
                                                Webhook Form •
                                            {/if}
                                            {#if !_get(form, 'data.spec.data.fields.length')}
                                                No fields yet
                                            {:else if _get(form, 'data.spec.data.fields.length') == 1}
                                                Only {_get(
                                                    form,
                                                    'data.spec.data.fields.0.data.contactField.data.name',
                                                )}
                                            {:else if _get(form, 'data.spec.data.fields.length') <= 5}
                                                {_map(
                                                    _get(
                                                        form,
                                                        'data.spec.data.fields',
                                                        [],
                                                    ),
                                                    'data.contactField.data.name',
                                                ).join(', ')}
                                            {:else}
                                                <!-- We use 4 here instead of 5 to specifically avoid "and 1 other field" -->
                                                {_map(
                                                    _take(
                                                        _get(
                                                            form,
                                                            'data.spec.data.fields',
                                                            [],
                                                        ),
                                                        4,
                                                    ),
                                                    'contactField.name',
                                                ).join(', ')}
                                                and {_get(
                                                    form,
                                                    'data.spec.data.fields.length',
                                                ) - 4} other fields
                                            {/if}
                                        </span>
                                    </span>
                                </a>
                                {#if isDeletingForms}
                                    <div class="form-delete-checkbox">
                                        <input
                                            type="checkbox"
                                            id={form.ref}
                                            on:change={handleCheckboxChange}
                                        />
                                    </div>
                                {/if}
                            </div>
                        </li>
                    {/if}
                {/each}
            {/if}
            <li></li>
        </ul>
    </section>
</OrgPageLayout>

<style>
    ul,
    li {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .card {
        background: var(--white);
        border: 1px solid var(--offoffwhite);
        border-radius: 9px;
    }
    .listgroup {
        margin: 0 20px;
    }
    .listgroup-head {
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }
    .listgroup-head-left {
        flex-grow: 1;
        flex-shrink: 1;
        padding: 12px 15px;
    }
    .listgroup-head-right {
        flex-grow: 0;
        flex-shrink: 0;
        padding: 12px 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .chip {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 700;
        line-height: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 15px;
        padding: 0 10px;
        background: var(--offwhite);
    }
    .listgroup-head-right .button {
        height: 30px;
        width: 180px;
        margin: 0 10px 0 10px;
    }
    .listgroup-head-right .button ion-icon {
        --ionicon-stroke-width: 48px;
    }
    .row {
        border-top: 1px solid var(--offwhite);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }
    .form-link {
        display: flex;
        align-items: center;
        flex-grow: 1;
        flex-shrink: 1;
        text-decoration: none;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        padding: 0;
        overflow: hidden;
        /* white-space: nowrap; */
        text-overflow: ellipsis;
    }
    .form-link > * {
        padding: 15px 10px;
    }
    .form-link > :first-child {
        padding-left: 20px;
    }
    .form-link > :last-child {
        padding-right: 20px;
    }
    .icon-col {
        font-size: 24px;
    }
    .text-col {
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .title {
        display: block;
        font-weight: 700;
    }
    .name {
        display: block;
        font-weight: 500;
    }
    .form-link:hover .title {
        text-decoration: underline;
    }
    .subtitle {
        display: block;
        opacity: 0.6;
        font-weight: 400;
    }
    .empty-message {
        margin: 20px;
        padding: 20px;
        background: var(--lightblue);
        color: var(--blue);
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        /* justify-content: space-around; */
        /* width: 100%; */
        box-sizing: border-box;
    }
    .empty-message .iconcol {
        flex-grow: 0;
        flex-shrink: 0;
        line-height: 20px;
        font-size: 48px;
        margin-right: 10px;
    }
    .empty-message .textcol {
        flex-grow: 1;
        flex-shrink: 0;
    }
    .empty-message .text-primary {
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
    }
    .empty-message .text-secondary {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        opacity: 0.8;
    }
    .button.danger {
        color: var(--red);
    }

    .button.cancel {
        color: var(--blue);
    }
    .form-delete-checkbox {
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        width: 90px;
        /* padding-top: 20px; */
        /* padding-bottom: 20px; */
        justify-content: center;
        align-items: center;
    }
    input[type='checkbox'] {
        width: 25px;
        height: 25px;
    }
    .filter-deleted {
        font-size: 14px;
        font-weight: 600;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 5px;
    }
    .filter-deleted .text {
        margin-right: 6px;
    }
    .row-deleted {
        text-decoration: line-through;
    }
    .form-retrieve-button {
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        width: 90px;
        /* padding-top: 20px; */
        /* padding-bottom: 20px; */
        justify-content: center;
        align-items: center;
    }
    .form-retrieve-icon {
        width: 30px;
        height: 30px;
    }
    .deleted-info {
        display: flex;
    }
    .deleted-style {
        display: flex;
        flex-direction: column;
        width: 115px;
        height: 45px;
        padding: 4px 15px;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 5px;
        color: #d65543;
        background: rgba(214, 67, 67, 0.12);
    }
    .status-text {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px 0px 2px 0px;
    }
    .date-text {
        margin-top: -10px;
        text-decoration: none !important;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
    .status-icon {
        width: 15px;
        height: 15px;
        margin-right: 4px;
    }
</style>
