<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};
    FRAGMENTS.EditOrgSmsLocal_org = gql`
        fragment EditOrgSmsLocal_org on OrgData {
            smsLocal {
                number
                order {
                    requestedAreaCode
                    orderedAt
                    failureReason
                }
                campaignAssignmentStatus
                campaignId
            }
        }
    `;
    const QUERIES = {};
    const MUTATIONS = {};

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getClient} from 'svelte-apollo';
    import _get from 'lodash/get';
    import _defaultsDeep from 'lodash/defaultsDeep';
    import {requestOrgSmsNumber, removeOrgSmsNumber} from '../api';
    import Timestamp from './Timestamp.svelte';
    import PhoneVerificationSvg from './PhoneVerificationSvg.svelte';
    const client = getClient();

    export let orgData = {};
    export let orgId;
    let smsLocalAreaCode = '';

    let millisFromIsoString = (isoString) => 1000 * Date.parse(isoString);

    let isOrdering = false;
    async function requestSmsLocal() {
        if (isOrdering) return;
        isOrdering = true;
        let areaCode = smsLocalAreaCode.toString();
        if (areaCode.length != 3) {
            alert('Invalid area code.');
            isOrdering = false;
            return;
        }
        try {
            let response = await requestOrgSmsNumber(orgId, {
                areaCode: areaCode,
                tollFree: false,
            });
            if (response.ok) {
                let orgFragmentData = await response.json();
                // FIXME Why doesn't writeFragment work here???
                // client.writeFragment({
                //     id: orgData._id,
                //     fragment: FRAGMENTS.EditOrgSmsLocal_org,
                //     fragmentName: 'EditOrgSmsLocal_org',
                //     data: orgFragmentData,
                // });
                orgData = _defaultsDeep(orgFragmentData, orgData);
            } else {
                alert(
                    'Error requesting a local SMS number. Please try again, or contact support.',
                );
            }
        } catch (error) {
            alert(error.message);
        }
        isOrdering = false;
    }

    let isRemoving = false;
    async function disconnectSmsLocal() {
        let numberToRemove = _get(orgData, 'smsLocal.number');
        let message = `The number ${numberToRemove} will be immediately disconnected from this account.`;
        if (!confirm(message)) return;

        if (isRemoving) return;
        isRemoving = true;

        try {
            let response = await removeOrgSmsNumber(orgId, {
                number: numberToRemove,
            });
            if (response.ok) {
                let orgFragmentData = await response.json();
                // FIXME Why doesn't writeFragment work here???
                // client.writeFragment({
                //     id: orgData._id,
                //     fragment: FRAGMENTS.EditOrgSmsLocal_org,
                //     fragmentName: 'EditOrgSmsLocal_org',
                //     data: orgFragmentData,
                // });
                orgData = _defaultsDeep(orgFragmentData, orgData);
            } else {
                alert(
                    'Error removing this SMS number. Please try again, or contact support.',
                );
            }
        } catch (error) {
            alert(error.message);
        }
        isRemoving = false;
    }

    const getDisplayPhone = (number) => {
        number = '' + number;
        let countryOrBlank = '';
        if (number.length > 10) {
            countryOrBlank =
                '+' + number.substring(0, number.length - 10) + ' ';
        }
        let area = number.substring(0, 3);
        let start = number.substring(3, 6);
        let end = number.substring(6);
        return `${countryOrBlank}(${area}) ${start}-${end}`;
    };
    $: displayNumber = getDisplayPhone(_get(orgData, 'smsLocal.number'));
</script>

<div class="fieldrow">
    <!-- svelte-ignore a11y-label-has-associated-control -->
    <label class="fieldlabel"> Personal SMS Number </label>
    {#if _get(orgData, 'smsLocal.number')}
        <p
            class="fieldhelp"
            style="display:flex; width:100%; align-items:flex-start;"
        >
            <span style="flex-grow:0; flex-shrink:0;">
                <ion-icon
                    name="checkmark-done-circle"
                    style="color:var(--blue); font-size:32px; vertical-align:middle;"
                />
            </span>
            <span
                style="flex-grow:1; flex-shrink:1; margin-left:5px; padding-top:5px;"
            >
                You're sending & receiving text messages at...
            </span>
        </p>
        <div class="field-with-tools">
            <div class="fieldcol">
                <input type="text" readonly value={displayNumber} />
            </div>
            <div class="toolcol">
                <PhoneVerificationSvg
                    verificationStatus={orgData.smsLocal
                        .campaignAssignmentStatus
                        ? 'Verified'
                        : 'Not Verified'}
                    size="20px"
                />
            </div>
            <div class="toolcol">
                <button
                    type="button"
                    on:click|stopPropagation={disconnectSmsLocal}
                    disabled={isRemoving}
                    class="button danger"
                >
                    <span class="btn-text">
                        {#if isRemoving}
                            Loading...
                        {:else}
                            Disconnect
                        {/if}
                    </span>
                </button>
            </div>
        </div>
    {:else if _get(orgData, 'smsLocal.order.orderedAt') && !_get(orgData, 'smsLocal.order.failureReason')}
        <p
            class="fieldhelp"
            style="display:flex; width:100%; align-items:flex-start;"
        >
            <span style="flex-grow:0; flex-shrink:0;">
                <ion-icon
                    name="checkmark-circle-outline"
                    style="color:var(--blue); font-size:32px; vertical-align:middle;"
                />
            </span>
            <span style="flex-grow:1; flex-shrink:1; margin-left:5px;">
                <strong>
                    Requested a
                    {orgData.smsLocal.order.requestedAreaCode}
                    number
                    <Timestamp
                        ts={millisFromIsoString(
                            orgData.smsLocal.order.orderedAt,
                        )}
                    />.
                </strong>
                We'll email you when your number is assigned. Please contact us if
                your number hasn't been assigned within 2 hours.
            </span>
        </p>
    {:else}
        {#if _get(orgData, 'smsLocal.order.failureReason') && !isOrdering}
            <p
                class="fieldhelp"
                style="display:flex; width:100%; align-items:flex-start;"
            >
                <span style="flex-grow:0; flex-shrink:0;">
                    <ion-icon
                        name="close-circle"
                        style="color:var(--red); font-size:32px; vertical-align:middle;"
                    />
                </span>
                <span style="flex-grow:1; flex-shrink:1; margin-left:5px;">
                    <strong>
                        Failed to request a
                        {orgData.smsLocal.order.requestedAreaCode}
                        number.
                    </strong>
                    {orgData.smsLocal.order.failureReason}
                    Please try again, or contact us so we can help out.
                </span>
            </p>
        {/if}
        <label class="fieldhelp" for="smsLocalAreaCode">
            Choose a local area code
        </label>
        <form on:submit|preventDefault={requestSmsLocal}>
            <div class="field-with-tools">
                <div class="fieldcol">
                    <input
                        bind:value={smsLocalAreaCode}
                        type="tel"
                        id="smsLocalAreaCode"
                        placeholder="555"
                    />
                </div>
                <div class="toolcol">
                    <button
                        type="submit"
                        disabled={isOrdering}
                        class="button primary"
                    >
                        <span class="btn-text">
                            {#if isOrdering}
                                Loading...
                            {:else}
                                Request SMS number
                            {/if}
                        </span>
                    </button>
                </div>
            </div>
        </form>
    {/if}
</div>

<style>
    .field-with-tools {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: stretch;
    }
    .fieldcol {
        flex-grow: 1;
        flex-shrink: 1;
    }
    .fieldcol input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .toolcol {
        flex-grow: 0;
        flex-shrink: 0;
        padding: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: var(--slate-o10);
        border-radius: 4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .toolcol .button {
        height: 32px;
    }
</style>
