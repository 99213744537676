<script>
    import 'styles/global.css';

    import {Router, Route, links} from 'svelte-routing';
    import {setClient} from 'svelte-apollo';
    import {client} from './apollo-client-app';

    import Home from './routes/Home.svelte';
    import NotFound from './routes/NotFound.svelte';

    import Signup from './routes/auth/Signup.svelte';
    import Login from './routes/auth/Login.svelte';
    import LoginReturn from './routes/auth/LoginReturn.svelte';
    import Logout from './routes/auth/Logout.svelte';

    import NewOrg from './routes/orgs/NewOrg.svelte';
    import ViewOrg from './routes/orgs/ViewOrg.svelte';

    import ModalController from './components/ModalController.svelte';

    import Admin from './routes/admin/Admin.svelte';

    setClient(client);
</script>

<div use:links class="app-wrapper">
    <ModalController>
        <Router>
            <Route path="/" component={Home} />
            <Route path="/login" component={Login} />
            <Route path="/login/return" component={LoginReturn} />
            <Route path="/signup" component={Signup} />
            <Route path="/logout" component={Logout} />
            <Route path="/orgs/new" component={NewOrg} />
            <Route path="/orgs/:orgId/*" component={ViewOrg} />
            <Route path="/admin/:orgId/*" component={Admin} />
            <Route path="" component={NotFound} />
        </Router>
    </ModalController>
</div>

<style>
    @import 'styles/normalize.css';
    @import 'styles/global.css';

    .app-wrapper {
        height: 100vh;
        overflow: visible;
        transform-style: preserve-3d;
        perspective: 2500px;
    }
</style>
