<script>
import OrgHeader from './OrgHeader.svelte';

export let orgId;
export let title = '';
</script>

<div class="header-layout">
    <div class="header-layout-header">
        <OrgHeader title={title} orgId={orgId} />
    </div>
    <div class="header-layout-content">
        <slot />
        <!-- <section class="content-layout"> -->
            <!-- <header class="content-layout-header">
                <h1>
                    <slot name="header" />
                </h1>
            </header> -->
            <!-- <div class="content-layout-main"> -->
                <!-- <slot name="main" /> -->
            <!-- </div>
        </section> -->
    </div>
</div>

<style>
.header-layout {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    overflow: hidden;
}
.header-layout-header {
    flex-grow: 0;
    flex-shrink: 0;
}
.header-layout-content {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    padding: 0 0 150px;
}
/* .content-layout {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
}
.content-layout-header {
    margin: 0;
    padding: 0 20px;
    flex-shrink: 0;
    flex-grow: 0;
}
.content-layout-header h1 {
    margin: 0;
    font-size: 20px;
    line-height: 40px;
    font-weight: 600;
    padding: 0 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid var(--offoffwhite);
}
.content-layout-main {
    margin: 0;
    padding: 20px 20px 90px;
    flex-shrink: 1;
    flex-grow: 1;
    overflow: auto;
} */
</style>
