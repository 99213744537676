<script>
import { createEventDispatcher } from 'svelte';
import _debounce from 'lodash/debounce';

export let open = false;
export let alignRight = false;

const dispatch = createEventDispatcher();
const triggerCancel = () => dispatch('cancel');

let containerElement;

function handleKeyDown({ key }) {
    if (key === "Escape") {
        triggerCancel()
    }
}

function handleWindowClick(event) {
    if (containerElement.contains(event.target)) {
        // Do not close menu when clicking on button or menu.
        return
    }
    triggerCancel()
}

// Flexbox scrolling doesn't bubble up to the window... :shrug:
// function handleScroll(event) {
//     console.log('handleScroll', event);
// }
</script>

<!-- <svelte:window on:click={open && handleWindowClick} on:keydown={open && handleKeyDown} on:scroll|passive={open && handleScroll} /> -->
<svelte:window on:click={open && handleWindowClick} on:keydown={open && handleKeyDown} />

<div class="dropdown-container" bind:this={containerElement}>
    <slot name="trigger" />
    <div class="dropdown-menu" class:open class:align-right={alignRight}>
        <slot name="menu" />
    </div>
</div>

<style>
.dropdown-container {
    position: relative;
}
.dropdown-menu {
    position: absolute;
    z-index: 999999;
    display: none;
    width: 100%;
    top: 100%;
    left: 0;
}
.dropdown-menu.open {
    display: block;
}
.dropdown-menu.align-right {
    right: 0;
    left: auto;
    direction: rtl; /* sets child overflow to go to left as opposed to right */
}
.dropdown-menu.align-right > :global(*) {
    direction: ltr;
}
</style>
