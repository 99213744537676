<script context="module">
    import gql from 'graphql-tag';
    import {FRAGMENTS as EditContactProfileField_FRAGMENTS} from './EditContactProfileField.svelte';

    const FRAGMENTS = {};
    FRAGMENTS.EditContactProfile_contactField = gql`
        fragment EditContactProfile_contactField on ContactField {
            ref
            data {
                isFirstName
                isLastName
                isEmail
                isSms
            }
        }
    `;
    FRAGMENTS.EditContactProfile_contact = gql`
        fragment EditContactProfile_contact on Contact {
            _id
            ...EditContactProfileField_contact
            org {
                _id
            }
            uids {
                data {
                    _id
                    ...EditContactProfileField_contactUid
                    contactField {
                        _id
                        ...EditContactProfile_contactField
                    }
                }
            }
            profile {
                data {
                    _id
                    ...EditContactProfileField_profileField
                    contactField {
                        _id
                        ...EditContactProfile_contactField
                    }
                }
            }
        }
        ${FRAGMENTS.EditContactProfile_contactField}
        ${EditContactProfileField_FRAGMENTS.EditContactProfileField_contact}
        ${EditContactProfileField_FRAGMENTS.EditContactProfileField_contactUid}
        ${EditContactProfileField_FRAGMENTS.EditContactProfileField_profileField}
    `;

    const QUERIES = {};
    QUERIES.orgContactFields = gql`
        query orgContactFields($orgId: ID!) {
            contactFieldsByOrgAndIsProfile(orgId: $orgId, isProfile: true) {
                ref
                data {
                    isUid
                    isFirstName
                    isLastName
                    isEmail
                    isSms
                }
            }
        }
    `;

    const MUTATIONS = {};

    MUTATIONS.retrieveContact = gql`
        mutation retrieveContact($contactId: ID!) {
            retrieveContact(contactId: $contactId) {
                ref
            }
        }
    `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import _get from 'lodash/get';
    import _find from 'lodash/find';
    import _filter from 'lodash/filter';
    import {getClient, mutation, query} from 'svelte-apollo';
    import {watchQuery} from '../svelte-apollo-watch-query';
    import EditContactProfileField from './EditContactProfileField.svelte';
    import {onMount} from 'svelte';
    const retrieveContactMutation = mutation(MUTATIONS.retrieveContact);
    export let contactId;
    export let contactData;
    $: contactData;
    // let orgId = contactData.data.org.ref;
    $: orgId = _get(contactData, 'data.org.ref', null);
    let orgContactFields = _filter(
        _get(contactData, 'data.org.data.contactFields', []),
        {
            data: {isProfile: true},
        },
    );
    $: isDeleted = _get(contactData, 'data.isDeleted', false);
    $: profileFields = _get(contactData, 'data.profile', []);
    $: firstNameField = _find(profileFields, {
        data: {contactField: {data: {isFirstName: true}}},
    });
    $: lastNameField = _find(profileFields, {
        data: {contactField: {data: {isLastName: true}}},
    });
    $: uidFields = _get(contactData, 'data.uids', []);
    $: emailFields = _filter(uidFields, {
        data: {contactField: {data: {isEmail: true}}},
    });
    $: phoneFields = _filter(uidFields, {
        data: {contactField: {data: {isSms: true}}},
    });

    $: firstNameContactField = _find(orgContactFields, {
        data: {isFirstName: true},
    });
    $: lastNameContactField = _find(orgContactFields, {
        data: {isLastName: true},
    });
    $: emailContactField = _find(orgContactFields, {data: {isEmail: true}});
    $: phoneContactField = _find(orgContactFields, {data: {isSms: true}});
    // $: orgId = _get(queryVariables, 'orgId');
    async function handleClickRetrieveButton(contactId) {
        try {
            const response = await retrieveContactMutation({
                variables: {
                    contactId: contactId,
                },
            });
            let tempContactData = contactData;
            tempContactData.data.isDeleted = false;
            tempContactData.data.deletedAt = null;
            contactData = tempContactData;
        } catch (error) {
            console.log(error);
            alert(error);
        }
    }
    
</script>

<section>
    {#if !contactData.ref}
        Loading...
    {:else}
    {#if !_get(contactData, 'data.isDeleted', false)}
        <div class="field-section">
            <h1>Contact Name</h1>
            <div class="name-row">
                <div class="name-col">
                    <EditContactProfileField
                        {isDeleted}
                        {orgId}
                        {contactId}
                        profileFieldData={firstNameField}
                        contactFieldData={firstNameContactField}
                    />
                </div>
                <div class="name-col">
                    <EditContactProfileField
                        {isDeleted}
                        {orgId}
                        {contactId}
                        profileFieldData={lastNameField}
                        contactFieldData={lastNameContactField}
                    />
                </div>
            </div>
        </div>
        <div class="field-section">
            <h1>Email Addresses</h1>
            {#each emailFields as emailField, i (emailField.ref)}
                <EditContactProfileField
                    {isDeleted}
                    {orgId}
                    {contactId}
                    profileFieldData={emailField}
                    contactFieldData={emailContactField}
                    labelText="Email {i + 1}"
                />
            {/each}
            <EditContactProfileField
                {isDeleted}
                {orgId}
                {contactId}
                contactFieldData={emailContactField}
                labelText="Add an email address"
                kind="Email"
            />
        </div>
        <div class="field-section">
            <h1>Phone Numbers (SMS)</h1>
            {#each phoneFields as phoneField, i (phoneField.ref)}
                <EditContactProfileField
                    {isDeleted}
                    {orgId}
                    {contactId}
                    profileFieldData={phoneField}
                    contactFieldData={phoneContactField}
                    labelText="Phone number {i + 1}"
                />
            {/each}
            <EditContactProfileField
                {isDeleted}
                {orgId}
                {contactId}
                contactFieldData={phoneContactField}
                labelText="Add a phone number (SMS)"
                kind="Phone number"
            />
        </div>
    {:else}
    <p class="disalbed-alert">
        This contact's record has been deleted and will be permanently deleted
        within 30 days. To modify or message this contact you will need to first
        restore the contact's record.
    </p>
    <div class="deleted-info">
        <div class="deleted-style">
            <div class="status-text">
                <ion-icon name="trash-outline" class="status-icon" />
                <span>Deleted At</span>
            </div>
            <span class="date-text"
                >{contactData.data.deletedAt
                    ? contactData.data.deletedAt.split('T')[0]
                    : ''}</span
            >
        </div>

        <button
            type="button"
            on:click|stopPropagation={() =>
                handleClickRetrieveButton(contactData.ref)}
            class="button"
        >
            <ion-icon name="reload-outline" class="btn-icon" />
            <span class="btn-text"> Restore </span>
        </button>
    </div>
    {/if}
    {/if}
</section>

<style>
    section {
        padding: 30px 20px 45px;
    }
    h1 {
        font-size: 18px;
        line-height: 1.4;
        font-weight: 600;
        margin: 0 0 10px;
        padding: 0;
    }
    .field-section + .field-section {
        margin-top: 20px;
        border-top: 1px solid var(--slate-o10);
        padding-top: 20px;
    }
    .name-row {
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }
    .name-col {
        flex-grow: 1;
        flex-shrink: 0;
        width: 200px;
    }
    .name-col + .name-col {
        margin-left: 20px;
    }
    .disalbed-alert {
        padding: 0px 30px;
        color: red;
        font-size: 24px;
    }
    .deleted-info {
        display: flex;
        padding-left: 30px;
        align-items: center;
    }
    .deleted-style {
        display: flex;
        flex-direction: column;
        width: 115px;
        height: 45px;
        padding: 4px 15px;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 5px;
        color: #d65543;
        background: rgba(214, 67, 67, 0.12);
    }
    .status-text {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px 0px 2px 0px;
    }
    .date-text {
        margin-top: -10px;
        text-decoration: none !important;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
    .status-icon {
        width: 15px;
        height: 15px;
        margin-right: 4px;
    }
    .button {
        margin: 0px 0px 0px 20px;
        padding: 10px 15px!important;
        
    }
    .button:hover {
        color: var(--blue);
    }
    .btn-text{
        font-size: 16px!important;
    }
</style>
