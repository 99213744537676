<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};
    FRAGMENTS.NewForm_form = gql`
        fragment NewForm_form on Form {
            _id
            name
            spec {
                _id
                fields {
                    data {
                        _id
                        contactField {
                            _id
                            name
                        }
                    }
                }
            }
        }
    `;

    const QUERIES = {};
    QUERIES.orgForms = gql`
        query orgForms($orgId: ID!, $size: Int) {
            findOrgByID(id: $orgId) {
                _id
                forms(_size: $size) {
                    data {
                        ...form
                    }
                }
            }
        }
        ${FRAGMENTS.NewForm_form}
    `;

    const MUTATIONS = {};
    MUTATIONS.createForm = gql`
        mutation createForm($form: FormInput!) {
            createForm(data: $form) {
                _id
            }
        }
    `;
    MUTATIONS.createHostedForm = gql`
        mutation createHostedForm($orgId: ID!, $formName: String!) {
            createHostedForm(orgId: $orgId, formName: $formName) {
                _id
            }
        }
    `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getClient, query, mutation} from 'svelte-apollo';
    import {navigate} from 'svelte-routing';
    import _get from 'lodash/get';
    import _take from 'lodash/take';
    import _map from 'lodash/map';
    import {watchQuery} from '../../../svelte-apollo-watch-query';
    import HtmlHeadTitle from '../../../components/HtmlHeadTitle.svelte';
    import OrgPageLayout from '../../../components/OrgPageLayout.svelte';

    export let orgId;

    const client = getClient();
    const createFormMutation = mutation(MUTATIONS.createForm);
    const createHostedFormMutation = mutation(MUTATIONS.createHostedForm);
    const pageTitle = 'Create a New Form';

    let isCreatingForm = false;
    async function createForm(options) {
        if (isCreatingForm) return;
        isCreatingForm = true;
        try {
            let createdFormId;
            if (options && options.isHosted) {
                // let response = await mutate(client, {
                //     mutation: MUTATIONS.createHostedForm,
                //     variables: {
                //         orgId: orgId,
                //         formName: 'Where should we send our pricing details?', // FIXME better name generation
                //     }
                // });
                let response = await createHostedFormMutation({
                    variables: {
                        orgId: orgId,
                        formName: 'Where should we send our pricing details?', // FIXME better name generation
                    },
                });
                createdFormId = response.data.createHostedForm._id;
            } else {
                // let response = await mutate(client, {
                //     mutation: MUTATIONS.createForm,
                //     variables: {
                //         form: {
                //             org: {connect: orgId},
                //             name: 'New Webhook', // FIXME better name generation
                //             draftSpec: {create: {}},
                //         },
                //     },
                // });
                let response = await createFormMutation({
                    variables: {
                        form: {
                            org: {connect: orgId},
                            name: 'New Webhook', // FIXME better name generation
                            draftSpec: {create: {}},
                        },
                    },
                });
                createdFormId = response.data.createForm._id;
            }
            navigate(`/orgs/${orgId}/forms/${createdFormId}`);
        } catch (error) {
            // FIXME handle errors better...
            alert(error.message);
        }
        isCreatingForm = false;
    }
</script>

<HtmlHeadTitle {pageTitle} />

<OrgPageLayout {orgId} title={pageTitle}>
    <section>
        <h1>
            Do you want to create a <strong>Hosted Form</strong> or a
            <strong>Webhook Form</strong>?
        </h1>
        <p style="max-width:600px; margin-bottom: 20px;">
            Choose a <strong>Hosted Form</strong> to get started quickly, or
            choose a <strong>Webhook Form</strong> for more customization. Both form
            types are included with your subscription.
        </p>
        <div class="cols form-options">
            <div class="col">
                <button
                    type="button"
                    class="form-option"
                    on:click={() => createForm({isHosted: true})}
                    disabled={isCreatingForm}
                    class:muted={isCreatingForm}
                >
                    <span class="option-info">
                        <span class="option-heading"> Hosted Form </span>
                        <span class="option-fields">
                            First name, Last name, Email, Phone number
                        </span>
                        <span class="option-features">
                            <span class="option-feature">
                                Set up in under 5️⃣ minutes.
                            </span>
                            <span class="option-feature">
                                Easy as adding a link to your website.
                            </span>
                            <span class="option-feature">
                                Simple functionality.
                            </span>
                            <span class="option-feature">
                                Optimized for 😃and 📈.
                            </span>
                        </span>
                    </span>
                    <span class="option-actions">
                        <span class="button primary">
                            <span class="btn-text"> Create Hosted Form </span>
                        </span>
                    </span>
                </button>
                <p class="form-option-encouragement">
                    Most people are happier with the Hosted Form. If you can add
                    a link to your website, you can set up the hosted form
                    yourself.
                </p>
            </div>
            <div class="col">
                <button
                    type="button"
                    class="form-option"
                    on:click={() => createForm({isHosted: false})}
                    disabled={isCreatingForm}
                    class:muted={isCreatingForm}
                >
                    <span class="option-info">
                        <span class="option-heading"> Webhook Form </span>
                        <span class="option-fields">
                            Customizable form fields
                        </span>
                        <span class="option-features">
                            <span class="option-feature">
                                Design & build a custom form on your website,
                                and point it at our webhook form endpoint.
                            </span>
                            <span class="option-feature">
                                Or send data from 3rd-party services like
                                Typeform or Zapier.
                            </span>
                        </span>
                    </span>
                    <span class="option-actions">
                        <span class="button primary">
                            <span class="btn-text"> Create Webhook Form </span>
                        </span>
                    </span>
                </button>
                <p class="form-option-encouragement">
                    Advanced website builders can choose the DIY Webhook and
                    customize the form fields and form design on their website.
                    If you don't know what this is, you probably don't need it.
                </p>
            </div>
        </div>
        <!-- <div>
            Looking for the best of both worlds? Our design team can work with you
            to build a custom form integrated into your website, at a flat-rate up-front cost.
        </div> -->
    </section>
</OrgPageLayout>

<style>
    section {
        padding: 0 20px;
        font-size: 15px;
        line-height: 20px;
    }
    section h1 {
        font-size: 21px;
        line-height: 28px;
        font-weight: 400;
        margin: 0 0 10px;
    }
    .cols {
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }
    .col {
        flex-grow: 0;
        flex-shrink: 1;
        max-width: 320px;
        display: flex;
        flex-direction: column;
    }
    .col + .col {
        margin-left: 10px;
    }
    .form-option {
        justify-content: flex-start;
        flex-grow: 1;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        background: var(--offwhite);
        background: radial-gradient(
            circle at 50% 120%,
            var(--offwhite) 0%,
            var(--lightblue) 150%
        );
        /* border-radius: 30px; */
        /* box-shadow: 0 30px 20px -30px rgba(0,0,0,0.2); */
        padding: 40px;
        text-align: left;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
    }
    .col + .col .form-option {
        /* background: radial-gradient(circle at 30% 107%, var(--slate-o10) 15%, var(--lightblue) 95%); */
    }
    .option-info {
        flex-grow: 1;
        flex-shrink: 0;
    }
    .option-heading {
        display: block;
        color: var(--blue);
        font-size: 21px;
        line-height: 28px;
        font-weight: 700;
        margin-bottom: 10px;
        margin-top: -3px; /* counter line-height padding? */
    }
    .option-fields {
        display: block;
        margin-bottom: 10px;
        font-weight: 600;
    }
    .option-features {
        display: block;
        padding-left: 18px;
    }
    .option-feature {
        display: list-item;
        list-style: disc outside;
    }
    .option-actions {
        flex-grow: 0;
        flex-shrink: 0;
        margin-top: 20px;
        display: flex;
    }
    .form-option-encouragement {
        flex-grow: 0;
        flex-shrink: 0;
        padding: 10px;
        opacity: 0.5;
        font-size: 12px;
        line-height: 16px;
        margin: 0;
    }
</style>
