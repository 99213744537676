<script>
// import { navigate } from 'svelte-routing';
// import { handleLoginReturn } from '../../auth0';
// import _get from 'lodash/get';

// ATTENTION: This is not handled automatically in auth0.js so the initial auth0Promise
// can handle the loginReturn and only resolve afterward.
// (async function() {
//     const loginData = await handleLoginReturn();
//     let dest = _get(loginData, 'appState.path', '/');
//     if (dest == '/login') {
//         dest = '/';
//     }
//     // if (confirm(`navigate to ${dest}?`)) {
//         // navigate(dest);
//         window.location = dest;
//     // }
// })();
</script>

<svelte:head>
	<title>{__APP_NAME__} • Logging in...</title>
</svelte:head>

Logging in...
