<script>
import { fly } from 'svelte/transition';

export let flipped = false;
</script>

<div class="flipper" class:flipped>
    {#if flipped}
        <div class="face back"
            in:fly={{x: 30, duration: 150, delay: 150}}
            out:fly={{x: 30, duration: 150}}
        >
            <slot name="back" />
        </div>
    {:else}
        <div class="face front"
            in:fly={{x: -30, duration: 150, delay: 150}}
            out:fly={{x: -30, duration: 150}}
        >
            <slot name="front" />
        </div>
    {/if}
</div>

<style>
/* .flipper {
    perspective: 2400px;
    position: relative;
    z-index: 1;
    transition: height 250ms;
    transform-style: preserve-3d;
    overflow: visible !important;
    display: flex;
}
.face {
    backface-visibility: hidden;
    transition: transform 700ms, opacity 700ms;
    width: 100%;
    flex-shrink: 0;
}
.face.front {
    transform: rotateY(0deg) translateZ(0);
    z-index: 2;
}
.face.back {
    transform: translateX(-100%) rotateY(180deg) translateZ(0);
    z-index: 1;
}
.flipper.flipped .face.front,
.face.back {
    opacity: 0;
}
.face.front,
.flipper.flipped .face.back {
    opacity: 1;
}
.flipper.flipped .face.front {
    transform: rotateY(-180deg) translateZ(0);
}
.flipper.flipped .face.back {
    transform: translateX(-100%) rotateY(0deg) translateZ(0);
} */
</style>
