<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};
    FRAGMENTS.EditOrgSmsTollFree_org = gql`
        fragment EditOrgSmsTollFree_org on OrgData {
            smsTollFree {
                number
                order {
                    orderedAt
                    failureReason
                }
                campaignAssignmentStatus
                campaignId
            }
        }
    `;

    const QUERIES = {};
    const MUTATIONS = {};

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getClient} from 'svelte-apollo';
    import _get from 'lodash/get';
    import _defaultsDeep from 'lodash/defaultsDeep';
    import {requestOrgSmsNumber, removeOrgSmsNumber} from '../api';
    import Timestamp from './Timestamp.svelte';
    import PhoneVerificationSvg from './PhoneVerificationSvg.svelte';

    const client = getClient();

    export let orgData = {};
    export let orgId;

    let millisFromIsoString = (isoString) => 1000 * Date.parse(isoString);

    let smsLocalAreaCode = '';

    let isOrdering = false;
    // async function requestSmsTollFree() {
    //     if (isOrdering) return;
    //     isOrdering = true;
    //     try {
    //         let response = await requestOrgSmsNumber(orgData._id, {
    //             tollFree: true,
    //         });
    //         if (response.ok) {
    //             let orgFragmentData = await response.json();
    //             // FIXME Why doesn't writeFragment work here???
    //             // client.writeFragment({
    //             //     id: orgData._id,
    //             //     fragment: FRAGMENTS.EditOrgSmsTollFree_org,
    //             //     fragmentName: 'EditOrgSmsTollFree_org',
    //             //     data: orgFragmentData,
    //             // });
    //             orgData = _defaultsDeep(orgFragmentData, orgData);
    //         } else {
    //             alert("Error requesting a Busness SMS number. Please try again, or contact support.");
    //         }
    //     } catch (error) {
    //         alert(error.message);
    //     }
    //     isOrdering = false;
    // }

    async function requestSmsBusiness() {
        if (isOrdering) return;
        isOrdering = true;
        let areaCode = smsLocalAreaCode.toString();
        if (areaCode.length != 3) {
            alert('Invalid area code.');
            isOrdering = false;
            return;
        }
        try {
            let response = await requestOrgSmsNumber(orgId, {
                areaCode: areaCode,
                tollFree: true,
            });
            if (response.ok) {
                let orgFragmentData = await response.json();
                // FIXME Why doesn't writeFragment work here???
                // client.writeFragment({
                //     id: orgData._id,
                //     fragment: FRAGMENTS.EditOrgSmsLocal_org,
                //     fragmentName: 'EditOrgSmsLocal_org',
                //     data: orgFragmentData,
                // });
                orgData = _defaultsDeep(orgFragmentData, orgData);
            } else {
                alert(
                    'Error requesting a local SMS number. Please try again, or contact support.',
                );
            }
        } catch (error) {
            alert(error.message);
        }
        isOrdering = false;
    }

    let isRemoving = false;
    async function disconnectSmsBusiness() {
        let numberToRemove = _get(orgData, 'smsTollFree.number');
        let message = `The number ${numberToRemove} will be immediately disconnected from this account.`;
        if (!confirm(message)) return;

        if (isRemoving) return;
        isRemoving = true;

        try {
            let response = await removeOrgSmsNumber(orgId, {
                number: numberToRemove,
            });
            if (response.ok) {
                let orgFragmentData = await response.json();
                // FIXME Why doesn't writeFragment work here???
                // client.writeFragment({
                //     id: orgData._id,
                //     fragment: FRAGMENTS.EditOrgSmsTollFree_org,
                //     fragmentName: 'EditOrgSmsTollFree_org',
                //     data: orgFragmentData,
                // });
                orgData = _defaultsDeep(orgFragmentData, orgData);
            } else {
                alert(
                    'Error removing this SMS number. Please try again, or contact support.',
                );
            }
        } catch (error) {
            alert(error.message);
        }
        isRemoving = false;
    }

    const getDisplayPhone = (number) => {
        number = '' + number;
        let countryOrBlank = '';
        if (number.length > 10) {
            countryOrBlank =
                '+' + number.substring(0, number.length - 10) + ' ';
        }
        let area = number.substring(0, 3);
        let start = number.substring(3, 6);
        let end = number.substring(6);
        return `${countryOrBlank}(${area}) ${start}-${end}`;
    };
    $: displayNumber = getDisplayPhone(_get(orgData, 'smsTollFree.number'));
</script>

<div class="fieldrow">
    <!-- svelte-ignore a11y-label-has-associated-control -->
    <label class="fieldlabel"> Business SMS Number </label>
    {#if _get(orgData, 'smsTollFree.number')}
        <p
            class="fieldhelp"
            style="display:flex; width:100%; align-items:flex-start;"
        >
            <span style="flex-grow:0; flex-shrink:0;">
                <ion-icon
                    name="checkmark-done-circle"
                    style="color:var(--blue); font-size:32px; vertical-align:middle;"
                />
            </span>
            <span
                style="flex-grow:1; flex-shrink:1; margin-left:5px; padding-top:5px;"
            >
                You're sending & receiving text messages at...
            </span>
        </p>
        <div class="field-with-tools">
            <div class="fieldcol">
                <input type="text" readonly value={displayNumber} />
                {#if orgData.smsTollFree.number.slice(0, 3) == '800' || orgData.smsTollFree.number.slice(0, 3) == '888' || orgData.smsTollFree.number.slice(0, 3) == '877' || orgData.smsTollFree.number.slice(0, 3) == '866' || orgData.smsTollFree.number.slice(0, 3) == '855' || orgData.smsTollFree.number.slice(0, 3) == '844' || orgData.smsTollFree.number.slice(0, 3) == '833'}
                    <p class="field-alert">
                        Please update this toll-free SMS number with a new one!
                    </p>
                {/if}
            </div>
            <div class="toolcol">
                <PhoneVerificationSvg
                    verificationStatus={orgData.smsTollFree
                        .campaignAssignmentStatus
                        ? 'Verified'
                        : 'Not Verified'}
                    size="20px"
                />
            </div>
            <div class="toolcol">
                <button
                    type="button"
                    on:click|stopPropagation={disconnectSmsBusiness}
                    disabled={isRemoving}
                    class="button danger"
                >
                    <span class="btn-text">
                        {#if isRemoving}
                            Loading...
                        {:else}
                            Disconnect
                        {/if}
                    </span>
                </button>
            </div>
        </div>
    {:else if _get(orgData, 'smsTollFree.order.orderedAt') && !_get(orgData, 'smsTollFree.order.failureReason')}
        <p
            class="fieldhelp"
            style="display:flex; width:100%; align-items:flex-start;"
        >
            <span style="flex-grow:0; flex-shrink:0;">
                <ion-icon
                    name="checkmark-circle-outline"
                    style="color:var(--blue); font-size:32px; vertical-align:middle;"
                />
            </span>
            <span style="flex-grow:1; flex-shrink:1; margin-left:5px;">
                <strong>
                    Requested a business number
                    <Timestamp
                        ts={millisFromIsoString(
                            orgData.smsTollFree.order.orderedAt,
                        )}
                    />.
                </strong>
                We'll email you when your number is assigned. Please contact us if
                your number hasn't been assigned within 2 hours.
            </span>
        </p>
    {:else}
        {#if _get(orgData, 'smsTollFree.order.failureReason') && !isOrdering}
            <p
                class="fieldhelp"
                style="display:flex; width:100%; align-items:flex-start;"
            >
                <span style="flex-grow:0; flex-shrink:0;">
                    <ion-icon
                        name="close-circle"
                        style="color:var(--red); font-size:32px; vertical-align:middle;"
                    />
                </span>
                <span style="flex-grow:1; flex-shrink:1; margin-left:5px;">
                    <strong> Failed to request a business number. </strong>
                    {orgData.smsTollFree.order.failureReason}
                    Please try again, or contact us so we can help out.
                </span>
            </p>
        {/if}
        <label class="fieldhelp" for="smsLocalAreaCode">
            Choose a local area code
        </label>
        <form on:submit|preventDefault={requestSmsBusiness}>
            <div class="field-with-tools">
                <div class="fieldcol">
                    <input
                        bind:value={smsLocalAreaCode}
                        type="tel"
                        id="smsLocalAreaCode"
                        placeholder="555"
                    />
                </div>
                <div class="toolcol">
                    <button
                        type="submit"
                        disabled={isOrdering}
                        class="button primary"
                    >
                        <span class="btn-text">
                            {#if isOrdering}
                                Loading...
                            {:else if _get(orgData, 'smsTollFree.order.failureReason')}
                                Try again
                            {:else}
                                Request a Business number
                            {/if}
                        </span>
                    </button>
                </div>
            </div>
        </form>
    {/if}
</div>

<style>
    .field-with-tools {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: stretch;
    }
    .fieldcol {
        flex-grow: 1;
        flex-shrink: 1;
    }
    .fieldcol input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .toolcol {
        flex-grow: 0;
        flex-shrink: 0;
        padding: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: var(--slate-o10);
        border-radius: 4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .toolcol .button {
        height: 32px;
    }
    .field-alert {
        color: #ff9800;
    }
</style>
