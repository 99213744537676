<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};
    const QUERIES = {};
    const MUTATIONS = {};

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    // import { getClient } from 'svelte-apollo';
    import _get from 'lodash/get';
    import {navigate} from 'svelte-routing';
    // import { watchQuery } from '../../svelte-apollo-watch-query';
    import HtmlHeadTitle from '../../../components/HtmlHeadTitle.svelte';
    import OrgPageLayout from '../../../components/OrgPageLayout.svelte';
    import {createStripeCheckoutSessionForNewSubscription} from '../../../api.js';

    // const client = getClient();
    // const pageTitle = 'Choose a subscription level for your organization';
    const pageTitle = 'Activating your free trial...';

    export let orgId;
    $: orgPath = `/orgs/${orgId}`;

    async function checkout(stripePriceId) {
        try {
            let response = await createStripeCheckoutSessionForNewSubscription(
                orgId,
                stripePriceId,
            );
            let sessionResult = await response.json();

            if (sessionResult.error == 'EXISTING_SUBSCRIPTION') {
                alert(
                    'We found an existing subscription for your account. We will take you to manage your existing subscription.',
                );
                navigate(`${orgPath}/plan/manage`, {replace: true});
                return;
            }

            const stripe = window.Stripe(__STRIPE_PUBLISHABLE_KEY__);
            let checkoutResult = await stripe.redirectToCheckout({
                sessionId: sessionResult.sessionId,
            });
        } catch (error) {
            alert(error.message);
            throw error;
        }
    }
    checkout(__STRIPE_DEFAULT_PRICE_ID__);
</script>

<HtmlHeadTitle {pageTitle} />

<OrgPageLayout {orgId} title={pageTitle}>
    <div class="org-plan">
        <div class="spinner" />
    </div>
    <script defer async src="https://js.stripe.com/v3/"></script>
</OrgPageLayout>

<style>
    .org-plan {
        padding: 0 20px 20px;
    }

    .spinner {
        width: 40px;
        height: 40px;
        margin: 100px auto;
        background-color: var(--slate);

        border-radius: 100%;
        animation: sk-scaleout 1s infinite ease-in-out;
    }
    @keyframes sk-scaleout {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 0;
        }
    }
</style>
