<script context="module">
    const modalPaddingPx = 50;
    export {modalPaddingPx};
</script>

<script>
    import {fly, fade} from 'svelte/transition';
    import MutationInput from './MutationInput.svelte';
    import _trim from 'lodash/trim';
    export let handleEmailSendingModalClose;
    export let codeSnippet;
    export let orgData;

    let modalNode;

    function handleContainerClick(event) {
        let isClickInsideModal =
            modalNode.contains(event.target) && modalNode != event.target;
        if (!isClickInsideModal) {
            handleEmailSendingModalClose();
        }
    }
    function handleEscape(event) {
        if (event.keyCode == 27) {
            handleEmailSendingModalClose();
        }
    }

    let developerEmail = '';
    let isSending = false;

    async function sendCodeSnippetToDeveloper() {
        const formData = new FormData();
        formData.append('from', 'info@leadaction.com');
        formData.append('to', developerEmail);
        formData.append('subject', 'LeadAction Stickybar Magic Script Insert.');
        const emailContent = `<div style="max-width: 600px; margin: auto; padding: 20px; border: 1px solid #ddd; border-radius: 8px;" ><p>Hi there,</p>
        <p>We are providing LeadAction Magic Script that allows you to easily develop the website and install a simple sticky bar to make it simple for your customers to use LeadAction.</p>
        <p>To Install LeadAction Magic Script, place this script at the top of the &lt;head&gt; section just after any &lt;meta&gt; tags.</p>
        <pre><code>${codeSnippet.replace(/\</g, '&lt;').replace(/\>/g, '&gt;')}</code></pre>
        <p>If you encounter any difficulties or have any questions at all, please contact us via this email.</p>
        <p>Thank you,</p>
        <p>${orgData.data.billingFirstName} ${orgData.data.billingLastName}</p></div>`

        formData.append('html', emailContent)
        const url = `https://api.mailgun.net/v3/${_trim(__MAILGUN_DOMAIN_TRANSACTIONAL__)}/messages`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: 'Basic ' + btoa('api:' + _trim(__MAILGUN_API_KEY__)),
                },
                body: formData,
            });

            const data = await response.json();
            alert('Email has been sent to the web developer!')
            handleEmailSendingModalClose();
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Error sending email:', error);
            handleEmailSendingModalClose();
        }
    }
</script>

<svelte:window on:keydown={handleEscape} />

<div
    class="modal-container"
    on:click={handleContainerClick}
    style="padding:{modalPaddingPx}px;"
>
    <div class="modal-backdrop" transition:fade={{duration: 200}} />
    <div class="modal-close">
        <ion-icon name="close-outline" />
        <span class="keyboard-shortcut"> esc </span>
    </div>
    <div
        class="modal"
        bind:this={modalNode}
        transition:fly={{y: 100, duration: 200}}
    >
        <slot />
        <div class="modal-content">
            {#if isSending}<div class="sending">
                    <div class="spinner"></div>
                    <h1>Loading...</h1>
                </div>
            {:else}
                <p>Enter your web developer email here:</p>
                <MutationInput
                    type="email"
                    id="developerEmail"
                    placeholder="abc@xyz.com"
                    bind:value={developerEmail}
                />
                <div class="check-verification-button">
                    <button
                        type="button"
                        class="button primary"
                        on:click={sendCodeSnippetToDeveloper}
                    >
                        <span class="btn-text">
                            Email To My Web Developer
                        </span>
                    </button>
                </div>{/if}
        </div>
    </div>
</div>

<style>
    .modal-container {
        background: rgba(0, 0, 0, 0.01); /* fix scrollbar contrast */
        position: absolute;
        overflow: auto;
        --webkit-overflow-scrolling: touch;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        /* padding: 80px; */
    }
    .modal-backdrop {
        background: var(--offblack-backdrop);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    @supports (backdrop-filter: blur(20px)) {
        .modal-backdrop {
            background: var(--offblack-backdrop-blur);
            backdrop-filter: blur(20px);
            backdrop-filter: saturate(180%) blur(20px);
        }
    }
    .modal-close {
        color: white;
        position: fixed;
        top: 0;
        right: 0;
        width: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }
    .modal-close ion-icon {
        font-size: 48px;
        padding-top: 2px;
    }
    .modal-close .keyboard-shortcut {
        /* border: 1px solid; */
        border-radius: 3px;
        font-size: 12px;
        /* font-family: monospace; */
        font-weight: 400;
        line-height: 14px;
        padding: 0 4px 1px;
        margin-top: -8px;
        opacity: 0.62;
    }
    .modal {
        position: relative;
        display: flex;
        justify-content: center;
    }
    .modal > :global(*) {
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    }
    .modal-content {
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        background: #ffffff;
        color: var(--offblack);
        border-radius: 4px;
        padding: 50px;
        /* width: 60%; */
        /* overflow: hidden; */
    }
    .spinner {
        width: 32px;
        height: 32px;
        border: 8px solid;
        border-color: #3d5af1 transparent #3d5af1 transparent;
        border-radius: 50%;
        animation: rotating-spin 1.2s linear infinite;
        margin: 6px;
    }
    .sending {
        display: flex;
        align-items: center;
    }
    @keyframes rotating-spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .check-verification-button {
        display: flex;
        margin: 10px;
        flex-direction: column-reverse;
    }
</style>
