<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};
    FRAGMENTS.FormSubmissionListItem_contactField = gql`
        fragment FormSubmissionListItem_contactField on ContactField {
            ref
            data {
                name
            }
        }
    `;
    FRAGMENTS.FormSubmissionListItem_form = gql`
        fragment FormSubmissionListItem_form on Form {
            ref
            data {
                name
                title
                isDeleted
            }
        }
    `;
    FRAGMENTS.FormSubmissionListItem_formSubmission = gql`
        fragment FormSubmissionListItem_formSubmission on FormSubmission {
            ref
            data {
                createdAt
                who {
                    ref
                    data {
                        org {
                            ref
                        }
                    }
                }
                form {
                    ...FormSubmissionListItem_form
                }
                fields {
                    formField {
                        ref
                    }
                    contactField {
                        ...FormSubmissionListItem_contactField
                    }
                    valueJson
                }
            }
        }
        ${FRAGMENTS.FormSubmissionListItem_form}
        ${FRAGMENTS.FormSubmissionListItem_contactField}
    `;

    const QUERIES = {};
    const MUTATIONS = {};

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import _get from 'lodash/get';
    import moment from 'moment';
    import {fly} from 'svelte/transition';

    export let formSubmissionData;
    export let isCollapsed = true;

    $: timeHeadingDate = getDateDisplay(
        moment(_get(formSubmissionData, 'data.createdAt')),
    );
    $: timeHeadingTime = moment(
        _get(formSubmissionData, 'data.createdAt'),
    ).format('LT');

    function getDateDisplay(momentObj) {
        if (momentObj.isAfter(moment().startOf('day'))) {
            return momentObj.format('[Today]');
        } else if (
            momentObj.isAfter(moment().subtract({days: 1}).startOf('day'))
        ) {
            return momentObj.format('[Yesterday]');
        } else if (
            momentObj.isAfter(moment().endOf('day').subtract({weeks: 1}))
        ) {
            return momentObj.format('dddd');
        } else if (
            momentObj.isAfter(moment().endOf('day').subtract({years: 1}))
        ) {
            return momentObj.format('ddd, MMM D,');
        } else {
            return momentObj.format('ddd, MMM D, YYYY,');
        }
    }
</script>

<article>
    <header>
        <span class="icon">
            <ion-icon name="clipboard-outline" />
        </span>
        <hgroup>
            <h1 class="title">
                <!-- <a href={`/orgs/${_get(formSubmissionData, 'who.org._id')}/forms/${_get(formSubmissionData, 'form._id')}`}>
                    Submitted
                    {_get(formSubmissionData, 'form.name', 'a form')}
                </a> -->
                <button
                    type="button"
                    on:click|stopPropagation={() => {
                        isCollapsed = !isCollapsed;
                    }}
                >
                    Submitted
                    {#if _get(formSubmissionData, 'data.form.data.isDeleted', false)}
                        <strong style="text-decoration: line-through">
                            ({_get(
                                formSubmissionData,
                                'data.form.data.title',
                                '',
                            )}) &nbsp;Lead Form
                        </strong>
                    {:else}<strong>
                            ({_get(
                                formSubmissionData,
                                'data.form.data.title',
                                '',
                            )}) &nbsp;Lead Form
                        </strong>{/if}
                </button>
                <small class="muted">
                    <a
                        href={`/orgs/${_get(
                            formSubmissionData,
                            'data.who.data.org.ref',
                        )}/forms/${_get(formSubmissionData, 'data.form.ref')}`}
                    >
                        Edit Form
                    </a>
                </small>
            </h1>
            <h2 class="time-heading muted">
                <strong>{timeHeadingDate}</strong>
                {timeHeadingTime}
            </h2>
        </hgroup>
        {#if isCollapsed}
            <button
                type="button"
                on:click|stopPropagation={() => {
                    isCollapsed = !isCollapsed;
                }}
                class="button"
            >
                <ion-icon
                    class="btn-icon"
                    name="add"
                    style="--ionicon-stroke-width:48px;"
                />
                <span class="btn-text">
                    expand {_get(formSubmissionData, 'data.fields.length') ||
                        ''} fields
                </span>
            </button>
        {:else}
            <button
                type="button"
                on:click|stopPropagation={() => {
                    isCollapsed = !isCollapsed;
                }}
                class="button"
            >
                <ion-icon
                    class="btn-icon"
                    name="remove"
                    style="--ionicon-stroke-width:48px;"
                />
                <span class="btn-text">
                    collapse {_get(formSubmissionData, 'data.fields.length') ||
                        ''} fields
                </span>
            </button>
        {/if}
    </header>
    {#if !isCollapsed}
        <div class="fields-container" transition:fly={{y: -50, duration: 200}}>
            {#each formSubmissionData.data.fields as fieldSub (_get(fieldSub, 'formField.ref'))}
                <div class="fieldrow">
                    <label class="fieldlabel">
                        {_get(fieldSub, 'contactField.data.name')}
                    </label>
                    <input
                        type="text"
                        readonly
                        disabled
                        value={JSON.parse(fieldSub.valueJson)}
                    />
                </div>
            {/each}
        </div>
    {/if}
</article>

<style>
    article {
        padding: 20px 20px 20px;
    }
    header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 0 10px;
    }
    header .icon {
        flex-grow: 0;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background: var(--slate);
        color: white;
        text-align: center;
        font-size: 22px;
        line-height: 44px;
        margin-right: 10px;
    }
    header hgroup {
        flex-grow: 1;
        flex-shrink: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .title {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        margin: 0;
    }
    .title button,
    .title a {
        text-decoration: none;
    }
    .title button:hover,
    .title a:hover {
        text-decoration: underline;
    }
    .time-heading {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        margin: 0;
    }
    /* .card {
    background: var(--white);
    border: 1px solid var(--offoffwhite);
    border-radius: 9px;
    padding: 20px;
} */
    .fields-container {
        max-width: 600px;
        margin: 0 auto 20px 50px;
        /* padding-top: 10px;
    padding-bottom: 10px; */
    }
</style>
