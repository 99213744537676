<script context="module">
    import gql from 'graphql-tag';
    import {FRAGMENTS as OrgForm_FRAGMENTS} from '../../components/OrgForm.svelte';

    const FRAGMENTS = {};
    const QUERIES = {};
    const MUTATIONS = {};

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {Router, Route, links, navigate} from 'svelte-routing';
    // import _get from 'lodash/get';
    import Dashboard from './Dashboard.svelte';
    import Users from './Users.svelte';
    import ActionsSequenceTemplates from './ActionsSequenceTemplates.svelte';

    import NotFound from '../NotFound.svelte';
    import SidebarLayout from '../../components/SidebarLayout.svelte';
    import AdminSidebar from '../../components/AdminSidebar.svelte';

    export let orgId;
    export let location;
</script>

<SidebarLayout>
    <div slot="sidebar" style="height:100%;">
        <AdminSidebar {orgId} {location} />
    </div>
    <Router>
        <Route path="dashboard" component={Dashboard} {orgId} />
        <Route path="users" component={Users} {orgId} />
        <Route
            path="actionssequencetemplates"
            component={ActionsSequenceTemplates}
            {orgId}
        />
        <Route path="" component={NotFound} />
    </Router>
</SidebarLayout>

<style>
</style>
