<script context="module">
    import gql from 'graphql-tag';
    import {FRAGMENTS as FormSubmissionListItem_FRAGMENTS} from './FormSubmissionListItem.svelte';

    const FRAGMENTS = {};

    FRAGMENTS.ContactForms_contact = gql`
        fragment ContactForms_contact on Contact {
            ref
            # submissions(_size: 256) {
            #     data {
            #         _id
            #         ...ContactForms_formSubmission
            #     }
            # }
        }
    `;

    const QUERIES = {};
    QUERIES.contactFormSubmissions = gql`
        query contactFormSubmissions($contactId: ID!, $size: Int!) {
            contactFormSubmissions(contactId: $contactId, size: $size) {
                page {
                    before
                    after
                    data {
                        node {
                            ...FormSubmissionListItem_formSubmission
                        }
                    }
                }
                # aggregate {
                #     count
                # }
            }
        }
        ${FormSubmissionListItem_FRAGMENTS.FormSubmissionListItem_formSubmission}
    `;

    const MUTATIONS = {};

    MUTATIONS.retrieveContact = gql`
        mutation retrieveContact($contactId: ID!) {
            retrieveContact(contactId: $contactId) {
                ref
            }
        }
    `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    // import { getContext } from 'svelte';
    import _get from 'lodash/get';
    import _map from 'lodash/map';
    import {getClient, mutation} from 'svelte-apollo';
    import {watchQuery} from '../svelte-apollo-watch-query';
    import Timestamp from './Timestamp.svelte';
    import FormSubmissionListItem from './FormSubmissionListItem.svelte';
    import {modalPaddingPx} from './Modal.svelte';

    export let contactId;
    export let contactData;
    export let sidebarHeightPx = 0;
    export let headerHeightPx = 0;

    // $: contactPath = `/contacts/${contactId}`;
    // $: formSubmissions = _get(contactData, 'submissions.data', []).slice(0).reverse();
    const retrieveContactMutation = mutation(MUTATIONS.retrieveContact);
    const client = getClient();
    const formSubmissionsQuery = watchQuery(client, {
        query: QUERIES.contactFormSubmissions,
        variables: {
            contactId: contactId,
            size: 100,
        },
        pollInterval: 999999,
    });
    $: formSubmissions = _map(
        _get(
            $formSubmissionsQuery,
            'data.contactFormSubmissions.page.data',
            [],
        ),
        'node',
    );

    let scrollContainer;
    const scrollTo = (top) => {
        // console.log('scrollTo', top);
        if (scrollContainer != undefined && scrollContainer != null)
            scrollContainer.scrollTo({top: top, behavior: 'smooth'});
    };
    let initialScrollComplete = false;
    function initScroll() {
        if (!formSubmissions.length) return;
        if (initialScrollComplete) return;
        initialScrollComplete = true;
        requestAnimationFrame(() => scrollTo(999999));
    }
    $: formSubmissions, initScroll();

    let windowHeightPx;
    let containerHeightPx;
    let containerHeightMin = 200;
    $: modalHeightPx = Math.max(
        sidebarHeightPx,
        windowHeightPx - 2 * modalPaddingPx,
    );
    $: containerHeightPx = Math.max(
        containerHeightMin,
        modalHeightPx - headerHeightPx,
    );

    async function handleClickRetrieveButton(contactId) {
        try {
            const response = await retrieveContactMutation({
                variables: {
                    contactId: contactId,
                },
            });
            let tempContactData = contactData;
            tempContactData.data.isDeleted = false;
            tempContactData.data.deletedAt = null;
            contactData = tempContactData;
        } catch (error) {
            console.log(error);
            alert(error);
        }
    }

</script>

<svelte:window bind:innerHeight={windowHeightPx} />
{#if !_get(contactData, 'data.isDeleted', false)}
    <div
        bind:this={scrollContainer}
        class="scroll-container"
        style="height:{containerHeightPx}px; overscroll-behavior:{containerHeightPx ==
        containerHeightMin
            ? 'contain'
            : 'auto'};"
    >
        <ol>
            {#each formSubmissions as submission (submission.ref)}
                <li>
                    <FormSubmissionListItem formSubmissionData={submission} />
                </li>
            {/each}
        </ol>
    </div>
{:else}
    <p class="disalbed-alert">
        This contact's record has been deleted and will be permanently deleted
        within 30 days. To modify or message this contact you will need to first
        restore the contact's record.
    </p>
    <div class="deleted-info">
        <div class="deleted-style">
            <div class="status-text">
                <ion-icon name="trash-outline" class="status-icon" />
                <span>Deleted At</span>
            </div>
            <span class="date-text"
                >{contactData.data.deletedAt
                    ? contactData.data.deletedAt.split('T')[0]
                    : ''}</span
            >
        </div>

        <button
            type="button"
            on:click|stopPropagation={() =>
                handleClickRetrieveButton(contactData.ref)}
            class="button"
        >
            <ion-icon name="reload-outline" class="btn-icon" />
            <span class="btn-text"> Restore </span>
        </button>
    </div>
{/if}

<style>
    .scroll-container {
        min-height: 100px;
        overflow: auto;
        --webkit-overflow-scrolling: touch;
        position: relative;
        z-index: 1;
    }
    ol,
    li {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .disalbed-alert {
        padding: 0px 30px;
        color: red;
        font-size: 24px;
    }
    .deleted-info {
        display: flex;
        padding-left: 30px;
        align-items: center;
    }
    .deleted-style {
        display: flex;
        flex-direction: column;
        width: 115px;
        height: 45px;
        padding: 4px 15px;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 5px;
        color: #d65543;
        background: rgba(214, 67, 67, 0.12);
    }
    .status-text {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px 0px 2px 0px;
    }
    .date-text {
        margin-top: -10px;
        text-decoration: none !important;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
    .status-icon {
        width: 15px;
        height: 15px;
        margin-right: 4px;
    }
    .button {
        margin: 0px 0px 0px 20px;
        padding: 10px 15px!important;
        
    }
    .button:hover {
        color: var(--blue);
    }
    .btn-text{
        font-size: 16px!important;
    }
</style>
