<script context="module">
    import gql from 'graphql-tag';

    const FRAGMENTS = {};
    FRAGMENTS.EditSequenceDelay_sequenceAction = gql`
        fragment EditSequenceDelay_sequenceAction on SequenceAction {
            ref
            data {
                delay {
                    minDelay
                    minDelayUnits
                    allowedDays
                    allowedStartTime {
                        hour
                        minute
                        pm
                    }
                    allowedEndTime {
                        hour
                        minute
                        pm
                    }
                }
            }
        }
    `;

    const QUERIES = {};
    // QUERIES.sequence = gql`
    //     query sequence($id: ID!) {
    //         findSequenceByID(id: $id) {
    //             ...editSeqActsSequence
    //         }
    //     }
    //     ${FRAGMENTS.editSeqActsSequence}
    // `;

    const MUTATIONS = {};
    MUTATIONS.updateSequenceAction = gql`
        mutation updateSequenceAction(
            $id: ID!
            $data: PartialUpdateSequenceActionInput!
        ) {
            partialUpdateSequenceAction(id: $id, data: $data) {
                ...EditSequenceDelay_sequenceAction
            }
        }
        ${FRAGMENTS.EditSequenceDelay_sequenceAction}
    `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {createEventDispatcher} from 'svelte';
    import {getClient, mutation} from 'svelte-apollo';
    import Select from 'svelte-select';
    import _get from 'lodash/get';
    import _has from 'lodash/has';
    import _toLower from 'lodash/toLower';
    import _map from 'lodash/map';
    import _range from 'lodash/range';
    import _keys from 'lodash/keys';

    const client = getClient();
    const updateSequenceActionMutation = mutation(
        MUTATIONS.updateSequenceAction,
    );
    const dispatch = createEventDispatcher();

    export let sequenceActionData;
    // let sequenceDelay = null;
    $: sequenceDelay = _get(sequenceActionData, 'data.delay');

    let hourNumberToString = (n) => n.toString();
    let minuteNumberToString = (n) => (n < 10 ? '0' : '') + n.toString();
    const allowedDaysDisplayText = {
        ANY: 'any day',
        WEEKDAY: 'a weekday',
        WEEKEND: 'a weekend',
        MONDAY: 'a Monday',
        TUESDAY: 'a Tuesday',
        WEDNESDAY: 'a Wednesday',
        THURSDAY: 'a Thursday',
        FRIDAY: 'a Friday',
        SATURDAY: 'a Saturday',
        SUNDAY: 'a Sunday',
    };
    const allowedTimeDisplayText = {
        AT: 'at',
        BETWEEN: 'between',
        ANY: 'at any time of day',
    };

    const timeUnitOptions = 'MINUTES HOURS DAYS NIGHTS WEEKS MONTHS'.split(' ');
    // const allowedDaysOptions = _map(allowedDaysDisplayText, (value, key) => ({
    //     label: value,
    //     value: key,
    // }));
    const allowedDaysOptions = _keys(allowedDaysDisplayText);
    // const allowedTimeOptions = _map(allowedTimeDisplayText, (value, key) => ({
    //     label: value,
    //     value: key,
    // }));
    const allowedTimeOptions = _keys(allowedTimeDisplayText);
    const hourOptions = _map(_range(1, 13), hourNumberToString);
    const minuteOptions = _map(_range(60), minuteNumberToString);
    const amPmOptions = ['AM', 'PM'];

    let minDelayEntry = 0;
    let minDelayUnitsEntry = 'NIGHTS';
    let allowedDaysEntry = 'ANY';
    let allowedStartTimeHourEntry = hourNumberToString(8);
    let allowedStartTimeMinuteEntry = minuteNumberToString(0);
    let allowedStartTimeAmPmEntry = 'AM';
    let allowedEndTimeHourEntry = hourNumberToString(6);
    let allowedEndTimeMinuteEntry = minuteNumberToString(0);
    let allowedEndTimeAmPmEntry = 'PM';
    let timingEntry = 'ANY';

    // Init form data when it loads (from the network).
    let hasInitMinDelay = false;
    let hasInitMinDelayUnits = false;
    let hasInitAllowedDays = false;
    let hasInitAllowedStartTimeHour = false;
    let hasInitAllowedStartTimeMinute = false;
    let hasInitAllowedStartTimeAmPm = false;
    let hasInitAllowedEndTimeHour = false;
    let hasInitAllowedEndTimeMinute = false;
    let hasInitAllowedEndTimeAmPm = false;
    let hasInitTiming = false;
    function initFields() {
        if (!_get(sequenceActionData, 'ref')) return;
        if (_has(sequenceDelay, 'minDelay') && !hasInitMinDelay) {
            minDelayEntry = _get(sequenceDelay, 'minDelay');
            hasInitMinDelay = true;
        }
        if (_has(sequenceDelay, 'minDelayUnits') && !hasInitMinDelayUnits) {
            minDelayUnitsEntry = _get(sequenceDelay, 'minDelayUnits');
            hasInitMinDelayUnits = true;
        }
        if (_has(sequenceDelay, 'allowedDays') && !hasInitAllowedDays) {
            allowedDaysEntry = _get(sequenceDelay, 'allowedDays');
            hasInitAllowedDays = true;
        }
        if (
            _has(sequenceDelay, 'allowedStartTime.hour') &&
            !hasInitAllowedStartTimeHour
        ) {
            allowedStartTimeHourEntry = hourNumberToString(
                _get(sequenceDelay, 'allowedStartTime.hour'),
            );
            hasInitAllowedStartTimeHour = true;
        }
        if (
            _has(sequenceDelay, 'allowedStartTime.minute') &&
            !hasInitAllowedStartTimeMinute
        ) {
            allowedStartTimeMinuteEntry = minuteNumberToString(
                _get(sequenceDelay, 'allowedStartTime.minute'),
            );
            hasInitAllowedStartTimeMinute = true;
        }
        if (
            _has(sequenceDelay, 'allowedStartTime.pm') &&
            !hasInitAllowedStartTimeAmPm
        ) {
            allowedStartTimeAmPmEntry = _get(
                sequenceDelay,
                'allowedStartTime.pm',
            )
                ? 'PM'
                : 'AM';
            hasInitAllowedStartTimeAmPm = true;
        }
        if (
            _has(sequenceDelay, 'allowedEndTime.hour') &&
            !hasInitAllowedEndTimeHour
        ) {
            allowedEndTimeHourEntry = hourNumberToString(
                _get(sequenceDelay, 'allowedEndTime.hour'),
            );
            hasInitAllowedEndTimeHour = true;
        }
        if (
            _has(sequenceDelay, 'allowedEndTime.minute') &&
            !hasInitAllowedEndTimeMinute
        ) {
            allowedEndTimeMinuteEntry = minuteNumberToString(
                _get(sequenceDelay, 'allowedEndTime.minute'),
            );
            hasInitAllowedEndTimeMinute = true;
        }
        if (
            _has(sequenceDelay, 'allowedEndTime.pm') &&
            !hasInitAllowedEndTimeAmPm
        ) {
            allowedEndTimeAmPmEntry = _get(sequenceDelay, 'allowedEndTime.pm')
                ? 'PM'
                : 'AM';
            hasInitAllowedEndTimeAmPm = true;
        }
        if (sequenceDelay && !hasInitTiming) {
            if (_get(sequenceDelay, 'allowedEndTime')) {
                timingEntry = 'BETWEEN';
            } else if (_get(sequenceDelay, 'allowedStartTime')) {
                timingEntry = 'AT';
            } else {
                timingEntry = 'ANY';
            }
            hasInitTiming = true;
        }
    }
    $: sequenceDelay, initFields();

    let isSaving = false;
    export async function saveForm() {
        let updates = {
            minDelay: minDelayEntry, // 0;
            minDelayUnits: minDelayUnitsEntry, // 'NIGHTS';
            allowedDays: allowedDaysEntry, // 'ANY';
            allowedStartTime: {
                hour: Math.round(parseFloat(allowedStartTimeHourEntry)), // hourNumberToString(8);
                minute: Math.round(parseFloat(allowedStartTimeMinuteEntry)), // minuteNumberToString(0);
                pm: allowedStartTimeAmPmEntry == 'PM', // 'AM';
            },
            allowedEndTime: {
                hour: Math.round(parseFloat(allowedEndTimeHourEntry)), // hourNumberToString(6);
                minute: Math.round(parseFloat(allowedEndTimeMinuteEntry)), // minuteNumberToString(0);
                pm: allowedEndTimeAmPmEntry == 'PM', // 'PM';
            },
        };
        if (['ANY', 'AT'].indexOf(timingEntry) >= 0) {
            updates.allowedEndTime = null;
        }
        if ('ANY' == timingEntry) {
            updates.allowedStartTime = null;
        }
        isSaving = true;
        await updateSequenceAction(sequenceActionData.ref, {delay: updates});
        dispatch('save');
        isSaving = false;
    }

    async function updateSequenceAction(id, data) {
        try {
            // let response = await mutate(client, {
            //     mutation: MUTATIONS.updateSequenceAction,
            //     variables: {
            //         id: id,
            //         data: data,
            //     },
            // });
            let response = await updateSequenceActionMutation({
                variables: {
                    id: id,
                    data: data,
                },
            });
            if (!response.error) {
                sequenceActionData.data.delay =
                    response.data.partialUpdateSequenceAction.data.delay;
            }
        } catch (error) {
            // FIXME handle errors better...
            alert(error.message);
        }
    }
</script>

<div>
    <div class="fieldrow cols with-spacing">
        <label class="fieldlabel fixedcol" for="input-minDelay">
            Wait at least
        </label>
        <div class="fixedcol cols">
            <div class="fixedcol minute">
                <input
                    autofocus
                    type="number"
                    bind:value={minDelayEntry}
                    id="input-minDelay"
                    min={0}
                    step={1}
                />
            </div>
            <div class="fixedcol" style="margin-left:6px;">
                <Select
                    items={timeUnitOptions}
                    selectedValue={minDelayUnitsEntry}
                    getOptionLabel={(option, filterText) =>
                        _toLower(option.value)}
                    getSelectionLabel={(option) => _toLower(option.value)}
                    isCreatable={false}
                    isClearable={false}
                    on:select={(event) => {
                        minDelayUnitsEntry = event.detail.value;
                    }}
                    showIndicator={true}
                ></Select>
            </div>
        </div>
    </div>
    <div
        class="fieldrow cols with-spacing"
        style="align-items:flex-start; padding-top:0;"
    >
        <label class="fieldlabel fixedcol" style="margin-top:20px;">
            Continue on
        </label>
        <div class="flexcol cols with-spacing flexwrap">
            <div class="growcol">
                <Select
                    items={allowedDaysOptions}
                    selectedValue={allowedDaysEntry}
                    getOptionLabel={(option, filterText) =>
                        allowedDaysDisplayText[option.value]}
                    getSelectionLabel={(option) =>
                        allowedDaysDisplayText[option.value]}
                    isCreatable={false}
                    isClearable={false}
                    on:select={(event) => {
                        allowedDaysEntry = event.detail.value;
                    }}
                    showIndicator={true}
                ></Select>
            </div>
            <div class="flexcol cols with-spacing flexwrap">
                <div class="growcol">
                    <Select
                        items={allowedTimeOptions}
                        selectedValue={timingEntry}
                        getOptionLabel={(option, filterText) =>
                            allowedTimeDisplayText[option.value]}
                        getSelectionLabel={(option) =>
                            allowedTimeDisplayText[option.value]}
                        isCreatable={false}
                        isClearable={false}
                        on:select={(event) => {
                            timingEntry = event.detail.value;
                        }}
                        showIndicator={true}
                    ></Select>
                </div>
                <div class="flexcol cols with-spacing flexwrap">
                    {#if ['AT', 'BETWEEN'].indexOf(timingEntry) >= 0}
                        <div class="fixedcol cols">
                            <div class="fixedcol hour">
                                <Select
                                    items={hourOptions}
                                    selectedValue={allowedStartTimeHourEntry}
                                    isCreatable={false}
                                    isClearable={false}
                                    on:select={(event) => {
                                        allowedStartTimeHourEntry = _get(
                                            event.detail,
                                            'value',
                                            event.detail,
                                        );
                                    }}
                                    showIndicator={true}
                                ></Select>
                                <!-- <input type="number" min={1} step={1} max={12} bind:value={allowedStartTimeHourEntry} /> -->
                            </div>
                            <div class="fixedcol" style="padding:0 1px;">
                                <strong>:</strong>
                            </div>
                            <div class="fixedcol minute">
                                <Select
                                    items={minuteOptions}
                                    selectedValue={allowedStartTimeMinuteEntry}
                                    isCreatable={false}
                                    isClearable={false}
                                    on:select={(event) => {
                                        allowedStartTimeMinuteEntry =
                                            event.detail.value;
                                    }}
                                    showIndicator={true}
                                ></Select>
                                <!-- <input type="number" min={0} step={1} max={59} bind:value={allowedStartTimeMinuteEntry} /> -->
                            </div>
                            <div class="fixedcol ampm" style="margin-left:6px;">
                                <Select
                                    items={amPmOptions}
                                    selectedValue={allowedStartTimeAmPmEntry}
                                    isCreatable={false}
                                    isClearable={false}
                                    on:select={(event) => {
                                        allowedStartTimeAmPmEntry =
                                            event.detail.value;
                                    }}
                                    showIndicator={true}
                                ></Select>
                            </div>
                        </div>
                    {/if}
                    {#if 'BETWEEN' == timingEntry}
                        <div class="fixedcol">and</div>
                        <div class="fixedcol cols">
                            <div class="fixedcol hour">
                                <Select
                                    items={hourOptions}
                                    selectedValue={allowedEndTimeHourEntry}
                                    isCreatable={false}
                                    isClearable={false}
                                    on:select={(event) => {
                                        allowedEndTimeHourEntry = _get(
                                            event.detail,
                                            'value',
                                            event.detail,
                                        );
                                    }}
                                    showIndicator={true}
                                ></Select>
                                <!-- <input type="number" min={1} step={1} max={12} bind:value={allowedEndTimeHourEntry} /> -->
                            </div>
                            <div class="fixedcol" style="padding:0 1px;">
                                <strong>:</strong>
                            </div>
                            <div class="fixedcol minute">
                                <Select
                                    items={minuteOptions}
                                    selectedValue={allowedEndTimeMinuteEntry}
                                    isCreatable={false}
                                    isClearable={false}
                                    on:select={(event) => {
                                        allowedEndTimeMinuteEntry =
                                            event.detail.value;
                                    }}
                                    showIndicator={true}
                                ></Select>
                                <!-- <input type="number" min={0} step={1} max={59} bind:value={allowedEndTimeMinuteEntry} /> -->
                            </div>
                            <div class="fixedcol ampm" style="margin-left:6px;">
                                <Select
                                    items={amPmOptions}
                                    selectedValue={allowedEndTimeAmPmEntry}
                                    isCreatable={false}
                                    isClearable={false}
                                    on:select={(event) => {
                                        allowedEndTimeAmPmEntry =
                                            event.detail.value;
                                    }}
                                    showIndicator={true}
                                ></Select>
                            </div>
                        </div>
                    {/if}
                </div>
            </div>
        </div>
    </div>
</div>

<style>
    .fieldlabel.fixedcol {
        font-weight: 400;
        width: 90px;
        padding: 0;
    }
    .cols {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
    }
    .cols .cols {
        width: auto;
    }
    .flexwrap {
        flex-wrap: wrap;
    }
    .fixedcol {
        flex-grow: 0;
        flex-shrink: 0;
    }
    .flexcol {
        flex-grow: 1;
        flex-shrink: 1;
    }
    .growcol {
        flex-grow: 1;
        flex-shrink: 0;
    }
    .flexwrap > * {
        margin-top: 10px;
    }
    .flexwrap > .flexwrap {
        margin-top: 0;
    }
    .cols.with-spacing > * + * {
        margin-left: 20px;
    }
    .fixedcol.hour,
    .fixedcol.minute {
        width: 80px;
    }
    .fixedcol.ampm {
        width: 90px;
    }
</style>
