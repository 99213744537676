<script context="module">
    import gql from 'graphql-tag';
    import {FRAGMENTS as EditOrgSmsLocal_FRAGMENTS} from './EditOrgSmsLocal.svelte';
    import {FRAGMENTS as EditOrgSmsTollFree_FRAGMENTS} from './EditOrgSmsTollFree.svelte';
    import {FRAGMENTS as OrgSubscription_FRAGMENTS} from './OrgSubscription.svelte';
    const FRAGMENTS = {};
    FRAGMENTS.OrgForm_org = gql`
        fragment OrgForm_org on OrgData {
            slug
            logoHandle
            name
            billingFirstName
            billingLastName
            billingPhone
            timeZone
            ...EditOrgSmsLocal_org
            ...EditOrgSmsTollFree_org
            subscriptions {
                ...OrgSubscription_subscription
            }
            verificationStatus
            brandRegistrationStatus {
                brandStatus
            }
            country
            state
            city
            street
            postalCode
            callForwardStatus {
                status
                forwardingNumber
            }
        }
        ${EditOrgSmsLocal_FRAGMENTS.EditOrgSmsLocal_org}
        ${EditOrgSmsTollFree_FRAGMENTS.EditOrgSmsTollFree_org}
        ${OrgSubscription_FRAGMENTS.OrgSubscription_subscription}
    `;

    const QUERIES = {};

    const MUTATIONS = {};
    MUTATIONS.createOrg = gql`
        mutation createOrg($data: CreateOrgInput!, $auth0_id: String!) {
            createOrg(data: $data, auth0_id: $auth0_id) {
                ref
                data {
                    ...OrgForm_org
                }
            }
        }
        ${FRAGMENTS.OrgForm_org}
    `;
    MUTATIONS.updateOrg = gql`
        mutation updateOrg($orgId: ID!, $data: PartialUpdateOrgInput!) {
            updateOrg(orgId: $orgId, data: $data) {
                ref
                data {
                    ...OrgForm_org
                }
            }
        }
        ${FRAGMENTS.OrgForm_org}
    `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {createEventDispatcher, onMount} from 'svelte';
    import {getClient, mutation} from 'svelte-apollo';
    import _get from 'lodash/get';
    import _has from 'lodash/has';
    import _kebabCase from 'lodash/kebabCase';
    import _uniq from 'lodash/uniq';
    import _keys from 'lodash/keys';
    import _filter from 'lodash/filter';
    import moment from 'moment';
    import moment_tz from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.js';
    import Select from 'svelte-select';
    import * as filestack from 'filestack-js';
    import MutationInput from './MutationInput.svelte';
    import LogoPreview from './LogoPreview.svelte';
    import OrgSlugAvailability from './OrgSlugAvailability.svelte';
    import EditOrgSmsLocal from './EditOrgSmsLocal.svelte';
    import EditOrgSmsTollFree from './EditOrgSmsTollFree.svelte';
    import OrgSubscription from './OrgSubscription.svelte';
    import BrandRegistrationModal from './BrandRegistrationModal.svelte';
    import CallForwardingVerificationModal from './CallForwardingVerificationModal.svelte';
    import {newAccountCreationDetails} from '../api';
    import Toggle from './Toggle.svelte';
    import {
        updateCallForwardSetting,
        sendCallForwardingVerificationCode,
    } from '../api';
    const dispatch = createEventDispatcher();
    // const client = getClient();
    const pageTitle = 'Org Settings';

    export let orgData = {};
    export let orgId;
    export let isNewOrg = false;
    export let userEmail;
    export let auth0_id;

    const client = getClient();

    const updateOrgMutation = mutation(MUTATIONS.updateOrg);
    const createOrgMutation = mutation(MUTATIONS.createOrg);

    let orgPath;
    $: orgPath = `/orgs/${orgId}`;
    let activeSubscriptions = [];
    $: activeSubscriptions = _filter(
        _get(orgData, 'subscriptions'),
        (sub) => !sub.ended_at,
    );

    $: callForwardStatus = _get(orgData, 'callForwardStatus.status', false);

    let forwardingNumberEntry = '';
    let slugEntry = '';
    let nameEntry = '';
    let billingFirstNameEntry = '';
    let billingLastNameEntry = '';
    let billingPhoneEntry = '';
    let logoHandleEntry = '';
    let timeZoneEntry = '';
    let countryEntry = '';
    const countryList = ['US', 'CA'];
    let stateList = [];
    let stateEntry = '';
    let cityEntry = '';
    let streetEntry = '';
    let postalCodeEntry = '';

    let verificationStatus = '';

    // Init form data when it loads (from the network).
    let hasInitSlug = false;
    let hasInitName = false;
    let hasInitBillingFirstName = false;
    let hasInitBillingLastName = false;
    let hasInitPhone = false;
    let hasInitLogoHandle = false;
    let hasInitTimeZone = false;
    let hasInitCountry = false;
    let hasInitState = false;
    let hasInitCity = false;
    let hasInitStreet = false;
    let hasInitPostalCode = false;
    let hasInitForwardingNumber = false;
    function updateStateList(countryEntry) {
        if (countryEntry === 'US') {
            stateList = [
                'AL',
                'AK',
                'AZ',
                'AR',
                'AS',
                'CA',
                'CO',
                'CT',
                'DE',
                'DC',
                'FL',
                'GA',
                'GU',
                'HI',
                'ID',
                'IL',
                'IN',
                'IA',
                'KS',
                'KY',
                'LA',
                'ME',
                'MD',
                'MA',
                'MI',
                'MN',
                'MS',
                'MO',
                'MT',
                'NE',
                'NV',
                'NH',
                'NJ',
                'NM',
                'NY',
                'NC',
                'ND',
                'MP',
                'OH',
                'OK',
                'OR',
                'PA',
                'PR',
                'RI',
                'SC',
                'SD',
                'TN',
                'TX',
                'TT',
                'UT',
                'VT',
                'VA',
                'VI',
                'WA',
                'WV',
                'WI',
                'WY',
            ];
        } else if (countryEntry === 'CA') {
            stateList = [
                'AB',
                'BC',
                'MB',
                'NB',
                'NL',
                'NT',
                'NS',
                'NU',
                'ON',
                'PE',
                'QC',
                'SK',
                'YT',
            ];
        } else {
            stateList = [];
        }
    }
    function initFormFields() {
        if (!orgId) return;
        if (_has(orgData, 'slug') && !hasInitSlug) {
            slugEntry = orgData.slug;
            hasInitSlug = true;
        }
        if (_has(orgData, 'name') && !hasInitName) {
            nameEntry = orgData.name;
            hasInitName = true;
        }
        if (_has(orgData, 'billingFirstName') && !hasInitBillingFirstName) {
            billingFirstNameEntry = orgData.billingFirstName;
            hasInitBillingFirstName = true;
        }
        if (_has(orgData, 'billingLastName') && !hasInitBillingLastName) {
            billingLastNameEntry = orgData.billingLastName;
            hasInitBillingLastName = true;
        }
        if (_has(orgData, 'billingPhone') && !hasInitPhone) {
            billingPhoneEntry = orgData.billingPhone;
            hasInitPhone = true;
        }
        if (_has(orgData, 'logoHandle') && !hasInitLogoHandle) {
            logoHandleEntry = orgData.logoHandle;
            hasInitLogoHandle = true;
        }
        if (_has(orgData, 'timeZone') && !hasInitTimeZone) {
            timeZoneEntry = orgData.timeZone;
            hasInitTimeZone = true;
        }
        if (_has(orgData, 'country') && !hasInitCountry) {
            countryEntry = orgData.country;
            updateStateList(countryEntry);
            hasInitCountry = true;
        }
        if (_has(orgData, 'state') && !hasInitState) {
            stateEntry = orgData.state;
            hasInitState = true;
        }
        if (_has(orgData, 'city') && !hasInitCity) {
            cityEntry = orgData.city;
            hasInitCity = true;
        }
        if (_has(orgData, 'street') && !hasInitStreet) {
            streetEntry = orgData.street;
            hasInitStreet = true;
        }
        if (_has(orgData, 'postalCode') && !hasInitPostalCode) {
            postalCodeEntry = orgData.postalCode;
            hasInitPostalCode = true;
        }
        if (_has(orgData, 'verificationStatus')) {
            verificationStatus = orgData.verificationStatus;
        }
        if (
            _has(orgData, 'callForwardStatus.forwardingNumber') &&
            !hasInitForwardingNumber
        ) {
            forwardingNumberEntry = orgData.callForwardStatus.forwardingNumber;
            hasInitForwardingNumber = true;
        }
    }
    $: orgData, orgId, initFormFields();

    let slugSaving = null;
    let nameSaving = null;
    let billingFirstNameSaving = null;
    let billingLastNameSaving = null;
    let billingPhoneSaving = null;
    let logoHandleSaving = null;
    let timeZoneSaving = null;
    let countrySaving = null;
    let stateSaving = null;
    let citySaving = null;
    let streetSaving = null;
    let postalCodeSaving = null;

    let isVerifying = false;

    let UNSAVED = {};
    let SAVED = {};
    let SAVING = {};
    // Treat falsey values as equal.
    const entriesEqual = (a, b) => (a || '') == (b || '');
    $: formStatus =
        isNewOrg ||
        !entriesEqual(
            slugEntry,
            slugSaving === null ? _get(orgData, 'slug') : slugSaving,
        ) ||
        !entriesEqual(
            nameEntry,
            nameSaving === null ? _get(orgData, 'name') : nameSaving,
        ) ||
        !entriesEqual(
            billingFirstNameEntry,
            billingFirstNameSaving === null
                ? _get(orgData, 'billingFirstName')
                : billingFirstNameSaving,
        ) ||
        !entriesEqual(
            billingLastNameEntry,
            billingLastNameSaving === null
                ? _get(orgData, 'billingLastName')
                : billingLastNameSaving,
        ) ||
        !entriesEqual(
            billingPhoneEntry,
            billingPhoneSaving === null
                ? _get(orgData, 'billingPhone')
                : billingPhoneSaving,
        ) ||
        !entriesEqual(
            logoHandleEntry,
            logoHandleSaving === null
                ? _get(orgData, 'logoHandle')
                : logoHandleSaving,
        ) ||
        !entriesEqual(
            timeZoneEntry,
            timeZoneSaving === null
                ? _get(orgData, 'timeZone')
                : timeZoneSaving,
        ) ||
        !entriesEqual(
            countryEntry,
            countrySaving === null ? _get(orgData, 'country') : countrySaving,
        ) ||
        !entriesEqual(
            stateEntry,
            stateSaving === null ? _get(orgData, 'state') : stateSaving,
        ) ||
        !entriesEqual(
            cityEntry,
            citySaving === null ? _get(orgData, 'city') : citySaving,
        ) ||
        !entriesEqual(
            streetEntry,
            streetSaving === null ? _get(orgData, 'street') : streetSaving,
        ) ||
        !entriesEqual(
            postalCodeEntry,
            postalCodeSaving === null
                ? _get(orgData, 'postalCode')
                : postalCodeSaving,
        )
            ? UNSAVED
            : entriesEqual(slugEntry, _get(orgData, 'slug')) &&
                entriesEqual(nameEntry, _get(orgData, 'name')) &&
                entriesEqual(
                    billingFirstNameEntry,
                    _get(orgData, 'billingFirstName'),
                ) &&
                entriesEqual(
                    billingLastNameEntry,
                    _get(orgData, 'billingLastName'),
                ) &&
                entriesEqual(
                    billingPhoneEntry,
                    _get(orgData, 'billingPhone'),
                ) &&
                entriesEqual(logoHandleEntry, _get(orgData, 'logoHandle')) &&
                entriesEqual(timeZoneEntry, _get(orgData, 'timeZone')) &&
                entriesEqual(countryEntry, _get(orgData, 'country')) &&
                entriesEqual(stateEntry, _get(orgData, 'state')) &&
                entriesEqual(cityEntry, _get(orgData, 'city')) &&
                entriesEqual(streetEntry, _get(orgData, 'street')) &&
                entriesEqual(postalCodeEntry, _get(orgData, 'postalCode'))
              ? SAVED
              : SAVING;

    function suggestSlugs(name) {
        return _uniq([
            _kebabCase(name).replace(/-/g, ''),
            _kebabCase(name).replace(/-/g, '.'),
            _kebabCase(name),
        ]);
    }
    $: suggestedSlugs = nameEntry ? suggestSlugs(nameEntry) : [];

    const timeZoneOptions = moment.tz.names();
    const guessedZone = moment.tz.guess();

    let savingData;
    async function saveForm() {
        if (formStatus != UNSAVED) return;

        let updates = {};
        savingData = updates;

        slugEntry = validateAndModifySlug(slugEntry);
        
        let optimisticSlug = slugSaving === null ? orgData.slug : slugSaving;
        let slugDirty =
            (hasInitSlug || isNewOrg) && slugEntry != optimisticSlug;
        if (slugDirty) updates.slug = slugEntry;
        slugSaving = slugDirty ? slugEntry : null;

        let optimisticName = nameSaving === null ? orgData.name : nameSaving;
        let nameDirty =
            (hasInitName || isNewOrg) && nameEntry != optimisticName;
        if (nameDirty) updates.name = nameEntry;
        nameSaving = nameDirty ? nameEntry : null;

        let optimisticBillingFirstName =
            billingFirstNameSaving === null
                ? orgData.billingFirstName
                : billingFirstNameSaving;
        let billingFirstNameDirty =
            (hasInitBillingFirstName || isNewOrg) &&
            billingFirstNameEntry != optimisticBillingFirstName;
        if (billingFirstNameDirty)
            updates.billingFirstName = billingFirstNameEntry;
        billingFirstNameSaving = billingFirstNameDirty
            ? billingFirstNameEntry
            : null;

        let optimisticBillingLastName =
            billingLastNameSaving === null
                ? orgData.billingLastName
                : billingLastNameSaving;
        let billingLastNameDirty =
            (hasInitBillingLastName || isNewOrg) &&
            billingLastNameEntry != optimisticBillingLastName;
        if (billingLastNameDirty)
            updates.billingLastName = billingLastNameEntry;
        billingLastNameSaving = billingLastNameDirty
            ? billingLastNameEntry
            : null;

        let optimisticPhone =
            billingPhoneSaving === null
                ? orgData.billingPhone
                : billingPhoneSaving;
        let billingPhoneDirty =
            (hasInitPhone || isNewOrg) && billingPhoneEntry != optimisticPhone;
        if (billingPhoneDirty) updates.billingPhone = billingPhoneEntry;
        billingPhoneSaving = billingPhoneDirty ? billingPhoneEntry : null;

        let optimisticLogoHandle =
            logoHandleSaving === null ? orgData.logoHandle : logoHandleSaving;
        let logoHandleDirty =
            (hasInitLogoHandle || isNewOrg) &&
            logoHandleEntry != optimisticLogoHandle;
        if (logoHandleDirty) updates.logoHandle = logoHandleEntry;
        logoHandleSaving = logoHandleDirty ? logoHandleEntry : null;

        let optimisticTimeZone =
            timeZoneSaving === null ? orgData.timeZone : timeZoneSaving;
        let timeZoneDirty =
            (hasInitTimeZone || isNewOrg) &&
            timeZoneEntry != optimisticTimeZone;
        if (timeZoneDirty) updates.timeZone = timeZoneEntry;
        timeZoneSaving = timeZoneDirty ? timeZoneEntry : null;

        let optimisticCountry =
            countrySaving === null ? orgData.country : countrySaving;
        let countryDirty =
            (hasInitCountry || isNewOrg) && countryEntry != optimisticCountry;
        if (countryDirty) updates.country = countryEntry;
        countrySaving = countryDirty ? countryEntry : null;

        let optimisticState =
            stateSaving === null ? orgData.state : stateSaving;
        let stateDirty =
            (hasInitState || isNewOrg) && stateEntry != optimisticState;
        if (stateDirty) updates.state = stateEntry;
        stateSaving = stateDirty ? stateEntry : null;

        let optimisticCity = citySaving === null ? orgData.city : citySaving;
        let cityDirty =
            (hasInitCity || isNewOrg) && cityEntry != optimisticCity;
        if (cityDirty) updates.city = cityEntry;
        citySaving = cityDirty ? cityEntry : null;

        let optimisticStreet =
            streetSaving === null ? orgData.street : streetSaving;
        let streetDirty =
            (hasInitStreet || isNewOrg) && streetEntry != optimisticStreet;
        if (streetDirty) updates.street = streetEntry;
        streetSaving = streetDirty ? streetEntry : null;

        let optimisticPostalCode =
            postalCodeSaving === null ? orgData.postalCode : postalCodeSaving;
        let postalCodeDirty =
            (hasInitPostalCode || isNewOrg) &&
            postalCodeEntry != optimisticPostalCode;
        if (postalCodeDirty) updates.postalCode = postalCodeEntry;
        postalCodeSaving = postalCodeDirty ? postalCodeEntry : null;

        let maybeClearSavingState = () => {
            if (savingData === updates) {
                slugSaving = null;
                nameSaving = null;
                billingFirstNameSaving = null;
                billingLastNameSaving = null;
                billingPhoneSaving = null;
                logoHandleSaving = null;
                timeZoneSaving = null;
                countrySaving = null;
                stateSaving = null;
                citySaving = null;
                streetSaving = null;
                postalCodeSaving = null;
                savingData = null;
            }
        };

        if (!_keys(updates).length) {
            maybeClearSavingState();
            return;
        }

        if (isNewOrg) {
            try {
                let result = await createOrg(updates);
                dispatch('save', result);
            } catch (error) {
                // Don't dispatch 'save'
            }
        } else {
            try {
                let result = await updateOrg(orgId, updates);
                dispatch('save', result);
            } catch (error) {
                // Don't dispatch 'save'
            }
        }

        maybeClearSavingState();
    }
    async function saveLogoHandle() {
        if (isNewOrg) return;
        if (formStatus != UNSAVED) return;
        let updates = {};
        savingData = updates;

        let optimisticLogoHandle =
            logoHandleSaving === null ? orgData.logoHandle : logoHandleSaving;
        let logoHandleDirty =
            (hasInitLogoHandle || isNewOrg) &&
            logoHandleEntry != optimisticLogoHandle;
        if (logoHandleDirty) updates.logoHandle = logoHandleEntry;
        logoHandleSaving = logoHandleDirty ? logoHandleEntry : null;

        let maybeClearSavingState = () => {
            if (savingData === updates) {
                logoHandleSaving = null;
                savingData = null;
            }
        };

        if (!_keys(updates).length) {
            maybeClearSavingState();
            return;
        }

        try {
            let result = await updateOrg(orgId, updates);
            dispatch('save', result);
        } catch (error) {
            // Don't dispatch 'save'
        }

        maybeClearSavingState();
    }
    // Save only the logo, not the whole form. Some of the settings affect their public profile,
    // so we don't want to save EVERYTHING in case they're not ready.
    $: logoHandleEntry, saveLogoHandle();

    function openVerificationModal() {
        isVerifying = true;
    }

    function handleBrandModalClose() {
        isVerifying = false;
    }
    let isCallForwardOrdering = false;
    let isCallForwardingModalOpen = false;

    async function handleCallForwardingButton() {
        if (!!forwardingNumberEntry) {
            openCallForwardingVerificationModal();
        } else alert('Please input correct phone number.');
    }

    function openCallForwardingVerificationModal() {
        isCallForwardingModalOpen = true;
    }

    function handleCallForwardingModalClose() {
        isCallForwardingModalOpen = false;
    }
    async function handleToggle() {
        if (!callForwardStatus) {
            if (!!forwardingNumberEntry) {
                openCallForwardingVerificationModal();
            } else alert('Please input correct phone number.');
        } else {
            await sendCallForwardingOrder(
                orgId,
                forwardingNumberEntry,
                callForwardStatus,
            );
        }
    }

    async function sendCallForwardingOrder(id, number, status) {
        isCallForwardOrdering = true;
        const response = await updateCallForwardSetting(id, number, !status);
        const responseData = await response.json();
        if (response.ok) {
            alert(
                status
                    ? 'Call forwarding to your primary phone number was successfully canceled.'
                    : 'Call forwarding to your primary phone number was successful.',
            );
            let tempOrgData = orgData ? orgData : {};
            tempOrgData.callForwardStatus =
                responseData.orgData.callForwardStatus;
            // tempOrgData.callForwardStatus.forwardingNumber =
            //     responseData.orgData.callForwardStatus.forwardingNumber;
            forwardingNumberEntry =
                responseData.orgData.callForwardStatus.forwardingNumber;
            orgData = tempOrgData;
        } else {
            const errorMessage = responseData.errorMessage;
            alert(errorMessage);
        }
        isCallForwardOrdering = false;
    }

    async function createOrg(data) {
        try {
            // let response = await client.mutate({
            //     mutation: MUTATIONS.createOrg,
            //     variables: {
            //         data: data,
            //         auth0_id,
            //     },
            // });
            let response = await createOrgMutation({
                variables: {
                    data: data,
                    auth0_id,
                },
            });
            // client.writeFragment({
            //     id: response.data.createOrg._id,
            //     fragment: FRAGMENTS.OrgForm_org,
            //     fragmentName: 'OrgForm_org',
            //     data: response.data.createOrg,
            // });
            const newOrgId = response.data.createOrg.ref;
            newAccountCreationDetails(newOrgId);
            return response.data.createOrg;
        } catch (error) {
            // FIXME handle errors better...
            alert(error.message);
            throw error;
        }
    }
    async function updateOrg(id, data) {
        try {
            // let response = await client.mutate({
            //     mutation: MUTATIONS.updateOrg,
            //     variables: {
            //         orgId: id,
            //         data: data,
            //     },
            // });
            let response = await updateOrgMutation({
                variables: {
                    orgId: id,
                    data: data,
                },
            });
            orgData = response.data.updateOrg.data;
            return response.data.updateOrg;
        } catch (error) {
            // FIXME handle errors better...
            alert(error.message);
            throw error;
        }
    }

    let blockEnterKey = (event) => {
        if (event.key == 'Enter') event.preventDefault();
    };

    const filestackClient = filestack.init(__FILESTACK_API_KEY__);
    function handleUploadLogo() {
        filestackClient
            .picker({
                // See options here: https://filestack.github.io/filestack-js/interfaces/pickeroptions.html
                fromSources: [
                    'local_file_system',
                    'url',
                    'imagesearch',
                    'googledrive',
                    'picasa',
                    'dropbox',
                ],
                accept: ['image/*'],
                globalDropZone: true,
                // disableTransformer: true,
                maxFiles: 1,
                onFileUploadFinished: async (data) => {
                    // console.log('onFileUploadFinished', data);
                    // data = {
                    //   "filename": "myfile.png",
                    //   "handle": "AFrHW1QRsWxmu5ZLU2qg",
                    //   "mimetype": "image/png",
                    //   "originalPath": "picker_transformation.png",
                    //   "size": 1277297,
                    //   "source": "local_file_system",
                    //   "url": "https://cdn.filestackcontent.com/AFrHW1QRsWxmu5ZLU2qg",
                    //   "uploadId": "cfcc198e63b7328c17f09f1af519fcdf",
                    //   "originalFile": {
                    //     "name": "myfile",
                    //     "type": "image/png",
                    //     "size": 1277297
                    //   },
                    //   "status": "Stored"
                    // }
                    logoHandleEntry = data.handle;
                },
                onUploadDone: (data) => console.log('onUploadDone', data),
                startUploadingWhenMaxFilesReached: true,
                supportEmail: __APP_HELP_EMAIL__,
                // transformations: {
                // See https://filestack.github.io/filestack-js/interfaces/pickertransformationoptions.html
                // },
                allowManualRetry: true,
            })
            .open();
    }

    function validateAndModifySlug(slug) {
        let modifiedSlug = slug.replace(/\s+/g, '');
        modifiedSlug = modifiedSlug.replace(/[^a-zA-Z0-9.-]/g, '_');
        modifiedSlug = modifiedSlug.replace(
            /^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g,
            '',
        );

        return modifiedSlug;
    }
</script>

<form on:submit|preventDefault={saveForm} class:muted={!orgId && !isNewOrg}>
    {#if isVerifying}
        <BrandRegistrationModal
            {handleBrandModalClose}
            {orgData}
            {orgId}
            {userEmail}
        />
    {:else}<section class="card">
            <h1>
                Public Profile
                <div style="float:right; font-weight:400; font-size:14px;">
                    <span class="muted">
                        {#if formStatus == UNSAVED}
                            Not saved.
                        {:else if formStatus == SAVING}
                            Saving...
                        {:else if formStatus == SAVED}
                            All changes saved.
                        {/if}
                    </span>
                    {#if !isNewOrg}
                        <span style="display:inline-block; margin-left:10px;">
                            <button type="submit" class="button">
                                <span class="btn-text"> Save Changes </span>
                            </button>
                        </span>
                    {/if}
                </div>
            </h1>
            <div class="fieldrow">
                <label class="fieldlabel" for="nameEntry">
                    What's the name of your business or organization?
                </label>
                <MutationInput
                    type="text"
                    id="nameEntry"
                    placeholder="The Avengers"
                    bind:value={nameEntry}
                    savingValue={nameSaving}
                    savedValue={_get(orgData, 'name')}
                    on:keydown={isNewOrg ? blockEnterKey : null}
                />
            </div>
            <div class="fieldrow">
                <label class="fieldlabel" for="slugEntry">
                    Choose an Organization ID
                </label>
                <p class="fieldhelp">
                    Your Organization ID is a unique identifier composed of
                    letters, numbers, periods, or dashes.
                    {#if (suggestedSlugs || []).length && !_get(orgData, 'slug')}
                        Suggested:
                        {#each suggestedSlugs as slug, i (slug)}
                            {#if i > 0}
                                ,
                            {/if}
                            <button
                                type="button"
                                on:click={() => (slugEntry = slug)}
                                class="link"
                            >
                                {slug}
                            </button>
                        {/each}
                    {/if}
                </p>
                <p class="fieldhelp muted">
                    <strong>{validateAndModifySlug(slugEntry) || '___'}</strong
                    >@leadactionmail.com
                </p>
                <div class="field-with-tools">
                    <div class="fieldcol">
                        <!-- pattern="[a-zA-Z0-9._-]*" -->

                        <MutationInput
                            type="text"
                            id="slugEntry"
                            placeholder="avengers"
                            bind:value={slugEntry}
                            savingValue={slugSaving}
                            savedValue={_get(orgData, 'slug')}
                            on:keydown={isNewOrg ? blockEnterKey : null}
                        />
                    </div>
                    <div class="toolcol">
                        {#if slugEntry != _get(orgData, 'slug')}
                            <div style="flex-grow:0; flex-shrink:0">
                                <OrgSlugAvailability slug={slugEntry} />
                            </div>
                        {/if}
                        {#if _get(orgData, 'slug') && slugEntry != _get(orgData, 'slug')}
                            <div style="flex-grow:0; flex-shrink:0">
                                <button
                                    type="button"
                                    on:click={() =>
                                        (slugEntry = _get(orgData, 'slug'))}
                                    class="button"
                                >
                                    <span class="btn-text"> Cancel </span>
                                </button>
                            </div>
                        {/if}
                    </div>
                </div>
                {#if slugEntry}
                    <p class="fieldhelp muted">
                        Your Organization ID will be set as
                        <strong>{validateAndModifySlug(slugEntry)}</strong>.
                    </p>
                {/if}
            </div>
            <div class="fieldrow">
                <label class="fieldlabel" for="input-timeZone"> Logo </label>
                <div class:muted={logoHandleSaving}>
                    <div class="cols">
                        <div class="col">
                            <LogoPreview fileHandle={logoHandleEntry} />
                        </div>
                        <div class="col">
                            <div>
                                <button
                                    type="button"
                                    class="button"
                                    on:click={handleUploadLogo}
                                >
                                    <ion-icon
                                        name="arrow-up"
                                        class="btn-icon"
                                    />
                                    <span class="btn-text">
                                        Upload New Logo
                                    </span>
                                </button>
                            </div>
                            {#if logoHandleEntry}
                                <div style="margin-top:10px;">
                                    <button
                                        type="button"
                                        class="button danger"
                                        on:click={() => {
                                            logoHandleEntry = '';
                                        }}
                                    >
                                        <ion-icon
                                            name="close"
                                            class="btn-icon"
                                        />
                                        <span class="btn-text">
                                            Remove Logo
                                        </span>
                                    </button>
                                </div>
                            {/if}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="card">
            <h1>
                Action Sequences
                <div style="float:right; font-weight:400; font-size:14px;">
                    <span class="muted">
                        {#if formStatus == UNSAVED}
                            Not saved.
                        {:else if formStatus == SAVING}
                            Saving...
                        {:else if formStatus == SAVED}
                            All changes saved.
                        {/if}
                    </span>
                    {#if !isNewOrg}
                        <span style="display:inline-block; margin-left:10px;">
                            <button type="submit" class="button">
                                <span class="btn-text"> Save Changes </span>
                            </button>
                        </span>
                    {/if}
                </div>
            </h1>
            <div class="fieldrow">
                <label class="fieldlabel" for="input-timeZone">
                    Time Zone
                </label>
                {#if timeZoneEntry != guessedZone}
                    <p class="fieldhelp">
                        Suggested:
                        <button
                            type="button"
                            on:click={() => (timeZoneEntry = guessedZone)}
                            class="link"
                        >
                            {guessedZone}
                        </button>
                    </p>
                {/if}
                <Select
                    placeholder="Select the best time zone for you and your clients..."
                    items={timeZoneOptions}
                    selectedValue={timeZoneEntry}
                    isCreatable={false}
                    isClearable={false}
                    on:select={(event) => {
                        timeZoneEntry = event.detail.value;
                    }}
                    showIndicator={true}
                />
            </div>
        </section>
        <section class="card-address">
            <h1>
                Address Info
                <div style="float:right; font-weight:400; font-size:14px;">
                    <span class="muted">
                        {#if formStatus == UNSAVED}
                            Not saved.
                        {:else if formStatus == SAVING}
                            Saving...
                        {:else if formStatus == SAVED}
                            All changes saved.
                        {/if}
                    </span>
                    {#if !isNewOrg}
                        <span style="display:inline-block; margin-left:10px;">
                            <button type="submit" class="button">
                                <span class="btn-text"> Save Changes </span>
                            </button>
                        </span>
                    {/if}
                </div>
            </h1>
            <div class="fieldrow-address">
                <div class="fieldrow-address-item">
                    <label class="fieldlabel" for="input-timeZone">
                        Country (US/CA)
                    </label>
                    <Select
                        placeholder="Select your country..."
                        items={countryList}
                        selectedValue={countryEntry}
                        isCreatable={false}
                        isClearable={false}
                        on:select={(event) => {
                            countryEntry = event.detail.value;
                            updateStateList(countryEntry);
                        }}
                        showIndicator={true}
                    />
                </div>

                <div class="fieldrow-address-item">
                    <label class="fieldlabel" for="input-timeZone">
                        State/Province
                    </label>
                    <Select
                        placeholder="Select your state or province..."
                        items={stateList}
                        selectedValue={stateEntry}
                        isCreatable={false}
                        isClearable={false}
                        on:select={(event) => {
                            stateEntry = event.detail.value;
                        }}
                        showIndicator={true}
                    />
                </div>
                <div class="fieldrow-address-item">
                    <label class="fieldlabel" for="city">
                        City (Max Length 100 characters)
                    </label>
                    <MutationInput
                        type="text"
                        id="city"
                        placeholder="New York"
                        bind:value={cityEntry}
                        savingValue={citySaving}
                        savedValue={_get(orgData, 'city')}
                        on:keydown={isNewOrg ? blockEnterKey : null}
                    />
                </div>
            </div>
            <div class="fieldrow-address">
                <div class="fieldrow-address-item">
                    <label class="fieldlabel" for="street">
                        Street (Max Length 100 characters)
                    </label>
                    <MutationInput
                        type="text"
                        id="street"
                        placeholder="Wall Street"
                        bind:value={streetEntry}
                        savingValue={streetSaving}
                        savedValue={_get(orgData, 'street')}
                        on:keydown={isNewOrg ? blockEnterKey : null}
                    />
                </div>
                <div class="fieldrow-address-item">
                    <label class="fieldlabel" for="postalCode">
                        Postal Code (Use 5 digit zipcode for United States.)
                    </label>
                    <MutationInput
                        type="text"
                        id="postalCode"
                        placeholder="12345"
                        bind:value={postalCodeEntry}
                        savingValue={postalCodeSaving}
                        savedValue={_get(orgData, 'postalCode')}
                        on:keydown={isNewOrg ? blockEnterKey : null}
                    />
                </div>
            </div>
        </section>
        {#if !isNewOrg}
            <section class="card">
                <h1>
                    SMS (Text Messages)
                    <div style="float:right; font-weight:400; font-size:14px;">
                        {#if !isNewOrg}
                            <span
                                style="display:inline-block; margin-left:10px;"
                            >
                                <button
                                    type="button"
                                    class="button"
                                    on:click={openVerificationModal}
                                    disabled={!!_get(
                                        'brandRegistrationStatus',
                                        orgData,
                                        '',
                                    )}
                                >
                                    <span class="btn-text">
                                        Verify Phone Numbers
                                    </span>
                                </button>
                            </span>
                        {/if}
                    </div>
                </h1>
                {#if isNewOrg}
                    <p class="explain" style="margin-top:10px;">
                        <em>
                            You can come back and configure your SMS phone
                            numbers after you finish creating this Organization.
                        </em>
                    </p>
                {:else}
                    <p class="explain" style="margin-top:10px;">
                        <strong>Two phone numbers?!</strong> We recommend that every
                        organization have two phone numbers for sending SMS — one
                        Business SMS Number, and one Personal SMS number.
                    </p>
                    <p class="explain">
                        <strong>Business SMS Number</strong> Use this number to send
                        contacts more general messages from your organization.
                    </p>
                    <p class="explain">
                        <strong>Personal SMS Number</strong> Use this number to send
                        contacts messages that look like they are coming from an
                        actual person in your organization.
                    </p>
                    <p class="explain">
                        <strong>Receiving Text Messages</strong> Your contacts can
                        reply to either SMS number and we'll make sure their messages
                        get to you. Some of our clients publish the Business SMS
                        Number on their website to centralize SMS conversations with
                        contacts and leads.
                    </p>
                    <div class="cols">
                        <div class="col equalcol">
                            <EditOrgSmsLocal {orgData} {orgId} />
                        </div>
                        <div class="col equalcol">
                            <EditOrgSmsTollFree {orgData} {orgId} />
                        </div>
                    </div>
                {/if}
            </section>
            <section class="card">
                <h1>
                    Call Forward
                    <div style="float:right; font-weight:400; font-size:14px;">
                        <span style="display:inline-block; margin-left:10px;">
                            <Toggle
                                on:change={handleToggle}
                                bind:checked={callForwardStatus}
                                disabled={isCallForwardOrdering ||
                                    (callForwardStatus &&
                                        forwardingNumberEntry !=
                                            _get(
                                                orgData,
                                                'callForwardStatus.forwardingNumber',
                                            ))}
                            />
                        </span>
                    </div>
                </h1>
                <p class="explain">
                    By checking this <strong>Toggle Button</strong>, an incoming
                    call to the Personal SMS Number or Business SMS Number will
                    be forward to the phone number listed below.
                </p>

                <MutationInput
                    type="text"
                    id="forwardingNumber"
                    placeholder="555-555-5555"
                    bind:value={forwardingNumberEntry}
                    savedValue={_get(
                        orgData,
                        'callForwardStatus.forwardingNumber',
                    )}
                    on:change={(event) => {
                        forwardingNumberEntry = event.detail.value;
                    }}
                />
                <!-- on:keydown={isNewOrg ? blockEnterKey : null} -->
                <!-- savingValue={forwardingNumberSaving} -->
                {#if callForwardStatus && forwardingNumberEntry != _get(orgData, 'callForwardStatus.forwardingNumber')}
                    <div class="call-forwarding-button">
                        <button
                            type="button"
                            class="button primary"
                            on:click={handleCallForwardingButton}
                        >
                            Update Call Forwarding Number
                        </button>
                    </div>
                {/if}
                {#if isCallForwardingModalOpen}
                    <CallForwardingVerificationModal
                        {handleCallForwardingModalClose}
                        {sendCallForwardingOrder}
                        {orgId}
                        {forwardingNumberEntry}
                    />
                {/if}
            </section>
        {/if}
        <section class="card">
            <h1>
                LeadAction Account
                <div style="float:right; font-weight:400; font-size:14px;">
                    <span class="muted">
                        {#if formStatus == UNSAVED}
                            Not saved.
                        {:else if formStatus == SAVING}
                            Saving...
                        {:else if formStatus == SAVED}
                            All changes saved.
                        {/if}
                    </span>
                    {#if !isNewOrg}
                        <span style="display:inline-block; margin-left:10px;">
                            <button type="submit" class="button">
                                <span class="btn-text"> Save Changes </span>
                            </button>
                        </span>
                    {/if}
                </div>
            </h1>
            <div class="cols">
                <div class="col equalcol">
                    <div class="fieldrow">
                        <label class="fieldlabel" for="billingFirstNameEntry">
                            Billing Contact First Name
                        </label>
                        <MutationInput
                            type="text"
                            id="billingFirstNameEntry"
                            placeholder="Arnold"
                            bind:value={billingFirstNameEntry}
                            savingValue={billingFirstNameSaving}
                            savedValue={_get(orgData, 'billingFirstName')}
                            on:keydown={isNewOrg ? blockEnterKey : null}
                        />
                    </div>
                </div>
                <div class="col equalcol">
                    <div class="fieldrow">
                        <label class="fieldlabel" for="billingLastNameEntry">
                            Last Name
                        </label>
                        <MutationInput
                            type="text"
                            id="billingLastNameEntry"
                            placeholder="Schwarzenegger"
                            bind:value={billingLastNameEntry}
                            savingValue={billingLastNameSaving}
                            savedValue={_get(orgData, 'billingLastName')}
                            on:keydown={isNewOrg ? blockEnterKey : null}
                        />
                    </div>
                </div>
            </div>
            <div class="fieldrow">
                <label class="fieldlabel" for="billingPhoneEntry">
                    Billing Contact Phone Number
                </label>
                <MutationInput
                    type="text"
                    id="billingPhoneEntry"
                    placeholder="555-123-4567"
                    bind:value={billingPhoneEntry}
                    savingValue={billingPhoneSaving}
                    savedValue={_get(orgData, 'billingPhone')}
                    on:keydown={isNewOrg ? blockEnterKey : null}
                />
            </div>
            {#if !isNewOrg}
                <div class="fieldrow">
                    <div class="fieldlabel">Current Plan</div>
                    {#if activeSubscriptions.length}
                        {#each activeSubscriptions as subscription}
                            <div class="subscription-container">
                                <OrgSubscription
                                    subscriptionData={subscription}
                                    {orgId}
                                />
                            </div>
                        {/each}
                    {:else if orgId}
                        <div style="display: flex;">
                            <a
                                class="button call-to-action"
                                href="{orgPath}/plan/start"
                            >
                                <span class="btn-text">
                                    Activate your Free Trial
                                </span>
                                <ion-icon
                                    name="arrow-forward"
                                    class="btn-icon"
                                />
                            </a>
                        </div>
                    {/if}
                    <div class="fieldhelp guarantee">
                        <!-- <ion-icon name="shield-checkmark-outline" /> -->
                        Your success is our success. We back that up with our
                        <a href="https://leadaction.com/fair-refund-policy">
                            Fair Refund Policy
                            <ion-icon name="arrow-up" class="muted rot45" />
                        </a>
                        and you can
                        <a href="https://leadaction.com/cancel"
                            >cancel your subscription</a
                        >
                        at any time — no contracts and no bullshit™.
                        <a href="mailto:{__APP_HELP_EMAIL__}"
                            >Contact us anytime</a
                        >
                        if you have ideas, questions, technical difficulties, or
                        an existential crisis.
                    </div>
                </div>
            {/if}
        </section>
        {#if isNewOrg}
            <footer class="card" class:muted={!!savingData}>
                <div class="iconcol">
                    <!-- <ion-icon name="bonfire" /> -->
                    <!-- <ion-icon name="sunny" /> -->
                    <ion-icon name="trail-sign" />
                </div>
                <div class="textcol">
                    <h1>Are you ready?</h1>
                </div>
                <div class="buttoncol">
                    <button
                        type="submit"
                        class="button call-to-action"
                        on:click|stopPropagation={saveForm}
                        disabled={!!savingData}
                    >
                        <span class="btn-text"> Create Account </span>
                        <ion-icon name="arrow-forward" class="btn-icon" />
                    </button>
                </div>
            </footer>
        {/if}
    {/if}
</form>

<style>
    .link {
        padding: 0;
        margin: 0;
        display: inline;
        font-size: inherit;
        font-weight: inherit;
        line-height: normal;
        color: var(--blue);
        text-decoration: none;
    }
    .link:hover {
        text-decoration: underline;
    }
    .card {
        background: var(--white);
        border: 1px solid var(--offoffwhite);
        border-radius: 9px;
        padding: 20px;
        margin: 0 0 20px;
    }
    .card-address {
        display: flex;
        flex-direction: column;
        background: var(--white);
        border: 1px solid var(--offoffwhite);
        border-radius: 9px;
        padding: 20px;
        margin: 0 0 20px;
    }
    h1 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        margin: 0;
        padding: 0 0 10px;
    }
    .explain {
        margin: 0;
        padding: 0 0 10px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }
    .explain strong {
        font-weight: 600;
    }
    .cols {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
    }
    .col + .col {
        margin-left: 20px;
    }
    .equalcol {
        flex-shrink: 1;
        flex-grow: 1;
        width: 300px; /* to give equal basis */
    }
    .field-with-tools {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: stretch;
    }
    .fieldcol {
        flex-grow: 1;
        flex-shrink: 1;
    }
    .fieldcol :global(input) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    /* .fieldrow{
        display:flex;
    } */
    .fieldrow-address {
        display: flex;
    }
    .fieldrow-address-item {
        width: 30%;
        margin: 10px;
    }
    .toolcol {
        flex-grow: 0;
        flex-shrink: 0;
        padding: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: var(--slate-o10);
        border-radius: 4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .toolcol * + * {
        margin-left: 4px;
    }
    .toolcol .button {
        height: 32px;
    }
    .button.call-to-action {
        background: var(--blue);
        color: var(--white);
        box-shadow: var(--box-shadow-raised-level1);
        height: 50px;
        border-radius: 25px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .button.call-to-action .btn-text {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
    }
    .button.call-to-action .btn-icon {
        font-size: 24px;
        --ionicon-stroke-width: 48px;
        margin-left: 4px;
    }
    footer.card {
        margin: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        padding: 40px;
        border-color: var(--lightblue);
        color: var(--blue);
    }
    footer .iconcol {
        flex-grow: 0;
        flex-shrink: 0;
        font-size: 64px;
        margin-right: 20px;
        line-height: 48px;
    }
    footer .textcol {
        flex-grow: 1;
        flex-shrink: 1;
    }
    footer .buttoncol {
        flex-grow: 0;
        flex-shrink: 0;
    }
    footer h1 {
        margin: 0;
        padding: 0;
    }
    footer h1 {
        font-size: 24px;
        line-height: 28px;
        font-weight: 600;
    }
    .subscription-container + .subscription-container {
        margin-top: 20px;
    }
    .guarantee {
        padding: 6px 0 0;
    }
    .guarantee a {
        text-decoration: none;
        font-weight: 600;
    }
    .guarantee a:hover {
        border-bottom: 1px solid;
    }
    .call-forwarding-button {
        display: flex;
        flex-direction: column-reverse;
        margin: 10px;
        /* justify-content: right; */
    }
</style>
