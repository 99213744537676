<script context="module">
    const modalPaddingPx = 50;
    export {modalPaddingPx};
</script>

<script>
    import {onMount} from 'svelte';
    import {fly, fade} from 'svelte/transition';
    import MutationInput from './MutationInput.svelte';
    import {sendCallForwardingVerificationCode} from '../api';

    export let handleCallForwardingModalClose;
    export let sendCallForwardingOrder;
    export let orgId;
    export let forwardingNumberEntry;

    let modalNode;
    let verificationTimes = 0;
    function handleContainerClick(event) {
        let isClickInsideModal =
            modalNode.contains(event.target) && modalNode != event.target;
        if (!isClickInsideModal) {
            handleCallForwardingModalClose();
        }
    }
    function handleEscape(event) {
        if (event.keyCode == 27) {
            handleCallForwardingModalClose();
        }
    }

    function isValidPhoneNumber(phoneNumber) {
        return /^\+\d{11}$/.test(phoneNumber);
    }

    let verificationCodeInput = '';
    let isCallForwarding = false;

    async function checkVerificationCode() {
        let checkResult = compareVerificationCode();

        if (checkResult) {
            isCallForwarding = true;
            const response = await sendCallForwardingOrder(
                orgId,
                forwardingNumberEntry,
                false,
            );
            isCallForwarding = false;
            handleCallForwardingModalClose();
        } else {
            verificationTimes++;
            if (verificationTimes < 3) {
                alert('Wrong Verification Code.');
                verificationCodeInput = '';
            } else {
                alert(
                    'You have entered wrong verification code 3 times. Please try a few minutes later.',
                );
                handleCallForwardingModalClose();
            }
        }
    }

    let verficationCode;

    function compareVerificationCode() {
        return verificationCodeInput == verficationCode;
    }

    async function sendCallForwardingVerficationCode() {
        verficationCode = Math.floor(Math.random() * 900000) + 100000;
        return await sendCallForwardingVerificationCode(
            orgId,
            forwardingNumberEntry,
            btoa(verficationCode),
        );
    }
    let isSendingVerificationCode = true;
    let contentText = '';
    onMount(async () => {
        const sendingVerificationCodeResponse =
            await sendCallForwardingVerficationCode();
        isSendingVerificationCode = false;
        if (sendingVerificationCodeResponse.ok) {
            contentText = `We sent verification code to ${forwardingNumberEntry}. Please enter this into this field to proceed the call forwarding feature.`;
        } else {
            alert(
                'Sorry. There is an error while sending verification code. Please check the phone number again and try it a few mins later',
            );
            handleCallForwardingModalClose();
        }
    });
</script>

<svelte:window on:keydown={handleEscape} />

<div
    class="modal-container"
    on:click={handleContainerClick}
    style="padding:{modalPaddingPx}px;"
>
    <div class="modal-backdrop" transition:fade={{duration: 200}} />
    <div class="modal-close">
        <ion-icon name="close-outline" />
        <span class="keyboard-shortcut"> esc </span>
    </div>
    <div
        class="modal"
        bind:this={modalNode}
        transition:fly={{y: 100, duration: 200}}
    >
        <slot />
        <div class="modal-content">
            {#if isSendingVerificationCode}<div class="sending">
                    <div class="spinner"></div>
                    <h1>Loading...</h1>
                </div>
            {:else}<p>{contentText}</p>
                <MutationInput
                    type="text"
                    id="billingPhoneEntry"
                    placeholder="123456"
                    bind:value={verificationCodeInput}
                />
                <!-- on:keydown={isNewOrg ? blockEnterKey : null} -->
                <!-- savingValue={billingPhoneSaving} -->
                <!-- savedValue={_get(orgData, 'billingPhone')} -->
                <div class='check-verification-button'>
                    <button
                        type="button"
                        class="button primary"
                        on:click={checkVerificationCode}
                    >
                        <span class="btn-text"> Verify Code </span>
                    </button>
                </div>{/if}
        </div>
    </div>
</div>

<style>
    .modal-container {
        /* overscroll-behavior: contain; */
        background: rgba(0, 0, 0, 0.01); /* fix scrollbar contrast */
        position: absolute;
        overflow: auto;
        --webkit-overflow-scrolling: touch;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        /* padding: 80px; */
    }
    .modal-backdrop {
        background: var(--offblack-backdrop);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    @supports (backdrop-filter: blur(20px)) {
        .modal-backdrop {
            background: var(--offblack-backdrop-blur);
            backdrop-filter: blur(20px);
            backdrop-filter: saturate(180%) blur(20px);
        }
    }
    .modal-close {
        color: white;
        position: fixed;
        top: 0;
        right: 0;
        width: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }
    .modal-close ion-icon {
        font-size: 48px;
        padding-top: 2px;
    }
    .modal-close .keyboard-shortcut {
        /* border: 1px solid; */
        border-radius: 3px;
        font-size: 12px;
        /* font-family: monospace; */
        font-weight: 400;
        line-height: 14px;
        padding: 0 4px 1px;
        margin-top: -8px;
        opacity: 0.62;
    }
    .modal {
        position: relative;
    }
    .modal > :global(*) {
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    }
    .modal-content {
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        background: #ffffff;
        color: var(--offblack);
        border-radius: 4px;
        padding: 50px;
        /* overflow: hidden; */
    }
    .spinner {
        width: 32px;
        height: 32px;
        border: 8px solid;
        border-color: #3d5af1 transparent #3d5af1 transparent;
        border-radius: 50%;
        animation: rotating-spin 1.2s linear infinite;
        margin: 6px;
    }
    .sending {
        display: flex;
        align-items: center;
    }
    @keyframes rotating-spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .check-verification-button{
        display: flex;
        margin: 10px;
        flex-direction: column-reverse;
    }
    /* .section {
        padding: 30px 20px 45px;
    }
    .field-name {
        font-size: 18px;
        line-height: 1.4;
        font-weight: 600;
        margin: 0 0 10px;
        padding: 0;
    }
    .field-section + .field-section {
        margin-top: 20px;
        border-top: 1px solid var(--slate-o10);
        padding-top: 20px;
    }
    .field-values {
        display: flex;
        padding: 20px;
        margin: 0 0 20px;
    }
    .field-item {
        width: 30%;
        margin: 10px;
    }
    .tag-title {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 6px;
    }
    .description {
        margin: 5px 10px;
        color: gray;
    } */
</style>
