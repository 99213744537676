<script context="module">
</script>

<script>
    import CanceledCaseIcon from './CanceledCaseIcon.svelte';
    export let status;
    export let date;
</script>

<div>
    {#if status == 'Active'}
        <div class="active-style">
            <div class="status-text">
                <ion-icon name="checkmark-outline" class="status-icon" />
                <span>{status}</span>
            </div>
            <span class="date-text">since {date}</span>
        </div>
    {:else if status == 'In trial'}
        <div class="trial-style">
            <div class="status-text">
                <ion-icon name="checkmark-circle-outline" class="status-icon" />
                <span>{status}</span>
            </div>
            <span class="date-text">since {date}</span>
        </div>
    {:else if status == 'Past due'}
        <div class="pastDue-style">
            <div class="status-text">
                <ion-icon name="notifications-outline" class="status-icon" />
                <span>{status}</span>
            </div>
            <span class="date-text">as of {date}</span>
        </div>
    {:else if status == 'Canceled'}
        {#if date != 'Invalid Date'}
            <div class="cancelled-style">
                <div class="status-text">
                    <CanceledCaseIcon />
                    <span>{status}</span>
                </div>
                <span class="date-text">as of {date}</span>
            </div>
        {:else}<div class="unsubscribed-style">
                <span class="status-text">Unsubscribed</span>
            </div>
        {/if}
    {:else if status == 'Deleted'}
        <div class="deleted-style">
            <div class="status-text">
                <ion-icon name="trash-outline" class="status-icon" />
                <span>{status}</span>
            </div>
            <span class="date-text">At {date}</span>
        </div>
    {/if}
</div>

<style>
    .status-text {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px 0px 2px 0px;
    }
    .date-text {
        margin-top: -10px;
        font-family: 'Inter', sans-serif;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
    .status-icon {
        width: 15px;
        height: 15px;
        margin-right: 4px;
    }
    .active-style {
        display: flex;
        flex-direction: column;
        width: 115px;
        height: 45px;
        padding: 4px 15px;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 5px;
        color: var(--green, #0ca94b);
        background: rgba(145, 197, 97, 0.12);
    }
    .deleted-style{
        display: flex;
        flex-direction: column;
        width: 115px;
        height: 45px;
        padding: 4px 15px;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 5px;
        color: var(--red);
        background: rgba(145, 197, 97, 0.12);
    }
    .trial-style {
        display: flex;
        flex-direction: column;
        width: 115px;
        height: 45px;
        padding: 4px 15px;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 5px;
        color: #4286f4;
        background: rgba(67, 126, 214, 0.12);
    }
    .pastDue-style {
        display: flex;
        flex-direction: column;
        width: 115px;
        height: 45px;
        padding: 4px 15px;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 5px;
        color: #ed9e42;
        background: rgba(214, 146, 67, 0.12);
    }
    .cancelled-style {
        display: flex;
        flex-direction: column;
        width: 115px;
        height: 45px;
        padding: 4px 15px;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 5px;
        color: #d65543;
        background: rgba(214, 67, 67, 0.12);
    }
    .unsubscribed-style {
        display: flex;
        width: 115px;
        height: 45px;
        padding: 4px 15px;
        align-items: center;
        justify-content: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 5px;
        color: #d65543;
        background: rgba(214, 67, 67, 0.12);
    }
</style>
