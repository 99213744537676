<script context="module">
    import gql from 'graphql-tag';
    import {FRAGMENTS as ContactSequence_FRAGMENTS} from './ContactSequence.svelte';

    const FRAGMENTS = {};
    FRAGMENTS.ContactSequences_contactSequence = gql`
        fragment ContactSequences_contactSequence on ContactSequence {
            ...ContactSequence_contactSequence
        }
        ${ContactSequence_FRAGMENTS.ContactSequence_contactSequence}
    `;
    FRAGMENTS.ContactSequences_sequence = gql`
        fragment ContactSequences_sequence on Sequence {
            ref
            data {
                name
            }
        }
    `;
    FRAGMENTS.ContactSequences_org = gql`
        fragment ContactSequences_org on Org {
            ref
            data {
                sequences {
                    ...ContactSequences_sequence
                }
            }
        }
        ${FRAGMENTS.ContactSequences_sequence}
    `;
    FRAGMENTS.ContactSequences_contact = gql`
        fragment ContactSequences_contact on Contact {
            ref
            data {
                sequences {
                    ...ContactSequences_contactSequence
                }
                org {
                    ...ContactSequences_org
                }
            }
        }
        ${FRAGMENTS.ContactSequences_contactSequence}
        ${FRAGMENTS.ContactSequences_org}
    `;

    const QUERIES = {};

    const MUTATIONS = {};
    MUTATIONS.startNewSequence = gql`
        mutation startNewSequence($id: ID!, $data: StartNewSequenceInput!) {
            startNewSequence(id: $id, data: $data) {
                ...ContactSequences_contact
            }
        }
        ${FRAGMENTS.ContactSequences_contact}
    `;

    MUTATIONS.retrieveContact = gql`
        mutation retrieveContact($contactId: ID!) {
            retrieveContact(contactId: $contactId) {
                ref
            }
        }
    `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import _get from 'lodash/get';
    import _find from 'lodash/find';
    import _sortBy from 'lodash/sortBy';
    import _filter from 'lodash/filter';
    import {getClient, mutation} from 'svelte-apollo';
    import Select from 'svelte-select';
    import ContactSequence from './ContactSequence.svelte';

    // export let contactId;
    export let contactData;
    const client = getClient();
    const startNewSequenceMutation = mutation(MUTATIONS.startNewSequence);
    const retrieveContactMutation = mutation(MUTATIONS.retrieveContact);

    let currentContactSequence;
    $: currentContactSequence = _find(
        _get(contactData, 'data.sequences', []),
        (contactSequence) => !_get(contactSequence, 'data.isCompleted', false),
    );
    let pastContactSequences = [];
    $: pastContactSequences = _sortBy(
        _filter(
            _get(contactData, 'data.sequences', []),
            (s) => s !== currentContactSequence,
        ),
        (contactSequence) => _get(contactSequence, 'data.createdAt'),
    ).reverse();
    $: orgSequences = _get(contactData, 'data.org.data.sequences', []);

    // async function startNewSequence(id, data) {
    //     try {
    //         let response = await mutate(client, {
    //             mutation: MUTATIONS.startNewSequence,
    //             variables: {
    //                 id: id,
    //                 data: data,
    //             },
    //         });
    //         client.writeFragment({
    //             id: id,
    //             fragment: FRAGMENTS.ContactSequences_contact,
    //             fragmentName: 'ContactSequences_contact',
    //             data: response.data.partialUpdateContact,
    //         });
    //         return response.data.partialUpdateContact;
    //     } catch (error) {
    //         // FIXME handle errors better...
    //         alert(error.message);
    //         throw error;
    //     }
    // }

    async function handleClickRetrieveButton(contactId) {
        try {
            const response = await retrieveContactMutation({
                variables: {
                    contactId: contactId,
                },
            });
            let tempContactData = contactData;
            tempContactData.data.isDeleted = false;
            tempContactData.data.deletedAt = null;
            contactData = tempContactData;
        } catch (error) {
            console.log(error);
            alert(error);
        }
    }

    let newSequenceEntry;
    let isSavingNewSequence = false;
    async function startNewSequence(sequence) {
        if (isSavingNewSequence) return;
        isSavingNewSequence = true;

        if (
            confirm(
                `Confirm that you want to start the sequence "${sequence.data.name}" for this contact.`,
            )
        ) {
            try {
                // let response = await mutate(client, {
                //     mutation: MUTATIONS.startNewSequence,
                //     variables: {
                //         id: contactData.ref,
                //         data: {
                //             sequence: sequence.ref,
                //             createdAt: new Date().toISOString(),
                //         },
                //     },
                // });
                let response = await startNewSequenceMutation({
                    variables: {
                        id: contactData.ref,
                        data: {
                            sequence: sequence.ref,
                            createdAt: new Date().toISOString(),
                        },
                    },
                });
                // console.log("response =>", response)
                // contactData.data = response.data.startNewSequence.data;
                client.writeFragment({
                    id: contactData.ref,
                    fragment: FRAGMENTS.ContactSequences_contact,
                    fragmentName: 'ContactSequences_contact',
                    data: response.data.partialUpdateContact,
                });
                return response.data.startNewSequence;
            } catch (error) {
                console.error(error);
            }
        }
        newSequenceEntry = sequence;
        setTimeout(() => {
            newSequenceEntry = null;
        });
        isSavingNewSequence = false;
    }
</script>

{#if !_get(contactData, 'ref')}
    Loading...
{:else if !_get(contactData, 'data.isDeleted', false)}
    <aside class="sequence new-sequence" class:muted={isSavingNewSequence}>
        <header>
            <h1>
                <span class="icon">
                    <ion-icon
                        name="play-forward"
                        style="position:relative; left:1px;"
                    />
                </span>
                <span class="title"> Start a New Action Sequence </span>
            </h1>
        </header>
        <div>
            {#if currentContactSequence}
                <Select
                    items={[]}
                    isDisabled={true}
                    placeholder={'You must cancel the current sequence before you can start a new sequence.'}
                    showIndicator={true}
                ></Select>
            {:else}
                <Select
                    items={orgSequences.filter(
                        (sequence) => !sequence.data.isDeleted,
                    )}
                    selectedValue={newSequenceEntry}
                    isDisabled={isSavingNewSequence}
                    placeholder="Select a sequence..."
                    getOptionLabel={(option, filterText) => option.data.name}
                    getSelectionLabel={(option) => option.data.name}
                    isCreatable={false}
                    isClearable={false}
                    on:select={(event) => startNewSequence(event.detail)}
                    showIndicator={true}
                ></Select>
            {/if}
        </div>
    </aside>
    {#if currentContactSequence}
        <div class="contact-sequence-container">
            <ContactSequence
                contactSequenceData={currentContactSequence}
                isCollapsed={false}
                isCollapsible={false}
                {contactData}
            />
        </div>
    {:else}
        <article class="sequence">
            <header>
                <h1>
                    <span class="icon primary">
                        <ion-icon
                            name="play-forward"
                            style="position:relative; left:1px;"
                        />
                    </span>
                    <span class="title"> Current Action Sequence: None </span>
                </h1>
            </header>
        </article>
    {/if}
    {#each pastContactSequences as contactSequence (contactSequence.ref)}
        <div class="contact-sequence-container">
            <ContactSequence
                contactSequenceData={contactSequence}
                {contactData}
            />
        </div>
    {/each}
{:else}
    <p class="disalbed-alert">
        This contact's record has been deleted and will be permanently deleted
        within 30 days. To modify or message this contact you will need to first
        restore the contact's record.
    </p>
    <div class="deleted-info">
        <div class="deleted-style">
            <div class="status-text">
                <ion-icon name="trash-outline" class="status-icon" />
                <span>Deleted At</span>
            </div>
            <span class="date-text"
                >{contactData.data.deletedAt
                    ? contactData.data.deletedAt.split('T')[0]
                    : ''}</span
            >
        </div>

        <button
            type="button"
            on:click|stopPropagation={() =>
                handleClickRetrieveButton(contactData.ref)}
            class="button"
        >
            <ion-icon name="reload-outline" class="btn-icon" />
            <span class="btn-text"> Restore </span>
        </button>
    </div>
{/if}

<style>
    .sequence {
        padding: 20px;
        margin-bottom: 20px;
    }
    .sequence header {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--slate);
        padding: 0 0 5px;
        margin: 0;
        border-radius: 10px;
    }
    .sequence header h1 {
        flex-grow: 0;
        flex-shrink: 1;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        margin: 0;
        padding-right: 30px;
    }
    .sequence header h1 .icon {
        flex-grow: 0;
        flex-shrink: 0;
        display: inline-block;
        text-align: center;
        width: 40px;
        height: 40px;
        margin-right: 10px;
        font-size: 24px;
        line-height: 46px;
        border-radius: 20px;
        background: var(--slate);
        color: white;
    }
    .sequence header h1 .icon.primary {
        background: var(--blue);
    }
    .sequence header h1 .title {
        flex-grow: 1;
        flex-shrink: 1;
        margin-top: 7px;
    }
    .contact-sequence-container {
        margin-bottom: 20px;
    }
    .new-sequence header {
        margin-bottom: 10px;
    }
    .disalbed-alert {
        padding: 0px 30px;
        color: red;
        font-size: 24px;
    }
    .deleted-info {
        display: flex;
        padding-left: 30px;
        align-items: center;
    }
    .deleted-style {
        display: flex;
        flex-direction: column;
        width: 115px;
        height: 45px;
        padding: 4px 15px;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 5px;
        color: #d65543;
        background: rgba(214, 67, 67, 0.12);
    }
    .status-text {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px 0px 2px 0px;
    }
    .date-text {
        margin-top: -10px;
        text-decoration: none !important;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
    .status-icon {
        width: 15px;
        height: 15px;
        margin-right: 4px;
    }
    .button {
        margin: 0px 0px 0px 20px;
        padding: 10px 15px !important;
    }
    .button:hover {
        color: var(--blue);
    }
    .btn-text {
        font-size: 16px !important;
    }
</style>
