import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { WebSocketLink } from "apollo-link-ws";
import { split } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { setContext } from 'apollo-link-context';
import { getMainDefinition } from "apollo-utilities";
import { getIdToken } from './auth0.js';

const graphqlEndpoint = __GRAPHQL_ENDPOINT__;
const defaultHeaders = {
    'content-type': 'application/json',
    "X-Schema-Preview": "partial-update-mutation",
};

// This code assumes that a user will not login, logout, or switch accounts
// (without reloading a page). This assumption depends on the fact that we are
// using Auth0 Universal Login via page redirects.
const logAndContinue = (val) => {
    // console.log(val);
    return val;
}

const faunaUserSecretPromise = getIdToken()
    .then(logAndContinue)
    .then(auth0IdToken => {
        const url = window.location.href;
        const regex = /\/orgs\/(.+)\//;
        const matches = url.match(regex);

        let requestedOrgId = null;

        if (matches && matches.length > 1) {
            requestedOrgId = matches[1];
        }

        return fetchFaunaSecret(auth0IdToken, requestedOrgId)
    })
    .then(logAndContinue);

async function getUserCredentials() {
    const auth0IdToken = await getIdToken();
    let response = await fetch('/api/user-info', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${auth0IdToken}`,
            'Content-Type': 'application/json',
        },
    });
    return response.ok ? response : null;
}

const wsLink = new WebSocketLink({
    uri: `wss://${graphqlEndpoint}`,
    options: {
        reconnect: true,
        lazy: true
    },
    connectionParams: async () => {
        const faunaSecret = await faunaUserSecretPromise;
        return {
            headers: {
                ...defaultHeaders,
                Authorization: `Bearer ${faunaSecret || __FAUNADB_CLIENT_SECRET__}`,
            }
        };
    },
});

// See https://www.apollographql.com/docs/link/links/http/#custom-auth
const fetchWithAuth = async (uri, options) => {
    const faunaSecret = await faunaUserSecretPromise;
    if (faunaSecret) {
        options.headers.Authorization = `Bearer ${faunaSecret}`;
    }
    return await fetch(uri, options);
};
const httpLink = new HttpLink({
    uri: `https://${graphqlEndpoint}`,
    headers: defaultHeaders,
    fetch: fetchWithAuth,
});

const link = split(
    ({
        query
    }) => {
        const {
            kind,
            operation
        } = getMainDefinition(query);
        return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    httpLink
);

const client = new ApolloClient({
    // link: authLink.concat(link),
    link: link,
    cache: new InMemoryCache({
        addTypename: true,
    }),
    defaultOptions: {
        watchQuery: {
            returnPartialData: true,
            pollInterval: 10000,
            fetchPolicy: 'cache-and-network',
            errorPolicy: 'all',
        },
    },
});

export {
    client,
    fetchWithAuth,
    getUserCredentials
};

async function fetchFaunaSecret(auth0IdToken, requestedOrgId) {
    if (!auth0IdToken) return;
    let response = await fetch('/api/login-fauna', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${auth0IdToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            requestedOrgId
        })
    });
    return response.ok ? await response.text() : null;
}
