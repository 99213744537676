<script context="module">
    import gql from 'graphql-tag';
    import {FRAGMENTS as ContactStatusDropdown_FRAGMENTS} from '../../../components/ContactStatusDropdown.svelte';
    import {FRAGMENTS as EditContactProfile_FRAGMENTS} from '../../../components/EditContactProfile.svelte';
    import {FRAGMENTS as EditContactNotes_FRAGMENTS} from '../../../components/EditContactNotes.svelte';
    import {FRAGMENTS as ContactSequence_FRAGMENTS} from '../../../components/ContactSequence.svelte';
    import {FRAGMENTS as ContactMessages_FRAGMENTS} from '../../../components/ContactMessages.svelte';
    import {FRAGMENTS as ContactForms_FRAGMENTS} from '../../../components/ContactForms.svelte';

    const FRAGMENTS = {};
    FRAGMENTS.Contact_sequenceAction = gql`
        fragment Contact_sequenceAction on SequenceActionData {
            type
            delay {
                minDelay
                minDelayUnits
                allowedDays
                allowedStartTime {
                    hour
                    minute
                    pm
                }
                allowedEndTime {
                    hour
                    minute
                    pm
                }
            }
            message {
                ref
                data {
                    body
                    subjectLine
                    email
                    sms
                    smsViaLocal
                }
            }
        }
    `;
    FRAGMENTS.Contact_contactSequence = gql`
        fragment Contact_contactSequence on ContactSequenceData {
            createdAt
            lastActionAt
            isPaused
            isCompleted
            actions {
                ref
                data {
                    completedAt
                    messages {
                        ref
                        data {
                            subject
                            strippedText
                        }
                    }
                    sequenceAction {
                        ...ContactSequence_sequenceAction
                    }
                }
            }
            nextActions {
                node {
                    ref
                    data {
                        ...Contact_sequenceAction
                    }
                }
            }
            sequence {
                ref
                data {
                    name
                }
            }
            canceledBy {
                ref
                data {
                    email
                }
            }
        }
        ${FRAGMENTS.Contact_sequenceAction}
        ${ContactSequence_FRAGMENTS.ContactSequence_sequenceAction}
    `;
    FRAGMENTS.Contact_contactStatus = gql`
        fragment Contact_contactStatus on ContactStatus {
            _id
            name
            color
        }
    `;
    FRAGMENTS.Contact_org = gql`
        fragment Contact_org on OrgData {
            timeZone
        }
    `;
    FRAGMENTS.Contact_contact = gql`
        fragment Contact_contact on Contact {
            ref
            data {
                notes
                status {
                    ref
                    data {
                        name
                        color
                    }
                }
                org {
                    ref
                    data {
                        timeZone
                        contactFields {
                            ref
                            data {
                                name
                                isUid
                                isProfile
                                isEmail
                                isSms
                                isFirstName
                                isLastName
                            }
                        }
                        sequences {
                            ref
                            data {
                                name
                                pauseOnReply
                                actionsOrder
                                isDeleted
                            }
                        }
                        contactStatuses {
                            ref
                            data {
                                name
                                color
                            }
                        }
                    }
                }
                uids {
                    ref
                    data {
                        value
                        contactField {
                            data {
                                isEmail
                                isSms
                            }
                        }
                    }
                }
                profile {
                    ref
                    data {
                        valueJson
                        contactField {
                            data {
                                isFirstName
                                isLastName
                            }
                        }
                    }
                }
                sequences {
                    ref
                    data {
                        ...Contact_contactSequence
                    }
                }
                isDeleted
                deletedAt
            }
        }
        ${FRAGMENTS.Contact_contactSequence}
    `;

    const QUERIES = {};
    QUERIES.contact = gql`
        # ATTENTION The name of this query ("contact") has downstream dependents who
        # specify it in apollo mutations under option 'refetchQueries'.
        query contact($id: ID!) {
            findContactByID(id: $id) {
                ...Contact_contact
            }
        }
        ${FRAGMENTS.Contact_contact}
    `;
    QUERIES.org = gql`
        query org($id: ID!) {
            findOrgByID(id: $id) {
                ref
                data {
                    timeZone
                }
            }
        }
        ${FRAGMENTS.Contact_org}
    `;
    QUERIES.contactMessages = gql`
        query contactMessages($contactId: ID!, $size: Int!) {
            contactMessages(contactId: $contactId, size: $size) {
                page {
                    before
                    after
                    data {
                        node {
                            ref
                            data {
                                fromContact {
                                    ref
                                }
                            }
                        }
                    }
                }
                # aggregate {
                #     count
                # }
            }
        }
    `;
    QUERIES.contactFormSubmissions = gql`
        query contactFormSubmissions($contactId: ID!) {
            contactFormSubmissions(contactId: $contactId, size: 1) {
                aggregate {
                    count
                }
            }
        }
    `;

    const MUTATIONS = {};
    // MUTATIONS.updateContact = gql`
    //     mutation updateContact($id: ID!, $data: PartialUpdateContactInput!) {
    //         partialUpdateContact(id: $id, data: $data) {
    //             _id
    //             ...Contact_contact
    //         }
    //     }
    //     ${FRAGMENTS.Contact_contact}
    // `;
    MUTATIONS.temporarilyDeleteContacts = gql`
        mutation temporarilyDeleteContacts($contactIds: [ID!]) {
            temporarilyDeleteContacts(contactIds: $contactIds) {
                ref
            }
        }
    `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {getContext} from 'svelte';
    import {getClient, mutation} from 'svelte-apollo';
    import {Router, Route} from 'svelte-routing';
    import _get from 'lodash/get';
    import _find from 'lodash/find';
    import _trim from 'lodash/trim';
    import _filter from 'lodash/filter';
    import _trimEnd from 'lodash/trimEnd';
    import _isArray from 'lodash/isArray';
    import {parsePhoneNumberFromString} from 'libphonenumber-js';
    import {watchQuery} from '../../../svelte-apollo-watch-query';
    import HtmlHeadTitle from '../../../components/HtmlHeadTitle.svelte';
    import Timestamp from '../../../components/Timestamp.svelte';
    import ContactStatusDropdown from '../../../components/ContactStatusDropdown.svelte';
    import Modal from '../../../components/Modal.svelte';
    import EditContactProfile from '../../../components/EditContactProfile.svelte';
    import EditContactNotes from '../../../components/EditContactNotes.svelte';
    import ContactSequences from '../../../components/ContactSequences.svelte';
    import ContactMessages from '../../../components/ContactMessages.svelte';
    import ContactForms from '../../../components/ContactForms.svelte';
    import Dropdown from '../../../components/Dropdown.svelte';
    import {contextKey as modalContextKey} from '../../../components/ModalController.svelte';

    export let location;
    export let contactId;

    const client = getClient();
    const temporarilyDeleteContactsMutation = mutation(
        MUTATIONS.temporarilyDeleteContacts,
    );

    $: contactPath = `/contacts/${contactId}`;

    const contactQuery = watchQuery(client, {
        query: QUERIES.contact,
        variables: {id: contactId},
        pollInterval: 5000,
    });
    $: contactData = _get($contactQuery, 'data.findContactByID', {});
    $: orgPath = `/orgs/${_get(contactData, 'data.org.ref')}`;

    let currentContactSequence;
    $: currentContactSequence = _find(
        _get(contactData, 'data.sequences', []),
        (contactSequence) => !_get(contactSequence, 'data.isCompleted', false),
    );

    let contactMessagesPageSize = 200;
    const countReceivedMessagesQuery = watchQuery(client, {
        query: QUERIES.contactMessages,
        variables: {
            contactId: contactId,
            size: contactMessagesPageSize,
        },
        pollInterval: 15000,
    });
    // $: receivedMessagesCount = _get($countReceivedMessagesQuery, 'data.contactMessages.aggregate.count', '');
    $: messages = _get(
        $countReceivedMessagesQuery,
        'data.contactMessages.page.data',
        [],
    );
    $: inboundMessagesCount = _filter(
        messages,
        'node.data.fromContact.ref',
    ).length;
    $: outboundMessagesCount = messages.length - inboundMessagesCount;
    $: hasMorePages = !!_get(
        $countReceivedMessagesQuery,
        'data.contactMessages.page.after',
    );
    $: displayInboundMessagesCount =
        hasMorePages || inboundMessagesCount > 99
            ? `${Math.min(99, inboundMessagesCount)}+`
            : inboundMessagesCount;
    $: displayOutboundMessagesCount =
        hasMorePages || outboundMessagesCount > 99
            ? `${Math.min(99, outboundMessagesCount)}+`
            : outboundMessagesCount;

    const countFormSubmissionsQuery = watchQuery(client, {
        query: QUERIES.contactFormSubmissions,
        variables: {contactId: contactId},
        pollInterval: 15000,
    });
    $: formSubmissionsCount = _get(
        $countFormSubmissionsQuery,
        'data.contactFormSubmissions.aggregate.count',
        '',
    );

    let isContactDropdownOpen = false;
    let isContactStatusDropdownOpen = false;

    const getProfile = (contact, where) => {
        let value = _get(
            _find(_get(contact, 'data.uids', []), {
                data: {contactField: {data: where}},
            }),
            'data.value',
        );
        if (value) {
            return value;
        }
        let valueJson = _get(
            _find(_get(contact, 'data.profile', []), {
                data: {contactField: {data: where}},
            }),
            'data.valueJson',
        );
        if (valueJson) {
            return JSON.parse(valueJson);
        }
    };

    $: profileFirstName = getProfile(contactData, {isFirstName: true}) || '';
    $: profileLastName = getProfile(contactData, {isLastName: true}) || '';
    $: displayFirstName =
        _trim(profileFirstName) || _trim(profileLastName) || 'no name';
    $: displayLastName =
        _trim(profileFirstName) && _trim(profileLastName)
            ? _trim(profileLastName)
            : '';

    $: pageTitle =
        _trim(_trim(profileFirstName) + ' ' + _trim(profileLastName)) ||
        'no name';

    $: phoneNumbers = _filter(_get(contactData, 'data.uids', []), (uid) =>
        _get(uid, 'data.contactField.data.isSms'),
    );
    $: emailAddresses = _filter(_get(contactData, 'data.uids', []), (uid) =>
        _get(uid, 'data.contactField.data.isEmail'),
    );

    // $: profileEmail = getProfile(contactData, {isEmail: true}) || '';
    //
    // $: profileSms = getProfile(contactData, {isSms: true}) || '';
    // $: phoneNumber = parsePhoneNumberFromString(profileSms) || parsePhoneNumberFromString('+1'+profileSms);
    // $: phoneNumberDisplay = phoneNumber ? phoneNumber.formatNational() : '';
    // $: phoneNumberUri = phoneNumber ? phoneNumber.getURI() : '';

    let isUpdatingContact = false;
    // async function updateContact(id, data) {
    //     if (isUpdatingContact) return;
    //     isUpdatingContact = true;
    //     try {
    //         let response = await mutate(client, {
    //             mutation: MUTATIONS.updateContact,
    //             variables: {
    //                 id: id,
    //                 data: data,
    //             },
    //         });
    //         client.writeFragment({
    //             id: id,
    //             fragment: FRAGMENTS.ContactListItem_contact,
    //             fragmentName: 'ContactListItem_contact',
    //             data: response.data.partialUpdateContact,
    //         });
    //         return response.data.partialUpdateContact;
    //     } catch (error) {
    //         // FIXME handle errors better...
    //         alert(error.message)
    //     }
    //     isUpdatingContact = false;
    // }
    async function temporarilyDeleteContacts(contactIds) {
        if (isUpdatingContact) return;
        // isUpdatingContact = true;
        try {
            let response = await temporarilyDeleteContactsMutation({
                variables: {
                    contactIds: contactIds,
                },
            });
            return response.data.temporarilyDeleteContacts;
        } catch (error) {
            alert(error.message);
        }
        // isUpdatingContact = false;
    }

    // function showContactTab(pathname) {
    //     modalController.show({
    //         modalKey: 'contact',
    //         props: {
    //             orgId: orgId,
    //             contactId: contactId,
    //         },
    //         pushUrl: pathname,
    //     });
    // }
    function getPhoneNumberDisplay(val) {
        if (typeof val != String) {
            return val;
        }
        let phoneNumber =
            parsePhoneNumberFromString(val) ||
            parsePhoneNumberFromString('+1' + val);
        return phoneNumber ? phoneNumber.formatNational() : val;
    }
    function getPhoneNumberUri(val) {
        if (typeof val != String) {
            return `tel:${val}`;
        }
        let phoneNumber =
            parsePhoneNumberFromString(val) ||
            parsePhoneNumberFromString('+1' + val);
        return phoneNumber ? phoneNumber.getURI() : `tel:${val}`;
    }

    let isPartiallyCurrentPath = (location, href) =>
        location.pathname.substring(0, href.length) === href;
    let isCurrentPath = (location, href) =>
        _trimEnd(location.pathname, '/') === _trimEnd(href);
    let isActivePath = (location, href) =>
        isCurrentPath(location, href) || isPartiallyCurrentPath(location, href);
    let paths = {};
    $: paths = {
        messages: `${contactPath}/messages`,
        forms: `${contactPath}/forms`,
        sequences: `${contactPath}/sequences`,
        edit: `${contactPath}/edit`,
        notes: `${contactPath}/notes`,
    };

    let contactSidebarHeightPx;
    let contactNavHeightPx;

    const modalController = getContext(modalContextKey);
    async function handleDeleteContact() {
        if (
            confirm(
                'This contact will be temporarily deleted. After 30 days, all the contact info including messages, form submissions, and action sequences will be permanently deleted. You will not be able to process your settings for this contact during that time.',
            )
        ) {
            let deletedContact = await temporarilyDeleteContacts([contactId]);
            if (deletedContact === undefined) {
                return;
            }
            if (deletedContact.length) {
                let tempContactData = contactData;
                if (tempContactData.data) tempContactData.data.isDeleted = true;
                contactData = tempContactData;
                // if (modalController && modalController.cancel) {
                //     // Couldn't get this to work, so...
                //     // // Trigger 'escape' key to close the modal:
                //     // let keyboardEvent = document.createEvent("KeyboardEvent");
                //     // (keyboardEvent.initKeyboardEvent || keyboardEvent.initKeyEvent).call(keyboardEvent,
                //     //     "keyup", // event type: keydown, keyup, keypress MUST MATCH HANDLER, obviously...
                //     //     true,      // bubbles
                //     //     true,      // cancelable
                //     //     window,    // view: should be window
                //     //     false,     // ctrlKey
                //     //     false,     // altKey
                //     //     false,     // shiftKey
                //     //     false,     // metaKey
                //     //     27,        // keyCode: unsigned long - the virtual key code, else 0
                //     //     0          // charCode: unsigned long - the Unicode character associated with the depressed key, else 0
                //     // );
                //     // window.dispatchEvent(keyboardEvent);
                //     modalController.cancel();
                // } else {
                //     navigate(`${orgPath}/contacts`);
                // }
            }
        }
    }
</script>

<HtmlHeadTitle {pageTitle} />

<Modal>
    <article class="sidebar-layout" class:muted={isUpdatingContact}>
        <aside class="sidebar">
            <div bind:offsetHeight={contactSidebarHeightPx}>
                <header>
                    <h1>
                        {#if _get(contactData, 'ref')}
                            {displayFirstName}
                        {:else}
                            Loading
                        {/if}
                    </h1>
                    {#if displayLastName}
                        <h2>
                            {displayLastName}
                        </h2>
                    {/if}
                    <div class="contact-status">
                        <ContactStatusDropdown
                            bind:open={isContactStatusDropdownOpen}
                            {contactData}
                        />
                    </div>
                </header>
                <div class="actions">
                    <div class="action-row">
                        <a
                            href={paths.messages}
                            class={`button primary raised ${_get(contactData, 'data.isDeleted', false) ? 'disabled-link' : ''}`}
                        >
                            <ion-icon name="chatbubble" class="btn-icon" />
                            <span class="btn-text"> Send Message </span>
                        </a>
                    </div>
                    <div class="action-row">
                        <a
                            href={paths.notes}
                            class={`button primary raised ${_get(contactData, 'data.isDeleted', false) ? 'disabled-link' : ''}`}
                        >
                            <ion-icon
                                name="attach"
                                class="btn-icon"
                                style="font-size:20px; margin-right:-2px;"
                            />
                            <span class="btn-text">
                                {#if _trim(_get(contactData, 'data.notes', ''))}
                                    View Notes
                                {:else}
                                    Add Notes
                                {/if}
                            </span>
                            {#if _trim(_get(contactData, 'data.notes', ''))}
                                <span class="badge-indicator primary"></span>
                            {/if}
                        </a>
                    </div>
                    <!-- <a class="button">
                        <ion-icon name="pencil-outline" class="btn-icon" />
                        <span class="btn-text">
                            note
                        </span>
                    </a> -->
                    <!-- <a class="button">
                        <ion-icon name="alarm-outline" class="btn-icon" />
                        <span class="btn-text">
                            reminder
                        </span>
                    </a> -->
                    <div
                        style="display:flex; flex-direction:row; align-items:center;"
                        class="action-row"
                    >
                        <div style="flex-grow:1; flex-shrink:1;">
                            <a
                                href={paths.edit}
                                class={`button ${_get(contactData, 'data.isDeleted', false) ? 'disabled-link' : ''}`}
                            >
                                <ion-icon name="create" class="btn-icon" />
                                <span class="btn-text"> Edit Profile </span>
                            </a>
                        </div>
                        <div
                            style="flex-grow:1; flex-shrink:1; margin-left:10px;"
                        >
                            <button
                                type="button"
                                on:click|stopPropagation={handleDeleteContact}
                                class= {`danger button ${_get(contactData, 'data.isDeleted', false) ? 'disabled-link' : ''}`}
                                disabled={_get(
                                    contactData,
                                    'data.isDeleted',
                                    false,
                                )}
                            >
                                <ion-icon name="trash" class="btn-icon" />
                                <!-- <span class="btn-text">
                                    Delete Contact
                                </span> -->
                            </button>
                        </div>
                    </div>
                    {#each phoneNumbers as phoneNumber (phoneNumber.ref)}
                        <div
                            style="display:flex; flex-direction:row; align-items:center;"
                            class="action-row"
                        >
                            <div style="flex-grow:0; flex-shrink:1;">
                                <a
                                    href={getPhoneNumberUri(
                                        phoneNumber.data.value,
                                    )}
                                    class={`button ${_get(contactData, 'data.isDeleted', false) ? 'disabled-link' : ''}`}
                                >
                                    <ion-icon name="call" class="btn-icon" />
                                    <!-- <span class="btn-text">
                                    </span> -->
                                </a>
                            </div>
                            <div
                                style="white-space:nowrap; flex-grow:1; flex-shrink:1; overflow:hidden; text-overflow:ellipsis; margin-left:10px;"
                            >
                                {getPhoneNumberDisplay(phoneNumber.data.value)}
                            </div>
                        </div>
                    {/each}
                    {#each emailAddresses as emailAddress (emailAddress.ref)}
                        <div
                            style="display:flex; flex-direction:row; align-items:center;"
                            class="action-row"
                        >
                            <div style="flex-grow:0; flex-shrink:1;">
                                <a
                                    href="mailto:{emailAddress.data.value}"
                                    title={emailAddress.data.value}
                                    class={`button ${_get(contactData, 'data.isDeleted', false) ? 'disabled-link' : ''}`}
                                >
                                    <ion-icon name="mail" class="btn-icon" />
                                    <!-- <span class="btn-text">
                                    </span> -->
                                </a>
                            </div>
                            <div
                                style="white-space:nowrap; flex-grow:1; flex-shrink:1; overflow:hidden; text-overflow:ellipsis; margin-left:10px;"
                                title={emailAddress.data.value}
                            >
                                {emailAddress.data.value}
                            </div>
                        </div>
                    {/each}
                    <!-- <Dropdown open={isContactDropdownOpen} on:cancel={() => isContactDropdownOpen = false}>
                        <button type="button" slot="trigger" class="button contact-dropdown" on:click={() => isContactDropdownOpen = !isContactDropdownOpen}>
                            <ion-icon name="settings" class="btn-icon" style="" />
                            <ion-icon style="display:none;" name="chevron-down-outline" class="btn-caret" />
                        </button>
                        <div slot="menu" class="contact-dropdown-menu">
                            <ul>
                                <li>
                                    <a href={paths.edit} on:click={() => isContactDropdownOpen = !isContactDropdownOpen}>
                                        <ion-icon name="pencil" />
                                        <ion-icon name="create-outline" style="display:none; --ionicon-stroke-width:48px;" />
                                        Edit Profile
                                    </a>
                                </li>
                                <li>
                                    <button type="button" on:click|stopPropagation={handleDeleteContact} class="danger">
                                        <ion-icon name="trash" />
                                        Delete Contact
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </Dropdown> -->
                </div>
            </div>
        </aside>
        <div class="sidebar-content">
            <nav bind:offsetHeight={contactNavHeightPx}>
                <a
                    href={paths.sequences}
                    class={`raised ${_get(contactData, 'data.isDeleted', false) ? 'disabled-link' : ''}`}
                    class:active={isActivePath(location, paths.sequences)}
                >
                    <span class="icon">
                        <ion-icon
                            name="play-forward"
                            style="margin-left:2px;"
                        />
                    </span>
                    <span class="infographic">
                        <span class="label"> action sequence </span>
                        <span class="value">
                            {#if _get(contactData, 'ref')}
                                {#if !currentContactSequence}
                                    no active sequence
                                {:else}
                                    {_get(
                                        currentContactSequence,
                                        'data.sequence.data.name',
                                    ) || 'unnamed sequence'}
                                    {#if _get(currentContactSequence, 'data.isCompleted')}
                                        <ion-icon name="checkmark-circle" />
                                    {:else if _get(currentContactSequence, 'data.isPaused')}
                                        <ion-icon name="pause" />
                                    {:else}
                                        <ion-icon name="play" />
                                    {/if}
                                {/if}
                            {/if}
                        </span>
                    </span>
                </a>
                <a
                    href={paths.messages}
                    class={`raised ${_get(contactData, 'data.isDeleted', false) ? 'disabled-link' : ''}`}
                    class:active={isActivePath(location, paths.messages)}
                >
                    <span class="icon">
                        <ion-icon name="chatbubbles" style="margin-top:-2px;" />
                    </span>
                    <span class="infographic">
                        <span class="label"> messages </span>
                        <span class="value">
                            <!-- receivedMessagesCount received -->
                            {#if _isArray(_get($countReceivedMessagesQuery, 'data.contactMessages.page.data'))}
                                we sent {displayOutboundMessagesCount}, they
                                sent {displayInboundMessagesCount}
                            {/if}
                        </span>
                    </span>
                </a>
                <a
                    href={paths.forms}
                    class={`raised ${_get(contactData, 'data.isDeleted', false) ? 'disabled-link' : ''}`}
                    class:active={isActivePath(location, paths.forms)}
                >
                    <span class="icon">
                        <ion-icon name="clipboard" />
                    </span>
                    <span class="infographic">
                        <span class="label"> forms </span>
                        <span class="value">
                            {formSubmissionsCount} submitted
                        </span>
                    </span>
                </a>
            </nav>
            {#if $contactQuery.errors}
                errors: <pre>{JSON.stringify(
                        $contactQuery.errors,
                        null,
                        4,
                    )}</pre>
            {:else}
                <Router>
                    <Route
                        path="edit"
                        component={EditContactProfile}
                        {contactId}
                        {contactData}
                    />
                    <Route
                        path="notes"
                        component={EditContactNotes}
                        {contactId}
                        {contactData}
                    />
                    <!-- <Route path="">All activity here</Route> -->
                    <Route
                        path="sequences"
                        component={ContactSequences}
                        {contactId}
                        {contactData}
                    />
                    <Route
                        path="messages"
                        component={ContactMessages}
                        {contactId}
                        {contactData}
                        headerHeightPx={contactNavHeightPx}
                        sidebarHeightPx={contactSidebarHeightPx}
                    />
                    <Route
                        path="forms"
                        component={ContactForms}
                        {contactId}
                        {contactData}
                        headerHeightPx={contactNavHeightPx}
                        sidebarHeightPx={contactSidebarHeightPx}
                    />
                </Router>
            {/if}
        </div>
    </article>
</Modal>

<style>
    .sidebar-layout {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        background: var(--offwhite);
        color: var(--offblack);
        border-radius: 4px;
    }
    .sidebar {
        background: var(--slate);
        color: var(--white);
        flex-grow: 0;
        flex-shrink: 0;
        border-radius: 3px 0 0 3px;
        width: 240px;
    }
    .sidebar header {
        padding: 15px 20px 5px;
    }
    .sidebar h1 {
        margin: 0;
        padding: 0;
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
    }
    .sidebar h2 {
        margin: -2px 0 0;
        padding: 0;
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        opacity: 0.8;
    }
    .sidebar .actions {
        padding: 0 20px 40px;
    }
    .sidebar .actions .action-row {
        margin: 10px 0 0;
        line-height: 18px;
        font-size: 13px;
        font-weight: 600;
    }
    .sidebar .actions .button {
        background: transparent;
        color: white;
        box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.33);
        /* margin: 10px 0 0; */
        width: 100%;
        font-weight: 600;
        transition: none;
    }
    .sidebar .actions .button:hover {
        box-shadow: none;
        background: rgba(0, 0, 0, 0.33);
    }
    .sidebar .actions .button.primary {
        border-color: transparent;
        box-shadow: none;
        background: var(--blue);
        color: #fff;
    }
    .sidebar .actions .button.secondary {
        border-color: transparent;
        box-shadow: none;
        background: white;
        color: var(--slate);
    }
    .sidebar .actions .button.danger {
        color: var(--red);
    }
    /* .sidebar .actions .button.danger:hover {
    background: var(--red);
    color: white;
} */
    /* @supports (backdrop-filter: blur(20px)) {
    .sidebar {
        background: rgba(255,255,255,0.5);
        backdrop-filter: blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
    }
} */
    .sidebar-content {
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        background: var(--white);
        color: var(--offblack);
        border-radius: 0 4px 4px 0;
        /* overflow: hidden; */
    }
    nav {
        background: var(--slate-o10);
        display: flex;
        flex-direction: row;
        align-items: stretch;
        padding: 14px 20px;
    }
    nav a {
        flex-grow: 1;
        flex-shrink: 1;
        width: 30%;
        display: block;
        padding: 10px;
        text-decoration: none;
        background: white;
        color: var(--slate);
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-overflow: ellipsis;
        overflow: hidden;
        box-shadow: 0 0 0 2px var(--slate-o10);
        transition: none;
    }
    nav a:hover {
        box-shadow: 0 0 0 2px var(--slate);
    }
    nav a + a {
        margin-left: 20px;
    }
    nav a .icon {
        color: white;
        color: var(--slate);
        background: var(--slate-o10);
        text-align: center;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    nav a .icon ion-icon {
        font-size: 24px;
    }
    nav a .infographic {
        flex-grow: 1;
        flex-shrink: 0;
        margin-left: 8px;
        text-align: left;
    }
    nav a .infographic .label {
        display: block;
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        margin-top: -4px;
    }
    nav a .infographic .value {
        display: block;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }
    nav a .infographic .value ion-icon {
        position: relative;
        top: 1px;
    }
    nav a.active {
        /* border-color: var(--slate); */
        color: var(--blue);
        box-shadow: 0 0 0 2px var(--blue);
    }
    nav a.active .icon {
        /* background: var(--slate); */
        /* color: #fff; */
        color: white;
        color: var(--blue);
        background: var(--lightblue);
    }
    .badge-indicator {
        background: var(--red);
        color: white;
        border-radius: 3px;
        width: 6px;
        height: 6px;
        position: relative;
        left: -1px;
        top: -4px;
        /* box-shadow: 0 1px 1px rgba(0,0,0,0.2); */
    }
    .badge-indicator.primary {
        background: var(--blue);
    }
    .disabled-link {
        pointer-events: none;
    }
    /* .sidebar-content header {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
}
.sidebar-content hgroup {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 15px;
}
.sidebar-content hgroup h1 {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 600;
}
.sidebar-content hgroup h2 {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
}
.sidebar-content hgroup h2 a {
    text-decoration: none;
} */
    /* .contact-dropdown {
    background: none;
    margin: 0 auto;
    align-items: center;
    width: 100%;
}
.contact-dropdown .btn-caret {
    opacity: 0.6;
}
.contact-dropdown-menu {
    background: var(--slate);
    color: white;
    box-shadow: rgba(0,0,0,.2) 0 1px 2px;
    border-radius: 4px;
    padding: 4px 0;
    margin-top: 2px;
    min-width: 115%;
    overflow: hidden;
    transition: transform 150ms ease-in-out;
    transform: scale(0.97) translateZ(0);
}
.contact-dropdown-menu:hover {
    transform: scale(1) translateZ(0);
}
.contact-dropdown-menu ul,
.contact-dropdown-menu li {
    list-style: none;
    padding: 0;
    margin: 0;
}
.contact-dropdown-menu a:hover,
.contact-dropdown-menu button:hover {
    background: rgba(0,0,0,0.2);
}
.contact-dropdown-menu a.active,
.contact-dropdown-menu button.active {
    background: var(--blue);
}
.contact-dropdown-menu a,
.contact-dropdown-menu button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    padding: 5px 10px;
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
} */
</style>
