<script>
    import {writable} from 'svelte/store';
    export let initialColor = '#ff0000';
    export let defaultColor = '#ff0000';
    export let onChangeColorPicker;
    let hue;
    let saturation;
    let lightness;
    let showPicker = writable(false);
    let isDragging = writable(false);

    let buttonColors = [
        '#000000',
        '#FFFFFF',
        '#DD3333',
        '#DD9933',
        '#EEEE22',
        '#82D743',
        '#1D73Bd',
        '#8124E4',
    ];
    const pickedColor = writable(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
    const hexColor = writable(hslToHex(hue, saturation, lightness));

    function hslToHex(h, s, l) {
        l /= 100;
        const a = (s * Math.min(l, 1 - l)) / 100;
        const f = (n) => {
            const k = (n + h / 30) % 12;
            const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
            return Math.round(255 * color)
                .toString(16)
                .padStart(2, '0');
        };
        return `${f(0)}${f(8)}${f(4)}`;
    }

    function hexToHsl(H) {
        let r = 0,
            g = 0,
            b = 0;
        if (H.length === 4) {
            r = parseInt(H[1] + H[1], 16);
            g = parseInt(H[2] + H[2], 16);
            b = parseInt(H[3] + H[3], 16);
        } else if (H.length === 7) {
            r = parseInt(H[1] + H[2], 16);
            g = parseInt(H[3] + H[4], 16);
            b = parseInt(H[5] + H[6], 16);
        }
        r /= 255;
        g /= 255;
        b /= 255;
        const cmin = Math.min(r, g, b),
            cmax = Math.max(r, g, b),
            delta = cmax - cmin;
        let h = 0,
            s = 0,
            l = (cmax + cmin) / 2;

        if (delta === 0) h = 0;
        else if (cmax === r) h = ((g - b) / delta) % 6;
        else if (cmax === g) h = (b - r) / delta + 2;
        else h = (r - g) / delta + 4;
        h = Math.round(h * 60);
        if (h < 0) h += 360;

        s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
        s = +(s * 100).toFixed(1);
        l = +(l * 100).toFixed(1);

        return {h, s, l};
    }

    function handleColorPicker(event) {
        isDragging.set(true);
        const rect = event.target.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        hue = Math.round((x / rect.width) * 360);
        saturation = Math.round((1 - y / rect.height) * 100);
        lightness = 50;
        updateColor();
    }

    function handleDrag(event) {
        if (!$isDragging) return;
        handleColorPicker(event);
    }

    function handleLightnessChange(event) {
        lightness = event.target.value;
        updateColor();
    }

    function updateColor() {
        const hslColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
        pickedColor.set(hslColor);
        hexColor.set(`${hslToHex(hue, saturation, lightness)}`);
    }

    function resetToDefault() {
        const hsl = hexToHsl(defaultColor);
        hue = hsl.h;
        saturation = hsl.s;
        lightness = hsl.l;
        updateColor();
    }

    function togglePicker() {
        showPicker.update((v) => !v);
    }

    function handleConfirmButton() {
        onChangeColorPicker('#' + hslToHex(hue, saturation, lightness));
        showPicker.update((v) => (v = false));
    }

    function handleCancelButton() {
        const hsl = hexToHsl(initialColor);
        hue = hsl.h;
        saturation = hsl.s;
        lightness = hsl.l;
        updateColor();
        showPicker.update((v) => (v = false));
    }
    $: {
        if (initialColor) {
            const hsl = hexToHsl(initialColor);
            hue = hsl.h;
            saturation = hsl.s;
            lightness = hsl.l;
            updateColor();
        }
    }

    function handleHexChange(event) {
        const hex = '#' + event.target.value;
        if (hex.length == 7 && /^#([0-9a-f]{3}){1,2}$/i.test(hex)) {
            const hsl = hexToHsl(hex);
            hue = hsl.h;
            saturation = hsl.s;
            lightness = hsl.l;
            updateColor();
        }
    }

    function handleColorButtonClick(color) {
        const hsl = hexToHsl(color);
        hue = hsl.h;
        saturation = hsl.s;
        lightness = hsl.l;
        updateColor();
    }
</script>

<div class="color-picker">
    <div class="color-picker-header">
        <div on:click={togglePicker} class="color-picker-selector">
            <div
                style="background: {$pickedColor}; color: white; width: 40px; height: 30px; border: 1px solid #aaaaaa; border-radius: 0px;"
            ></div>
            <div
                style="display: flex; align-items: center; justify-content: center; padding: 0px 10px;
         height: 30px; border: 1px solid #aaaaaa; border-radius: 0px; border-left: 0px solid white; width: 70px;"
            >
                Select Color
            </div>
        </div>
        {#if $showPicker}
            <div class="hex-input-container">
                <span class="hash-symbol">#</span>
                <input
                    type="text"
                    id="hex"
                    bind:value={$hexColor}
                    on:input={handleHexChange}
                    class="hex-input"
                    maxlength="6"
                />
            </div>
            <div
                class="default-button"
                on:click|stopPropagation={resetToDefault}
            >
                Default
            </div>
        {/if}
    </div>

    {#if $showPicker}
        <div class="popover">
            <div class="color-picker-panel">
                <div class="color-picker-spectrum">
                    <div
                        class="spectrum"
                        on:mousedown={handleColorPicker}
                        on:mousemove={handleDrag}
                        on:mouseup={() => isDragging.set(false)}
                        on:mouseleave={() => isDragging.set(false)}
                    >
                        <div
                            class="color-indicator"
                            style="background: hsl({hue}, {saturation}%, 50%); left: {(hue /
                                360) *
                                100}%; top: {100 -
                                saturation}%; border: 2px solid white"
                        ></div>
                    </div>
                    <div class="color-button-container">
                        {#each buttonColors as color}
                            <button
                                class="color-button"
                                style="background: {color};"
                                on:click={() => handleColorButtonClick(color)}
                            ></button>
                        {/each}
                    </div>
                </div>
                <div>
                    <input
                        type="range"
                        class="slider"
                        min="0"
                        max="100"
                        value={lightness}
                        on:input|stopPropagation={handleLightnessChange}
                        style="background: linear-gradient(to bottom, hsl({hue}, {saturation}%, 0%), hsl({hue}, {saturation}%, 50%), hsl({hue}, {saturation}%, 100%)); "
                    />
                </div>
            </div>
            <div class="confirm-button-group">
                <button
                    type="button"
                    class="button"
                    on:click={handleConfirmButton}>Confirm</button
                >
                <button
                    type="button"
                    class="button"
                    on:click={handleCancelButton}>Cancel</button
                >
            </div>
        </div>
    {/if}
</div>

<style>
    .color-picker {
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: 300px;
        font-size: 12px;
    }
    .spectrum {
        width: 200px;
        height: 200px;
        background: linear-gradient(
            to right,
            #f00 0%,
            #ff0 17%,
            #0f0 33%,
            #0ff 50%,
            #00f 67%,
            #f0f 83%,
            #f00 100%
        );
        position: relative;
        cursor: crosshair;
        border-radius: 4px;
    }
    .spectrum::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, black, transparent);
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 4px;
    }

    .color-indicator {
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        pointer-events: none;
        transform: translate(-50%, -50%);
        cursor: grab;
        z-index: 999;
    }

    .slider {
        width: 20px;
        height: 220px;
        -webkit-appearance: none;
        appearance: none;
        border-radius: 2px;
        border: 1px solid #aaaaaa;
        cursor: pointer;
        writing-mode: vertical-lr;
    }
    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 28px;
        height: 14px;
        border-radius: 2px;
        cursor: pointer;
        border: 3px solid #aaa;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }
    .hex-input-container {
        display: flex;
        align-items: center;
        position: relative;
        border: 0px solid #aaaaaa;
    }
    .hash-symbol {
        position: absolute;
        left: 10px;
    }
    .hex-input {
        height: 32px;
        width: 90px;
        padding: 0px 10px;
        border: 1px solid #aaaaaa;
        border-radius: 2px;
        padding-left: 20px;
    }
    .default-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        padding: 0px 10px;
        cursor: pointer;
        border-radius: 2px;
        border: 1px solid #aaaaaa;
    }
    .default-button:hover {
        color: #4286f5;
    }
    .popover {
        width: 240px;
        z-index: 10;
        background: white;
        border: 1px solid #ccc;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        padding: 10px;
    }
    .color-picker-panel {
        display: flex;
        justify-content: space-between;
    }
    .confirm-button-group {
        padding: 10px;
        display: flex;
        justify-content: space-between;
    }
    .color-picker-header {
        gap: 2px;
        display: flex;
        align-items: center;
    }
    .color-picker-selector {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .color-picker-selector:hover {
        color: #4286f5;
    }
    .color-picker-spectrum {
        width: 200px;
    }
    .color-button {
        height: 22px;
        width: 22px;
        border-radius: 2px;
        border: 1px solid #bbbbbb;
    }
    .color-button-container {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
    }
    .button {
        border: 1px solid #cccccc;
        border-radius: 2px;
        width: 80px;
    }
    .button:hover {
        color: var(--blue);
        border: 1px solid var(--blue);
    }
</style>
