<script context="module">
import gql from 'graphql-tag';

const FRAGMENTS = {};
FRAGMENTS.SequenceDelay_sequenceAction = gql`
    fragment SequenceDelay_sequenceAction on SequenceActionData {
        delay {
            minDelay
            minDelayUnits
            allowedDays
            allowedStartTime {
                hour
                minute
                pm
            }
            allowedEndTime {
                hour
                minute
                pm
            }
        }
    }
`;

const QUERIES = {};
// QUERIES.sequence = gql`
//     query sequence($id: ID!) {
//         findSequenceByID(id: $id) {
//             ...editSeqActsSequence
//         }
//     }
//     ${FRAGMENTS.editSeqActsSequence}
// `;

const MUTATIONS = {};
// MUTATIONS.updateSequenceAction = gql`
//     mutation updateSequenceAction($id: ID!, $data: [PartialUpdateSequenceActionInput!]!) {
//         partialUpdateSequenceAction(id: $id, data: $data) {
//             ...SequenceDelay_sequenceAction
//         }
//     }
//     ${FRAGMENTS.SequenceDelay_sequenceAction}
// `;

export {
    FRAGMENTS,
    QUERIES,
    MUTATIONS,
}
</script>

<script>
import { createEventDispatcher } from 'svelte';
import { getClient } from 'svelte-apollo';
import Select from 'svelte-select';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _toLower from 'lodash/toLower';
import _defaults from 'lodash/defaults';

const client = getClient();
const dispatch = createEventDispatcher();

export let sequenceActionData;
let sequenceDelay = null;
$: sequenceDelay = _defaults(_get(sequenceActionData, 'data.delay'), {
    minDelay: 0,
    minDelayUnits: 'NIGHTS',
    allowedDays: 'ANY',
});

let hourNumberToString = (n) => n.toString();
let minuteNumberToString = (n) => (n < 10 ? '0' : '') + n.toString();
const allowedDaysDisplayText = {
    'ANY': 'any day',
    'WEEKDAY': 'a weekday',
    'WEEKEND': 'a weekend',
    'MONDAY': 'a Monday',
    'TUESDAY': 'a Tuesday',
    'WEDNESDAY': 'a Wednesday',
    'THURSDAY': 'a Thursday',
    'FRIDAY': 'a Friday',
    'SATURDAY': 'a Saturday',
    'SUNDAY': 'a Sunday',
};
const allowedTimeDisplayText = {
    'AT': 'at',
    'BETWEEN': 'between',
    'ANY': 'at any time of day',
};

$: minDelayEntry = _get(sequenceDelay, 'minDelay');
$: minDelayUnitsEntry = _get(sequenceDelay, 'minDelayUnits');
$: allowedDaysEntry = _get(sequenceDelay, 'allowedDays');
$: allowedStartTimeHourEntry = _has(sequenceDelay, 'allowedStartTime.hour') && hourNumberToString(_get(sequenceDelay, 'allowedStartTime.hour'));
$: allowedStartTimeMinuteEntry = _has(sequenceDelay, 'allowedStartTime.minute') && minuteNumberToString(_get(sequenceDelay, 'allowedStartTime.minute'));
$: allowedStartTimeAmPmEntry = _get(sequenceDelay, 'allowedStartTime.pm') ? 'PM' : 'AM';
$: allowedEndTimeHourEntry = _has(sequenceDelay, 'allowedEndTime.hour') && hourNumberToString(_get(sequenceDelay, 'allowedEndTime.hour'));
$: allowedEndTimeMinuteEntry = _has(sequenceDelay, 'allowedEndTime.minute') && minuteNumberToString(_get(sequenceDelay, 'allowedEndTime.minute'));
$: allowedEndTimeAmPmEntry = _get(sequenceDelay, 'allowedEndTime.pm') ? 'PM' : 'AM';

let timingEntry = 'ANY';
$: if (_get(sequenceDelay, 'allowedEndTime')) {
    timingEntry = 'BETWEEN';
} else if (_get(sequenceDelay, 'allowedStartTime')) {
    timingEntry = 'AT';
} else {
    timingEntry = 'ANY';
}
</script>

<div>
    <div class="fieldrow cols with-spacing">
        <label class="fieldlabel fixedcol" for="input-minDelay">
            Wait at least
        </label>
        <div class="fixedcol cols">
            <div class="fixedcol minute">
                <input type="text" value={minDelayEntry} disabled />
            </div>
            <div class="fixedcol" style="margin-left:6px;">
                <input type="text" value={_toLower(minDelayUnitsEntry)} disabled />
            </div>
        </div>
    </div>
    <div class="fieldrow cols with-spacing" style="align-items:flex-start; padding-top:0;">
        <label class="fieldlabel fixedcol" style="margin-top:20px;">
            Continue on
        </label>
        <div class="flexcol cols with-spacing flexwrap">
            <div class="growcol">
                <input type="text" value={allowedDaysDisplayText[allowedDaysEntry]} disabled />
            </div>
            <div class="flexcol cols with-spacing flexwrap">
                <div class="growcol">
                    <input type="text" value={allowedTimeDisplayText[timingEntry]} disabled />
                </div>
                <div class="flexcol cols with-spacing flexwrap">
                    {#if ['AT', 'BETWEEN'].indexOf(timingEntry) >= 0}
                        <div class="fixedcol cols">
                            <div class="fixedcol hour">
                                <input type="text" value={allowedStartTimeHourEntry} disabled />
                            </div>
                            <div class="fixedcol" style="padding:0 1px;">
                                <strong>:</strong>
                            </div>
                            <div class="fixedcol minute">
                                <input type="text" value={allowedStartTimeMinuteEntry} disabled />
                            </div>
                            <div class="fixedcol ampm" style="margin-left:6px;">
                                <input type="text" value={allowedStartTimeAmPmEntry} disabled />
                            </div>
                        </div>
                    {/if}
                    {#if 'BETWEEN' == timingEntry}
                        <div class="fixedcol">
                            and
                        </div>
                        <div class="fixedcol cols">
                            <div class="fixedcol hour">
                                <input type="text" value={allowedEndTimeHourEntry} disabled />
                            </div>
                            <div class="fixedcol" style="padding:0 1px;">
                                <strong>:</strong>
                            </div>
                            <div class="fixedcol minute">
                                <input type="text" value={allowedEndTimeMinuteEntry} disabled />
                            </div>
                            <div class="fixedcol ampm" style="margin-left:6px;">
                                <input type="text" value={allowedEndTimeAmPmEntry} disabled />
                            </div>
                        </div>
                    {/if}
                </div>
            </div>
        </div>
    </div>
</div>

<style>
.fieldlabel.fixedcol {
    font-weight: 400;
    width: 90px;
    padding: 0;
}
.cols {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
}
.cols .cols {
    width: auto;
}
.flexwrap {
    flex-wrap: wrap;
}
.fixedcol {
    flex-grow: 0;
    flex-shrink: 0;
}
.flexcol {
    flex-grow: 1;
    flex-shrink: 1;
}
.growcol {
    flex-grow: 1;
    flex-shrink: 0;
}
.flexwrap > * {
    margin-top: 10px;
}
.flexwrap > .flexwrap {
    margin-top: 0;
}
.cols.with-spacing > * + * {
    margin-left: 20px;
}
.fixedcol.hour,
.fixedcol.minute {
    width: 60px;
}
.fixedcol.ampm {
    width: 70px;
}
</style>
