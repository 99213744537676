import {readable} from 'svelte/store';

const watchQuery = (client, options) => {
    // console.log('client => ', client, options);
    const observableQuery = client.watchQuery(options);
    const withObservableQuery = (result) => {
        // result.observableQuery = observableQuery;
        // return result;
        const updatedResult = {...result};
        updatedResult.observableQuery = observableQuery;
        return updatedResult;
    };

    let frozenPollInterval;
    const pausePollingIfHidden = () => {
        if (document.hidden) {
            frozenPollInterval = observableQuery.options.pollInterval;
            observableQuery.stopPolling();
        } else if (frozenPollInterval) {
            observableQuery.refetch();
            observableQuery.startPolling(frozenPollInterval);
        }
    };
    document.addEventListener('visibilitychange', pausePollingIfHidden);
    pausePollingIfHidden();
    // console.log('observableQuery : ', observableQuery);
    return readable(
        withObservableQuery(observableQuery.currentResult()),
        (set) => {
            // const result = await observableQuery.result();
            // set(result);
            const subscription = observableQuery.subscribe(
                (x) => set(withObservableQuery(x)),
                (err) => console.log(`Finished with error: ${err}`),
                () => console.log('Finished'),
            );
            return () => {
                subscription.unsubscribe();
                document.removeEventListener(
                    'visibilitychange',
                    pausePollingIfHidden,
                );
            };
        },
    );
};

export {watchQuery};
