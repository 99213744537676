const _trim = require('lodash/trim');
const _values = require('lodash/values');
const _startsWith = require('lodash/startsWith');
const _includes = require('lodash/includes');

let defaultSubjectsBySource = {
    sms: 'New Text Message',
    app: 'New Message',
};

let isDerivedSubjectLine = (subject) => {
    let prefixes = ['Re:', 'Fwd:'];
    while (true) {
        subject = _trim(subject);
        let prefix = 'Re:';
        if (
            _startsWith(subject, prefix) ||
            _startsWith(subject, prefix.toLowerCase())
        ) {
            subject = subject.substring(prefix.length);
            continue;
        }
        prefix = 'Fwd:';
        if (
            _startsWith(subject, prefix) ||
            _startsWith(subject, prefix.toLowerCase())
        ) {
            subject = subject.substring(prefix.length);
            continue;
        }
        break;
    }
    return _includes(defaultSubjectsBySource, subject);
};

module.exports = {
    bySource: defaultSubjectsBySource,
    isDerivedSubjectLine: isDerivedSubjectLine,
};
