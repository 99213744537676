<script>
import { getFilestackUrl } from '../filestack';

export let fileHandle;

let fileUrl;
$: fileUrl = fileHandle ? getFilestackUrl(fileHandle, 'HOSTED_FORM_HEADER') : null;
</script>

<div class="logo-container">
    {#if fileUrl}
        <img src={fileUrl} class="logo" />
    {:else}
        <div class="nologo">
            <span>
                No logo yet!
            </span>
        </div>
    {/if}
</div>

<style>
.logo-container {
    display: inline-block;
    height: 80px;
    max-width: 320px;
    padding: 10px;
    border-radius: 10px;
    border: 1px dashed var(--slate-o20);
}
.logo {
    max-height: 100%;
    max-width: 100%;
}
.nologo {
    display: flex;
    width: 80px;
    height: 80px;
    font-size: 12px;
    font-weight: 600;
    flex-direction: row;
    align-items: center;
    background: var(--slate-o10);
    padding: 5px;
    justify-content: center;
    box-sizing: border-box;
}
</style>
