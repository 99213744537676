<script context="module">
  import gql from "graphql-tag";
</script>

<script>
  import { fly, fade } from "svelte/transition";
  export let handleModalClose;
  export let orgData;
  export let handleSubmitFilterOptions;
  export let filterOption;

  let modalNode;

  const modalPaddingPx = 50;

  function handleEscape(event) {
    if (event.keyCode == 27) {
      handleModalClose();
    }
  }

  let creationDateFilterOptions = [
    "Past 7 days",
    "Past 30 days",
    "This month",
    "Last month",
    "Custom date range",
  ];

  let submissionDateFilterOptions = [
    "Past 7 days",
    "Past 30 days",
    "This month",
    "Last month",
    "Custom date range",
  ];

  let selectedSubItems = filterOption;
  let statusFilterOptions = orgData ? orgData.contactStatuses : [];
  let actionSequenceFilterOptions = orgData ? orgData.sequences : [];
  let leadFormFilterOptions = orgData ? orgData.forms : [];
  let filters = [
    {
      name: "Creation Date",
      options: [
        "Past 7 days",
        "Past 30 days",
        "This month",
        "Last month",
        "Custom date range",
      ],
      type: "radio",
    },
    {
      name: "Submission Date",
      options: [
        "Past 7 days",
        "Past 30 days",
        "This month",
        "Last month",
        "Custom date range",
      ],
      type: "radio",
    },
    {
      name: "Status",
      options: orgData.contactStatuses,
      type: "checkbox",
    },
    {
      name: "Action Sequence",
      options: orgData.sequences,
      type: "checkbox",
    },
    {
      name: "Lead Form",
      options: orgData.forms,
      type: "checkbox",
    },
  ];

  let selectedMainItem = "Creation Date"; // Default selected

  // Handle checkbox toggle
  function toggleSelection(list, item) {
    if (list.includes(item)) {
      list = list.filter((i) => i !== item);
    } else {
      list = [...list, item];
    }
    return list;
  }

  function handleChangeStatusCheckBox(id, name) {
    let a;
    if (selectedSubItems.status.some((item) => item.id === id)) {
      a = selectedSubItems.status.filter((i) => i.id !== id);
    } else {
      a = [...selectedSubItems.status, { id: id, name: name }];
    }
    return a;
  }

  function handleChangeActionSequenceCheckBox(id, name) {
    let a;
    if (selectedSubItems.actionSequence.some((item) => item.id === id)) {
      a = selectedSubItems.actionSequence.filter((i) => i.id !== id);
    } else {
      a = [
        ...selectedSubItems.actionSequence,
        { id: id, name: name, option: "Active" },
      ];
    }
    return a;
  }

  function handleActionSequenceOptionRadioChange(optionRef, selectedOption) {
    selectedSubItems.actionSequence = selectedSubItems.actionSequence.map(
      (item) =>
        item.id === optionRef ? { ...item, option: selectedOption } : item
    );
  }

  function handleChangeLeadFormCheckBox(id, name) {
    let a;
    if (selectedSubItems.leadForm.some((item) => item.id === id)) {
      a = selectedSubItems.leadForm.filter((i) => i.id !== id);
    } else {
      a = [...selectedSubItems.leadForm, { id: id, name: name }];
    }
    return a;
  }

  let subItemboxTopValue = "20";
  let subItemboxStyle = "position: absolute; top:" + subItemboxTopValue + "px;";
  function handleMainItemClick(event) {
    switch (event.target.id) {
      case "Creation Date":
        subItemboxTopValue = "20";
        break;
      case "Submission Date":
        subItemboxTopValue = "76";
        break;
      case "Status":
        subItemboxTopValue = "132";
        break;
      case "Action Sequence":
        subItemboxTopValue = "188";
        break;
      case "Lead Form":
        subItemboxTopValue = "244";
        break;
      default:
        break;
    }
    subItemboxStyle = "position: absolute; top:" + subItemboxTopValue + "px;";
    selectedMainItem = event.target.id;
  }
  let startDateForCreation;
  let endDateForCreation;
  let startDateForSubmission;
  let endDateForSubmission;

  function handleSubmitButton() {
    if (
      selectedSubItems.creationDate &&
      selectedSubItems.creationDate.name == "Custom date range" &&
      (!startDateForCreation || !endDateForCreation)
    ) {
      alert(
        "You selected creation date filter as custom date range. Please select start date and end date."
      );
    } else if (
      selectedSubItems.submissionDate &&
      selectedSubItems.submissionDate.name == "Custom date range" &&
      (!startDateForSubmission || !endDateForSubmission)
    ) {
      alert(
        "You selected submission date filter as custom date range. Please select start date and end date."
      );
    } else {
      handleSubmitFilterOptions(selectedSubItems);
    }
  }
</script>

<svelte:window on:keydown={handleEscape} />

<div class="modal-container" style="padding:{modalPaddingPx}px;">
  <div class="modal-backdrop" transition:fade={{ duration: 200 }} />
  <div class="modal-close" on:click={handleModalClose}>
    <ion-icon name="close-outline" />
    <span class="keyboard-shortcut"> esc </span>
  </div>
  <div class="modal">
    <!-- <slot /> -->
    <div
      class="filter-modal"
      bind:this={modalNode}
      transition:fly={{ y: 100, duration: 200 }}
    >
      <div class="filter-header">
        <span>Filter Options</span>
        <button class="close-btn" on:click={handleModalClose}>×</button>
      </div>
      <div class="filter-container">
        <div class="filter-main-items">
          {#each filters as filter}
            <button
              id={filter.name}
              class:selected={selectedMainItem === filter.name}
              on:click={handleMainItemClick}
            >
              {filter.name}
              {#if selectedMainItem === filter.name}
                <ion-icon name="caret-forward-outline" />
              {:else}
                <ion-icon name="caret-down-outline" />
              {/if}
            </button>
          {/each}
        </div>

        <div class="filter-sub-items">
          <div class="filter-sub-items-box" style={subItemboxStyle}>
            {#if selectedMainItem === "Creation Date"}
              {#each creationDateFilterOptions as option}
                <label>
                  <input
                    type="radio"
                    name="Creation Date"
                    value={option}
                    checked={selectedSubItems.creationDate?.name == option}
                    on:change={(event) => {
                      selectedSubItems.creationDate = {
                        name: event.target.value,
                      };
                    }}
                  />
                  {option}
                </label>
                {#if selectedSubItems.creationDate && selectedSubItems.creationDate.name == "Custom date range" && option == "Custom date range"}
                  <div class="date-range-picker">
                    <div>
                      <label for="start-date-input">From : </label>
                      <input
                        id="start-date-input"
                        type="date"
                        value={startDateForCreation}
                        on:change={(event) => {
                          startDateForCreation = event.target.value;
                          selectedSubItems.creationDate.from =
                            startDateForCreation;
                        }}
                      />
                    </div>
                    <div>
                      <label for="end-date-input">To : </label>
                      <input
                        id="end-date-input"
                        type="date"
                        value={endDateForCreation}
                        on:change={(event) => {
                          endDateForCreation = event.target.value;
                          selectedSubItems.creationDate.to = endDateForCreation;
                        }}
                      />
                    </div>
                  </div>
                {/if}
              {/each}
            {:else if selectedMainItem === "Submission Date"}
              {#each submissionDateFilterOptions as option}
                <label>
                  <input
                    type="radio"
                    name="Submission Date"
                    value={option}
                    checked={selectedSubItems.submissionDate?.name == option}
                    on:change={(event) => {
                      selectedSubItems.submissionDate = {
                        name: event.target.value,
                      };
                    }}
                  />
                  {option}
                </label>
                {#if selectedSubItems.submissionDate && selectedSubItems.submissionDate.name == "Custom date range" && option == "Custom date range"}
                  <div class="date-range-picker">
                    <div>
                      <label for="start-date-input">From : </label>
                      <input
                        id="start-date-input"
                        type="date"
                        value={startDateForSubmission}
                        on:change={(event) => {
                          startDateForSubmission = event.target.value;
                          selectedSubItems.submissionDate.from =
                            startDateForSubmission;
                        }}
                      />
                    </div>
                    <div>
                      <label for="end-date-input">To : </label>
                      <input
                        id="end-date-input"
                        type="date"
                        value={endDateForSubmission}
                        on:change={(event) => {
                          endDateForSubmission = event.target.value;
                          selectedSubItems.submissionDate.to =
                            endDateForSubmission;
                        }}
                      />
                    </div>
                  </div>
                {/if}
              {/each}
            {:else if selectedMainItem === "Status"}
              {#each statusFilterOptions as option}
                <label>
                  <input
                    type="checkbox"
                    value={option.ref}
                    checked={selectedSubItems.status.some(
                      (item) => item.id == option.ref
                    )}
                    on:change={() =>
                      (selectedSubItems.status = handleChangeStatusCheckBox(
                        option.ref,
                        option.data.name
                      ))}
                  />
                  {option.data.name}
                </label>
              {/each}
            {:else if selectedMainItem === "Action Sequence"}
              {#each actionSequenceFilterOptions as option}
                <label>
                  <input
                    type="checkbox"
                    value={option.ref}
                    checked={selectedSubItems.actionSequence.some(
                      (item) => item.id == option.ref
                    )}
                    on:change={() =>
                      (selectedSubItems.actionSequence =
                        handleChangeActionSequenceCheckBox(
                          option.ref,
                          option.data.name
                        ))}
                  />
                  {option.data.name}
                </label>
                {#if selectedSubItems.actionSequence.some((item) => item.id == option.ref)}
                  <div class="action-sequence-radio-box">
                    <label>
                      <input
                        type="radio"
                        name="sequenceOption_{option.ref}"
                        value="Active"
                        checked={selectedSubItems.actionSequence.find(
                          (item) => item.id === option.ref
                        )?.option === "Active"}
                        on:change={() =>
                          handleActionSequenceOptionRadioChange(
                            option.ref,
                            "Active"
                          )}
                      />
                      Active
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="sequenceOption_{option.ref}"
                        value="Paused"
                        checked={selectedSubItems.actionSequence.find(
                          (item) => item.id === option.ref
                        )?.option === "Paused"}
                        on:change={() =>
                          handleActionSequenceOptionRadioChange(
                            option.ref,
                            "Paused"
                          )}
                      />
                      Paused
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="sequenceOption_{option.ref}"
                        value="Completed"
                        checked={selectedSubItems.actionSequence.find(
                          (item) => item.id === option.ref
                        )?.option === "Completed"}
                        on:change={() =>
                          handleActionSequenceOptionRadioChange(
                            option.ref,
                            "Completed"
                          )}
                      />
                      Completed
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="sequenceOption_{option.ref}"
                        value="All"
                        checked={selectedSubItems.actionSequence.find(
                          (item) => item.id === option.ref
                        )?.option === "All"}
                        on:change={() =>
                          handleActionSequenceOptionRadioChange(
                            option.ref,
                            "All"
                          )}
                      />
                      All
                    </label>
                  </div>
                {/if}
              {/each}
            {:else if selectedMainItem === "Lead Form"}
              {#each leadFormFilterOptions as option}
                <label>
                  <input
                    type="checkbox"
                    value={option.ref}
                    checked={selectedSubItems.leadForm.some(
                      (item) => item.id == option.ref
                    )}
                    on:change={() =>
                      (selectedSubItems.leadForm = handleChangeLeadFormCheckBox(
                        option.ref,
                        option.data.title
                      ))}
                  />
                  {option.data.title}
                </label>
              {/each}
            {/if}
          </div>
        </div>
      </div>
      <div class="filter-footer">
        <button on:click={handleSubmitButton}> Add Filter Options </button>
      </div>
    </div>
  </div>
</div>

<style>
  .modal-container {
    /* overscroll-behavior: contain; */
    background: rgba(0, 0, 0, 0.01); /* fix scrollbar contrast */
    position: absolute;
    overflow: auto;
    --webkit-overflow-scrolling: touch;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* padding: 80px; */
  }
  .modal-backdrop {
    background: var(--offblack-backdrop);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  @supports (backdrop-filter: blur(20px)) {
    .modal-backdrop {
      background: var(--offblack-backdrop-blur);
      backdrop-filter: blur(20px);
      backdrop-filter: saturate(180%) blur(20px);
    }
  }
  .modal-close {
    color: white;
    position: fixed;
    top: 0;
    right: 0;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  .modal-close ion-icon {
    font-size: 48px;
    padding-top: 2px;
  }
  .modal-close .keyboard-shortcut {
    /* border: 1px solid; */
    border-radius: 3px;
    font-size: 12px;
    /* font-family: monospace; */
    font-weight: 400;
    line-height: 14px;
    padding: 0 4px 1px;
    margin-top: -8px;
    opacity: 0.62;
  }
  .modal {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal > :global(*) {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  }
  .filter-modal {
    /* background-color: white; */
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 60%;
    max-width: 100%;
  }
  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--blue);
    color: white;
    border-radius: 8px 8px 0 0;
    font-weight: 600;
  }
  /* .filter-header h3 {
    margin: 0;
    font-size: 1.25rem;
  } */

  .close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .filter-container {
    display: flex;
    width: 100%;
    background-color: white;
  }

  /* Left side: Main filter items */
  .filter-main-items {
    /* background-color: #f1f1f1; */
    padding: 1rem;
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 20px;
    gap: 15px;
  }

  .filter-main-items button {
    background-color: transparent;
    border: none;
    padding: 10px;
    font-size: 14px;
    text-align: left;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #cccccc;
    border-radius: 8px;
    /* color: var(--blue); */
  }

  /* Highlight selected main item */
  .filter-main-items button.selected {
    background-color: #f1f1f1;
    color: var(--blue);
  }

  .filter-main-items button .arrow-icon {
    font-size: 1.25rem;
  }

  /* Right side: Sub-items */
  .filter-sub-items {
    padding: 20px 20px 20px 0px;
    flex-grow: 1;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    height: 100%;
    position: relative;
  }

  .filter-sub-items-box {
    padding: 10px;
    max-height: 300px;
    display: flex;
    border: 1px solid #ccc;
    /* width: 80%; */
    border-radius: 8px;
    flex-direction: column;
    flex-grow: 1;
    background-color: #f1f1f1;
    overflow-y: auto;
    right: 20px;
    left: 10px;
    /* position: absolute; */
    font-size: 14px;
    font-weight: 600;
  }

  .filter-sub-items-box label {
    display: flex;
    /* align-items: center; */
    margin: 0.5rem 0;
  }

  .filter-sub-items-box input[type="radio"],
  .filter-sub-items-box input[type="checkbox"] {
    margin-right: 0.5rem;
  }

  /* Footer */
  .filter-footer {
    text-align: center;
    padding: 40px 20px 20px 20px;
    border-radius: 0px 0px 8px 8px;
    /* border-top: 1px solid #e3e6f0; */
    background-color: white;
    font-weight: 600;
  }

  .filter-footer button {
    background-color: var(--blue);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }

  .filter-footer button:hover {
    background-color: #2e59d9;
  }

  .date-range-picker {
    margin-left: 20px;
    display: flex;
    gap: 10px;
  }
  .action-sequence-radio-box {
    margin-left: 20px;
  }
</style>
