<script context="module">
    import gql from 'graphql-tag';
    import {FRAGMENTS as SequenceDelay_FRAGMENTS} from './SequenceDelay.svelte';
    import {FRAGMENTS as EditSequenceDelay_FRAGMENTS} from './EditSequenceDelay.svelte';
    import {FRAGMENTS as MessageTemplateConnection_FRAGMENTS} from './MessageTemplateConnection.svelte';
    import {FRAGMENTS as MessageTemplateBlock_FRAGMENTS} from './MessageTemplateBlock.svelte';

    const FRAGMENTS = {};
    FRAGMENTS.EditSequenceActionItem_sequenceAction = gql`
        fragment EditSequenceActionItem_sequenceAction on SequenceAction {
            ref
            data {
                type
                ...SequenceDelay_sequenceAction
                message {
                    ref
                    data {
                        ...MessageTemplateConnection_messageTemplate
                    }
                }
                sequence {
                    ref
                    data {
                        org {
                            ref
                            data {
                                ...MessageTemplateConnection_org
                                ...MessageTemplateBlock_org
                            }
                        }
                    }
                }
            }
        }
        ${SequenceDelay_FRAGMENTS.SequenceDelay_sequenceAction}
        ${MessageTemplateConnection_FRAGMENTS.MessageTemplateConnection_messageTemplate}
        ${MessageTemplateConnection_FRAGMENTS.MessageTemplateConnection_org}
        ${MessageTemplateBlock_FRAGMENTS.MessageTemplateBlock_org}
    `;

    const QUERIES = {};

    const MUTATIONS = {};
    MUTATIONS.updateSequenceAction = gql`
        mutation changeSequenceActionMessageStatus(
            $sequenceActionId: ID!
            $messageTemplateId: ID!
            $connectAction: Boolean!
        ) {
            changeSequenceActionMessageStatus(
                sequenceActionId: $sequenceActionId
                messageTemplateId: $messageTemplateId
                connectAction: $connectAction
            ) {
                ...EditSequenceActionItem_sequenceAction
            }
        }
        ${FRAGMENTS.EditSequenceActionItem_sequenceAction}
    `;
    MUTATIONS.createMessageTemplateToAction = gql`
        mutation createMessageTemplateToAction(
            $sequenceActionId: ID!
            $data: MessageTemplateInput!
        ) {
            createMessageTemplateToAction(
                sequenceActionId: $sequenceActionId
                data: $data
            ) {
                ...EditSequenceActionItem_sequenceAction
            }
        }
        ${FRAGMENTS.EditSequenceActionItem_sequenceAction}
    `;

    export {FRAGMENTS, QUERIES, MUTATIONS};
</script>

<script>
    import {createEventDispatcher} from 'svelte';
    import {getClient, mutation} from 'svelte-apollo';
    import Select from 'svelte-select';
    import _get from 'lodash/get';
    import _reduce from 'lodash/reduce';
    import Flipper from './Flipper.svelte';
    import SequenceDelay from './SequenceDelay.svelte';
    import EditSequenceDelay from './EditSequenceDelay.svelte';
    import MessageTemplateConnection from './MessageTemplateConnection.svelte';
    import MessageTemplateBlock from './MessageTemplateBlock.svelte';

    const client = getClient();
    const updateSequenceActionMutation = mutation(
        MUTATIONS.updateSequenceAction,
    );
    const createMessageTemplateToActionMutation = mutation(
        MUTATIONS.createMessageTemplateToAction,
    );
    const dispatch = createEventDispatcher();

    export let sequenceActionData;
    export let isNew = false;

    let isEditing = false;
    let handledIsNew = false;
    $: if (isNew && !handledIsNew) {
        isEditing = true;
        handledIsNew = true;
    }

    async function updateSequenceAction(
        sequenceActionId,
        messageTemplateId,
        connectAction,
    ) {
        try {
            // let response = await mutate(client, {
            //     mutation: MUTATIONS.updateSequenceAction,
            //     variables: {
            //         sequenceActionId: sequenceActionId,
            //         messageTemplateId: messageTemplateId,
            //         connectAction: connectAction,
            //     },
            // });
            let response = await updateSequenceActionMutation({
                variables: {
                    sequenceActionId: sequenceActionId,
                    messageTemplateId: messageTemplateId,
                    connectAction: connectAction,
                },
            });
            // client.writeFragment({
            //     id: id,
            //     fragment: FRAGMENTS.EditSequenceActionItem_sequenceAction,
            //     fragmentName: 'EditSequenceActionItem_sequenceAction',
            //     data: response.data.partialUpdateSequenceAction,
            // });
            sequenceActionData =
                response.data.changeSequenceActionMessageStatus;
        } catch (error) {
            // FIXME handle errors better...
            alert(error.message);
        }
    }

    $: defaultMessageTemplateMutationData = {
        org: sequenceActionData.data.sequence.data.org.ref,
        body: '',
        subjectLine: '',
        email: true,
        sms: true,
    };

    async function handleCreateMessage() {
        if (isSaving) return;
        isSaving = true;
        try {
            // let response = await mutate(client, {
            //     mutation: MUTATIONS.createMessageTemplateToAction,
            //     variables: {
            //         sequenceActionId: sequenceActionData.ref,
            //         data: defaultMessageTemplateMutationData,
            //     },
            // });
            let response = await createMessageTemplateToActionMutation({
                variables: {
                    sequenceActionId: sequenceActionData.ref,
                    data: defaultMessageTemplateMutationData,
                },
            });
            // client.writeFragment({
            //     id: sequenceActionData.ref,
            //     fragment: FRAGMENTS.EditSequenceActionItem_sequenceAction,
            //     fragmentName: 'EditSequenceActionItem_sequenceAction',
            //     data: response.data.createMessageTemplateToAction,
            // });
            sequenceActionData = response.data.createMessageTemplateToAction;
        } catch (error) {
            // FIXME handle errors better...
            alert(error.message);
        }

        isSaving = false;
    }
    async function handleDeleteAction() {
        if (
            confirm(
                'Confirm that you want to delete this action from the sequence.',
            )
        ) {
            dispatch('requestDelete');
        }
    }
    async function editSequenceDelay() {
        if (!sequenceActionData.ref) {
            dispatch('requestCreateDelay');
        }
        isEditing = true;
    }

    let isSaving = false;
    // async function handleCreateMessage() {
    //     if (isSaving) return;
    //     isSaving = true;
    //     await updateSequenceAction(sequenceActionData.ref, {
    //         message: {
    //             create: defaultMessageTemplateMutationData,
    //         },
    //     });
    //     isSaving = false;
    // }
    async function handleConnectMessage(messageTemplateId) {
        if (isSaving) return;
        isSaving = true;
        await updateSequenceAction(
            sequenceActionData.ref,
            messageTemplateId,
            true,
        );
        isSaving = false;
    }
    async function handleDisconnectMessage(messageTemplateId) {
        if (isSaving) return;
        isSaving = true;
        await updateSequenceAction(
            sequenceActionData.ref,
            messageTemplateId,
            false,
        );
        isSaving = false;
    }

    let sequenceDelayForm;
</script>

<div class="action">
    {#if sequenceActionData.data.type == 'DELAY'}
        <div class="action-icon">
            <ion-icon name="time" style="font-size:26px;" />
        </div>
        <div class="action-content">
            <header class="cols">
                <h2 class="flexcol">
                    {#if isEditing}
                        Edit Wait Period
                    {:else}
                        Wait Period
                    {/if}
                </h2>
                <div class="fixedcol tools">
                    {#if isEditing}
                        <button
                            type="button"
                            on:click|stopPropagation={() =>
                                sequenceDelayForm.saveForm()}
                            class="button"
                        >
                            <span class="btn-text"> Save </span>
                        </button>
                        <button
                            type="button"
                            class="button"
                            on:click|stopPropagation={() => {
                                isEditing = false;
                            }}
                        >
                            <span class="btn-text"> Cancel </span>
                        </button>
                    {:else}
                        <ion-icon
                            name="lock-closed-outline"
                            class="muted"
                            style="margin-right:10px; font-size:20px;"
                        />
                        <button
                            type="button"
                            class="button"
                            on:click|stopPropagation={editSequenceDelay}
                        >
                            <span class="btn-text"> Edit </span>
                        </button>
                        <button
                            type="button"
                            class="button danger"
                            on:click|stopPropagation={() =>
                                handleDeleteAction()}
                        >
                            <ion-icon
                                name="trash-outline"
                                class="btn-icon"
                                style="font-size:20px;"
                            />
                        </button>
                    {/if}
                </div>
            </header>
            <div style="margin-top:10px;" class:muted={isSaving}>
                <Flipper flipped={isEditing}>
                    <div slot="front" class="card sequence-delay">
                        <SequenceDelay {sequenceActionData} />
                    </div>
                    <div slot="back" class="card sequence-delay">
                        {#if isEditing}
                            <EditSequenceDelay
                                {sequenceActionData}
                                on:save={() => {
                                    isEditing = false;
                                }}
                                bind:this={sequenceDelayForm}
                            />
                        {/if}
                    </div>
                </Flipper>
            </div>
        </div>
    {:else if sequenceActionData.data.type == 'MESSAGE'}
        <div class="action-icon">
            <ion-icon name="chatbubble" style="margin-top:1px;" />
        </div>
        <div class="action-content">
            <header class="cols">
                <h2 class="flexcol">Send a Message</h2>
                <div class="fixedcol tools">
                    <button
                        type="button"
                        class="button danger"
                        on:click|stopPropagation={() => handleDeleteAction()}
                    >
                        <ion-icon
                            name="trash-outline"
                            class="btn-icon"
                            style="font-size:20px;"
                        />
                    </button>
                </div>
            </header>
            <div style="margin-top:10px;" class:muted={isSaving}>
                {#if _get(sequenceActionData, 'data.message')}
                    <MessageTemplateBlock
                        messageTemplateData={_get(
                            sequenceActionData,
                            'data.message',
                        )}
                        orgData={_get(
                            sequenceActionData,
                            'data.sequence.data.org',
                        )}
                    />
                {:else}
                    <MessageTemplateConnection
                        orgData={_get(
                            sequenceActionData,
                            'data.sequence.data.org',
                        )}
                        messageTemplateData={_get(
                            sequenceActionData,
                            'data.message',
                        )}
                        on:create={handleCreateMessage}
                        on:connect={async (event) =>
                            await handleConnectMessage(event.detail)}
                        on:disconnect={async (event) =>
                            await handleDisconnectMessage(event.detail)}
                    />
                {/if}
            </div>
        </div>
    {/if}
</div>

<style>
    .action {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
    .action-icon {
        flex-grow: 0;
        flex-shrink: 0;
        font-size: 24px;
        text-align: center;
        margin-top: 9px;
    }
    .action-content {
        padding: 3px 10px;
        flex-grow: 1;
        flex-shrink: 1;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }
    /* .action-content .message-container {
    margin: 10px 0 0;
}
.action-content .message {
    padding: 10px;
    background: var(--slate-o10);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    white-space: pre-wrap;
    max-width: 500px;
    border-radius: 5px;
    display: inline-block;
}
.action-content .message strong {
    font-weight: 600;
} */
    .cols {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
    }
    .fixedcol {
        flex-grow: 0;
        flex-shrink: 0;
    }
    .flexcol {
        flex-grow: 1;
        flex-shrink: 1;
    }
    h2 {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
    }
    .tools {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .card.sequence-delay {
        border: 1px solid var(--offoffwhite);
        border-radius: 9px;
        padding: 10px 20px;
    }
</style>
